<template>
  <div id="app">
    <router-view />
    <notifications group="status" position="bottom right" :duration="10000" />
    <upload-helper>
      <div class="image-upload-widget">Choose Profile Image</div>
    </upload-helper>
    <map-helper />
    <vue-confirm-dialog></vue-confirm-dialog>
    <div class="app-loader" v-if="$store.state.main_loader">
      <div class="loading-panel">
        <a-spin :spinning="true"></a-spin>
        <span class="text">Loading...</span>
      </div>
    </div>
    <AnnouncementModal></AnnouncementModal>
  </div>
</template>
<script>
import AnnouncementModal from "./components/modals/AnnouncementModal.vue";
export default {
  name: "app",
  components: {
    AnnouncementModal,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
body {
  padding: 0px;
  margin: 0px;
  overflow: hidden;
}
* {
  font-family: "Lato", sans-serif;
  outline: none;
}
button {
  cursor: pointer;
}
.app-loader {
  position: fixed;
  z-index: 9999999999999999999999999;
  bottom: 20px;
  right: 25px;
  .loading-panel {
    width: 174px;
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 10px 13px;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    .text {
      font-size: 19px;
      margin-left: 19px;
    }
  }
}
</style>
