import { render, staticRenderFns } from "./AffiliateWinnerWidget.vue?vue&type=template&id=2172400e&scoped=true"
import script from "./AffiliateWinnerWidget.vue?vue&type=script&lang=js"
export * from "./AffiliateWinnerWidget.vue?vue&type=script&lang=js"
import style0 from "./AffiliateWinnerWidget.vue?vue&type=style&index=0&id=2172400e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_react-dom@18.3.1_react@18.3.1_vue-temp_btlxz3i55afq2w4yuozfcdpdny/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2172400e",
  null
  
)

export default component.exports