<template>
  <div class="tags-input-widget">
    <div v-for="(item, index) in tags" :key="index" class="tag">
      <div class="tag-container">
        {{ item }}
        <button @click="removeTag(index)" class="remove-btn">&times;</button>
      </div>
    </div>
    <input
      placeholder="Ex: 20Kg,1GB"
      type="text"
      v-model="tag_text"
      @blur="addTag()"
      @keypress.enter="addTag()"
      class="tag-input"
    />
  </div>
</template>

<script>
export default {
  name: "TagsInput",
  components: {},
  data() {
    return {
      tags: [],
      tag_text: "",
    };
  },
  props: {
    value: {
      type: Array,
      default: [],
    },
  },
  watch: {
    value: {
      handler: function (data) {
        let vm = this;
        vm.tags = data;
      },
      deep: true,
    },
  },
  mounted() {
    let vm = this;
    vm.tags = vm.value;
  },
  methods: {
    addTag() {
      let vm = this;
      if (vm.tag_text) {
        vm.tags.push(vm.tag_text);
        vm.tag_text = "";
      }
      vm.$emit("input", vm.tags);
      vm.$emit("onChange", vm.tags);
    },
    removeTag(index) {
      let vm = this;
      vm.tags.splice(index, 1);
      vm.$emit("input", vm.tags);
      vm.$emit("onChange", vm.tags);
    },
  },
};
</script>

<style lang="scss" scoped>
.tags-input-widget {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 4px;
  min-height: 140px;
  display: inline-block;
  .tag {
    border-radius: 4px;
    background: #8dc73f;
    color: #fff;
    display: inline-block;
    margin-right: 5px;
    margin-top: 5px;
    height: 35px;
    .tag-container {
      display: flex;
      align-items: center;
      height: 35px;
      text-transform: capitalize;
      padding: 4px 4px;
      .remove-btn {
        border-radius: 50%;
        height: 21px;
        width: 21px;
        background: #fff;
        color: #000;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .tag-input {
    padding: 3px 3px;
    border: none !important;
    background: transparent !important;
    width: 100px;
    height: 35px;
    padding-left: 5px;
  }
}
</style>

