<template>
  <div class="tags-widget">
    <hooper :settings="hooperSettings" :itemsToSlide="1" :initialSlide="1">
      <template v-for="(item, i) in all_categories">
        <slide :key="i" v-if="$route.query.tag != item.category_name">
          <div
            class="tag-name"
            @click.prevent="
              $router.push({
                name: 'search',
                query: { tag: item.category_name },
              })
            "
          >
            {{ item.category_name }}
          </div>
        </slide>
      </template>
      <hooper-navigation slot="hooper-addons"></hooper-navigation>
    </hooper>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
export default {
  name: "home",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {
    return {
      all_categories: [],
      hooperSettings: {
        infiniteScroll: false,
        centerMode: false,
        autoPlay: false,
        playSpeed: 5000,
        breakpoints: {
          2400: {
            itemsToShow: 7,
          },
          1800: {
            itemsToShow: 6,
          },
          1500: {
            itemsToShow: 5,
          },
          1100: {
            itemsToShow: 4,
          },
          700: {
            itemsToShow: 3,
          },
          500: {
            itemsToShow: 2,
          },
          400: {
            itemsToShow: 2,
          },
          0: {
            itemsToShow: 2,
          },
        },
      },
    };
  },
  mounted() {
    let vm = this;
    vm.getAllCategories();
  },
  methods: {
    getAllCategories() {
      let vm = this;
      vm.$store
        .dispatch("PRODUCT_GET_ALL_CATEGORIES")
        .then((response) => {
          if (response.data.status == "ok") {
            vm.all_categories = response.data.return.splice(0, 30);
          }
        })
        .catch(({ response }) => {
          console.error("PRODUCT_GET_ALL_CATEGORIES", { response });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tags-widget {
  background: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px 10px;
  background: #fff;
  border-bottom: 1px solid #ddd;
}
</style>

