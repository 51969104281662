<template>
  <div class="story-widget">
    <hooper
      v-if="!loading_story"
      :settings="hooperSettings"
      :itemsToSlide="3"
      :initialSlide="1"
    >
      <template v-for="(item, index) in stories">
        <slide :key="index">
          <div @click="openStoryBySlide(index)" class="story-item">
            <div class="story-overlay"></div>
            <img
              :src="
                $store.state.ASSETS_BASE_URL + '/assets/uploaded/' + item.image
              "
              class="story-image"
            />
            <div class="show-info">
              <label>{{ item.title }}</label>
            </div>
          </div>
        </slide>
      </template>
      <hooper-navigation slot="hooper-addons"></hooper-navigation>
    </hooper>

    <div v-if="loading_story" class="story-loading-widget">
      <div class="story-loading-body">
        <div
          class="story-loading-item bubble"
          v-for="(item, index) in 3"
          :key="index"
        ></div>
      </div>
    </div>

    <!-- Story widget modal-->
    <transition name="fade">
      <div class="overlay" v-if="show_stories_modal">
        <div class="story-widget-modal">
          <div class="story-list-widget">
            <div class="story-list-header">
              <h1>All Stories</h1>
            </div>
            <div class="story-list-body">
              <vue-scroll>
                <div class="story-scoll-container">
                  <div
                    class="story-item"
                    v-for="(item, index) in stories"
                    :key="index"
                    :class="{ active: item.active }"
                    @click="selectStory(index)"
                  >
                    <div class="story-image">
                      <img
                        :src="
                          $store.state.ASSETS_BASE_URL +
                          '/assets/uploaded/' +
                          item.image
                        "
                      />
                    </div>
                    <div class="story-content">
                      <div class="story-title">{{ item.title }}</div>
                      <div class="story-description">
                        {{ item.description }}
                      </div>
                    </div>
                  </div>
                </div>
              </vue-scroll>
            </div>
            <div class="story-list-footer">
              <button @click="closeStoryModal()" class="btn-success btn-close">
                Close
              </button>
            </div>
          </div>
          <div class="story-preview-widget">
            <!-- story modal -->
            <div class="story-preview-modal">
              <template v-for="(story, index) in stories">
                <transition :key="index" name="slide-fade">
                  <div class="story-preview-container" v-if="story.active">
                    <div class="story-preview-header">
                      <div class="story-slider-widget">
                        <div
                          class="story-slider-item"
                          v-for="(story_item, index) in story.story_items"
                          :key="index"
                        >
                          <div
                            :style="{ width: story_item.progress + '%' }"
                            class="story-slider-progress-bar"
                          ></div>
                        </div>
                      </div>
                      <div class="story-item-widget">
                        <div class="story-item">
                          <div class="story-image">
                            <img
                              :src="
                                $store.state.ASSETS_BASE_URL +
                                '/assets/uploaded/' +
                                story.image
                              "
                            />
                          </div>
                          <div class="story-content">
                            <div class="story-title">{{ story.title }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <template v-for="(story_item, index) in story.story_items">
                      <transition :key="index" name="fade">
                        <div v-if="story_item.active">
                          <div class="story-preview-body">
                            <img
                              :src="story_item.image_base64"
                              class="story-preview-image"
                            />
                          </div>
                          <div class="story-preview-footer">
                            <button
                              v-if="story_item.type == 'product'"
                              @click="
                                $router
                                  .push({
                                    path: $getProductLink(story_item.product),
                                  })
                                  .catch(() => {})
                              "
                              class="btn-view-link"
                            >
                              Visit now
                            </button>
                            <button
                              v-if="story_item.type == 'shop'"
                              @click="
                                $router.push({
                                  path: $getShopLink(story_item.shop),
                                })
                              "
                              class="btn-view-link"
                            >
                              Open shop
                            </button>
                            <button
                              v-if="story_item.type == 'movie'"
                              @click="
                                $router
                                  .push({
                                    name: 'movie-player',
                                    params: {
                                      id: story_item.movie.id,
                                      type: 'act',
                                    },
                                  })
                                  .catch(() => {})
                              "
                              class="btn-view-link"
                            >
                              Watch now
                            </button>
                          </div>
                        </div>
                      </transition>
                    </template>
                  </div>
                </transition>
              </template>

              <!-- Story modal options -->
              <div class="story-modal-options">
                <button class="btn-prev" @click="prevStory()">
                  <img src="@/assets/images/icons/arrow-prev.svg" />
                </button>
                <button class="btn-next" @click="nextStory()">
                  <img src="@/assets/images/icons/arrow-next.svg" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- End Story widget modal-->
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
export default {
  name: "story-widget",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {
    return {
      hooperSettings: {
        infiniteScroll: true,
        centerMode: true,
        autoPlay: true,
        playSpeed: 10 * 1000,
        hoverPause: true,
        trimWhiteSpace: true,
        mouseDrag: false,
        touchDrag: false,
        wheelControl: false,
        breakpoints: {
          2400: {
            itemsToShow: 3,
          },
          1800: {
            itemsToShow: 3,
          },
          1500: {
            itemsToShow: 3,
          },
          1100: {
            itemsToShow: 3,
          },
          700: {
            itemsToShow: 3,
          },
          500: {
            itemsToShow: 3,
          },
          400: {
            itemsToShow: 2,
          },
          0: {
            itemsToShow: 2,
          },
        },
      },
      stories: [],
      story_load_timer: null,
      active_story_index: 0,
      active_index: 0,
      show_stories_modal: false,
      loading_story: false,
    };
  },
  mounted() {
    let vm = this;
    vm.getStoryData();
  },
  methods: {
    getStoryData() {
      let vm = this;
      vm.loading_story = true;
      vm.$store
        .dispatch("FEEDS_GET_ALL_STORIES")
        .then((response) => {
          vm.loading_story = false;
          vm.stories = response.data.return
            .map((item) => {
              item.story_items = item.story_items.map((story) => {
                vm.$store.state.eventBus.$set(story, "active", false);
                vm.$store.state.eventBus.$set(story, "progress", 0);
                return story;
              });
              return item;
            })
            .sort((a, b) => {
              return parseInt(b.prio) - parseInt(a.prio);
            });
        })
        .catch((error) => {
          console.error("FEEDS_GET_ALL_STORIES", { error });
        });
    },
    closeStoryModal() {
      let vm = this;
      vm.resetAllStory();
      clearInterval(vm.story_load_timer);
      vm.show_stories_modal = false;
    },
    openStoryBySlide(i) {
      let vm = this;
      vm.loadStories(i);
      vm.show_stories_modal = true;
    },
    closeOnStoryEnd(next_story_index) {
      let vm = this;
      if (vm.active_index == vm.stories.length - 1) {
        if (
          vm.stories[vm.active_index].story_items.length == next_story_index
        ) {
          clearInterval(vm.story_load_timer);
          vm.resetAllStory();
          vm.show_stories_modal = false;
        }
      }
    },
    nextStory() {
      let vm = this;
      vm.active_story_index += 1;
      let next_story_index = vm.active_story_index;
      vm.closeOnStoryEnd(next_story_index);
      clearInterval(vm.story_load_timer);
      if (vm.active_index < vm.stories.length) {
        if (vm.stories[vm.active_index].story_items.length > next_story_index) {
          // Prev
          vm.stories[vm.active_index].story_items[
            vm.active_story_index - 1
          ].active = false;
          vm.stories[vm.active_index].story_items[
            vm.active_story_index - 1
          ].progress = 100;

          // Next
          vm.stories[vm.active_index].story_items[
            next_story_index
          ].progress = 0;
          vm.stories[vm.active_index].story_items[
            next_story_index
          ].active = true;

          let loading_image = true;
          let url =
            vm.$store.state.ASSETS_BASE_URL +
            "/assets/uploaded/" +
            vm.stories[vm.active_index].story_items[next_story_index].image;
          vm.axios
            .get(url, {
              responseType: "arraybuffer",
            })
            .then((response) => {
              loading_image = false;
              let base64 =
                "data:image/png;base64, " +
                Buffer.from(response.data, "binary").toString("base64");
              vm.$store.state.eventBus.$set(
                vm.stories[vm.active_index].story_items[next_story_index],
                "image_base64",
                base64
              );
            })
            .catch((error) => {
              console.log(error);
              loading_image = true;
            });

          // Auto timer
          vm.story_load_timer = setInterval(() => {
            vm.closeOnStoryEnd(next_story_index);
            if (
              next_story_index < vm.stories[vm.active_index].story_items.length
            ) {
              if (!loading_image) {
                vm.stories[vm.active_index].story_items[
                  next_story_index
                ].progress += 0.095;
              }
              if (
                vm.stories[vm.active_index].story_items[next_story_index]
                  .progress > 100
              ) {
                vm.stories[vm.active_index].story_items[
                  next_story_index
                ].progress = 100;
                next_story_index += 1;
              }
            } else {
              vm.resetStory(vm.active_index);
              vm.loadStories(vm.active_index + 1);
            }
          }, 10);
        } else {
          vm.resetStory(vm.active_index);
          vm.loadStories(vm.active_index + 1);
        }
      }
    },
    prevStory() {
      let vm = this;
      vm.active_story_index -= 1;
      let next_story_index = 0;
      if (vm.active_story_index >= 0) {
        next_story_index = vm.active_story_index;
      } else {
        if (vm.active_index == 0) {
          vm.active_story_index = 0;
        }
      }
      clearInterval(vm.story_load_timer);
      if (vm.active_index >= 0) {
        if (0 <= next_story_index && vm.active_story_index >= 0) {
          // Prev
          vm.stories[vm.active_index].story_items[
            vm.active_story_index
          ].active = true;
          vm.stories[vm.active_index].story_items[
            vm.active_story_index
          ].progress = 0;

          // Next
          vm.stories[vm.active_index].story_items[
            next_story_index + 1
          ].progress = 0;
          vm.stories[vm.active_index].story_items[
            next_story_index + 1
          ].active = false;

          let loading_image = true;
          let url =
            vm.$store.state.ASSETS_BASE_URL +
            "/assets/uploaded/" +
            vm.stories[vm.active_index].story_items[next_story_index].image;
          vm.axios
            .get(url, {
              responseType: "arraybuffer",
            })
            .then((response) => {
              loading_image = false;
              let base64 =
                "data:image/png;base64, " +
                Buffer.from(response.data, "binary").toString("base64");

              vm.$store.state.eventBus.$set(
                vm.stories[vm.active_index].story_items[next_story_index],
                "image_base64",
                base64
              );
            })
            .catch((error) => {
              console.log(error);
              loading_image = true;
            });

          // Auto timer
          vm.story_load_timer = setInterval(() => {
            vm.closeOnStoryEnd(next_story_index);
            if (
              next_story_index < vm.stories[vm.active_index].story_items.length
            ) {
              if (!loading_image) {
                vm.stories[vm.active_index].story_items[
                  next_story_index
                ].progress += 0.095;
              }
              if (
                vm.stories[vm.active_index].story_items[next_story_index]
                  .progress > 100
              ) {
                vm.stories[vm.active_index].story_items[
                  next_story_index
                ].progress = 100;

                next_story_index += 1;
              }
            } else {
              vm.resetStory(vm.active_index);
              vm.loadStories(vm.active_index + 1);
            }
          }, 10);
        } else {
          if (vm.active_index > 0) {
            vm.resetStory(vm.active_index);
            vm.loadStories(vm.active_index - 1);
          }
        }
      }
    },
    selectStory(index) {
      let vm = this;
      vm.resetAllStory();
      vm.loadStories(index);
    },
    loadStories(story_index) {
      let vm = this;
      vm.active_story_index = 0;
      vm.active_index = story_index;
      if (vm.story_load_timer) {
        clearInterval(vm.story_load_timer);
      }
      if (story_index < vm.stories.length) {
        vm.stories[story_index].active = true;
        vm.openStory(vm.stories[story_index], story_index);
      }
    },
    openStory(story, index) {
      let vm = this;
      if (index < vm.stories.length) {
        story.story_items[vm.active_story_index].active = true;
        let loading_image = true;
        let url =
          vm.$store.state.ASSETS_BASE_URL +
          "/assets/uploaded/" +
          story.story_items[vm.active_story_index].image;
        vm.axios
          .get(url, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            loading_image = false;
            let base64 =
              "data:image/png;base64, " +
              Buffer.from(response.data, "binary").toString("base64");

            vm.$store.state.eventBus.$set(
              story.story_items[vm.active_story_index],
              "image_base64",
              base64
            );
          })
          .catch((error) => {
            console.log(error);
            loading_image = true;
          });
        vm.story_load_timer = setInterval(() => {
          vm.closeOnStoryEnd(vm.active_story_index);
          if (vm.active_story_index < story.story_items.length) {
            if (!loading_image) {
              story.story_items[vm.active_story_index].progress += 0.095;
            }

            if (story.story_items[vm.active_story_index].progress > 100) {
              story.story_items[vm.active_story_index].progress = 100;
              vm.active_story_index += 1;
              if (vm.active_story_index < story.story_items.length) {
                clearInterval(vm.story_load_timer);
                vm.openStory(story, index);
              }
            }
          } else {
            let next_index = index + 1;
            vm.resetStory(index);
            vm.loadStories(next_index);
          }
        }, 10);
      }
    },
    resetAllStory() {
      let vm = this;
      vm.stories.forEach((story) => {
        story.active = false;
        story.story_items.forEach((item) => {
          item.active = false;
          item.progress = 0;
        });
      });
    },
    resetStory(index) {
      let vm = this;
      if (vm.stories.length > index) {
        vm.stories[index].active = false;
        vm.stories[index].story_items.forEach((item) => {
          item.active = false;
          item.progress = 0;
        });
      }
    },
  },
};
</script>

<style lang="scss" >
.story-widget {
  background: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px 10px;
  background: #fff;
  width: 100%;
  @media screen and (max-width: 800px) {
    display: none;
  }
  .bubble {
    animation: bubble-animation 1s infinite;
    transition: 500 ease-in-out;
  }
  @keyframes bubble-animation {
    0% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  .story-loading-widget {
    width: 100%;
    display: flex;
    .story-loading-body {
      width: 100%;
      display: grid;
      grid-template-columns: 32.333% 32.333% 32.333%;
      grid-gap: 8px;
      .story-loading-item {
        height: 280px;
        border: 1px solid #ccc;
        background: #ddd;
        border-radius: 5px;
      }
    }
  }
  .overlay {
    background: rgba(0, 0, 0, 0.9) !important;
    z-index: 10000001;
    .story-widget-modal {
      display: grid;
      grid-template-columns: 350px calc(100% - 350px);
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      .story-list-widget {
        height: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
        padding-top: 70px;
        .story-list-header {
          height: 60px;
          border-bottom: 1px solid #ddd;
          padding: 0px 15px;
          display: flex;
          align-items: center;
          h1 {
            margin: 0px;
            font-size: 23px;
          }
        }
        .story-list-body {
          height: calc(100% - 120px);
          overflow-y: auto;
          .story-scoll-container {
            padding: 10px 10px;
            padding-right: 20px;
            .story-item {
              display: flex;
              align-items: center;
              padding: 8px 10px;
              cursor: pointer;
              margin-bottom: 6px;
              &.active {
                background: rgb(243, 243, 243);
                border-radius: 5px;
              }
              &:hover {
                background: rgb(243, 243, 243);
                border-radius: 5px;
              }
              .story-image {
                width: 60px;
                img {
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
                  object-fit: cover;
                  border: 3px solid #ccc;
                  padding: 4px;
                }
              }
              .story-content {
                width: calc(100% - 60px);
                padding: 5px 10px;
                .story-title {
                  color: #000000;
                  font-size: 16px;
                }
                .story-description {
                  color: #777777;
                  font-size: 13px;
                }
              }
            }
          }
        }
        .story-list-footer {
          height: 60px;
          border-top: 1px solid #ddd;
          padding: 0px 15px;
          display: flex;
          align-items: center;
          .btn-close {
            width: 100%;
          }
        }
      }
      .story-preview-widget {
        display: flex;
        justify-content: center;
        padding-top: 90px;
        padding-bottom: 20px;
        height: 100%;
        .story-preview-modal {
          position: relative;
          width: 500px;
          background: rgba(0, 0, 0, 0.5);
          height: 100%;
          border-radius: 5px;
          .story-preview-container {
            position: relative;
            width: 100%;
            height: 100%;
            .story-preview-header {
              position: absolute;
              left: 0px;
              right: 0px;
              top: 0px;
              z-index: 99;
              height: 123px;
              background: black;
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.676908) 30%,
                rgba(0, 0, 0, 0) 100%
              );
              display: flex;
              flex-direction: column;
              padding: 8px 10px;
              .story-slider-widget {
                display: flex;
                align-items: center;
                margin: 10px 0px;
                .story-slider-item {
                  width: 50%;
                  height: 5px;
                  background: #9c9c9c;
                  margin: 0px 4px;
                  .story-slider-progress-bar {
                    width: 0%;
                    height: 5px;
                    background: #ececec;
                  }
                }
              }
              .story-item-widget {
                .story-item {
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  .story-image {
                    width: 60px;
                    img {
                      width: 60px;
                      height: 60px;
                      border-radius: 50%;
                      object-fit: cover;
                      border: 3px solid #ccc;
                      padding: 4px;
                    }
                  }
                  .story-content {
                    width: calc(100% - 60px);
                    padding: 5px 10px;
                    .story-title {
                      color: #fff;
                      font-size: 16px;
                    }
                  }
                }
              }
            }
            .story-preview-body {
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              .story-preview-image {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 5px;
              }
            }
            .story-preview-footer {
              position: absolute;
              left: 0px;
              right: 0px;
              bottom: 0px;
              z-index: 99;
              height: 100px;
              background: black;
              background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.676908) 2%,
                rgba(0, 0, 0, 0) 100%
              );
              display: flex;
              align-items: center;
              justify-content: center;
              .btn-view-link {
                width: 300px;
                height: 45px;
                border: 1px solid #fff;
                color: #fff;
                border-radius: 5px;
                background: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                  border: 1px solid #8dc73f;
                  color: #8dc73f;
                  background: #fff;
                }
              }
            }
          }

          // Options
          .story-modal-options {
            .btn-next,
            .btn-prev {
              position: absolute;
              top: 45%;
              z-index: 999;
              border: 1px solid #fff;
              height: 40px;
              width: 40px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #8dc73f;
              transition: 300ms ease-in-out;
              &:hover {
                transition: 300ms ease-in-out;
                transform: scale(1.2, 1.2);
              }
              img {
                width: 6px;
              }
            }
            .btn-next {
              right: -80px;
            }
            .btn-prev {
              left: -80px;
            }
          }
        }
      }
    }
  }
}
</style>

