<template>
  <div class="progress-container">
    <div :style="{ width: value + '%' }" class="progress-bar"></div>
  </div>
</template>

<script>
export default {
  name: "movie-progress",
  components: {},
  props: {
    value: {
      type: String,
      default: 0,
    },
  },
  data() {
    return {
      progress: 0,
    };
  },
  mounted() {
    let vm = this;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.progress-container {
  display: flex;
  align-items: center;
  background: #ccc;
  .progress-bar {
    height: 6px;
    background: #8dc73f;
  }
  &.error {
    .progress-bar {
      background: #c73f3f;
    }
  }
}
</style>

