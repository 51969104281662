<template>
  <div class="cagura-wrapper">
    <!-- Navbar -->
    <client-header />
    <!-- Navbar -->
    <div class="cagura-container">
      <!-- Side bar -->
      <side-bar />
      <!-- End of sidebar -->
      <!-- Content - body -->
      <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
        <div class="cagura-products-widget">
          <vue-scroll @handle-scroll="handlePostScroll">
            <div class="under-scoll-container-widget">
              <div class="fit-container">
                <div class="home-section">
                  <div class="feeds">
                    <feedPost></feedPost>
                  </div>
                  <div class="cagura-sp-content">
                    <!-- Notify user section  -->
                    <Announcement></Announcement>
                    <!-- End of notification -->
                    <div class="cagura-sp-widget">
                      <div class="cagura-sp-header">
                        <h4>Stories</h4>
                      </div>
                      <div class="cagura-sp-body">
                        <Story></Story>
                      </div>
                    </div>
                    <!-- Products -->
                    <div class="cagura-sp-widget">
                      <div class="cagura-sp-header">
                        <h4>Popular Products</h4>
                        <button
                          @click.prevent="
                            $router.push({ name: 'products' }).catch(() => {})
                          "
                          class="btn-see-all"
                        >
                          See All
                        </button>
                      </div>
                      <div class="cagura-sp-body">
                        <popularProduts></popularProduts>
                      </div>
                    </div>
                    <div class="stick-container">
                      <!-- Shops -->
                      <div class="cagura-sp-widget">
                        <div class="cagura-sp-header">
                          <h4>Popular Shops</h4>
                        </div>
                        <div class="cagura-sp-body">
                          <popularShops></popularShops>
                        </div>
                      </div>
                      <!-- Ads -->
                      <Adsense
                        data-ad-client="ca-pub-8242653303172643"
                        data-ad-slot="4073025340"
                      >
                      </Adsense>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
      <!-- End of content -->
    </div>
  </div>
</template>

<script>
import ClientHeader from "../components/partials/header.vue";
import SideBar from "../components/partials/sidebar.vue";
import Announcement from "./feeds/Announcement.vue";
import feedPost from "./feeds/feedPost.vue";
import popularProduts from "./feeds/popularProduts.vue";
import Story from "./feeds/Story.vue";
import popularShops from "./feeds/popularShops.vue";

export default {
  name: "home",
  components: {
    ClientHeader,
    SideBar,
    Announcement,
    feedPost,
    popularProduts,
    Story,
    popularShops,
  },
  data() {
    return {};
  },
  head: {
    title: function () {
      return {
        inner: "Cagura - Feeds",
      };
    },
  },
  mounted() {
    let vm = this;
    vm.$emit("updateHead");
  },
  methods: {
    handlePostScroll(vertical, horizontal, nativeEvent) {
      let vm = this;
      if (
        nativeEvent.target.scrollTop + 5 >
        nativeEvent.target.scrollHeight - nativeEvent.target.offsetHeight
      ) {
        vm.$store.state.eventBus.$emit("onPostScoll", null);
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
    display: block;
    .cagura-products-widget {
      .home-section {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: calc(98% - 500px) 500px;
        width: 100%;
        @media screen and (max-width: 800px) {
          grid-template-columns: 100%;
        }
        .feed {
          padding: 0px;
        }
        .cagura-sp-content {
          padding-top: 10px;
          padding-right: 15px;
          @media screen and (max-width: 800px) {
            display: none;
          }
          .stick-container {
            width: 100%;
            position: sticky;
            top: 10px;
          }
          .cagura-sp-widget {
            width: 100%;
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 5px;
            margin-bottom: 30px;

            .cagura-sp-header {
              padding-top: 20px;
              padding-left: 20px;
              padding-right: 20px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              h4 {
                font-size: 14px;
                margin: 0px;
              }
              .btn-see-all {
                border: none;
                outline: none;
                background: fixed;
              }
            }
            .cagura-sp-body {
              padding-top: 20px;
              padding-left: 10px;
              padding-right: 10px;
              padding-bottom: 20px;
            }
          }
        }
      }
    }
  }
}
</style>

