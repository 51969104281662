<template>
  <div class="cagura-wrapper">
    <!-- Navbar -->
    <client-header />
    <!-- Navbar -->
    <div class="cagura-container">
      <!-- Side bar -->
      <side-bar />
      <!-- End of sidebar -->
      <!-- Content - body -->
      <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
        <div class="cagura-products-widget">
          <div class="notification-widget-header">
            <button
              class="btn-back"
              @click="$router.push({ name: 'notification' }).catch(() => {})"
            >
              <img src="@/assets/images/icons/arrow-left.svg" />
            </button>
            <h3>NOTIFICATION</h3>
          </div>
          <vue-scroll>
            <div class="under-scoll-container-widget">
              <div class="notification-fit-container">
                <div class="notification-widget">
                  <!-- notification list -->
                  <div
                    class="body"
                    v-if="$getLoggedUser() && !is_loading && notification_item"
                  >
                    <ReviewWidget
                      :item="notification_item"
                      v-if="
                        notification_item.notification_type ==
                          'product_review' && notification_item.user
                      "
                      @remove="removeNotification()"
                    ></ReviewWidget>
                    <OrderWidget
                      :item="notification_item"
                      v-if="
                        notification_item.notification_type ==
                          'product_order' && notification_item.user
                      "
                      @remove="removeNotification()"
                    ></OrderWidget>
                    <shopMessageWidget
                      :item="notification_item"
                      v-if="
                        notification_item.notification_type == 'shop_message' &&
                        notification_item.user
                      "
                      @remove="removeNotification()"
                    ></shopMessageWidget>
                    <postMessageWidget
                      :item="notification_item"
                      v-if="
                        notification_item.notification_type == 'post_contact' &&
                        notification_item.user
                      "
                      @remove="removeNotification()"
                    ></postMessageWidget>
                    <CommentLikeWidget
                      :item="notification_item"
                      v-if="
                        notification_item.notification_type == 'comment_like' &&
                        notification_item.user
                      "
                      @remove="removeNotification()"
                    ></CommentLikeWidget>
                    <PostLikeWidget
                      :item="notification_item"
                      v-if="
                        notification_item.notification_type == 'post_like' &&
                        notification_item.user
                      "
                      @remove="removeNotification()"
                    ></PostLikeWidget>
                    <PostCommentWidget
                      :item="notification_item"
                      v-if="
                        notification_item.notification_type == 'post_comment' &&
                        notification_item.user
                      "
                      @remove="removeNotification()"
                    ></PostCommentWidget>
                    <CommentReplyWidget
                      :item="notification_item"
                      v-if="
                        notification_item.notification_type ==
                          'comment_reply' && notification_item.user
                      "
                      @remove="removeNotification()"
                    ></CommentReplyWidget>
                    <AffiliateWinnerWidget
                      :item="notification_item"
                      v-if="
                        notification_item.notification_type ==
                          'affiliate_winner' && notification_item.user
                      "
                      @remove="removeNotification()"
                    ></AffiliateWinnerWidget>
                  </div>
                  <!-- When no notification -->

                  <div
                    class="body"
                    v-if="$getLoggedUser() && !is_loading && !notification_item"
                  >
                    <div class="not-logged-widget">
                      <img
                        src="@/assets/images/icons/notification-not-logged.svg"
                      />
                      <p>Notification not found</p>
                    </div>
                  </div>
                  <!-- Loading widget -->
                  <div class="body" v-if="is_loading">
                    <div class="notification-loading-widget">
                      <div class="notification-loading-header pulse"></div>
                      <div class="notification-loading-body pulse"></div>
                      <div class="notification-loading-footer">
                        <div
                          class="notification-loading-footer-option pulse"
                        ></div>
                        <div
                          class="notification-loading-footer-option pulse"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <!-- When you are not logged in -->
                  <div class="body" v-if="!$getLoggedUser() && !is_loading">
                    <div class="not-logged-widget">
                      <img
                        src="@/assets/images/icons/notification-not-logged.svg"
                      />
                      <p>
                        Please login to see your notification, click on button
                        below to login
                      </p>
                      <button
                        class="btn-default btn-sign-in"
                        @click="$router.push({ name: 'login' }).catch(() => {})"
                      >
                        SIGN IN
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
      <!-- End of content -->
    </div>
  </div>
</template>

<script>
import ClientHeader from "../components/partials/header.vue";
import SideBar from "../components/partials/sidebar.vue";
import ReviewWidget from "../views/notification/ReviewWidget.vue";
import OrderWidget from "../views/notification/OrderWidget.vue";
import shopMessageWidget from "../views/notification/shopMessageWidget.vue";
import postMessageWidget from "./notification/postMessageWidget.vue";
import CommentLikeWidget from "../views/notification/CommentLikeWidget.vue";
import PostLikeWidget from "../views/notification/PostLikeWidget.vue";
import PostCommentWidget from "../views/notification/PostCommentWidget.vue";
import CommentReplyWidget from "../views/notification/CommentReplyWidget.vue";
import AffiliateWinnerWidget from "../views/notification/AffiliateWinnerWidget.vue";
export default {
  name: "home",
  components: {
    ClientHeader,
    SideBar,
    ReviewWidget,
    OrderWidget,
    shopMessageWidget,
    postMessageWidget,
    CommentLikeWidget,
    PostLikeWidget,
    PostCommentWidget,
    AffiliateWinnerWidget,
    CommentReplyWidget,
  },
  data() {
    return {
      notification_item: null,
      is_unreaded_message: false,
      is_loading: false,
    };
  },
  head: {
    title: function () {
      return {
        inner: "Cagura - Notification",
      };
    },
  },
  mounted() {
    let vm = this;
    vm.$emit("updateHead");
    if (vm.$getLoggedUser()) {
      if (vm.$route.params.id) {
        vm.getAllNotificationItem(vm.$route.params.id);
      }
    }
  },
  methods: {
    removeNotification() {
      let vm = this;
      vm.$router.push({ name: "notification" }).catch(() => {});
    },
    getAllNotificationItem(id) {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("NOTIFICATION_GET_NOTIFICATION_ITEM", {
          id: id,
        })
        .then((response) => {
          vm.is_loading = false;
          vm.notification_item = response.data.return;
          if (
            vm.notification_item.post &&
            vm.notification_item.post.file_type == "image"
          ) {
            vm.$store.state.eventBus.$set(
              vm.notification_item.post,
              "images",
              JSON.parse(vm.notification_item.post.files)
            );
          }
          if (vm.notification_item.product) {
            vm.$store.state.eventBus.$set(
              vm.notification_item.product,
              "images",
              JSON.parse(vm.notification_item.product.other_images)
            );
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("NOTIFICATION_GET_NOTIFICATION_ITEM", { response });
        });
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
    display: block;
    .cagura-products-widget {
      .notification-widget-header {
        border-bottom: 1px solid #ddd;
        background: #fff;
        height: 50px;
        display: flex;
        align-items: center;
        .btn-back {
          background: transparent;
          border: none;
        }
        h3 {
          margin: 0px;
          padding-left: 10px;
        }
      }
      .notification-widget {
        padding-bottom: 50px;
        padding-top: 10px;
        .body {
          .not-logged-widget {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img {
              width: 300px;
              margin-bottom: 30px;
            }
            p {
              text-align: center;
            }
            .btn-sign-in {
              margin-top: 30px;
            }
          }
          .notification-loading-widget {
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 5px;
            padding: 15px;
            margin-bottom: 20px;
            .notification-loading-header {
              background: rgb(240, 240, 240);
              height: 25px;
              border-radius: 3px;
              margin-bottom: 20px;
            }
            .notification-loading-body {
              background: rgb(240, 240, 240);
              height: 180px;
              border-radius: 3px;
              margin-bottom: 20px;
            }
            .notification-loading-footer {
              display: grid;
              grid-template-columns: 48% 48%;
              grid-gap: 20px;
              .notification-loading-footer-option {
                background: rgb(240, 240, 240);
                height: 25px;
                border-radius: 3px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

