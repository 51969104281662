var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"story-widget"},[(!_vm.loading_story)?_c('hooper',{attrs:{"settings":_vm.hooperSettings,"itemsToSlide":3,"initialSlide":1}},[_vm._l((_vm.stories),function(item,index){return [_c('slide',{key:index},[_c('div',{staticClass:"story-item",on:{"click":function($event){return _vm.openStoryBySlide(index)}}},[_c('div',{staticClass:"story-overlay"}),_c('img',{staticClass:"story-image",attrs:{"src":_vm.$store.state.ASSETS_BASE_URL + '/assets/uploaded/' + item.image}}),_c('div',{staticClass:"show-info"},[_c('label',[_vm._v(_vm._s(item.title))])])])])]}),_c('hooper-navigation',{attrs:{"slot":"hooper-addons"},slot:"hooper-addons"})],2):_vm._e(),(_vm.loading_story)?_c('div',{staticClass:"story-loading-widget"},[_c('div',{staticClass:"story-loading-body"},_vm._l((3),function(item,index){return _c('div',{key:index,staticClass:"story-loading-item bubble"})}),0)]):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.show_stories_modal)?_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"story-widget-modal"},[_c('div',{staticClass:"story-list-widget"},[_c('div',{staticClass:"story-list-header"},[_c('h1',[_vm._v("All Stories")])]),_c('div',{staticClass:"story-list-body"},[_c('vue-scroll',[_c('div',{staticClass:"story-scoll-container"},_vm._l((_vm.stories),function(item,index){return _c('div',{key:index,staticClass:"story-item",class:{ active: item.active },on:{"click":function($event){return _vm.selectStory(index)}}},[_c('div',{staticClass:"story-image"},[_c('img',{attrs:{"src":_vm.$store.state.ASSETS_BASE_URL +
                        '/assets/uploaded/' +
                        item.image}})]),_c('div',{staticClass:"story-content"},[_c('div',{staticClass:"story-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"story-description"},[_vm._v(" "+_vm._s(item.description)+" ")])])])}),0)])],1),_c('div',{staticClass:"story-list-footer"},[_c('button',{staticClass:"btn-success btn-close",on:{"click":function($event){return _vm.closeStoryModal()}}},[_vm._v(" Close ")])])]),_c('div',{staticClass:"story-preview-widget"},[_c('div',{staticClass:"story-preview-modal"},[_vm._l((_vm.stories),function(story,index){return [_c('transition',{key:index,attrs:{"name":"slide-fade"}},[(story.active)?_c('div',{staticClass:"story-preview-container"},[_c('div',{staticClass:"story-preview-header"},[_c('div',{staticClass:"story-slider-widget"},_vm._l((story.story_items),function(story_item,index){return _c('div',{key:index,staticClass:"story-slider-item"},[_c('div',{staticClass:"story-slider-progress-bar",style:({ width: story_item.progress + '%' })})])}),0),_c('div',{staticClass:"story-item-widget"},[_c('div',{staticClass:"story-item"},[_c('div',{staticClass:"story-image"},[_c('img',{attrs:{"src":_vm.$store.state.ASSETS_BASE_URL +
                              '/assets/uploaded/' +
                              story.image}})]),_c('div',{staticClass:"story-content"},[_c('div',{staticClass:"story-title"},[_vm._v(_vm._s(story.title))])])])])]),_vm._l((story.story_items),function(story_item,index){return [_c('transition',{key:index,attrs:{"name":"fade"}},[(story_item.active)?_c('div',[_c('div',{staticClass:"story-preview-body"},[_c('img',{staticClass:"story-preview-image",attrs:{"src":story_item.image_base64}})]),_c('div',{staticClass:"story-preview-footer"},[(story_item.type == 'product')?_c('button',{staticClass:"btn-view-link",on:{"click":function($event){_vm.$router
                                .push({
                                  path: _vm.$getProductLink(story_item.product),
                                })
                                .catch(() => {})}}},[_vm._v(" Visit now ")]):_vm._e(),(story_item.type == 'shop')?_c('button',{staticClass:"btn-view-link",on:{"click":function($event){_vm.$router.push({
                                path: _vm.$getShopLink(story_item.shop),
                              })}}},[_vm._v(" Open shop ")]):_vm._e(),(story_item.type == 'movie')?_c('button',{staticClass:"btn-view-link",on:{"click":function($event){_vm.$router
                                .push({
                                  name: 'movie-player',
                                  params: {
                                    id: story_item.movie.id,
                                    type: 'act',
                                  },
                                })
                                .catch(() => {})}}},[_vm._v(" Watch now ")]):_vm._e()])]):_vm._e()])]})],2):_vm._e()])]}),_c('div',{staticClass:"story-modal-options"},[_c('button',{staticClass:"btn-prev",on:{"click":function($event){return _vm.prevStory()}}},[_c('img',{attrs:{"src":require("@/assets/images/icons/arrow-prev.svg")}})]),_c('button',{staticClass:"btn-next",on:{"click":function($event){return _vm.nextStory()}}},[_c('img',{attrs:{"src":require("@/assets/images/icons/arrow-next.svg")}})])])],2)])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }