<template>
  <div class="cagura-main-navbar">
    <div class="cagura-nav-brand">
      <button class="menu-btn" @click="menuClick()">
        <img src="@/assets/images/icons/main-menu.svg" />
      </button>
      <img
        v-if="type == 'normal'"
        @click="$router.push({ name: 'home' }).catch(() => {})"
        src="@/assets/images/logo.png"
        alt="Cagura Logo"
        class="cagura-logo"
      />
      <img
        v-if="type == 'shopping'"
        style="margin-left: 0.15px"
        src="@/assets/images/shopping-logo.svg"
        alt="Cagura Logo"
        class="cagura-logo"
      />
      <img
        v-if="type == 'live'"
        style="margin-left: 0.15px"
        src="@/assets/images/cagura-live-logo.svg"
        alt="Cagura Logo"
        class="cagura-logo"
      />
      <img
        v-if="type == 'movie'"
        style="margin-left: 0.15px"
        src="@/assets/images/cagura-movies-logo.svg"
        alt="Cagura Logo"
        class="cagura-logo"
      />
    </div>

    <div class="search-widget" :class="{ show: toggle_search }">
      <div class="search-input" ref="search-widget">
        <input
          type="text"
          name="search"
          placeholder="Search Products"
          v-model="search_input"
          @keypress.enter="searchAll()"
        />
        <button class="search-btn">
          <img src="@/assets/images/icons/search.svg" @click="searchAll()" />
        </button>
      </div>
      <div
        class="search-result"
        v-if="show_result && auto_complete_result.length > 0"
        v-closable="{ exclude: ['profile-action'], handler: 'hideSearch' }"
      >
        <vue-scroll>
          <div
            class="search-result-item"
            v-for="(item, i) in auto_complete_result"
            :key="i"
            @click="
              $router.push({
                name: 'search',
                query: { q: item.text, from: item.name ? 'app' : 'typing' },
              }),
                hideSearch()
            "
          >
            <span v-if="item.name"
              ><b>{{ item.name }}:</b></span
            >
            {{ item.text }}
          </div>
        </vue-scroll>
      </div>
    </div>

    <div class="cagura-nav-options">
      <div class="option-item">
        <button class="btn-search" @click="toggle_search = !toggle_search">
          <img src="@/assets/images/icons/search-grey.svg" alt />
        </button>
      </div>
      <div class="option-item">
        <button class="btn-add-product" @click="show_add_product_modal = true">
          <img src="@/assets/images/icons/plus.svg" alt />
        </button>
      </div>
      <div class="option-item">
        <span
          class="notification-alert-dot"
          v-if="show_notification_status"
        ></span>
        <button
          class="btn-notification"
          @click="$router.push({ name: 'notification' })"
        >
          <img src="@/assets/images/icons/ring.svg" />
        </button>
      </div>
      <div class="option-item">
        <CaguraApps>
          <img src="@/assets/images/icons/apps-menu.svg" alt />
        </CaguraApps>
      </div>
      <profile> <span>SIGN IN</span></profile>
    </div>
    <!-- Add product modal -->
    <transition name="fadeanim">
      <add-product
        v-if="show_add_product_modal"
        @onSubmit="onProductSubmit()"
        @onClose="show_add_product_modal = false"
      />
    </transition>
  </div>
</template>

<script>
import AddProduct from "../../components/modals/AddProduct.vue";
import Profile from "../../components/partials/profile.vue";
export default {
  name: "client-header",
  props: {
    type: {
      type: String,
      default: "normal",
    },
  },
  components: {
    AddProduct,
    Profile,
  },
  data() {
    return {
      show_result: false,
      show_add_product_modal: false,
      toggle_search: false,
      search_input: "",
      search_movies_input: "",
      auto_complete_result: [],
      show_notification_status: false,
      add_product_audio: null,
      navigation_type: "",
    };
  },
  mounted() {
    let vm = this;
    if (vm.$route.name == "movies-search") {
      vm.toggle_search = true;
      setTimeout(() => {
        document.querySelector("#movie_search").focus();
      }, 300);
    }
    if (vm.$getLoggedUser()) {
      vm.checkNotifiationStatus();
      setInterval(() => {
        vm.checkNotifiationStatus();
      }, 1000 * 30);
    }
  },
  watch: {
    search_input(val) {
      let vm = this;
      if (val) {
        vm.getSearchAutComplete(val);
      } else {
        vm.show_result = false;
      }
    },
    search_movies_input(val) {
      let vm = this;
      if (val) {
        vm.$emit("onSearch", val);
      } else {
        vm.show_result = false;
      }
    },
  },
  methods: {
    checkNotifiationStatus() {
      let vm = this;
      vm.$store
        .dispatch("NOTIFICATION_CHECK_STATUS")
        .then((response) => {
          vm.show_notification_status = response.data.return;
        })
        .catch(({ response }) => {
          console.error("NOTIFICATION_CHECK_STATUS", { response });
        });
    },
    searchAll() {
      let vm = this;
      if (vm.search_input.length > 0) {
        vm.$router.push({
          name: "search",
          query: { q: vm.search_input, from: "typing" },
        });
        vm.search_input = "";
        vm.hideSearch();
      } else {
        vm.$notify({
          group: "status",
          type: "warn",
          title: "OOPS!!!",
          text: "Type something...",
        });
      }
    },
    getSearchAutComplete(val) {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("GET_SEARCH_TEXT", { query: val })
        .then((response) => {
          vm.is_loading = false;
          if (response.data.status == "ok") {
            vm.auto_complete_result = response.data.return.slice(0, 10);
            if (vm.auto_complete_result.length > 0) {
              vm.show_result = true;
            } else {
              vm.show_result = false;
            }
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("GET_SEARCH_TEXT", response);
        });
    },
    onProductSubmit() {
      let vm = this;
      vm.show_add_product_modal = false;
    },
    menuClick() {
      let vm = this;
      vm.$store.dispatch("TOGGLE_SIDE_BAR", !vm.$store.getters.getSideBar);
    },
    hideSearch() {
      let vm = this;
      vm.show_result = false;
      vm.auto_complete_result = [];
      vm.search_input = "";
    },
    searchAllMovies() {
      let vm = this;
      if (vm.search_input.length > 0) {
        vm.$emit("onSearch", vm.search_input);
        vm.toggle_search = false;
      } else {
        vm.$notify({
          group: "status",
          type: "warn",
          title: "OOPS!!!",
          text: "Type something...",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-main-navbar {
  border-bottom: 1px solid #ddd;
  height: 70px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background: #fff;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
  transition: 800ms ease-in-out;
  &.header_sleep {
    top: -70px;
    transition: 800ms ease-in-out;
  }
  @media screen and (max-width: 600px) {
    padding-left: 2px;
  }
  .cagura-nav-brand {
    display: flex;
    align-items: center;
    width: 30%;
    .menu-btn {
      border: none;
      background: transparent;
      height: 50px;
      display: block;
      padding-right: 16px;
      padding-left: 10px;
      @media screen and (max-width: 500px) {
        margin-left: 0px;
        padding-right: 13px;
        padding-left: 10px;
      }
      @media screen and (max-width: 330px) {
        margin-right: 3px;
      }
    }
    .cagura-logo {
      width: 146px;
      margin-left: -14px;
      display: block;
      @media screen and (max-width: 500px) {
        display: block;
        width: 121px;
      }
      @media screen and (max-width: 330px) {
        width: 110px;
      }
    }
  }
  .search-widget {
    position: relative;
    width: 40%;
    @media screen and (max-width: 800px) {
      width: 100%;
      position: absolute;
      top: 69px;
      background: #fff;
      left: 0px;
      right: 0px;
      display: none;
      z-index: 9999;
      padding: 12px 8px;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      &.show {
        display: block !important;
      }
    }

    .search-input {
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #dddddd;
      border-radius: 5px;
      @media screen and (max-width: 1040px) {
        width: 100%;
      }
      @media screen and (max-width: 800px) {
        width: 100%;
      }
      input {
        width: 100%;
        height: 32px;
        padding: 0px 15px;
        outline: none;
        border: none;
      }
      .search-btn {
        height: 40px;
        border: none;
        background: #8dc73f;
        width: 71px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
    .search-result {
      top: 40px;
      width: 100%;
      height: 300px;
      background: #fff;
      position: absolute;
      left: 0px;
      right: 0px;
      border: 1px solid #ccc;
      border-radius: 5px;
      .search-result-item {
        padding: 12px 10px;
        border-bottom: 1px solid #ddd;
        color: #000;
        cursor: pointer;
        text-transform: capitalize;
        &:hover {
          background: rgb(254, 254, 254);
        }
      }
    }
  }
  .cagura-nav-options {
    display: flex;
    align-items: center;
    width: 30%;
    justify-content: flex-end;
    .option-item {
      margin-left: 20px;
      position: relative;
      @media screen and (max-width: 500px) {
        margin-left: 10px;
      }
      @media screen and (max-width: 330px) {
        margin-left: 7px;
      }
      .btn-add-product {
        height: 31px;
        width: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 5px;
        background: #8dc73f;
        color: #fff;
        font-weight: 800;
        @media screen and (max-width: 500px) {
          height: 30px;
          width: 42px;
        }
        @media screen and (max-width: 330px) {
          height: 25px;
          width: 25px;
          img {
            width: 15px;
          }
        }
        img {
          margin-right: 0px;
        }
        &:before {
          content: "";
          background: linear-gradient(
            45deg,
            #ff0000,
            #ff7300,
            #fffb00,
            #48ff00,
            #00ffd5,
            #002bff,
            #7a00ff,
            #ff00c8,
            #ff0000
          );
          position: absolute;
          top: 0px;
          left: 0px;
          background-size: 400%;
          z-index: -1;
          filter: blur(20px);
          width: calc(100% + 0px);
          height: calc(100% + 0px);
          animation: glowing 15s linear infinite;
          opacity: 1;
          transition: opacity 0.3s ease-in-out;
          border-radius: 10px;
        }

        &:active {
          color: #000;
        }

        &:active:after {
          background: transparent;
        }

        &:hover:before {
          opacity: 1;
        }

        &:after {
          z-index: -1;
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border-radius: 10px;
        }
      }
      .btn-search {
        background: black;
        border: 1px solid #8dc73f;
        height: 32px;
        width: 47px;
        border-radius: 5px;
        display: none;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 800px) {
          display: flex;
        }
        @media screen and (max-width: 530px) {
          display: none;
        }
        img {
          width: 19px;
        }
      }
      .btn-notification {
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 5px;
        background: transparent;
        color: #fff;
        font-weight: 800;
      }
      .notification-alert-dot {
        position: absolute;
        top: -1px;
        height: 10px;
        width: 10px;
        animation: babo 500ms infinite;
        background: #ff3e3e;
        right: 6px;
        border-radius: 50%;
      }
    }
  }
}

.cagura-movie-header-widget {
  .cagura-navbar {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 70px;
    z-index: 999999;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 10px;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.676908263305322) 2%,
      rgba(0, 0, 0, 0) 100%
    );
    @media screen and (max-width: 700px) {
      background: #000;
      align-items: center;
      padding-top: 0px;
      padding-right: 15px;
      padding-left: 15px;
      height: 67px;
    }

    .cagura-nav-brand {
      display: flex;
      align-items: center;
      width: 200px;
      .menu-btn {
        background: transparent;
        border: none;
        display: none;
        @media screen and (max-width: 700px) {
          display: block;
        }
      }
      .cagura-logo {
        width: 146px;
        display: block;
        @media screen and (max-width: 500px) {
          display: block;
          width: 121px;
        }
      }
    }

    .cagura-nav-options {
      display: flex;
      align-items: center;
      width: calc(100% - 200px);
      justify-content: flex-end;
      .option-item {
        margin-left: 20px;
        position: relative;
        @media screen and (max-width: 500px) {
          margin-left: 0px;
        }

        .menu-list {
          @media screen and (max-width: 700px) {
            display: none;
          }
          ul {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 0px;
            padding-left: 0px;
            li {
              color: #fff;
              text-decoration: none;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-right: 20px;
              cursor: pointer;
              &:hover {
                color: #8dc73f;
              }
              &.active {
                color: #8dc73f;
              }
            }
          }
        }
        .search-widget {
          position: relative;
          width: 600px;
          @media screen and (max-width: 1300px) {
            width: 370px;
          }
          @media screen and (max-width: 1000px) {
            display: none;
          }
          &.show {
            @media screen and (max-width: 1000px) {
              width: 100%;
              position: fixed;
              display: flex;
              left: 0px;
              right: 0px;
              top: 56px;
              background: #000;
              padding: 4px 8px;
            }
          }
          .search-input {
            height: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #414141;
            border-radius: 5px;
            background: #0006;
            border-right: none;
            input {
              width: 100%;
              height: 32px;
              padding: 0px 15px;
              outline: none;
              border: none;
              background: transparent;
              color: #ccc;
              padding-bottom: 4px;
            }
            .search-btn {
              height: 40px;
              border: none;
              background: #8dc73f;
              width: 71px;
              border-bottom-right-radius: 5px;
              border-top-right-radius: 5px;
            }
          }
          .search-result {
            top: 40px;
            width: 100%;
            height: 300px;
            background: #fff;
            position: absolute;
            left: 0px;
            right: 0px;
            border: 1px solid #ccc;
            border-radius: 5px;
            .search-result-item {
              padding: 12px 10px;
              border-bottom: 1px solid #ddd;
              color: #000;
              cursor: pointer;
              text-transform: capitalize;
              &:hover {
                background: rgb(254, 254, 254);
              }
            }
          }
        }
        .search-btn-phone {
          background: transparent;
          border: none;
          display: none;
          @media screen and (max-width: 1000px) {
            display: block;
          }
        }
        .btn-notification {
          height: 35px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          border-radius: 5px;
          background: transparent;
          color: #fff;
          font-weight: 800;
        }
        .notification-alert-dot {
          position: absolute;
          top: -1px;
          height: 10px;
          width: 10px;
          animation: babo 500ms infinite;
          background: #ff3e3e;
          right: 6px;
          border-radius: 50%;
        }
      }
    }
  }
  .cagura-sidebar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 999999999;
    display: none;
    flex-direction: row;
    justify-content: space-between;
    border-top: 2px solid #232323;
    height: 60px;
    background: #151515;
    padding: 0px 15px;
    @media screen and (max-width: 500px) {
      display: flex;
      align-items: center;
    }
    .menu-widget {
      width: 100%;
      ul {
        padding-left: 0px;
        margin: 0px;
        list-style: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        li {
          display: flex;
          align-items: center;
          color: #fff;
          text-decoration: none;
          font-size: 13px;
          font-weight: 500;
          font-size: 13px;
          flex-direction: column;
          justify-content: center;
          span {
            font-size: 11px;
            color: #fff;
            margin-top: 6px;
          }
          &:hover {
            span {
              color: #8dc73f;
            }
          }
          &.active {
            span {
              color: #8dc73f;
            }
          }
        }
      }
    }
  }
}
</style>
