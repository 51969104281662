<template>
  <div class="products-widget-wrapper">
    <div v-if="!popular_product_loading" class="products-widget-body">
      <div class="product-item" v-for="(item, i) in products_data" :key="i">
        <div class="product-announcement success" v-if="item.discount">
          {{ item.discount }}% OFF
        </div>
        <div
          class="product-image"
          @click="$router.push({ path: $getProductLink(item) }).catch(() => {})"
        >
          <img
            :src="
              $store.state.ASSETS_BASE_URL +
              '/assets/uploaded/' +
              item.main_image
            "
          />
        </div>
        <div class="product-content">
          <h4>{{ item.product_name }}</h4>
          <p>
            FRW
            {{
              item.discount
                ? $formatMoney(item.price - (item.price * item.discount) / 100)
                : $formatMoney(item.price)
            }}
          </p>
          <div
            class="shop-viewer"
            v-if="item.shop"
            @click="$router.push({ path: $getShopLink(item.shop) })"
          >
            <img
              class="shop-image"
              :src="
                $store.state.ASSETS_BASE_URL +
                '/assets/uploaded/' +
                item.shop.shop_logo
              "
            />
            <div class="shop-content">
              <label class="shop-name">{{ item.shop.shop_name }}</label>
              <span class="shop-location">{{ item.shop.location_name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="popular_product_loading" class="product-loading-widget">
      <div class="product-loading-body">
        <div
          class="product-loading-item bubble"
          v-for="(item, index) in 4"
          :key="index"
        >
          <div class="product-loading-image"></div>
          <div class="product-loading-content">
            <div class="product-loading-title"></div>
            <div class="product-loading-price"></div>
          </div>
          <div class="product-loading-shop"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "popular-products",
  components: {},
  data() {
    return {
      products_data: [],
      popular_product_loading: false,
    };
  },
  mounted() {
    let vm = this;
    vm.getPopularProducts();
  },
  methods: {
    getPopularProducts() {
      let vm = this;
      vm.popular_product_loading = true;
      vm.$store
        .dispatch("FEEDS_ALL_POPULAR_PRODUCTS")
        .then((response) => {
          vm.popular_product_loading = false;
          if (response.data.status == "ok") {
            vm.products_data = response.data.return.sort((a, b) => {
              return parseInt(b.prio) - parseInt(a.prio);
            });
          }
        })
        .catch(({ response }) => {
          console.error("FEEDS_ALL_POPULAR_PRODUCTS", response);
        });
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.products-widget-wrapper {
  .products-widget-body {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-gap: 10px;
    justify-content: space-between;
    @media screen and (max-width: 800px) {
      display: none;
    }
    @media screen and (max-width: 500px) {
      grid-template-columns: 49% 49% !important;
      grid-gap: 6px;
    }
    .product-item {
      position: relative;
      cursor: pointer;
      transition: 500ms ease-in-out;
      border: 1px solid #ddd;
      background: #fff;
      padding: 8px;
      border-radius: 5px;
      &:hover {
        background: #fff;
        border-radius: 5px;
        transition: 500ms ease-in-out;
      }
      .product-announcement {
        position: absolute;
        top: 7px;
        left: 7px;
        padding: 7px 8px;
        font-size: 12px;
        color: #fff;
        font-weight: 800;
        border-radius: 5px;
        &.success {
          background: #8dc73f;
        }
        &.danger {
          background: #ff0000;
        }
      }
      .product-image {
        background: #ddd;
        img {
          width: 100%;
          object-fit: cover;
          height: 200px;
          @media screen and (max-width: 500px) {
            height: 160px !important;
          }
        }
      }
      .product-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        h4,
        p {
          margin-bottom: 5px;
          margin-top: 5px;
        }
        h4 {
          text-transform: uppercase;
          font-size: 12px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          -webkit-line-clamp: 2;
          line-height: 17px;
          max-height: 4.4rem;
          @media screen and (max-width: 500px) {
            font-size: 11px;
            margin-bottom: 0px !important;
            margin-top: 5px !important;
          }
        }
        p {
          font-size: 13px;
          @media screen and (max-width: 500px) {
            font-size: 10px;
            margin-bottom: 5px !important;
            margin-top: 0px !important;
          }
        }
        .shop-viewer {
          width: 100%;
          padding: 7px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          background: #eaeaea;
          cursor: pointer;
          border: 1px solid #eaeaea;
          &:hover {
            border: 1px solid #ddd;
          }
          .shop-image {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            object-fit: cover;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            margin-right: 10px;
            border: 2px solid #ddd;
            background: #fff;
            @media screen and (max-width: 500px) {
              width: 25px;
              height: 25px;
              margin-right: 6px;
            }
          }
          .shop-content {
            display: flex;
            flex-direction: column;
            .shop-name {
              color: #000;
              font-size: 15px;
              overflow: hidden;
              text-decoration: none;
              text-overflow: ellipsis;
              white-space: nowrap;
              @media screen and (max-width: 500px) {
                font-size: 12px;
              }
            }
            .shop-location {
              color: #858585;
              font-size: 13px;
              overflow: hidden;
              text-decoration: none;
              text-overflow: ellipsis;
              white-space: nowrap;
              @media screen and (max-width: 500px) {
                font-size: 9px;
              }
            }
          }
        }
      }
    }
  }

  .bubble {
    animation: bubble-animation 1s infinite;
    transition: 500 ease-in-out;
  }
  @keyframes bubble-animation {
    0% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  .product-loading-widget {
    width: 100%;
    display: flex;
    .product-loading-body {
      width: 100%;
      display: grid;
      grid-template-columns: 48% 48%;
      grid-gap: 8px;
      .product-loading-item {
        height: 220px;
        border: 1px solid #ccc;
        background: #ddd;
        border-radius: 5px;
      }
    }
  }
}
</style>