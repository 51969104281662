<template>
  <div class="cagura-wrapper">
    <!-- Navbar -->
    <client-header />
    <!-- Navbar -->
    <div class="cagura-container">
      <!-- Side bar -->
      <transition
        name="bounce"
        enter-active-class="bounceInLeft"
        leave-active-class="bounceOutLeft"
      >
        <side-bar v-if="$store.state.sidebar" />
      </transition>
      <!-- End of sidebar -->
      <!-- Content - body -->
      <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
        <vue-scroll>
          <div class="cagura-help-widget">
            <div class="help-widget">
              <div class="help-widget-header">
                <div class="call-actions">
                  <button
                    class="btn-back"
                    @click="$router.push({ name: 'home' }).catch(() => {})"
                  >
                    <img src="@/assets/images/icons/arrow-left.svg" /> TERMS &
                    CONDITION
                  </button>
                </div>
              </div>
              <!-- BODY -->
              <div class="content-widget">
                <h3>
                  PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY! PLEASE READ
                  THIS AGREEMENT CAREFULLY BEFORE ACCESSING OR USING THE WEBSITE
                  OR OUR APPLICATION.
                </h3>
                <h3>
                  BY ACCESSING OR USING ANY PART OF THE WEBSITE, YOU AGREE TO BE
                  BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU DO
                  NOT AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT, THEN
                  YOU MAY NOT ACCESS THE WEBSITE. THE WEBSITE IS AVAILABLE ONLY
                  TO INDIVIDUALS WHO ARE AT LEAST 13 YEARS OLD
                </h3>
                <p>
                  By accepting this Agreement, you agree not to use, or
                  facilitate others to use, the Website in a way that is harmful
                  to others or overburdens or otherwise impairs the Website.
                  Examples of such use include, without limitation:
                </p>
                <ul>
                  <li>Engaging in illegal or fraudulent activities</li>
                  <li>
                    Infringing a third party’s intellectual property rights
                  </li>
                  <li>
                    Distributing harmful or offensive Content that is
                    defamatory, obscene, abusive, an invasion of privacy or
                    harassing
                  </li>
                  <li>The distribution of malicious/commercial spam</li>
                  <li>
                    or Violating the security or integrity of any computer,
                    network or communications system
                  </li>
                  <li>
                    Using the Website to mine or demonstrate proof-of-work for a
                    cryptocurrency or blockchain
                  </li>
                </ul>
                <h4 class="title">Seller and Buyer Agreement</h4>
                <p>
                  We only join seller and buyer so they can do business
                  themselves, that why they have to be careful to avoid some
                  conflict ,if happens Cagura don’t provide any help.
                </p>
                <p>Note this:</p>
                <ul>
                  <li>For now, we don’t accept payment on buying products</li>
                  <li>Buyer must buy to the Trusted seller</li>
                </ul>
                <h4 class="title">Affiliate Agreement</h4>
                <p>
                  An Affiliate Agreement is a document through which two
                  parties, the company and the affiliate, form a relationship
                  whereby the affiliate receives funds for certain qualified
                  actions. Online affiliate agreements can take one of two
                  forms:
                </p>
                <ul>
                  <li>
                    Affiliate agreements whereby the affiliate receives funds
                    for user clicks through to the company's website
                  </li>
                  <li>
                    Affiliate agreements whereby the affiliate receives funds
                    for each shared link that reaches to the target number. For
                    the company's goods or services.
                  </li>
                </ul>
                <p>This program is intended for Rwandans only.</p>
                <p>
                  Cagura wants to make sure that views are coming from real
                  people (not robots or bots). That’s why a Cagura view is only
                  counted when the following criteria apply:
                </p>
                <ul>
                  <li>
                    If user goes on link for at least 30 seconds and above.
                  </li>
                  <li>
                    If the views are not coming from wrong users (robot,bots)
                  </li>
                  <li>
                    If the user are following all required instructions(Having
                    Account on Cagura and Accept this agreement)
                  </li>
                </ul>
                <p>
                  Affiliate Agreements are very common nowadays, as many
                  individuals who choose to work online can make significant
                  income from affiliate relationships, depending on the reach of
                  their websites or social media. Many users on big social media
                  platforms contract with companies to promote products or
                  services. When the company receives clicks or purchases from
                  the affiliate's individualized links, the affiliate then
                  receives a set amount per link’s click target.
                </p>
                <div class="sub-content">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/98gFqEoDcJg"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <p>
                  We decide number on winner from 1 to above, Only the first one
                  is the one who reaches to the target number we need before
                  others In these types of agreements, the affiliate most often
                  does not get a say in the specific terms. The affiliate simply
                  signs up for the program that the company is offering and
                  there is no bargaining over the agreement. These agreements
                  are not signed, they are simply posted up on the company's
                  website for the affiliate to read and agree to prior to
                  signing up for the affiliate program.
                </p>

                <h4 class="title">Refund Policies</h4>
                <p>
                  For now we don't accept payment, if something changed you will
                  be notified through your account
                </p>

                <h4 class="title">Shopping order cancellation policy</h4>
                <p>
                  For now we don't accept payment on product being sold on our
                  site, in the future if something changed this secion will be
                  changed too, you will be notified.
                </p>
                <h4 class="title">Terminations of agreement</h4>
                <p>
                  Cagura shall have a right to close the user's access to our
                  Application with immediate effect if the user
                </p>
                <ul>
                  <li>
                    Uses Cagura in a way constituting a criminal act or contrary
                    to applicable law (especially personal data protection
                    legislation)
                  </li>
                  <li>
                    Uses Cagura in a way constituting a risk of damage for
                    Cagura or a third party
                  </li>
                  <li>Does not pay Subscription Fees or other charges</li>
                </ul>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
      <!-- End of content -->
    </div>
  </div>
</template>

<script>
import ClientHeader from "../../components/partials/header.vue";
import SideBar from "../../components/partials/sidebar.vue";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
export default {
  name: "about",
  components: {
    ClientHeader,
    SideBar,
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {
    return {
      tabs: {
        properties: true,
        reviews: false,
      },
    };
  },
  methods: {
    goPropertyTab() {
      let vm = this;
      vm.tabs.properties = true;
      vm.tabs.reviews = false;
    },
    goReviewTab() {
      let vm = this;
      vm.tabs.properties = false;
      vm.tabs.reviews = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
    .cagura-body {
      .cagura-help-widget {
        height: 100%;
        padding-bottom: 50px;
        .help-widget {
          padding: 0px 20px;
          .help-widget-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0px;
            .call-actions {
              .btn-back {
                height: 36px;
                border: none;
                border-radius: 5px;
                background: transparent;
                outline: none;
                display: flex;
                align-items: center;
                img {
                  margin-right: 15px;
                }
              }
            }
          }
          .content-widget {
            display: flex;
            flex-direction: column;
            width: 800px;
            background: #fff;
            padding: 25px;
            border-radius: 5px;
            @media screen and (max-width: 800px) {
              width: 100%;
            }
            .title {
              color: #8cc73f;
              font-weight: 800;
              font-size: 20px;
              display: block;
              padding-bottom: 4px;
              margin-bottom: 10px;
              margin-bottom: 4px;
            }
            .sub-title {
              color: #000;
              font-weight: 800;
              font-size: 16px;
              display: block;
              padding-bottom: 4px;
              margin-bottom: 6px;
              width: 300px;
            }
            p {
              color: rgb(69, 69, 69);
              margin-bottom: 8px;
              margin-top: 3px;
            }
            .sub-content {
              padding-left: 30px;
              margin-bottom: 20px;
            }
            .link {
              color: #8cc73f;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>

