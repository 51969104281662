export const GET_ALL_MOVIES = "GET_ALL_MOVIES";
export const GET_MAIN_MOVIE = "GET_MAIN_MOVIE";
export const GET_MOVIE_INFO = "GET_MOVIE_INFO";
export const GET_MOVIE_EPISODES = "GET_MOVIE_EPISODES";
export const GET_MOVIE_ITEM_TO_PLAY = "GET_MOVIE_ITEM_TO_PLAY";
export const ADD_MOVIE_MYLIST = "ADD_MOVIE_MYLIST";
export const GET_MYLIST_MOVIES = "GET_MYLIST_MOVIES";
export const SEARCH_MOVIES = "SEARCH_MOVIES"
export const LIKE_MOVIE = "LIKE_MOVIE";
export const UNLIKE_MOVIE = "UNLIKE_MOVIE";
export const SAVE_MOVIE_WACTCHING_HISTORY = "SAVE_MOVIE_WACTCHING_HISTORY";
export const GET_WATCHING_HISTORY = "GET_WATCHING_HISTORY";
export const REMOVE_WATCHING_HISTORY = "REMOVE_WATCHING_HISTORY";
export const SUBMIT_MOVIE_FEEDBACK = "SUBMIT_MOVIE_FEEDBACK";
export const MOVIE_SUBSCRIBE = "MOVIE_SUBSCRIBE";
export const MOVIE_CHECK_PAYMENT = "MOVIE_CHECK_PAYMENT";
export const STREAMING_GET_MAIN_CHANNEL = "STREAMING_GET_MAIN_CHANNEL";
export const STREAMING_GET_ALL_CHANNEL = "STREAMING_GET_ALL_CHANNEL";
export const MOVIES_GET_ALL_CATEGORIZED_MOVIES = "MOVIES_GET_ALL_CATEGORIZED_MOVIES";