<template>
  <div class="cagura-wrapper">
    <!-- Navbar -->
    <client-header />
    <!-- Navbar -->
    <div class="cagura-container">
      <!-- Side bar -->
      <side-bar />
      <!-- End of sidebar -->
      <!-- Content - body -->
      <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
        <vue-scroll>
          <div class="cagura-help-widget">
            <div class="help-widget">
              <div class="help-widget-header">
                <div class="call-actions">
                  <button
                    class="btn-back"
                    @click="$router.push({ name: 'home' }).catch(() => {})"
                  >
                    <img src="@/assets/images/icons/arrow-left.svg" /> HOME
                  </button>
                </div>
              </div>
              <!-- BODY -->
              <div class="content-widget">
                <h1 class="main-text">
                  Page you are trying to find not found !!!
                </h1>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
      <!-- End of content -->
    </div>
  </div>
</template>

<script>
import ClientHeader from "../components/partials/header.vue";
import SideBar from "../components/partials/sidebar.vue";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
export default {
  name: "about",
  components: {
    ClientHeader,
    SideBar,
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {
    return {
      tabs: {
        properties: true,
        reviews: false,
      },
    };
  },
  methods: {
    goPropertyTab() {
      let vm = this;
      vm.tabs.properties = true;
      vm.tabs.reviews = false;
    },
    goReviewTab() {
      let vm = this;
      vm.tabs.properties = false;
      vm.tabs.reviews = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
    .cagura-body {
      .cagura-help-widget {
        height: 100%;
        padding-bottom: 50px;
        .help-widget {
          padding: 0px 20px;
          .help-widget-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0px;
            .call-actions {
              .btn-back {
                height: 36px;
                border: none;
                border-radius: 5px;
                background: transparent;
                outline: none;
                display: flex;
                align-items: center;
                img {
                  margin-right: 15px;
                }
              }
            }
          }
          .content-widget {
            .main-text {
              text-align: center;
              font-size: 40px;
            }
          }
        }
      }
    }
  }
}
</style>

