<template>
  <div class="cagura-wrapper">
    <div class="cagura-nav-bar">
      <img
        src="@/assets/images/logo.png"
        alt="Cagura Logo"
        @click.prevent="$router.push({ name: 'home' }).catch(() => {})"
        class="cagura-logo"
      />
    </div>
    <div class="body">
      <div class="register-wrapper">
        <div class="image-section">
          <img src="@/assets/images/register-img.svg" alt />
        </div>
        <div class="register-section">
          <div class="register-header">
            <h2>Hello there, Create a new account</h2>
          </div>
          <div class="form-widget">
            <div class="tab-body">
              <div class="help-text">
                <b>N.B</b>: Phone and Email will be used by customers to reach
                you out
              </div>
              <div class="twin-input">
                <div class="input-group">
                  <div class="input-widget">
                    <input
                      type="text"
                      class="bg-cagura-form-control"
                      name="text"
                      placeholder="First Name"
                      v-model="form.first_name"
                    />
                  </div>
                </div>
                <div class="input-group">
                  <div class="input-widget">
                    <input
                      type="text"
                      class="bg-cagura-form-control"
                      name="text"
                      placeholder="Last Name"
                      v-model="form.last_name"
                    />
                  </div>
                </div>
              </div>
              <div class="twin-input">
                <div class="input-group">
                  <div class="input-widget">
                    <input
                      type="text"
                      class="bg-cagura-form-control"
                      name="phone"
                      placeholder="Phone Number"
                      v-model="form.phone_number"
                    />
                  </div>
                </div>
                <div class="input-group">
                  <div class="input-widget">
                    <input
                      type="text"
                      class="bg-cagura-form-control"
                      name="email"
                      placeholder="Email(Optional)"
                      v-model="form.email"
                    />
                  </div>
                </div>
              </div>
              <div class="twin-input">
                <div class="input-group">
                  <div class="">
                    <VuePassword
                      v-model="form.password"
                      placeholder="Password"
                      class="password-input"
                      :disableStrength="true"
                      type="password"
                    />
                  </div>
                </div>
                <div class="input-group">
                  <div class="">
                    <VuePassword
                      v-model="form.confirm_password"
                      placeholder="Confirm password"
                      class="password-input"
                      :disableStrength="true"
                      type="password"
                    />
                  </div>
                </div>
              </div>
              <div class="terms-input">
                <input type="checkbox" v-model="form.terms" id="term" />
                <label for="term">
                  Before Create Account Please Read and Accept our
                  <a
                    href="/help/terms"
                    @click.prevent="
                      $router.push({ name: 'terms' }).catch(() => {})
                    "
                    >Terms and Conditions</a
                  >
                  and our
                  <a
                    href="/help/terms"
                    @click.prevent="
                      $router.push({ name: 'privacy' }).catch(() => {})
                    "
                    >Privacy Policy</a
                  >
                </label>
              </div>
              <div class="form-option">
                <c-button
                  class="register-btn"
                  :loading="is_loading"
                  @click="createAccount()"
                  >CREATE ACCOUNT</c-button
                >
                <div class="have-account-section">
                  <p>
                    Got an account:
                    <a
                      @click.prevent="
                        $router.push({ name: 'login' }).catch(() => {})
                      "
                      >SIGN IN</a
                    >
                  </p>
                </div>
              </div>
              <div class="other-singup-laber">Or Sign up with:</div>
              <div class="other-register-option">
                <div class="option" @click="registerWithSocialMedia('google')">
                  <img src="@/assets/images/google.svg" class="logo" alt />
                  <span class="name">GOOGLE</span>
                </div>
                <div
                  class="option"
                  @click="registerWithSocialMedia('facebook')"
                >
                  <img src="@/assets/images/facebook.svg" class="logo" alt />
                  <span class="name">FACEBOOK</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import VuePassword from "vue-password";
export default {
  name: "cagura-register",
  components: {
    VuePassword,
  },
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        password: "",
        terms: true,
        confirm_password: "",
        user_type: "sale",
        type: "create",
      },
      is_loading: false,
    };
  },
  mounted() {
    let vm = this;
    if (vm.$getLoggedUser()) {
      vm.$router.push({ name: "home" }).catch(() => {});
    }
  },
  methods: {
    setUserType(type) {
      let vm = this;
      vm.form.user_type = type;
    },
    registerWithSocialMedia(type) {
      let vm = this;
      vm.loading = true;
      vm.$localStorage.set("cagura_user_type", vm.form.user_type);
      if (type == "google") {
        vm.$store
          .dispatch("ACCOUNT_GOOGLE_AUTH", { type: "register" })
          .then((response) => {
            vm.loading = false;
            let url = response.data.url;
            window.location.href = url;
          })
          .catch(({ response }) => {
            vm.loading = false;
            console.log("ACCOUNT_GOOGLE_AUTH", response);
          });
      } else if (type == "facebook") {
        vm.$store
          .dispatch("ACCOUNT_FACEBOOK_AUTH", { type: "register" })
          .then((response) => {
            vm.loading = false;
            let url = response.data.url;
            window.location.href = url;
          })
          .catch(({ response }) => {
            vm.loading = false;
            console.log("ACCOUNT_FACEBOOK_AUTH", response);
          });
      }
    },
    getLocation(href) {
      var l = document.createElement("a");
      l.href = href;
      return l;
    },
    createAccount() {
      let vm = this;
      if (vm.form.terms) {
        vm.is_loading = true;
        if (vm.form.password == vm.form.confirm_password) {
          vm.$store
            .dispatch("CREATE_ACCOUNT", vm.form)
            .then((response) => {
              vm.is_loading = false;
              if (vm.$localStorage.get("redirection_app")) {
                vm.accountCallBackUrl(
                  vm.$localStorage.get("redirection_app"),
                  response.data.access_token
                );
              } else {
                let user = {
                  access_token: response.data.access_token,
                  user: response.data.user,
                };
                vm.$saveLoginInfo(user);
                vm.$appRedirect();
                vm.$notify({
                  group: "status",
                  type: "success",
                  title: "Important message",
                  text: response.data.message,
                });
              }
            })
            .catch(({ response }) => {
              vm.is_loading = false;
              vm.$notify({
                group: "status",
                type: "warn",
                title: "OOPS!!!",
                text: response.data.message,
              });
              console.log("CREATE_ACCOUNT", response);
            });
        } else {
          vm.is_loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: "Password does not match",
          });
        }
      } else {
        vm.$notify({
          group: "status",
          type: "warn",
          title: "OOPS!!!",
          text: "Please accept our Terms and Conditions",
        });
      }
    },
    accountCallBackUrl(app, access_token) {
      let vm = this;
      vm.loading = true;
      vm.$store
        .dispatch("ACCOUNT_URL_CALLBACK", {
          app: app,
        })
        .then((response) => {
          vm.$localStorage.remove("redirection_app");
          if (response.data.url) {
            window.location.replace(
              response.data.url + "?code=" + access_token
            );
          }
        })
        .catch(({ response }) => {
          vm.loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
          console.log("ACCOUNT_LOGIN", response);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  padding: 30px 0px;
  overflow: auto;
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgb(243, 245, 247);
  @media screen and (max-width: 1040px) {
    overflow-y: auto;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
  .cagura-nav-bar {
    padding: 0px 40px;
    @media screen and (max-width: 1040px) {
      display: flex;
      justify-content: center;
    }
    .cagura-logo {
      width: 200px;
      margin-left: -10px;
      @media screen and (max-width: 1040px) {
        margin-left: -26px;
      }
    }
  }
  .body {
    margin-top: 30px;
    padding: 0px 40px;
    @media screen and (max-width: 1040px) {
      display: flex;
      justify-content: center;
      padding: 0px 0px;
    }
    .register-wrapper {
      display: grid;
      grid-template-columns: 46% 46%;
      grid-column-gap: 40px;
      @media screen and (max-width: 1040px) {
        grid-template-columns: 100%;
        width: 388px;
      }
      .image-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @media screen and (max-width: 1040px) {
          display: none;
        }
        img {
          width: 100%;
        }
      }
      .register-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: -60px;
        @media screen and (max-width: 1040px) {
          margin-top: 0px;
        }
        .register-header {
          h2 {
            font-size: 30px;
            color: #8dc73f;
            margin-top: -11px;
            text-align: center;
            @media screen and (max-width: 1040px) {
              font-size: 18px;
              text-align: center;
            }
          }
        }
        .form-widget {
          border: 1px solid #ddd;
          border-radius: 4px;
          background: rgb(249, 249, 249);
          @media screen and (max-width: 1040px) {
            padding: 10px 0px;
            border: none;
          }
          .tabs {
            display: grid;
            grid-template-columns: 50% 50%;
            .tab {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 50px;
              font-weight: 800;
              font-size: 18px;
              background: #ddd;
              cursor: pointer;
              &.active {
                background: #fff;
                border-bottom: 3px solid #8dc73f;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
              }
            }
          }
          .tab-body {
            padding: 50px 60px;
            @media screen and (max-width: 1040px) {
              padding: 10px 17px;
            }
            .help-text {
              margin-bottom: 10px;
            }

            .form-option {
              display: flex;
              justify-content: space-between;
              align-items: center;
              @media screen and (max-width: 1040px) {
                flex-direction: column;
                justify-content: flex-start;
              }
              .register-btn {
                width: 154px;
                height: 45px;
                background: #8dc73f;
                border-radius: 5px;
                color: #fff;
                border: none;
              }
              .have-account-section {
                @media screen and (max-width: 1040px) {
                  margin-top: 20px;
                }
                a {
                  color: #8dc73f;
                  text-decoration: none;
                  cursor: pointer;
                }
              }
            }
            .terms-input {
              margin: 24px 10px;
              text-align: center;
            }
            .other-singup-laber {
              padding-top: 20px;
              text-transform: capitalize;
            }
            .other-register-option {
              display: grid;
              grid-template-columns: 142px 142px;
              grid-column-gap: 13px;
              @media screen and (max-width: 1040px) {
                grid-template-columns: 120px 120px;
              }
              .option {
                display: flex;
                align-items: center;
                background: #ffffff;
                border-radius: 5px;
                padding: 10px 10px;
                cursor: pointer;
                justify-content: center;
                border: 1px solid #ddd;
                &:hover {
                  background: #f3ffe4;
                }

                .logo {
                  margin-right: 5px;
                  width: 17px;
                }
                .name {
                  @media screen and (max-width: 1040px) {
                    font-size: 10px;
                    text-align: center;
                    justify-content: center;
                  }
                }
              }
            }
            .help-tips {
              margin-top: 5px;
              font-size: 11px;
            }
          }
        }
      }
    }
  }
  .confirmation-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: rgba(#fff, 0.9);
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    .confirm-widget {
      width: 1040px;
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media screen and (max-width: 1040px) {
        width: 100%;
      }
      .message {
        .text {
          text-align: center;
        }
        .nb {
          font-style: italic;
          font-size: 13px;
        }
      }
      .actions {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-column-gap: 20px;
      }
    }
  }
}
</style>
