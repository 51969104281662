<template>
  <div class="video-wrapper">
    <video
      ref="cagura_video_player"
      :src="src"
      class="video-player"
      controls
    ></video>
  </div>
</template>

<script>
export default {
  name: "VideoPlayer",
  props: {
    src: null,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="scss" >
.video-wrapper {
  width: 100%;
  .video-player {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>