import {
    VERIFY_ACCOUNT,
    VERIFY_EMAIL_FOR_RESET,
    UPDATE_ACCOUNT_PASSWORD,
    CREATE_ACCOUNT,
    ACCOUNT_LOGIN,
    ACCOUNT_GOOGLE_AUTH,
    CONFIRM_GOOGLE_LOGIN_AUTH,
    CONFIRM_GOOGLE_REGISTER_AUTH,
    ACCOUNT_FACEBOOK_AUTH,
    CONFIRM_FACEBOOK_LOGIN_AUTH,
    CONFIRM_FACEBOOK_REGISTER_AUTH,
    ACCOUNT_URL_CALLBACK,
    VERIFY_TOKEN,
    NOTIFICATION_GET_ALL,
    NOTIFICATION_MAKE_IT_SEEN,
    SEARCH_BASED_CATEGORY,
    SEARCH_BASED_QUERY,
    SAVE_SEARCH_TEXT,
    GET_SEARCH_TEXT,
    USER_UPDATE_INFO,
    SEARCH_GET_HISTORY,
    SEARCH_REMOVE_FROM_HISTORY,
    SEND_CONTACT_MESSAGE,
    RESENT_VERIFIY_ACCOUNT_EMAIL,
    VERIFY_ACCOUNT_CODE,
    SKIP_WHATS_NEW,
    ACCEPT_AFFILIATE_PROGRAM,
    GET_AFFILIATE_LINKS,
    AFFILIATE_COPY_LINK,
    AFFILIATE_GET_COPYED_LINKS,
    AFFILIATE_REDIRECT_TO_LINK,
    CASHOUT_AFFILIATE_MONEY,
    AFFILIATE_REWARD_LINK_OWNER,
    GET_ALL_AFFILIATE_ADS,
    GET_FIXED_AFFILIATE_ADS,
    PAGE_SUBSCRIBE,
    PAGE_CHECK_PAYMENT,
    GET_IP,
    NODE_GET_VAPID,
    SAVE_USER_NOTIFICATION_CREDECTIALS,
    NOTIFICATION_REMOVE_ITEM,
    NOTIFICATION_MARK_ITEM,
    NOTIFICATION_CHECK_STATUS,
    NOTIFICATION_GET_NOTIFICATION_ITEM
} from "../actions/user";

import {
    API_BASE_URL,
    API_NODE_BASE_URL,
    VERIFY_ACCOUNT_URL,
    VERIFY_EMAIL_FOR_RESET_URL,
    UPDATE_ACCOUNT_PASSWORD_URL,
    CREATE_ACCOUNT_URL,
    ACCOUNT_LOGIN_URL,
    ACCOUNT_GOOGLE_AUTH_URL,
    CONFIRM_GOOGLE_LOGIN_AUTH_URL,
    CONFIRM_GOOGLE_REGISTER_AUTH_URL,
    ACCOUNT_FACEBOOK_AUTH_URL,
    CONFIRM_FACEBOOK_LOGIN_AUTH_URL,
    CONFIRM_FACEBOOK_REGISTER_AUTH_URL,
    ACCOUNT_URL_CALLBACK_URL,
    VERIFY_TOKEN_URL,
    NOTIFICATION_GET_ALL_URL,
    NOTIFICATION_MAKE_IT_SEEN_URL,
    SEARCH_BASED_CATEGORY_URL,
    SEARCH_BASED_QUERY_URL,
    SAVE_SEARCH_TEXT_URL,
    GET_SEARCH_TEXT_URL,
    USER_UPDATE_INFO_URL,
    SEARCH_GET_HISTORY_URL,
    SEARCH_REMOVE_FROM_HISTORY_URL,
    SEND_CONTACT_MESSAGE_URL,
    RESENT_VERIFIY_ACCOUNT_EMAIL_URL,
    VERIFY_ACCOUNT_CODE_URL,
    SKIP_WHATS_NEW_URL,
    ACCEPT_AFFILIATE_PROGRAM_URL,
    GET_AFFILIATE_LINKS_URL,
    AFFILIATE_COPY_LINK_URL,
    AFFILIATE_GET_COPYED_LINKS_URL,
    AFFILIATE_REDIRECT_TO_LINK_URL,
    CASHOUT_AFFILIATE_MONEY_URL,
    AFFILIATE_REWARD_LINK_OWNER_URL,
    GET_ALL_AFFILIATE_ADS_URL,
    GET_FIXED_AFFILIATE_ADS_URL,
    PAGE_SUBSCRIBE_URL,
    PAGE_CHECK_PAYMENT_URL,
    NODE_GET_VAPID_URL,
    SAVE_USER_NOTIFICATION_CREDECTIALS_URL,
    NOTIFICATION_REMOVE_ITEM_URL,
    NOTIFICATION_MARK_ITEM_URL,
    NOTIFICATION_CHECK_STATUS_URL,
    NOTIFICATION_GET_NOTIFICATION_ITEM_URL
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [ACCOUNT_URL_CALLBACK]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + ACCOUNT_URL_CALLBACK_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [NOTIFICATION_GET_NOTIFICATION_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + NOTIFICATION_GET_NOTIFICATION_ITEM_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [NOTIFICATION_CHECK_STATUS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + NOTIFICATION_CHECK_STATUS_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [NOTIFICATION_MARK_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + NOTIFICATION_MARK_ITEM_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [NOTIFICATION_REMOVE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + NOTIFICATION_REMOVE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SAVE_USER_NOTIFICATION_CREDECTIALS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SAVE_USER_NOTIFICATION_CREDECTIALS_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [NODE_GET_VAPID]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_NODE_BASE_URL + NODE_GET_VAPID_URL,
            method: "GET",
            data: payload,
        });
    },
    [GET_IP]: ({ commit, dispatch }, payload) => {
        return axios({
            url: "https://api.db-ip.com/v2/free/self",
            method: "GET"
        });
    },
    [PAGE_SUBSCRIBE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PAGE_SUBSCRIBE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PAGE_CHECK_PAYMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PAGE_CHECK_PAYMENT_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [GET_FIXED_AFFILIATE_ADS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_FIXED_AFFILIATE_ADS_URL,
            method: "GET",
            data: payload
        });
    },
    [GET_ALL_AFFILIATE_ADS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_ALL_AFFILIATE_ADS_URL,
            method: "GET",
            data: payload
        });
    },
    [AFFILIATE_REWARD_LINK_OWNER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + AFFILIATE_REWARD_LINK_OWNER_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [CASHOUT_AFFILIATE_MONEY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CASHOUT_AFFILIATE_MONEY_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [AFFILIATE_REDIRECT_TO_LINK]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + AFFILIATE_REDIRECT_TO_LINK_URL,
            method: "POST",
            data: payload,
        });
    },
    [AFFILIATE_GET_COPYED_LINKS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + AFFILIATE_GET_COPYED_LINKS_URL,
            method: "GET",
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [AFFILIATE_COPY_LINK]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + AFFILIATE_COPY_LINK_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [GET_AFFILIATE_LINKS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_AFFILIATE_LINKS_URL,
            method: "GET",
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [ACCEPT_AFFILIATE_PROGRAM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + ACCEPT_AFFILIATE_PROGRAM_URL,
            method: "POST",
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SKIP_WHATS_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SKIP_WHATS_NEW_URL,
            method: "POST",
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [VERIFY_ACCOUNT_CODE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + VERIFY_ACCOUNT_CODE_URL,
            method: "POST",
            data: payload,
        });
    },
    [RESENT_VERIFIY_ACCOUNT_EMAIL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + RESENT_VERIFIY_ACCOUNT_EMAIL_URL,
            method: "POST",
            data: payload,
        });
    },
    [SEND_CONTACT_MESSAGE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SEND_CONTACT_MESSAGE_URL,
            method: "POST",
            data: payload,
        });
    },
    [SEARCH_REMOVE_FROM_HISTORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SEARCH_REMOVE_FROM_HISTORY_URL + '/' + payload.id,
            method: "DELETE",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SEARCH_GET_HISTORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SEARCH_GET_HISTORY_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [USER_UPDATE_INFO]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + USER_UPDATE_INFO_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [GET_SEARCH_TEXT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_SEARCH_TEXT_URL,
            method: "POST",
            data: payload,
        });
    },
    [SAVE_SEARCH_TEXT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SAVE_SEARCH_TEXT_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SEARCH_BASED_QUERY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SEARCH_BASED_QUERY_URL,
            method: "POST",
            data: payload,
        });
    },
    [SEARCH_BASED_CATEGORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SEARCH_BASED_CATEGORY_URL,
            method: "POST",
            data: payload,
        });
    },
    [NOTIFICATION_MAKE_IT_SEEN]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + NOTIFICATION_MAKE_IT_SEEN_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [NOTIFICATION_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + NOTIFICATION_GET_ALL_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [VERIFY_TOKEN]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + VERIFY_TOKEN_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [CONFIRM_FACEBOOK_LOGIN_AUTH]: ({ commit, dispatch }, payload) => {
        return axios({
            url: API_BASE_URL + CONFIRM_FACEBOOK_LOGIN_AUTH_URL + payload.query,
            method: "GET",
            data: payload
        });
    },
    [CONFIRM_FACEBOOK_REGISTER_AUTH]: ({ commit, dispatch }, payload) => {
        return axios({
            url: API_BASE_URL + CONFIRM_FACEBOOK_REGISTER_AUTH_URL,
            method: "POST",
            data: payload
        });
    },
    [ACCOUNT_FACEBOOK_AUTH]: ({ commit, dispatch }, payload) => {
        return axios({
            url: API_BASE_URL + ACCOUNT_FACEBOOK_AUTH_URL,
            method: "POST",
            data: payload
        });
    },
    [CONFIRM_GOOGLE_REGISTER_AUTH]: ({ commit, dispatch }, payload) => {
        return axios({
            url: API_BASE_URL + CONFIRM_GOOGLE_REGISTER_AUTH_URL,
            method: "POST",
            data: payload
        });
    },
    [CONFIRM_GOOGLE_LOGIN_AUTH]: ({ commit, dispatch }, payload) => {
        return axios({
            url: API_BASE_URL + CONFIRM_GOOGLE_LOGIN_AUTH_URL,
            method: "POST",
            data: payload
        });
    },
    [ACCOUNT_GOOGLE_AUTH]: ({ commit, dispatch }, payload) => {
        return axios({
            url: API_BASE_URL + ACCOUNT_GOOGLE_AUTH_URL,
            method: "POST",
            data: payload
        });
    },
    [ACCOUNT_LOGIN]: ({ commit, dispatch }, payload) => {
        return axios({
            url: API_BASE_URL + ACCOUNT_LOGIN_URL,
            method: "POST",
            data: payload,
        });
    },
    [CREATE_ACCOUNT]: ({ commit, dispatch }, payload) => {
        return axios({
            url: API_BASE_URL + CREATE_ACCOUNT_URL,
            method: "POST",
            data: payload,
        });
    },
    [UPDATE_ACCOUNT_PASSWORD]: ({ commit, dispatch }, payload) => {
        return axios({
            url: API_BASE_URL + UPDATE_ACCOUNT_PASSWORD_URL,
            method: "POST",
            data: payload,
        });
    },
    [VERIFY_EMAIL_FOR_RESET]: ({ commit, dispatch }, payload) => {
        return axios({
            url: API_BASE_URL + VERIFY_EMAIL_FOR_RESET_URL,
            method: "POST",
            data: payload,
        });
    },
    [VERIFY_ACCOUNT]: ({ commit, dispatch }, payload) => {
        return axios({
            url: API_BASE_URL + VERIFY_ACCOUNT_URL,
            method: "POST",
            data: payload,
        });
    },
};


export default {
    mutations,
    actions,
    getters,
    state
};