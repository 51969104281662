<template>
  <div class="cagura-wrapper">
    <!-- Navbar -->
    <client-header />
    <!-- Navbar -->
    <div class="cagura-container">
      <!-- Side bar -->
      <transition
        name="bounce"
        enter-active-class="bounceInLeft"
        leave-active-class="bounceOutLeft"
      >
        <side-bar v-if="$store.state.sidebar" />
      </transition>
      <!-- End of sidebar -->
      <!-- Content - body -->
      <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
        <vue-scroll>
          <div class="cagura-help-widget">
            <div class="help-widget">
              <div class="help-widget-header">
                <div class="call-actions">
                  <button
                    class="btn-back"
                    @click="$router.push({ name: 'home' }).catch(() => {})"
                  >
                    <img src="@/assets/images/icons/arrow-left.svg" /> PRIVACY
                    POLICY & SAFETY
                  </button>
                </div>
              </div>
              <!-- BODY -->
              <div class="content-widget">
                <h4 class="title">Introduction</h4>
                <p>
                  At Cagura , we take the privacy and security of your
                  information seriously. This privacy policy (“Privacy Policy”)
                  will explain how we collect, use and share your personal
                  information, and how you may exercise your rights. This
                  Privacy Policy applies to the Cagura websites and Cagura App
                </p>
                <p>
                  “Personal information” as used in this Privacy Policy is
                  information that identifies or can reasonably be linked
                  directly or indirectly to an identifiable person
                </p>
                <h4 class="title">
                  What personal information does Cagura collect about me?
                </h4>
                <h3 class="sub-title">Information You Provide Directly</h3>
                <p>
                  We collect the personal information you provide to us, for
                  example:
                </p>
                <div class="sub-content">
                  <p>
                    <b> Account Information:</b> When you register with Cagura
                    you do this with Cagura itself, Google or Facebook, When you
                    register for an account with Cagura, we collect information
                    that identifies you such as your name, username, email,
                    phone address and password but when you choose Google or
                    Facebook we collect your names, email and your profile
                    picture.
                  </p>
                  <p>
                    <b> Profile Information:</b> We collect information that you
                    voluntarily provide in your user profile; this may include
                    your public avatar (which may be a photo), email addresses,
                    phone numbers, Please note this information will be visible
                    to the public.
                  </p>
                  <p>
                    <b> Payment Information:</b> If you purchase a paid
                    subscription from Cagura like movies, we as Cagura will
                    collect payment information from you that may include your
                    name, billing address and credit card or bank information.
                  </p>

                  <p>
                    <b> Contact Information:</b> If you request Cagura to
                    contact you, Cagura may collect information such as email
                    address or other information you provided in the message
                    field.
                  </p>

                  <p>
                    <b> Content you provide through the use of the Services:</b>
                    If you request Cagura to contact you, Cagura may collect
                    information such as email address or other information you
                    provided in the message field.
                    <br />
                    We may also collect other content that you submit to our
                    Services. For example: feedback, comments and blog posts, or
                    when you participate in any interactive features, surveys,
                    contests, promotions, activities or events.
                  </p>
                  <p>
                    <b>Customer Support and Professional Services :</b>
                    If you contact Cagura customer support or receive
                    professional services, we will collect information about you
                    related to your account and to the requests you are making
                    or the services being provided.
                  </p>
                </div>
                <h4 class="sub-title">
                  Information About Your Use of the Services We Collect
                  Automatically
                </h4>
                <div class="sub-content">
                  <p>
                    <b>Device Information and Identifiers:</b>
                    When you access and use our Services, we automatically
                    collect information about your device, which may include:
                    device type, your device operating system; browser type and
                    version; language preference; IP address, cookie
                    identifiers.
                  </p>
                  <p>
                    <b>Services Usage Data:</b>
                    Cagura may also collect information about how you use it
                    such as, activity data, feature usage, and product version
                    data. This information may be aggregated or identifiable.
                    For more information about the data collected navigate to
                    history to see those information by logging into your
                    account
                  </p>
                  <p>
                    <b>Website Usage Data:</b>
                    When you visit our Websites, we automatically log
                    information about how you interact with the sites, such as
                    the referring site, data and time of visit, and the pages
                    you have viewed or links clicked on.
                  </p>
                  <p>
                    <b>Cookies and Similar Tracking Technologies:</b>Cagura uses
                    cookies and similar technologies to provide functionality,
                    such as storing your settings, and to recognize you as you
                    use our Services. In addition, we use cookies to gather
                    information to provide interest-based advertising which is
                    tailored to you based on your online activity.
                  </p>
                  <p>
                    <b>Email marketing:</b>When we send you emails, they may
                    include technology such as a web beacon, that tells us your
                    device type, email client, and whether you have received and
                    opened an email, or clicked on any links contained in the
                    email.
                  </p>
                  <p>
                    <b>Buttons, tools, and content from other companies:</b>The
                    Services may include links or buttons to third party
                    services such as Facebook and Google. We may collect
                    information about your use of these features. In addition,
                    when you see or interact with these buttons, tools, or
                    content, some information from your browser may
                    automatically be sent to the other company. Please read that
                    company’s privacy policy for more information.
                  </p>
                </div>
                <h3 class="sub-title">
                  Information from Third parties and Partners
                </h3>
                <p>
                  We may also receive information about you from third parties
                  such as vendors, resellers, partners, or affiliates. For
                  example,we may supplement the data we collect with demographic
                  information licensed from third parties in order to
                  personalize the Services and our offers to you.
                </p>
                <div class="sub-content">
                  <p>
                    <b>Third Party sign-in services:</b>
                    Cagura allows you to sign up or in to our Services using
                    third party accounts, such as Facebook or Google. When you
                    give permission for this to happen, Cagura will receive
                    information about you from your third-party account, such as
                    name, email address, location, profile picture.
                  </p>
                </div>
                <p>
                  When you are asked to provide personal data, you may decline.
                  And you may use web browser or operating system controls to
                  prevent certain types of automatic data collection. But if you
                  choose not to provide or allow information that is necessary
                  for certain products or features, those products or features
                  may not be available or function correctly.
                </p>

                <h3 class="title">How is your personal information used?</h3>
                <p>
                  Cagura uses your personal information for the following
                  purposes:
                </p>
                <ul>
                  <li>
                    To create your account, identify and authenticate your
                    access to the Services and provide you with the Services you
                    have requested.
                  </li>
                  <li>
                    To process your payment for the Services you have purchased
                  </li>
                  <li>
                    To understand how our Services are used and to improve our
                    Services.
                  </li>
                  <li>To provide personalized experiences</li>
                  <li>To send you important information about the Services</li>
                  <li>To send you information you have requested.</li>
                  <li>
                    To send you advertising, marketing content, offers,
                    promotions, newsletters, surveys or other information.
                  </li>
                  <li>To respond to your requests for customer support</li>
                  <li>
                    To improve the security of and troubleshoot our Services, as
                    necessary to perform the contract governing your use of our
                    applications or to communicate with you
                  </li>
                  <li>
                    To detect, prevent, or otherwise address fraud and abuse to
                    protect you, Cagura and third parties
                  </li>
                  <li>To enforce the legal terms that govern our Services</li>
                  <li>To comply with our legal obligations</li>
                  <li>
                    To protect the rights, safety and property of Cagura, you,
                    or any third party
                  </li>
                  <li>
                    And for other purposes, for which we obtain your consent
                  </li>
                </ul>
                <h3 class="sub-title">
                  Legal Basis for Processing of your information
                </h3>
                <p>
                  We collect and process your personal information on the
                  following legal bases set out by applicable law:
                </p>
                <div class="sub-content">
                  <p>
                    <b>Performance of a contract:</b>We use your personal
                    information to provide the Services you have subscribed to,
                    and to complete and administer the contract you have entered
                    into with Cagura.
                  </p>
                  <p>
                    <b>Legitimate Interests:</b>We use your personal information
                    for our legitimate interests, such as to provide you with
                    relevant content, improve our products and services, and for
                    administrative, security, fraud prevention and legal
                    purposes. You may object to the processing of your personal
                    information for these purposes at any time.
                  </p>
                  <p>
                    <b>Consent:</b>We may use your personal information, with
                    your consent, for specific purposes such as marketing,
                    surveys. You may withdraw your consent for the specific
                    purpose or object to the processing of your personal
                    information at any time.
                  </p>
                </div>

                <h3 class="title">Who is your information shared with?</h3>
                <p>
                  We may share each of the categories of personal information we
                  collect with the types of third parties described below, for
                  the following business purposes:
                </p>
                <div class="sub-content">
                  <p>
                    <b>Sharing with Users and the Public:</b>
                    We may share your personal information with other users of
                    the Services and with the public if you choose to make your
                    Cagura Profile public. You have control over what
                    information is public. To change your settings, go to User
                    Settings in your profile. You should also be aware that any
                    information you share as part of a project, blog, website
                    etc. may be publicly available and you should consider this
                    carefully when interacting with the Services.
                  </p>
                  <p>
                    <b>Sharing with Managed Accounts and Administrators:</b>
                    If you have created a Cagura account with your corporate
                    email address, we may share your personal information with
                    your Company if your Company enters into a commercial
                    relationship with Cagura. If this happens, then your use of
                    the software and your account is subject to the terms and
                    any data protection agreement between your Company and
                    Cagura.
                  </p>
                  <p>
                    <b>Sharing with Service Providers:</b>We share your personal
                    information with our service providers. These are companies
                    who provide services on our behalf, such as hosting our
                    Services, social, analytics, credit card processing,
                    security and other such similar services. These companies
                    are subject to contractual requirements that govern the
                    security and confidentiality of your information.
                    <br />
                    For example, we use analytics providers, such as Google
                    Analytics, to help us understand the operation and
                    performance of our Services. To learn about how Google uses
                    and shares data it collects through its services, please
                    visit
                    <span class="link"
                      >https://www.google.com/policies/privacy/partners</span
                    >.
                  </p>
                  <p>
                    <b>Sharing for Fraud and Prevention Abuse:</b>
                    We may share your information when we have a good faith
                    belief that the disclosure is necessary to prevent fraud,
                    abuse of our services, defend against attacks, and to
                    protect the safety of Cagura and our users.
                  </p>
                  <p>
                    <b>Law Enforcement:</b>
                    Cagura may disclose personal information or other
                    information we collect about you to law enforcement if
                    required in response to a valid subpoena, court order,
                    search warrant, a similar government order, or when we
                    believe in good faith that disclosure is necessary to comply
                    with our legal obligations, to protect our property or
                    rights, or those of third parties or the public at large.
                  </p>
                  <p>
                    <b>Merger or Acquisition:</b>
                    We may share your personal information if we are involved in
                    a merger, sale, or acquisition of corporate entities or
                    business units. If any such change of ownership happens, we
                    will ensure that it is under terms that preserve the
                    confidentiality of your personal information, and we will
                    notify you on our website or by email before any transfer of
                    your personal information.
                  </p>
                </div>

                <h3 class="title">
                  Is your personal information transferred across national
                  borders?
                </h3>
                <p>
                  Our Services are hosted in Frankfurt, Germany and information
                  we collect will be stored and processed in those servers. Our
                  employees, contractors that process personal information may
                  be located in Rwanda or other countries outside of your home
                  country.
                </p>
                <h3 class="title">Security</h3>
                <p>
                  We work hard to protect your personal information. We employ
                  administrative, technical, and physical security controls
                  where appropriate, to protect your information.
                </p>
                <h3 class="title">Data Retention</h3>
                <p>
                  Cagura will retain your information for as long as your
                  account is active or as needed to perform our contractual
                  obligations, provide you the Services, comply with legal
                  obligations, resolve disputes, preserve legal rights, or
                  enforce our agreements. We may delete inactive accounts and
                  associated data after a period of 10 months.
                </p>
                <h3 class="title">Rights and Choices</h3>
                <p>
                  You have the right to access, correct, restrict or delete your
                  personal information, and to port your personal information to
                  another company. While these rights may vary by jurisdiction,
                  Cagura provides you with the same rights and choices, no
                  matter where you live.
                </p>
                <p>You may exercise your choices and rights as follows:</p>
                <div class="sub-content">
                  <p>
                    <b>To opt out of email marketing: </b>You may opt-out of
                    email marketing by clicking the “unsubscribe” link located
                    at the bottom of any email you receive. You may continue to
                    receive transactional email messages about your account and
                    the Service after you have unsubscribed.
                  </p>
                  <p>
                    <b>Update your Information:</b>
                    If you already have an account, you may access, update, or
                    alter your user profile information by logging into your
                    account and updating profile settings.
                  </p>
                  <p>
                    <b>To delete your Account:</b>You may delete your account by
                    logging into your account and going to the “Delete my
                    Account” option in your profile settings. <br />
                    Please note that due to protecting our platform, we may
                    retain limited personal information indefinitely in order to
                    provide a transactional history. For example, if you provide
                    your information in connection with a post or comment, we
                    may display that information even if you have deleted your
                    account as we do not automatically delete community posts.
                    Also, we will not be able to delete or erase it because
                    doing so would break Cagura as a platform.
                    <br />If you add some information to a Cagura by commenting
                    in, or creating a post and you provide your personal
                    information in connection with that information, your
                    personal information associated with your information will
                    be deleted and attributed to a ghost user. However, please
                    note that if the content of the information contains
                    personal information, this information would remain and you
                    will need to submit a specific request to have this
                    information deleted.
                  </p>
                </div>

                <h4 class="title">How Safe is Cagura?</h4>
                <p>
                  Cagura is safe because we provide freedom while selling or
                  buying things using our platform, for buyers they have to make
                  sure they are buying product on trusted seller and make sure
                  they are receiving product meet requirements from product
                  description, according to business events is good to provide
                  review for product you are buying to help other clients will
                  visit that product in future.
                </p>

                <p>
                  Cagura is absolutely safe, The longer answer is that Cagura is
                  completely safe when you know the signs to look for. It’s
                  important to choose the right seller.
                </p>

                <p>
                  And the most dangerous obstacles to finding the perfect seller
                  are the scammers and middle-men. Luckily, it’s not difficult
                  to avoid these low lives.
                </p>

                <h3 class="title">Information Cagura Does Not Collect</h3>
                <p>
                  Cagura does not intentionally collect sensitive personal
                  information, such as social security numbers, genetic data,
                  health information,ID number or religious information.
                  Although Cagura does not request or intentionally collect any
                  sensitive personal information, we realize that users might
                  store this kind of information in a Cagura. If you are a child
                  under the age of 13, you may not have an account. does Cagura
                  not knowingly collect information from, or direct any of our
                  Services to, children under 13. If we learn or have reason to
                  suspect that a user is under the age of 13, we will close the
                  child’s account.
                </p>
                <h3 class="title">Policy Changes</h3>
                <p>
                  Cagura may change its Privacy Policy from time to time. When
                  we do, we will update the date at the top of this Policy. If
                  we decide to make a significant change to our Privacy Policy,
                  we will post a notice of the update on the homepage of our
                  Website. We may also provide notification via email of any
                  material changes to our Privacy Policy.
                </p>

                <h3 class="title">Contact Us</h3>
                <p>
                  Your information is controlled by Cagura. If you have
                  questions or concerns about the way we are handling your
                  information, please email us with the subject line "Privacy
                  Concern" at <span class="link">support@wecodefy.com</span>
                </p>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
      <!-- End of content -->
    </div>
  </div>
</template>

<script>
import ClientHeader from "../../components/partials/header.vue";
import SideBar from "../../components/partials/sidebar.vue";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
export default {
  name: "about",
  components: {
    ClientHeader,
    SideBar,
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {
    return {
      tabs: {
        properties: true,
        reviews: false,
      },
    };
  },
  methods: {
    goPropertyTab() {
      let vm = this;
      vm.tabs.properties = true;
      vm.tabs.reviews = false;
    },
    goReviewTab() {
      let vm = this;
      vm.tabs.properties = false;
      vm.tabs.reviews = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
    .cagura-body {
      .cagura-help-widget {
        height: 100%;
        padding-bottom: 50px;
        .help-widget {
          padding: 0px 20px;
          .help-widget-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0px;
            .call-actions {
              .btn-back {
                height: 36px;
                border: none;
                border-radius: 5px;
                background: transparent;
                outline: none;
                display: flex;
                align-items: center;
                img {
                  margin-right: 15px;
                }
              }
            }
          }
          .content-widget {
            display: flex;
            flex-direction: column;
            width: 800px;
            background: #fff;
            padding: 25px;
            border-radius: 5px;
            @media screen and (max-width: 800px) {
              width: 100%;
            }
            .title {
              color: #8cc73f;
              font-weight: 800;
              font-size: 20px;
              display: initial;
              padding-bottom: 4px;
              margin-bottom: 6px;
            }
            .sub-title {
              color: #000;
              font-weight: 800;
              font-size: 16px;
              display: initial;
              padding-bottom: 4px;
              margin-bottom: 6px;
            }
            p {
              color: rgb(69, 69, 69);
              margin-bottom: 8px;
              margin-top: 3px;
            }
            .sub-content {
              padding-left: 30px;
              margin-bottom: 20px;
            }
            .link {
              color: #8cc73f;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>

