<template>
  <div class="cagura-wrapper">
    <!-- Navbar -->
    <client-header />
    <!-- Navbar -->
    <div class="cagura-container">
      <!-- Side bar -->
      <side-bar />
      <!-- End of sidebar -->
      <!-- Content - body -->
      <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
        <vue-scroll>
          <div class="cagura-history-widget">
            <!-- History -->
            <div class="history-product">
              <div class="history-product-header">
                <div class="title">
                  <h3>VIEW HISTORY</h3>
                </div>
                <div class="history-options">
                  <div class="search-widget">
                    <div class="search-input">
                      <input
                        type="text"
                        placeholder="Search in History"
                        v-model="search_history_input"
                      />
                      <img src="@/assets/images/icons/search-grey.svg" alt />
                    </div>
                  </div>
                  <div class="search-type-widget">
                    <a-select
                      default-value="product_view_history"
                      class="input"
                      style="width: 100%"
                      size="large"
                      v-model="history_type"
                    >
                      <a-select-option value="product_view_history"
                        >Product View history</a-select-option
                      >
                      <a-select-option value="search_history"
                        >Search history</a-select-option
                      >
                    </a-select>
                  </div>
                </div>
              </div>

              <!-- Product list -->
              <div
                class="product-items"
                v-if="history_type == 'product_view_history'"
              >
                <div
                  class="product-item"
                  v-for="(item, i) in product_history"
                  :key="i"
                >
                  <div
                    class="product-image-widget"
                    @click="
                      $router.push({ path: $getProductLink(item.product) })
                    "
                  >
                    <img
                      :src="
                        $store.state.ASSETS_BASE_URL +
                        '/assets/uploaded/' +
                        item.product.main_image
                      "
                    />
                  </div>
                  <div class="product-content">
                    <div class="header">
                      <h2>{{ item.product.product_name }}</h2>
                      <dropdown-menu
                        v-model="item.is_menu_open"
                        :right="false"
                        :hover="false"
                        :interactive="false"
                      >
                        <button class="dropdown-toggle">
                          <img src="@/assets/images/icons/menu.svg" />
                        </button>
                        <div slot="dropdown" class="drop-down-menu">
                          <a
                            class="dropdown-item"
                            @click.prevent="removeProductFromHistory(item, i)"
                          >
                            <img src="@/assets/images/icons/trash.svg" /> Remove
                            from History
                          </a>
                        </div>
                      </dropdown-menu>
                    </div>
                    <div
                      class="content"
                      @click="
                        $router.push({ path: $getProductLink(item.product) })
                      "
                    >
                      <p>{{ $cutText(item.product.description, 130) }}</p>
                      <div class="product-options">
                        <div
                          class="price"
                          v-if="item.product.discount"
                          :class="{ strikethrough: item.product.discount }"
                        >
                          <strike
                            >FRW {{ $formatMoney(item.product.price) }}</strike
                          >
                        </div>

                        <div class="price" v-else>
                          FRW {{ $formatMoney(item.product.price) }}
                        </div>

                        <div class="price" v-if="item.product.discount">
                          {{
                            "FRW " +
                            $formatMoney(
                              item.product.price -
                                (item.product.price * item.product.discount) /
                                  100
                            )
                          }}
                        </div>
                        <div class="rate">
                          <a-rate
                            :value="item.product.rating"
                            :disabled="false"
                            style="font-size: 14px"
                          />
                        </div>
                      </div>
                      <div class="product-option">
                        <p>
                          {{
                            moment(item.created_at).format(
                              "dddd, MMM Do YYYY, h:mm A"
                            )
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="empty-widget"
                v-if="
                  history_type == 'product_view_history' &&
                  product_history.length == 0 &&
                  !is_loading
                "
              >
                <a-empty
                  :image="require('@/assets/images/icons/empty.svg')"
                  :image-style="{ height: '116px' }"
                >
                  <span slot="description"
                    >No product history views available !!!</span
                  >
                </a-empty>
              </div>

              <div
                class="loader-widget"
                style="height: 300px"
                v-if="is_loading"
              >
                <div class="loading-panel">
                  <a-spin :spinning="true"></a-spin>
                  <span class="text">Loading...</span>
                </div>
              </div>

              <!-- Search history -->
              <div class="search-items" v-if="history_type == 'search_history'">
                <div
                  class="search-item"
                  v-for="(item, i) in search_history"
                  :key="i"
                >
                  <span
                    class="text"
                    @click="
                      $router.push({ name: 'search', query: { q: item.text } })
                    "
                    >{{ item.text }}</span
                  >
                  <div class="option">
                    <dropdown-menu
                      v-model="item.is_menu_open"
                      :right="false"
                      :hover="false"
                      :interactive="false"
                    >
                      <button class="dropdown-toggle">
                        <img src="@/assets/images/icons/menu.svg" />
                      </button>
                      <div slot="dropdown" class="drop-down-menu">
                        <a
                          class="dropdown-item"
                          @click.prevent="removeSeachItemFromHistory(item, i)"
                        >
                          <img src="@/assets/images/icons/trash.svg" /> Remove
                          from History
                        </a>
                      </div>
                    </dropdown-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
      <!-- End of content -->
    </div>
    <ask-auth
      @onCancel="ask_for_account = false"
      v-if="ask_for_account"
      message="to access to History"
    ></ask-auth>
  </div>
</template>

<script>
import ClientHeader from "../components/partials/header.vue";
import SideBar from "../components/partials/sidebar.vue";
import DropdownMenu from "@innologica/vue-dropdown-menu";

export default {
  name: "history",
  components: {
    ClientHeader,
    SideBar,
    DropdownMenu,
  },
  data() {
    return {
      show: false,
      history_type: "search_history",
      product_history: [],
      store_products_history: [],
      is_loading: false,
      search_history_input: "",
      search_history: [],
      store_search_history: [],
      ask_for_account: false,
    };
  },
  mounted() {
    let vm = this;
    if (vm.$getLoggedUser()) {
      vm.getProductHistory();
      vm.getSearchHistory();
    } else {
      vm.ask_for_account = true;
    }
  },
  methods: {
    removeSeachItemFromHistory(item, i) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure? This will remove search from history.`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmDeleteSearch(item.id, i);
          }
        },
      });
    },
    confirmDeleteSearch(id, i) {
      let vm = this;
      vm.$store.commit("START_LOADER");
      vm.$store
        .dispatch("SEARCH_REMOVE_FROM_HISTORY", { id: id })
        .then((response) => {
          vm.$store.commit("STOP_LOADER");
          if (response.data.status == "ok") {
            if (i > -1) {
              vm.search_history.splice(i, 1);
            }
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          } else {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.Message,
            });
          }
        })
        .catch(({ response }) => {
          vm.$store.commit("STOP_LOADER");
          console.error("SEARCH_REMOVE_FROM_HISTORY", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    getSearchHistory() {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("SEARCH_GET_HISTORY")
        .then((response) => {
          vm.is_loading = false;
          if (response.data.status == "ok") {
            vm.search_history = response.data.return.map((item) => {
              vm.$store.state.eventBus.$set(item, "is_menu_open", false);
              return item;
            });
            vm.store_search_history = vm.search_history;
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("SEARCH_GET_HISTORY", response);
        });
    },
    searchInHistory(query) {
      let vm = this;
      if (vm.history_type == "product_view_history") {
        vm.product_history = vm.store_products_history.filter((item) => {
          if (
            item.product.product_name
              .toLowerCase()
              .indexOf(query.toLowerCase()) !== -1 ||
            item.product.description
              .toLowerCase()
              .indexOf(query.toLowerCase()) !== -1 ||
            item.product.brand.toLowerCase().indexOf(query.toLowerCase()) !==
              -1 ||
            item.product.price <= query
          ) {
            return true;
          }
          return false;
        });
      }
      if (vm.history_type == "search_history") {
        vm.search_history = vm.store_search_history.filter((item) => {
          if (item.text.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
            return true;
          }
          return false;
        });
      }
    },
    removeProductFromHistory(item, i) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure? This will remove product from history.`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmDeleteProduct(item.id, i);
          }
        },
      });
    },
    confirmDeleteProduct(id, i) {
      let vm = this;
      vm.$store.commit("START_LOADER");
      vm.$store
        .dispatch("PRODUCT_REMOVE_FROM_HISTORY", { id: id })
        .then((response) => {
          vm.$store.commit("STOP_LOADER");
          if (response.data.status == "ok") {
            if (i > -1) {
              vm.product_history.splice(i, 1);
            }
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          } else {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.Message,
            });
          }
        })
        .catch(({ response }) => {
          vm.$store.commit("STOP_LOADER");
          console.error("PRODUCT_REMOVE_FROM_HISTORY", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    getProductHistory() {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("PRODUCT_GET_HISTORY")
        .then((response) => {
          vm.is_loading = false;
          if (response.data.status == "ok") {
            vm.product_history = response.data.return
              .map((item) => {
                vm.$store.state.eventBus.$set(item, "is_menu_open", false);
                return item;
              })
              .slice(0, 15);
            vm.store_products_history = vm.product_history;
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("PRODUCT_GET_HISTORY", response);
        });
    },
  },
  watch: {
    search_history_input(query) {
      let vm = this;
      vm.searchInHistory(query);
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
    .cagura-body {
      .cagura-history-widget {
        height: 100%;
        .history-product {
          padding-top: 40px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 40px;
          .history-product-header {
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 500px) {
              flex-direction: column;
            }
            .history-options {
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              @media screen and (max-width: 500px) {
                display: flex;
                align-items: center;
              }
              .search-widget {
                padding-right: 20px;
                @media screen and (max-width: 500px) {
                  margin-bottom: 3px;
                  padding-right: 0px;
                  width: 100%;
                  margin-top: 23px;
                }
                .search-input {
                  border: 1px solid #b8b8b8;
                  height: 40px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0px 13px;
                  border-radius: 5px;
                  input {
                    border: none;
                    background: transparent;
                    width: 240px;
                    @media screen and (max-width: 500px) {
                      width: 100%;
                    }
                  }
                  img {
                    width: 15px;
                  }
                }
              }
              .search-type-widget {
                width: 100%;
                @media screen and (max-width: 500px) {
                  width: 144px;
                  top: -42px;
                  position: absolute;
                  right: -3px;
                }
                .input {
                  @media screen and (max-width: 500px) {
                    width: 100%;
                  }
                }
              }
              .btns-options {
                display: flex;
                @media screen and (max-width: 500px) {
                  display: flex;
                }
                .btn-cagura {
                  width: 39.89px;
                  height: 39.89px;
                  background: #8dc73f;
                  border-radius: 6.93797px;
                  border: none;
                  margin-left: 15px;
                }
              }
            }
          }

          .product-items {
            display: grid;
            grid-template-columns: 48% 48%;
            grid-column-gap: 40px;
            padding-top: 20px;
            grid-row-gap: 20px;
            @media screen and (max-width: 500px) {
              grid-template-columns: 100%;
            }
            .product-item {
              background: #fff;
              border-radius: 5px;
              display: grid;
              grid-template-columns: 200px calc(99% - 200px);
              grid-column-gap: 10px;
              @media screen and (max-width: 1040px) {
                grid-template-columns: 119px calc(99% - 119px);
              }
              @media screen and (max-width: 500px) {
                grid-template-columns: 100%;
                border: 1px solid #ddd;
                padding: 12px;
              }
              cursor: pointer;
              .product-image-widget {
                img {
                  height: 200px;
                  width: 200px;
                  object-fit: cover;
                  border-bottom-left-radius: 5px;
                  border-top-left-radius: 5px;
                  @media screen and (max-width: 1040px) {
                    width: 100%;
                    object-fit: contain;
                    background: #000;
                  }
                  @media screen and (max-width: 500px) {
                    height: 150px;
                    width: 100%;
                    object-fit: contain;
                    background: #000;
                  }
                }
              }
              .product-content {
                padding-right: 15px;
                .header {
                  display: flex;
                  justify-content: space-between;
                  position: relative;
                  align-items: center;
                  margin-bottom: 8px;
                  margin-top: 11px;
                  h2 {
                    margin: 0px;
                    @media screen and (max-width: 500px) {
                      font-size: 17px;
                    }
                  }
                }
                .shop-name {
                  color: rgba(42, 140, 229, 0.71);
                  font-weight: 800;
                  @media screen and (max-width: 500px) {
                    font-size: 14px;
                  }
                }
                p {
                  text-align: justify;
                  @media screen and (max-width: 500px) {
                    font-size: 14px;
                  }
                }
                .product-options {
                  display: flex;
                  justify-content: space-between;
                  @media screen and (max-width: 1040px) {
                    flex-direction: column;
                  }
                  @media screen and (max-width: 500px) {
                    flex-direction: column;
                  }
                  .price {
                    font-weight: 800;
                  }
                }
                .product-option {
                  display: flex;
                  justify-content: space-between;
                  margin-top: 10px;
                  @media screen and (max-width: 500px) {
                    flex-direction: column;
                  }
                  .btn-contact-seller {
                    border: none;
                    background: transparent;
                    display: flex;
                    align-items: center;
                    color: #939393;
                    padding: 0px;
                    @media screen and (max-width: 500px) {
                      padding: 0px;
                      padding-bottom: 15px;
                    }
                    img {
                      margin-right: 6px;
                    }
                  }
                  .btn-add-fovourites {
                    border: none;
                    background: transparent;
                    display: flex;
                    align-items: center;
                    color: #8cc73f;
                    @media screen and (max-width: 500px) {
                      padding: 0px;
                      padding-bottom: 15px;
                    }
                    img {
                      margin-right: 6px;
                    }
                  }
                }
              }
            }
          }
          .search-items {
            margin-top: 20px;
            .search-item {
              width: 100%;
              background: #fff;
              border: 1px solid #ddd;
              display: flex;
              align-items: center;
              border-radius: 5px;
              justify-content: space-between;
              margin-top: 8px;
              cursor: pointer;
              .text {
                padding: 10px 10px;
              }
              .option {
                width: 40px;
              }
            }
          }
        }
      }
    }
  }
  .overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba($color: #fff, $alpha: 0.9);
    z-index: 99999;
    display: flex;
    justify-content: center;
    padding-top: 80px;
    .contact-form {
      width: 500px;
      background: #fff;
      border: 1px solid #ddd;
      display: inline-table;
      padding: 20px;
      h2 {
        font-size: 27px;
      }
      .form-input {
        width: 100%;
        .contant-input {
          width: 100%;
          height: 160px;
          margin-bottom: 20px;
          padding: 10px;
          resize: none;
          border-radius: 5px;
          background: #eeeeee;
          border: none;
        }
      }
      .form-options {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>

