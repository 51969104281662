import Vue from 'vue'
import Vuex from 'vuex'
import {
    BASE_URL,
    GOOGLE_API_KEY,
    GOOGLE_MAP_KEY
} from "./variables";
import User from './modules/user.js'
import Upload from './modules/upload.js'
import Shop from './modules/shop.js'
import Product from './modules/product.js'
import Movies from './modules/movies.js'
import Feeds from "./modules/feeds.js"



Vue.use(Vuex)
export default new Vuex.Store({
    state: () => ({
        sidebar: true,
        show_notification: false,
        eventBus: new Vue(),
        access_token: 'no_access_token',
        BASE_URL: BASE_URL,
        ASSETS_BASE_URL: 'https://cagura-assets.b-cdn.net',
        ASSETS_MOVIE_BASE_URL: 'https://cagura-assets.b-cdn.net',
        CDN_MOVIE_BASE_URL: 'https://file-cagura.b-cdn.net',
        GOOGLE_API_KEY: GOOGLE_API_KEY,
        GOOGLE_MAP_KEY: GOOGLE_MAP_KEY,
        main_loader: false,
    }),
    mutations: {
        ["SET_TOKEN"]: (state, payload) => {
            state.access_token = payload;
        },
        ["START_LOADER"]: (state) => {
            state.main_loader = true;
        },
        ["STOP_LOADER"]: (state) => {
            state.main_loader = false;
        },
        ["TOGGLE_SIDE_BAR"]: (state, payload) => {
            state.sidebar = payload;
        }
    },
    actions: {
        ["TOGGLE_SIDE_BAR"]: ({ state, commit }, payload) => {
            commit("TOGGLE_SIDE_BAR", !state.sidebar);
            Vue.localStorage.set("is_sidebar", state.sidebar);
        }
    },
    getters: {
        getUserAccessToken(state) {
            return state.access_token ? state.access_token : "no_access_token";
        },
        getSideBar(state) {
            return state.sidebar;
        }
    },
    modules: {
        User,
        Upload,
        Shop,
        Product,
        Movies,
        Feeds
    }
})