<template>
  <div class="notify-user-section">
    <div class="notifiy-widget" v-if="$getLoggedUser() && checkMissings()">
      <div
        class="notify-item"
        v-if="
          !$getLoggedUser().user.profile_image ||
          !$getLoggedUser().user.phone ||
          !$getLoggedUser().user.email
        "
      >
        <div class="progress">
          <radial-progress-bar
            :diameter="100"
            :completed-steps="completedSteps"
            :strokeWidth="5"
            :total-steps="totalSteps"
          >
            <span>{{ completedSteps }}%</span>
          </radial-progress-bar>
        </div>
        <div class="message">
          <p>
            Some account information are missing like
            <b v-if="!$getLoggedUser().user.profile_image">Profile image</b
            ><b v-if="!$getLoggedUser().user.phone">,Phone number, </b
            ><b v-if="!$getLoggedUser().user.email">Email</b>
            Update your profile.
          </p>
          <button class="btn-default" @click="viewProfileModal()">
            UPDATE PROFILE
          </button>
        </div>
      </div>
      <div class="notify-item" v-if="!$getLoggedUser().user.activated">
        <div class="message">
          <p>
            You account is not verified, Please verify your account by clicking
            on button below.
          </p>
          <button class="btn-default" @click="verifyAccount()">
            VERIFY NOW
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RadialProgressBar from "vue-radial-progress";
export default {
  name: "announcement",
  components: {
    RadialProgressBar,
  },
  data() {
    return {
      completedSteps: 100,
      totalSteps: 100,
    };
  },
  mounted() {
    let vm = this;
    vm.calcMissingInfo();
  },
  methods: {
    viewProfileModal() {
      let vm = this;
      vm.$store.state.eventBus.$emit("viewPostProfile", null);
    },
    calcMissingInfo() {
      let vm = this;
      if (vm.$getLoggedUser()) {
        if (!vm.$getLoggedUser().user.profile_image) {
          vm.completedSteps -= 33;
        }
        if (!vm.$getLoggedUser().user.phone) {
          vm.completedSteps -= 33;
        }
        if (!vm.$getLoggedUser().user.email) {
          vm.completedSteps -= 33;
        }
      }
    },
    checkMissings() {
      let vm = this;
      if (vm.$getLoggedUser()) {
        return (
          !vm.$getLoggedUser().user.profile_image ||
          !vm.$getLoggedUser().user.phone ||
          !vm.$getLoggedUser().user.email ||
          !vm.$getLoggedUser().user.activated
        );
      }
      return false;
    },
    verifyAccount() {
      let vm = this;
      if (vm.$getLoggedUser()) {
        let data = vm.$getLoggedUser().user;
        vm.$store.state.eventBus.$set(data, "type", "create");
        vm.$localStorage.set("user_register_data", JSON.stringify(data));
        vm.$router.push({
          name: "account-activate",
          params: { type: "code" },
        });
      }
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.notify-user-section {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 8px;
  justify-content: space-between;
  @media screen and (max-width: 500px) {
    grid-template-columns: 100%;
  }
  .notify-item {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    .progress {
      width: 120px;
    }
    .message {
      padding: 0px 20px;
    }
  }
}
</style>