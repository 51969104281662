<template>
  <div class="cagura-wrapper">
    <loading
      :height="60"
      color="#8cc73f"
      :opacity="0"
      :width="60"
      :active.sync="is_loading"
      :is-full-page="true"
      :can-cancel="false"
      background-color="#8cc73f"
      class="loader"
    ></loading>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "cagura-auth",
  components: {},
  data() {
    return {
      is_loading: true,
      form: {
        code: "",
        user_type: "sale",
        query: "",
      },
      type: "",
    };
  },
  mounted() {
    let vm = this;
    if (vm.$getLoggedUser()) {
      vm.$router.push({ name: "home" }).catch(() => {});
    } else {
      if (vm.$route.query.code) {
        vm.form.code = vm.$route.query.code;
        if (vm.$route.params.app == "google") {
          if (vm.$route.params.type == "login") {
            vm.confirmGoogleLoginAuth();
          } else if (vm.$route.params.type == "register") {
            vm.confirmGoogleRegisterAuth();
          } else {
            vm.is_loading = false;
            vm.$router.push({ name: "home" }).catch(() => {});
          }
        } else if (vm.$route.params.app == "facebook") {
          if (vm.$route.params.type == "login") {
            vm.confirmFacebookLoginAuth();
          } else if (vm.$route.params.type == "register") {
            vm.confirmFacebookRegisterAuth();
          } else {
            vm.is_loading = false;
            vm.$router.push({ name: "home" }).catch(() => {});
          }
        } else {
          vm.is_loading = false;
          vm.$router.push({ name: "home" }).catch(() => {});
        }
      } else {
        vm.is_loading = false;
        vm.$router.push({ name: "home" }).catch(() => {});
      }
    }
  },
  methods: {
    accountCallBackUrl(app, access_token) {
      let vm = this;
      vm.loading = true;
      vm.$store
        .dispatch("ACCOUNT_URL_CALLBACK", {
          app: app,
        })
        .then((response) => {
          vm.$localStorage.remove("redirection_app");
          if (response.data.url) {
            window.location.replace(
              response.data.url + "?code=" + access_token
            );
          }
        })
        .catch(({ response }) => {
          vm.loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
          console.log("ACCOUNT_LOGIN", response);
        });
    },
    confirmFacebookLoginAuth() {
      let vm = this;
      vm.is_loading = true;
      let query = "?";
      if (vm.$route.query) {
        var keys = Object.keys(vm.$route.query);
        keys.forEach((item) => {
          query += item + "=" + vm.$route.query[item] + "&";
        });
      }
      vm.form.query = query;
      vm.$store
        .dispatch("CONFIRM_FACEBOOK_LOGIN_AUTH", vm.form)
        .then((response) => {
          if (response.data.status == "ok") {
            if (vm.$localStorage.get("redirection_app")) {
              vm.accountCallBackUrl(
                vm.$localStorage.get("redirection_app"),
                response.data.access_token
              );
            } else {
              vm.$notify({
                group: "status",
                type: "success",
                title: "Important message",
                text: response.data.message,
              });
              let user = {
                access_token: response.data.access_token,
                user: response.data.user,
              };
              vm.$saveLoginInfo(user);
              vm.$appRedirect();
            }
          } else {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: "Link was expired, login again",
            });
            vm.$router.push({ name: "login" });
          }
          vm.is_loading = false;
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
          vm.$router.push({ name: "login" });
          console.error("CONFIRM_FACEBOOK_LOGIN_AUTH", { error });
        });
    },
    confirmFacebookRegisterAuth() {
      let vm = this;
      vm.is_loading = true;
      if (vm.$localStorage.get("cagura_user_type")) {
        vm.form.user_type = vm.$localStorage.get("cagura_user_type");
        vm.$localStorage.remove("cagura_user_type");
      }
      vm.$store
        .dispatch("CONFIRM_FACEBOOK_REGISTER_AUTH", vm.form)
        .then((response) => {
          if (response.data.status == "ok") {
            if (vm.$localStorage.get("redirection_app")) {
              vm.accountCallBackUrl(
                vm.$localStorage.get("redirection_app"),
                response.data.access_token
              );
            } else {
              vm.$notify({
                group: "status",
                type: "success",
                title: "Important message",
                text: response.data.message,
              });
              let user = {
                access_token: response.data.access_token,
                user: response.data.user,
              };
              vm.$saveLoginInfo(user);
              vm.$appRedirect();
            }
          } else {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: "Link was expired, login again",
            });
            vm.$router.push({ name: "login" });
          }
          vm.is_loading = false;
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
          vm.$router.push({ name: "login" });
          console.error("CONFIRM_FACEBOOK_REGISTER_AUTH", { error });
        });
    },
    confirmGoogleRegisterAuth() {
      let vm = this;
      vm.is_loading = true;
      if (vm.$localStorage.get("cagura_user_type")) {
        vm.form.user_type = vm.$localStorage.get("cagura_user_type");
        vm.$localStorage.remove("cagura_user_type");
      }
      vm.$store
        .dispatch("CONFIRM_GOOGLE_REGISTER_AUTH", vm.form)
        .then((response) => {
          if (response.data.status == "ok") {
            if (vm.$localStorage.get("redirection_app")) {
              vm.accountCallBackUrl(
                vm.$localStorage.get("redirection_app"),
                response.data.access_token
              );
            } else {
              vm.$notify({
                group: "status",
                type: "success",
                title: "Important message",
                text: response.data.message,
              });
              let user = {
                access_token: response.data.access_token,
                user: response.data.user,
              };
              vm.$saveLoginInfo(user);
              vm.$appRedirect();
            }
          } else {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: "Link was expired, login again",
            });
            vm.$router.push({ name: "login" });
          }
          vm.is_loading = false;
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
          vm.$router.push({ name: "login" });
          console.error("CONFIRM_GOOGLE_REGISTER_AUTH", { error });
        });
    },
    confirmGoogleLoginAuth() {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("CONFIRM_GOOGLE_LOGIN_AUTH", vm.form)
        .then((response) => {
          if (response.data.status == "ok") {
            if (vm.$localStorage.get("redirection_app")) {
              vm.accountCallBackUrl(
                vm.$localStorage.get("redirection_app"),
                response.data.access_token
              );
            } else {
              vm.$notify({
                group: "status",
                type: "success",
                title: "Important message",
                text: response.data.message,
              });
              let user = {
                access_token: response.data.access_token,
                user: response.data.user,
              };
              vm.$saveLoginInfo(user);
              vm.$appRedirect();
            }
          } else {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: "Link was expired, login again",
            });
            vm.$router.push({ name: "login" });
          }
          vm.is_loading = false;
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
          vm.$router.push({ name: "login" });
          console.error("CONFIRM_GOOGLE_LOGIN_AUTH", { error });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
}
</style>
