var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notification-item-widget"},[_c('div',{staticClass:"notification-item"},[_c('div',{staticClass:"notification-item-header"},[(_vm.item.status == 'new')?_c('span',{staticClass:"notification-dot"}):_vm._e(),_vm._m(0),_vm._m(1),_c('div',{staticClass:"notification-date"},[_vm._v(" "+_vm._s(_vm.moment(_vm.item.created_at).format("dddd, MMM Do YYYY, h:mm A"))+" ")])]),_c('div',{staticClass:"notification-item-body"},[_c('div',{staticClass:"notification-content"},[_c('p',{staticClass:"content"},[(_vm.item.user_type == 'guest')?_c('b',[_vm._v(_vm._s(_vm.item.user.names))]):_c('b',[_vm._v(_vm._s(_vm.item.user.first_name)+" "+_vm._s(_vm.item.user.last_name))]),_vm._v(" sent a message on "),(_vm.item.shop)?_c('span',{staticClass:"link"},[_vm._v(_vm._s(_vm.item.shop.shop_name))]):_vm._e(),_vm._v(" shop ")]),_c('p',{staticClass:"notification_desc"},[_vm._v(" "+_vm._s(_vm.item.notification_message)+" ")])]),_c('div',{staticClass:"profile-image"},[(_vm.item.user.profile_image)?_c('img',{attrs:{"src":_vm.$store.state.ASSETS_BASE_URL +
            '/assets/uploaded/' +
            _vm.item.user.profile_image}}):_c('img',{attrs:{"src":require("@/assets/images/icons/empty_profile.svg")}})])]),_c('div',{staticClass:"notification-item-footer"},[(_vm.item.shop)?_c('button',{staticClass:"btn-view",on:{"click":function($event){_vm.$router.push({
            path: _vm.$getShopLink(_vm.item.shop),
          })}}},[_vm._v(" View Shop ")]):_vm._e(),(_vm.item.status == 'new')?_c('c-button',{staticClass:"btn-mark",attrs:{"loading":_vm.mark_notification_loading},on:{"click":function($event){return _vm.markNotification(_vm.item, 'seen')}}},[_vm._v("Mark as read")]):_c('c-button',{staticClass:"btn-mark",attrs:{"loading":_vm.mark_notification_loading},on:{"click":function($event){return _vm.markNotification(_vm.item, 'new')}}},[_vm._v("Mark as unread")]),_c('c-button',{staticClass:"btn-remove",on:{"click":function($event){return _vm.removeNotification(_vm.item)}}},[_vm._v("Remove")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notification-icon"},[_c('img',{attrs:{"src":require("@/assets/images/icons/shop-message.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notification-title"},[_c('b',[_vm._v("You")]),_vm._v(" got message on "),_c('b',[_vm._v("your shop")])])
}]

export { render, staticRenderFns }