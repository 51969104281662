import {
    GET_ALL_MOVIES,
    GET_MAIN_MOVIE,
    GET_MOVIE_INFO,
    GET_MOVIE_EPISODES,
    GET_MOVIE_ITEM_TO_PLAY,
    ADD_MOVIE_MYLIST,
    GET_MYLIST_MOVIES,
    SEARCH_MOVIES,
    LIKE_MOVIE,
    UNLIKE_MOVIE,
    SAVE_MOVIE_WACTCHING_HISTORY,
    GET_WATCHING_HISTORY,
    REMOVE_WATCHING_HISTORY,
    SUBMIT_MOVIE_FEEDBACK,
    MOVIE_SUBSCRIBE,
    MOVIE_CHECK_PAYMENT,
    STREAMING_GET_MAIN_CHANNEL,
    STREAMING_GET_ALL_CHANNEL,
    MOVIES_GET_ALL_CATEGORIZED_MOVIES
} from "../actions/movies";

import {
    API_BASE_URL,
    GET_ALL_MOVIES_URL,
    GET_MAIN_MOVIE_URL,
    GET_MOVIE_INFO_URL,
    GET_MOVIE_EPISODES_URL,
    GET_MOVIE_ITEM_TO_PLAY_URL,
    ADD_MOVIE_MYLIST_URL,
    GET_MYLIST_MOVIES_URL,
    SEARCH_MOVIES_URL,
    LIKE_MOVIE_URL,
    UNLIKE_MOVIE_URL,
    SAVE_MOVIE_WACTCHING_HISTORY_URL,
    GET_WATCHING_HISTORY_URL,
    REMOVE_WATCHING_HISTORY_URL,
    SUBMIT_MOVIE_FEEDBACK_URL,
    MOVIE_SUBSCRIBE_URL,
    MOVIE_CHECK_PAYMENT_URL,
    STREAMING_GET_MAIN_CHANNEL_URL,
    STREAMING_GET_ALL_CHANNEL_URL,
    MOVIES_GET_ALL_CATEGORIZED_MOVIES_URL
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [MOVIES_GET_ALL_CATEGORIZED_MOVIES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIES_GET_ALL_CATEGORIZED_MOVIES_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [STREAMING_GET_ALL_CHANNEL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + STREAMING_GET_ALL_CHANNEL_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [STREAMING_GET_MAIN_CHANNEL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + STREAMING_GET_MAIN_CHANNEL_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [MOVIE_SUBSCRIBE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIE_SUBSCRIBE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [MOVIE_CHECK_PAYMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIE_CHECK_PAYMENT_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SUBMIT_MOVIE_FEEDBACK]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SUBMIT_MOVIE_FEEDBACK_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [REMOVE_WATCHING_HISTORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + REMOVE_WATCHING_HISTORY_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [GET_WATCHING_HISTORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_WATCHING_HISTORY_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SAVE_MOVIE_WACTCHING_HISTORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SAVE_MOVIE_WACTCHING_HISTORY_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [LIKE_MOVIE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + LIKE_MOVIE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [UNLIKE_MOVIE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + UNLIKE_MOVIE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SEARCH_MOVIES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SEARCH_MOVIES_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [GET_MYLIST_MOVIES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_MYLIST_MOVIES_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [ADD_MOVIE_MYLIST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + ADD_MOVIE_MYLIST_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [GET_MOVIE_ITEM_TO_PLAY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_MOVIE_ITEM_TO_PLAY_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [GET_MOVIE_EPISODES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_MOVIE_EPISODES_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [GET_MOVIE_INFO]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_MOVIE_INFO_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [GET_MAIN_MOVIE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_MAIN_MOVIE_URL,
            method: "GET",
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [GET_ALL_MOVIES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_ALL_MOVIES_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
};


export default {
    mutations,
    actions,
    getters,
    state
};