<template>
  <div class="cagura-wrapper">
    <div class="cagura-nav-bar">
      <img
        @click.prevent="$router.push({ name: 'home' }).catch(() => {})"
        src="@/assets/images/logo.png"
        alt="Cagura Logo"
        class="cagura-logo"
      />
    </div>
    <div class="body">
      <div class="verify-wrapper">
        <div class="image-section">
          <img src="@/assets/images/verify-img.svg" alt />
        </div>
        <div class="verify-section">
          <div class="verify-header">
            <h2>
              Hello there, Provide your Phone or Email to reset your Password
            </h2>
          </div>
          <div class="form-widget">
            <div class="input-group">
              <div class="input-widget">
                <input
                  type="email"
                  class="bg-cagura-form-control"
                  name="email"
                  placeholder="Phone or Email"
                  v-model="form.phone_email"
                />
              </div>
            </div>

            <div class="form-option">
              <c-button
                class="verify-btn"
                :loading="is_loading"
                @click="verifyEmail()"
                >SUBMIT</c-button
              >
              <div class="have-account-section">
                <p>
                  Remember Password?
                  <a
                    @click.prevent="
                      $router.push({ name: 'login' }).catch(() => {})
                    "
                    >SIGN IN</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "cagura-verify",
  components: {},
  data() {
    return {
      form: { type: "reset", phone_email: "" },
      is_loading: false,
    };
  },
  mounted() {
    let vm = this;
    if (vm.$getLoggedUser()) {
      vm.$router.push({ name: "home" }).catch(() => {});
    }
  },
  methods: {
    verifyEmail() {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("VERIFY_EMAIL_FOR_RESET", vm.form)
        .then((response) => {
          vm.is_loading = false;
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
          if (response.data.return == "phone") {
            vm.$localStorage.set("user_register_data", JSON.stringify(vm.form));
            vm.$router.push({
              name: "account-activate",
              params: { type: "code" },
              query: { type: "reset" },
            });
          } else {
            setTimeout(() => {
              vm.$router.push({ name: "home" }).catch(() => {});
            }, 1000 * 30);
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
          console.log("VERIFY_EMAIL_FOR_RESET", { error });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  padding: 30px 40px;
  width: 100%;
  .cagura-nav-bar {
    .cagura-logo {
      width: 200px;
      margin-left: -10px;
      @media screen and (max-width: 500px) {
        margin-left: -26px;
      }
    }
  }
  .body {
    margin-top: 30px;
    .verify-wrapper {
      display: grid;
      grid-template-columns: 46% 46%;
      grid-column-gap: 40px;
      @media screen and (max-width: 500px) {
        grid-template-columns: 100%;
      }
      .image-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @media screen and (max-width: 500px) {
          display: none;
        }
        img {
          width: 100%;
        }
      }
      .verify-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .verify-header {
          display: flex;
          h2 {
            font-size: 30px;
            color: #8dc73f;
            @media screen and (max-width: 500px) {
              font-size: 18px;
            }
          }
        }
        .form-widget {
          border: 1px solid #ddd;
          padding: 60px 60px;
          @media screen and (max-width: 500px) {
            padding: 10px 0px;
            border: none;
          }
          .twin-input {
            display: grid;
            grid-template-columns: 48% 48%;
            grid-column-gap: 20px;
            .input-group {
              width: 100%;
              margin-bottom: 15px;
              .input-widget {
                display: flex;
                .bg-cagura-form-control {
                  height: 50px;
                  width: 100%;
                  padding: 0px 15px;
                  background: #eeeeee;
                  border: none;
                  border-radius: 5px;
                  outline: none;
                  font-size: 16px;
                  font-weight: 500;
                  &::placeholder {
                    color: #b2b2b2;
                  }
                }
              }
            }
          }
          .input-group {
            width: 100%;
            margin-bottom: 15px;
            .input-widget {
              display: flex;
              .bg-cagura-form-control {
                height: 50px;
                width: 100%;
                padding: 0px 15px;
                background: #eeeeee;
                border: none;
                border-radius: 5px;
                outline: none;
                font-size: 16px;
                font-weight: 500;
                &::placeholder {
                  color: #b2b2b2;
                }
              }
            }
            .account-type {
              display: flex;
              padding: 20px 0px;
              .type-item {
                margin-right: 20px;
              }
            }
          }
          .form-option {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: 500px) {
              flex-direction: column;
              margin-bottom: 20px;
            }
            .verify-btn {
              width: 154px;
              height: 45px;
              background: #8dc73f;
              border-radius: 5px;
              color: #fff;
              border: none;
              @media screen and (max-width: 500px) {
                margin-bottom: 20px;
              }
            }
            .have-account-section {
              a {
                color: #8dc73f;
                text-decoration: none;
                cursor: pointer;
              }
            }
          }

          .other-verify-option {
            display: flex;
            justify-content: space-between;
            padding-top: 15px;
            @media screen and (max-width: 500px) {
              margin-bottom: 20px;
            }
            .option {
              display: flex;
              align-items: center;
              background: #ffffff;
              box-shadow: 0px 2px 5.7px rgba(20, 20, 20, 0.21);
              border-radius: 5px;
              padding: 10px 10px;
              cursor: pointer;
              .logo {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
