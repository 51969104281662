<template>
    <div class="cagura-wrapper">
        <!-- Navbar -->
        <client-header />
        <!-- Navbar -->
        <div class="cagura-container">
            <!-- Side bar -->
            <side-bar />
            <!-- End of sidebar -->
            <!-- Content - body -->
            <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
                <vue-scroll>
                    <!-- Dashboard widget -->
                    <div class="dashboard-widgets">
                        <div class="item">
                            <div class="head">
                                <span class="title">TOTAL LINKS SHARED</span>
                            </div>
                            <div class="body">
                                <div class="numbers-widget">
                                    <h3 class="count">{{ total_links_shared }}</h3>
                                    <div class="parc">
                                        <img v-if="getLinkSharedParc(total_links_shared) >= 50" src="@/assets/images/icons/trend-up.svg" />
                                        <img v-if="getLinkSharedParc(total_links_shared) < 50" src="@/assets/images/icons/trend-down.svg" />
                                        <span class="number">{{ getLinkSharedParc(total_links_shared) }}%</span
                        >
                      </div>
                    </div>
                    <div class="icon-preview">
                      <img src="@/assets/images/icons/graph_1.svg" />
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="head">
                    <span class="title">TOTAL CLICKS</span>
                                    </div>
                                    <div class="body">
                                        <div class="numbers-widget">
                                            <h3 class="count">{{ total_clicks }}</h3>
                                            <div class="parc">
                                                <img v-if="getClicksParc() >= 50" src="@/assets/images/icons/trend-up.svg" />
                                                <img v-if="getClicksParc() < 50" src="@/assets/images/icons/trend-down.svg" />
                                                <span class="number">{{ getClicksParc() }}%</span>
                                            </div>
                                        </div>
                                        <div class="icon-preview">
                                            <img src="@/assets/images/icons/graph_2.svg" />
                                        </div>
                                    </div>
                                </div>
    
                                <div class="item">
                                    <div class="head">
                                        <span class="title">BALANCE (RWF)</span>
                                        <Help>
                                            You can only get your cash if we get first winner in the link your shared, may be winner can be you.
                                        </Help>
                                    </div>
                                    <div class="body">
                                        <div class="numbers-widget">
                                            <h3 class="count">
                                                {{ $formatMoney( total_balance, (decimalCount = 0), (decimal = "."), (thousands = ",") ) }}
                                            </h3>
                                            <div class="parc" v-if="true">
                                                <img v-if="getBaranceParc() >= 50 && getBaranceParc() < 99" src="@/assets/images/icons/trend-up.svg" />
                                                <img v-if="getBaranceParc() < 50" src="@/assets/images/icons/trend-down.svg" />
                                                <span class="number">{{ getBaranceParc() }}%</span>
                                            </div>
                                        </div>
                                        <div class="icon-preview">
                                            <img src="@/assets/images/icons/graph_3.svg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <!-- My shared link -->
                            <div class="link-widget" v-if="
                  my_affiliate_links.length > 0 && !is_get_affiliate_links_loading
                ">
                                <div class="header">
                                    <h3>MY SHARED LINKS</h3>
                                </div>
                                <div class="body" v-if="my_affiliate_links.length > 0">
                                    <div class="item" v-for="(item, index) in my_affiliate_links" :key="index">
                                        <div class="image-section">
                                            <img :src="
                          $store.state.ASSETS_BASE_URL +
                          '/assets/uploaded/' +
                          item.image
                        " />
                                        </div>
                                        <div class="content">
                                            <div class="content-header">
                                                <h4>{{ item.title }}</h4>
                                                <div class="option">
                                                    <button class="btn-copy" @click="copyCopiedLink(item)">
                            <img src="@/assets/images/icons/copy.svg" />
                          </button>
                                                </div>
                                            </div>
                                            <div>
                                               <p>
                          Expire date: {{ moment(item.end_date).format("MMM Do YYYY") }}
                        </p>
                                            </div>
                                            <div class="widged-content">
                                                <div class="content-body">
                                                    <div class="stati-content" v-if="
                              item.middle_views_needed &&
                              calculateCommition(item) <= item.middle_win_price
                            ">
                                                        <span class="clicks">Views:{{ item.user_count }}/{{
                                item.middle_views_needed
                              }}</span
                            >
                            <span class="commission"
                              >FRW{{
                                $formatMoney(
                                  calculateCommition(item),
                                  (decimalCount = 0),
                                  (decimal = "."),
                                  (thousands = ",")
                                )
                              }}/{{
                                $formatMoney(
                                  item.middle_win_price,
                                  (decimalCount = 0),
                                  (decimal = "."),
                                  (thousands = ",")
                                )
                              }}</span
                            >
                          </div>
                          <div class="stati-content">
                            <span class="clicks"
                              >Views:{{ item.user_count }}/{{
                                item.views_needed
                              }}</span
                            >
                            <span class="commission"
                              >FRW{{
                                $formatMoney(
                                  calculateCommition(item),
                                  (decimalCount = 0),
                                  (decimal = "."),
                                  (thousands = ",")
                                )
                              }}/{{
                                $formatMoney(
                                  item.win_price,
                                  (decimalCount = 0),
                                  (decimal = "."),
                                  (thousands = ",")
                                )
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    
              <!-- Link to be shared-->
              <div class="link-widget">
                <div class="header">
                  <h3>LINKS TO BE SHARED</h3>
                </div>
                <div class="body" v-if="affiliate_links.length > 0">
                  <div
                    class="item"
                    v-for="(item, index) in affiliate_links"
                    :key="index"
                  >
                    <div class="image-section">
                      <img
                        :src="
                          $store.state.ASSETS_BASE_URL +
                          '/assets/uploaded/' +
                          item.image
                        "
                      />
                    </div>
                    <div class="content">
                      <div class="content-header">
                        <h4>{{ item.title }}</h4>
                        <div class="option">
                          <button
                            class="btn-copy"
                            title="Copy link"
                            @click="copyLink(item)"
                          >
                            <img src="@/assets/images/icons/copy.svg" />
                          </button>
                        </div>
                      </div>
                      <div>
                        <p>{{ item.content }}</p>
                        <p>
                          Expire date: {{ moment(item.end_date).format("MMM Do") }}
                        </p>
                      </div>
                      <div class="widged-content">
                        <div class="widged-content-header">
                          <label>Winner</label>
                        </div>
                        <div class="content-body">
                          <div
                            class="stati-content"
                            v-if="item.middle_views_needed"
                          >
                            <span class="clicks"
                              >VIEWS:{{ item.middle_views_needed }}</span
                            >
                            <span class="commission"
                              >FRW{{
                                $formatMoney(
                                  item.middle_win_price,
                                  (decimalCount = 0),
                                  (decimal = "."),
                                  (thousands = ",")
                                )
                              }}</span
                            >
                          </div>
                          <div class="stati-content">
                            <span class="clicks"
                              >VIEWS:{{ item.views_needed }}</span
                            >
                            <span class="commission"
                              >FRW{{
                                $formatMoney(
                                  item.win_price,
                                  (decimalCount = 0),
                                  (decimal = "."),
                                  (thousands = ",")
                                )
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="empty-widget">
                    <a-empty
                      :image="require('@/assets/images/icons/empty.svg')"
                      :image-style="{ height: '116px' }"
                    >
                      <span slot="description"
                        >Please wait other links are comming soon to continue making
                        money !!!!</span
                      >
                    </a-empty>
                  </div>
                </div>
              </div>
              <div
                class="loader-widget"
                style="height: 300px"
                v-if="is_get_affiliate_links_loading"
              >
                <div class="loading-panel">
                  <a-spin :spinning="true"></a-spin>
                  <span class="text">Loading...</span>
                                                    </div>
                                                </div>
                </vue-scroll>
                </div>
                </div>
    
                <!-- Affiliate Modal -->
                <div class="overlay" v-if="show_affiliate_modal">
                    <div class="whatsnew-modal">
                        <div class="header">
                            <h3>CAGURA AFFILIATE PROGRAM</h3>
                        </div>
                        <div class="body">
                            <vue-scroll>
                                <div class="body-content">
                                    <h5>Affiliate Agreement</h5>
                                    <div class="content">
                                        <p>
                                            An Affiliate Agreement is a document through which two parties, the company and the affiliate, form a relationship whereby the affiliate receives funds for certain qualified actions. Online affiliate agreements can take one of two forms: affiliate agreements
                                            whereby the affiliate receives funds for user clicks through to the company's website or affiliate agreements whereby the affiliate receives funds for each shared link that reaches to the target number. For the
                                            company's goods or services.
                                        </p>
                                        <p>This program is intended for Rwandans only.</p>
                                        <p>
                                            Cagura want to make sure that views are coming from real people (not robot). That’s why a Cagura view is only counted when the following criteria apply:
                                            <ul>
                                                <li>If user goes on link for at least 30 seconds and above.</li>
                                                <li>If the views are not coming from wrong users (robot)</li>
                                                <li>If the user are following all required instructions(Having Account on Cagura and Accept this aggrement)</li>
                                            </ul>
                                        </p>
                                        <p>
                                            Affiliate Agreements are very common nowadays, as many individuals who choose to work online can make significant income from affiliate relationships, depending on the reach of their websites or social media. Many users on big social media platforms contract
                                            with companies to promote products or services. When the company receives clicks or purchases from the affiliate's individualized links, the affiliate then receives a set of amount per link’s click target.
                                        </p>
                                        <p>We decide number on winner from 1 to above, Only the first one is the one who reaches to the target number we need before others</p>
                                        <p>
                                            In these types of agreements, the affiliate most often does not get a say in the specific terms. The affiliate simply signs up for the program that the company is offering and there is no bargaining over the agreement. These agreements are not signed,
                                            they are simply posted up on the company's website for the affiliate to read and agree to prior to signing up for the affiliate program.
                                        </p>
                                    </div>
                                </div>
                            </vue-scroll>
                        </div>
                        <div class="footer">
                            <c-button :loading="accept_affiliate_loading" @click="acceptAffiliateProgram()" class="btn-success">ACCEPT & GET STARTER</c-button>
                            <button @click="cancelAffiliateProgram()" class="btn-default">
                CANCEL
              </button>
                        </div>
                    </div>
                </div>
    
                <!-- Copied Modal -->
                <div class="overlay" v-if="show_copy_modal">
                    <div class="copy-modal">
                        <div class="header">
                            <h3>CONGLATULATIONS !</h3>
                        </div>
                        <div class="body">
                            <vue-scroll>
                                <div class="body-content">
                                    <img src="@/assets/images/icons/done.svg" />
                                    <div class="content">
                                        Link is copied, share the link and start to make money for every click.
                                    </div>
                                    <div class="form-input">
                                        <input type="text" v-model="share_link_input" readonly class="link-input" />
                                        <button class="btn-copy" title="Copy" v-clipboard:copy="share_link_input" v-clipboard:success="onCopy" v-clipboard:error="onError">
                      <a-icon type="copy" />
                    </button>
                                    </div>
                                </div>
                            </vue-scroll>
                        </div>
                        <div class="footer">
                            <button @click="closeCopyModal()" class="btn-success">BACK</button>
                        </div>
                    </div>
                </div>
    
                <!-- Cash-out Modal -->
                <div class="overlay" v-if="show_cashout_modal">
                    <div class="cashout-modal">
                        <div class="header">
                            <h3>CASH OUT</h3>
                        </div>
                        <div class="body">
                            <vue-scroll>
                                <div class="body-content">
                                    <div class="content">
                                        Provide Mobile Money number to receive your funds
                                    </div>
                                    <div class="form-input">
                                        <input type="text" placeholder="07......." v-model="form.phone_number" class="link-input" />
                                    </div>
                                </div>
                            </vue-scroll>
                        </div>
                        <div class="footer">
                            <c-button :loading="is_loading_cashout" @click="submitCashoutRequest()" class="btn-success">
                                SUBMIT
                            </c-button>
                            <button @click="show_cashout_modal = false" class="btn-default">
                CANCEL
              </button>
                        </div>
                    </div>
                </div>
    
                <!-- Cash-out done Modal -->
                <div class="overlay" v-if="show_cashout_done_modal">
                    <div class="copy-modal">
                        <div class="header">
                            <h3>CASH OUT</h3>
                        </div>
                        <div class="body">
                            <vue-scroll>
                                <div class="body-content">
                                    <img src="@/assets/images/icons/done.svg" />
                                    <div class="content">
                                        The amount of FRW20,000 will be deposited to your mobile money account!
                                    </div>
                                </div>
                            </vue-scroll>
                        </div>
                        <div class="footer">
                            <button @click="show_cashout_done_modal = false" class="btn-success">
                BACK
              </button>
                        </div>
                    </div>
                </div>
    
                <!-- When use not logged in -->
                <ask-auth  
                @onCancel="ask_for_account = false"
                v-if="ask_for_account"  
                message="to start this program of making money."></ask-auth>
                </div>
</template>


<script>
import ClientHeader from "../components/partials/header.vue";
import SideBar from "../components/partials/sidebar.vue";

export default {
  name: "affiliate",
  components: {
    ClientHeader,
    SideBar,
  },
  data() {
    return {
      show_affiliate_modal: false,
      accept_affiliate_loading: false,
      is_loading_my_links: false,
      is_get_affiliate_links_loading: false,
      affiliate_links: [],
      my_affiliate_links: [],
      share_link_input: "",
      show_copy_modal: false,
      total_links_shared: 0,
      total_clicks: 0,
      total_balance: 0,
      total_win_balance: 0,
      total_win_clicks: 0,
      show_cashout_modal: false,
      show_cashout_done_modal: false,
      is_loading_cashout: false,
      is_loading: false,
      form: { phone_number: "" },
      ask_for_account: false,
    };
  },
  mounted() {
    let vm = this;
    // Check if user is in affiliate program and agree to enter into it
    vm.checkUserIsAnaffilate();
    vm.getLinkToShare();
    vm.getCopiedLinks();
    if (!vm.$getLoggedUser()) {
      vm.ask_for_account = true;
    }
  },
  methods: {
    calculateCommition(item) {
      if (item.user_count >= item.views_needed) {
        return item.win_price;
      } else if (
        item.user_count >= item.middle_views_needed &&
        item.user_count < item.views_needed
      ) {
        return item.middle_win_price;
      }
      return 0;
    },
    submitCashoutRequest() {
      let vm = this;
      vm.is_loading_cashout = true;
      vm.$store
        .dispatch("CASHOUT_AFFILIATE_MONEY", vm.form)
        .then((response) => {
          vm.is_loading_cashout = false;
          vm.show_cashout_modal = false;
          vm.show_cashout_done_modal = true;
        })
        .catch(({ response }) => {
          vm.is_loading_cashout = false;
          console.error("CASHOUT_AFFILIATE_MONEY", response);
        });
    },
    showCashOut() {
      let vm = this;
      vm.show_cashout_modal = true;
    },
    getLinkSharedParc(total_links_shared) {
      let vm = this;
      if (total_links_shared) {
        return parseInt(
          (total_links_shared * 100) /
            (vm.my_affiliate_links.length + vm.affiliate_links.length)
        );
      }
      return 0;
    },
    getClicksParc() {
      let vm = this;
      if (vm.total_clicks) {
        return parseInt((vm.total_clicks * 100) / vm.total_win_clicks);
      }
      return 0;
    },
    getBaranceParc() {
      let vm = this;
      if (vm.total_balance) {
        return parseInt((vm.total_balance * 100) / vm.total_win_balance);
      }
      return 0;
    },
    getCopiedLinks() {
      let vm = this;
      vm.is_loading_my_links = true;
      vm.$store
        .dispatch("AFFILIATE_GET_COPYED_LINKS")
        .then((response) => {
          vm.is_loading_my_links = false;
          vm.my_affiliate_links = response.data.return;
          vm.total_links_shared = vm.my_affiliate_links.length;
          vm.my_affiliate_links.map((item) => {
            vm.total_clicks += item.user_count;
            vm.total_win_clicks += item.views_needed;
            vm.total_balance += vm.calculateCommition(item);
            vm.total_win_balance += item.win_price;
          });
        })
        .catch(({ response }) => {
          vm.is_loading_my_links = false;
          console.error("AFFILIATE_GET_COPYED_LINKS", response);
        });
    },
    copyCopiedLink(item) {
      let vm = this;
      let url = vm.$store.state.BASE_URL + "af/" + item.token;
      vm.share_link_input = url;
      vm.$copyText(url).then(
        (e) => {
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: "Link copied",
          });
        },
        (e) => {
          vm.$notify({
            group: "status",
            type: "warn",
            title: "Important message",
            text: "Failed to copy",
          });
        }
      );
      vm.show_copy_modal = true;
    },
    copyLink(item) {
      let vm = this;
      let obj = {
        id: item.id,
        token: vm.token(2),
      };
      let url = vm.$store.state.BASE_URL + "af/" + obj.token;
      vm.share_link_input = url;
      vm.$store
        .dispatch("AFFILIATE_COPY_LINK", obj)
        .then((response) => {
          vm.show_copy_modal = true;
          vm.getLinkToShare();
          vm.getCopiedLinks();
          vm.$copyText(url).then(
            (e) => {
              vm.$notify({
                group: "status",
                type: "success",
                title: "Important message",
                text: "Link copied",
              });
            },
            (e) => {
              vm.$notify({
                group: "status",
                type: "warn",
                title: "Important message",
                text: "Failed to copy",
              });
            }
          );
        })
        .catch(({ response }) => {
          vm.$notify({
            group: "status",
            type: "warn",
            title: "Important message",
            text: "Failed to copy",
          });
          console.error("AFFILIATE_COPY_LINK", response);
        });
    },
    closeCopyModal() {
      let vm = this;
      vm.show_copy_modal = false;
    },
    onCopy: function (e) {
      let vm = this;
      vm.$notify({
        group: "status",
        type: "success",
        title: "Important message",
        text: "Link copied",
      });
    },
    onError: function (e) {
      let vm = this;
      vm.$notify({
        group: "status",
        type: "warn",
        title: "Important message",
        text: "Failed to copy",
      });
    },
    getLinkToShare() {
      let vm = this;
      vm.is_get_affiliate_links_loading = true;
      vm.$store
        .dispatch("GET_AFFILIATE_LINKS")
        .then((response) => {
          vm.affiliate_links = response.data.return;
          vm.is_get_affiliate_links_loading = false;
        })
        .catch(({ response }) => {
          console.error("GET_AFFILIATE_LINKS", response);
          vm.is_get_affiliate_links_loading = false;
        });
    },
    acceptAffiliateProgram() {
      let vm = this;
      vm.accept_affiliate_loading = true;
      vm.$store
        .dispatch("ACCEPT_AFFILIATE_PROGRAM")
        .then((response) => {
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
          vm.$updateLoggedUser(response.data.user);
          vm.show_affiliate_modal = false;
          vm.accept_affiliate_loading = false;
        })
        .catch(({ response }) => {
          console.error("ACCEPT_AFFILIATE_PROGRAM", response);
          vm.show_whats_new_loading = false;
        });
    },
    cancelAffiliateProgram() {
      let vm = this;
      vm.$router.push({ name: "home" });
    },
    checkUserIsAnaffilate() {
      let vm = this;
      vm.show_affiliate_modal = false;
      if (vm.$getLoggedUser()) {
        if (
          vm.$getLoggedUser().user.is_affiliate &&
          vm.$getLoggedUser().user.is_affiliate_agreed
        ) {
          vm.show_affiliate_modal = false;
        } else {
          vm.show_affiliate_modal = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
    display: block;
    .cagura-body {
      .dashboard-widgets {
        display: grid;
        padding: 20px;
        grid-template-columns: 31.33% 31.33% 31.33%;
        justify-content: space-between;
        grid-gap: 20px;
        @media screen and (max-width: 500px) {
          grid-template-columns: 100%;
        }
        .item {
          display: flex;
          flex-direction: column;
          background: #fff;
          border: 1px solid #ddd;
          padding: 20px;
          border-radius: 5px;
          .head {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            .title {
              font-size: 15px;
            }
          }
          .body {
            display: flex;
            justify-content: space-between;
            .numbers-widget {
              margin-right: 30px;
              .count {
                font-size: 30px;
                font-weight: 800;
              }
            }
          }
        }
      }
      .link-widget {
        .header {
          padding: 0px 20px;
        }
        .body {
          display: grid;
          grid-template-columns: 23% 23% 23% 23%;
          justify-content: space-between;
          grid-gap: 20px;
          padding: 20px;
          @media screen and (max-width: 800px) {
            grid-template-columns: 48%;
          }
          @media screen and (max-width: 500px) {
            grid-template-columns: 100%;
          }
          .item {
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 5px;
            .image-section {
              width: 100%;
              img {
                width: 100%;
                height: 200px;
                object-fit: cover;
              }
            }
            .content {
              padding: 10px 15px;
              .content-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                h4 {
                  margin: 0px;
                  font-size: 13px;
                }
                .option {
                  .btn-copy {
                    background: transparent;
                    border: none;
                    outline: none;
                    img {
                      width: 25px;
                    }
                  }
                }
              }
              .widged-content {
                .widged-content-header {
                  display: flex;
                  justify-content: center;
                  label {
                    font-weight: 800;
                  }
                }
                .content-body {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 10px;
                  label {
                    font-size: 12px;
                    font-weight: 800;
                  }
                  .stati-content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .clicks {
                      font-size: 12px;
                    }
                    .commission {
                      font-size: 12px;
                      color: #8dc73f;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  } // What 's new modal
  .overlay {
    .whatsnew-modal {
      height: 500px;
      width: 400px;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 800px) {
        width: 100%;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
      }
      .header {
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;
        border-bottom: 1px solid #ccc;
        h3 {
          margin: 0px;
        }
      }
      .body {
        height: calc(100% - 120px);
        .body-content {
          padding: 15px;
          h5 {
            font-weight: 800;
            font-size: 15px;
          }
          p {
            text-align: left;
          }
        }
      }
      .footer {
        border-top: 1px solid #ccc;
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;
        justify-content: space-between;
      }
    }
    .copy-modal {
      width: 350px;
      border-radius: 5px;
      border: 1px solid #ddd;
      background: #fff;
      .header {
        display: flex;
        justify-content: center;
        padding: 20px 0px;
      }
      .body {
        .body-content {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          padding: 20px 20px;
          .content {
            text-align: center;
            padding: 20px 20px;
          }
          .form-input {
            width: 100%;
            display: flex;
            border: 1px solid #ddd;
            margin-top: 10px;
            .link-input {
              width: 100%;
              height: 40px;
              padding: 10px;
              border-radius: 5px;
              background: #fcfcfc;
              border: none;
            }
            .btn-copy {
              height: 40px;
              border: none;
              width: 64px;
            }
          }
        }
      }
      .footer {
        display: flex;
        justify-content: center;
        padding: 20px 0px;
      }
    }
    .cashout-modal {
      width: 350px;
      border-radius: 5px;
      border: 1px solid #ddd;
      background: #fff;
      .header {
        display: flex;
        justify-content: center;
        padding: 20px 0px;
        h3 {
          font-size: 30px;
        }
      }
      .body {
        .body-content {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          padding: 0px 60px;
          .content {
            text-align: center;
            padding: 0px 20px;
          }
          .form-input {
            width: 100%;
            display: flex;
            border: 1px solid #ddd;
            margin: 30px 0;
            .link-input {
              width: 100%;
              height: 40px;
              padding: 10px;
              border-radius: 5px;
              background: #fcfcfc;
              border: none;
            }
            .btn-copy {
              height: 40px;
              border: none;
              width: 64px;
            }
          }
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        padding: 20px 40px;
      }
    }
  }
}
</style>

