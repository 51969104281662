<template>
  <div class="cagura-wrapper">
    <ClientHeader v-if="is_youtube"></ClientHeader>
    <SideBar></SideBar>
    <loading
      :height="50"
      color="#fff"
      :opacity="1"
      :width="50"
      :active.sync="is_loading"
      :is-full-page="true"
      :can-cancel="false"
      background-color="#8cc73f"
      class="loader"
    ></loading>

    <div class="hytPlayerWrap" v-if="is_youtube">
      <YoutubePlayer
        :ytid="video_id"
        width="100%"
        height="100%"
        ref="yt"
        @ready="onPlayerLoaded()"
        @playing="onPlayerPlaying()"
        @paused="onPlayerPaused()"
        @ended="onPlayerEnded()"
        :playerVars="{
          controls: 0,
          autoplay: 1,
          modestbranding: 0,
          rel: 0,
          enablejsapi: 0,
        }"
      ></YoutubePlayer>
    </div>

    <iframe
      class="cagura-iframe"
      v-else
      :src="iframe_url"
      frameborder="0"
    ></iframe>

    <div class="cagura-annauncement" v-if="show_annauncement">
      <div class="header">
        <img
          @click="$router.push({ name: 'home' }).catch(() => {})"
          src="@/assets/images/logo.png"
          alt="Cagura Logo"
          class="cagura-logo"
        />
        <h3>WHAT’S NEW</h3>
      </div>
      <div class="body">
        <img src="@/assets/images/icons/affilate-icon.svg" />
        <p>
          Get <b>200</b> views for every link you shared and make up to
          <b>FRW 30,000</b> or more a very day
        </p>
      </div>
      <div class="footer">
        <c-button @click.prevent="navigateToAffiliate()" class="btn-success"
          >START NOW</c-button
        >
        <c-button
          v-if="show_skip_down"
          @click="skipWhatsNew()"
          class="btn-default"
          >SKIP</c-button
        >
        <span v-else>Skip in {{ count_down }}s</span>
      </div>
    </div>

    <transition name="fadeanim">
      <div class="cagura-new-present" v-if="show_ads && ad_item">
        <a :href="ad_item.link" target="_blank">
          <img
            :src="
              $store.state.ASSETS_BASE_URL + '/assets/uploaded/' + ad_item.image
            "
          />
        </a>
        <div class="content" v-if="show_ads_skip_button && ad_item.title">
          <h3>{{ ad_item.title }}</h3>
          <p>{{ ad_item.content }}</p>
          <button
            @click="skipAds()"
            v-if="show_ads_skip_button"
            class="btn btn-success btn-skip-add"
          >
            SKIP ADS
          </button>
        </div>
        <button
          @click="skipAds()"
          v-if="show_ads_skip_button && !ad_item.title"
          class="btn btn-success btn-skip-add"
        >
          SKIP ADS
        </button>
      </div>
    </transition>
    <!-- cagura banner -->
    <a class="cagura-banner" href="http://cagura.rw/" target="_blank">
      <img
        src="@/assets/images/logo.png"
        alt="Cagura Logo"
        class="cagura-logo"
      />
      <p>Powered by Cagura</p>
    </a>

    <!-- Fixed banner -->
    <a v-if="fixed_ads_data" :href="fixed_ads_data.link" target="_blank">
      <img
        class="fixed-banner"
        :src="
          $store.state.ASSETS_BASE_URL +
          '/assets/uploaded/' +
          fixed_ads_data.image
        "
        v-if="fixed_ads_data"
      />
    </a>
  </div>
</template>

<script>
// @ is an alias to /src
import ClientHeader from "../components/partials/header.vue";
import SideBar from "../components/partials/sidebar.vue";
export default {
  name: "cagura-affiliate",
  components: {
    ClientHeader,
    SideBar,
  },
  data() {
    return {
      iframe_url: "",
      show_whats_new_loading: false,
      show_what_new_modal: false,
      show_annauncement: true,
      is_loading: true,
      announcement_content: null,
      show_skip_down: false,
      count_down: 45,
      all_ads_data: [],
      show_ads: false,
      ad_item: null,
      count_ads: 1,
      show_ads_skip_button: true,
      get_ads_loading: false,
      is_youtube: false,
      video_id: "",
      count_playing_time: 0,
      fixed_ads_data: null,
    };
  },
  mounted() {
    let vm = this;
    vm.$store.commit("TOGGLE_SIDE_BAR", false);
    if (!vm.$localStorage.get("user_session")) {
      vm.$localStorage.set("user_session", vm.token(5));
    }
    if (vm.$route.params.token) {
      let token = vm.$route.params.token;
      vm.redirectToAffiliateLink(token);
    }
    vm.getFixedAds();
    vm.countDown();
    vm.getAllAds();
  },
  methods: {
    getFixedAds() {
      let vm = this;
      vm.$store
        .dispatch("GET_FIXED_AFFILIATE_ADS")
        .then((response) => {
          vm.fixed_ads_data = response.data.return;
        })
        .catch((error) => {
          console.error("GET_FIXED_AFFILIATE_ADS", error);
        });
    },
    onPlayerEnded() {
      for (let playerWrap of document.querySelectorAll(".hytPlayerWrap")) {
        playerWrap.classList.add("ended");
      }
    },
    onPlayerPaused() {
      for (let playerWrap of document.querySelectorAll(".hytPlayerWrap")) {
        playerWrap.classList.add("paused");
      }
    },
    onPlayerPlaying() {
      let vm = this;
      // let token = vm.$route.params.token;
      // // if (vm.count_playing_time == 35) {
      // //   vm.rewardAffiliateLinkOwner(token);
      // // }
      // vm.count_playing_time += 1;

      for (let playerWrap of document.querySelectorAll(".hytPlayerWrap")) {
        playerWrap.classList.remove("ended");
        playerWrap.classList.remove("paused");
      }
    },
    onPlayerLoaded() {
      let vm = this;
      vm.is_loading = false;
      for (let playerWrap of document.querySelectorAll(".hytPlayerWrap")) {
        playerWrap.addEventListener("click", () => {
          vm.$refs.yt.player.playVideo();
        });
      }
    },
    skipAds() {
      let vm = this;
      vm.show_ads = false;
    },
    adsShowTimeout(time) {
      let vm = this;
      setTimeout(() => {
        vm.show_ads_skip_button = true;
      }, 6000);
      setTimeout(() => {
        vm.show_ads = false;
        vm.showAds(time);
      }, 1000 * time);
    },
    showAds(time) {
      let vm = this;
      let index = Math.floor(Math.random() * vm.all_ads_data.length) + 0;
      if (vm.count_ads == 1) {
        if (vm.all_ads_data.length > 0) {
          vm.ad_item = vm.all_ads_data[index];
          vm.show_ads = true;
          vm.show_ads_skip_button = false;
          vm.adsShowTimeout(vm.ad_item.time);
        } else {
          if (!vm.get_ads_loading) {
            vm.getAllAds();
          }
          setTimeout(() => {
            vm.showAds(1);
          }, 2000);
          return;
        }
      } else {
        if (vm.all_ads_data.length > 0) {
          setTimeout(() => {
            vm.ad_item = vm.all_ads_data[index];
            vm.show_ads = true;
            vm.show_ads_skip_button = false;
            vm.adsShowTimeout(vm.ad_item.time);
          }, 60 * 1000);
        } else {
          if (!vm.get_ads_loading) {
            vm.getAllAds();
          }
          setTimeout(() => {
            vm.showAds(1);
          }, 2000);
          return;
        }
      }
      vm.count_ads += 1;
    },
    getAllAds() {
      let vm = this;
      vm.get_ads_loading = true;
      vm.$store
        .dispatch("GET_ALL_AFFILIATE_ADS")
        .then((response) => {
          vm.all_ads_data = response.data.return;
          vm.get_ads_loading = false;
        })
        .catch((error) => {
          vm.get_ads_loading = false;
          console.error("GET_ALL_AFFILIATE_ADS", error);
        });
    },
    countDown() {
      let vm = this;
      var sec = 45;
      let timer = setInterval(() => {
        vm.count_down = sec;
        if (sec == 1) {
          vm.show_skip_down = true;
          clearInterval(timer);
        }
        if (sec == 30) {
          vm.showAds(1);
        }
        sec--;
      }, 1000);
    },
    skipWhatsNew() {
      let vm = this;
      vm.show_annauncement = false;
    },
    parseVideoID(url) {
      let videoID = url
        .replace("http://", "")
        .replace("https://", "")
        .replace("www.", "")
        .replace("youtu.be/", "youtube.com/watch?v=")
        .split("/")
        .splice(1)
        .join("");

      videoID = videoID.substring(8, 19);
      return videoID;
    },
    isYoutube(url) {
      return (
        url
          .replace("http://", "")
          .replace("https://", "")
          .replace("www.", "")
          .replace("youtu.be/", "youtube.com/watch?v=")
          .slice(0, 20) === "youtube.com/watch?v="
      );
    },
    navigateToAffiliate() {
      let vm = this;
      vm.$router.push({ name: "affiliate" }).catch(() => {});
    },
    redirectToAffiliateLink(token) {
      let vm = this;
      vm.is_loading = true;
      if (vm.$localStorage.get("user_session")) {
        let user_session = vm.$localStorage.get("user_session");
        let site_token = document.head.querySelector(
          'meta[name="site-token"]'
        ).content;
        vm.$store
          .dispatch("AFFILIATE_REDIRECT_TO_LINK", {
            token: token,
            user_session: user_session,
            site_token: site_token,
          })
          .then((response) => {
            vm.iframe_url = response.data.return.link;
            vm.is_youtube = vm.isYoutube(vm.iframe_url);
            if (vm.is_youtube) {
              vm.video_id = vm.parseVideoID(vm.iframe_url);
            }
            vm.announcement_content = response.data.return;
            let reflesher = setInterval(() => {
              if (document.querySelector("iframe")) {
                document.querySelector("iframe").onload = () => {
                  vm.is_loading = false;
                  vm.rewardAffiliateLinkOwner(token);
                  clearInterval(reflesher);
                };
              }
            }, 200);
          })
          .catch((error) => {
            console.log(error);
            vm.$router.push({ name: "home" }).catch(() => {});
            console.error("AFFILIATE_REDIRECT_TO_LINK", error.response);
          });
      }
    },
    rewardAffiliateLinkOwner(token) {
      let vm = this;
      if (vm.$localStorage.get("user_session")) {
        let user_session = vm.$localStorage.get("user_session");
        let site_token = document.head.querySelector(
          'meta[name="site-token"]'
        ).content;
        vm.$store
          .dispatch("AFFILIATE_REWARD_LINK_OWNER", {
            token: token,
            user_session: user_session,
            site_token: site_token,
          })
          .then((response) => {})
          .catch(({ response }) => {
            console.error("AFFILIATE_REWARD_LINK_OWNER", response);
          });
      }
    },
  },
};
</script>

<style lang="scss">
.youtube-top-hider {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 58px;
  background: #fff;
  z-index: 22;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 12px #7d7d7d;
  img {
    height: 100%;
    object-fit: cover;
  }
}
.hytPlayerWrap {
  display: inline-block;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.hytPlayerWrap.ended::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  background-color: black;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 64px 64px;
  background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgNTEwIDUxMCI+PHBhdGggZD0iTTI1NSAxMDJWMEwxMjcuNSAxMjcuNSAyNTUgMjU1VjE1M2M4NC4xNSAwIDE1MyA2OC44NSAxNTMgMTUzcy02OC44NSAxNTMtMTUzIDE1My0xNTMtNjguODUtMTUzLTE1M0g1MWMwIDExMi4yIDkxLjggMjA0IDIwNCAyMDRzMjA0LTkxLjggMjA0LTIwNC05MS44LTIwNC0yMDQtMjA0eiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==);
}

.hytPlayerWrap.paused::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0px;
  right: 0;
  cursor: pointer;
  background-color: black;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 40px;
  background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEiIHdpZHRoPSIxNzA2LjY2NyIgaGVpZ2h0PSIxNzA2LjY2NyIgdmlld0JveD0iMCAwIDEyODAgMTI4MCI+PHBhdGggZD0iTTE1Ny42MzUgMi45ODRMMTI2MC45NzkgNjQwIDE1Ny42MzUgMTI3Ny4wMTZ6IiBmaWxsPSIjZmZmIi8+PC9zdmc+);
}
</style>
<style lang="scss" scoped>
.cagura-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  .fixed-banner {
    position: fixed;
    bottom: 15px;
    left: 0px;
    height: 61px;
    width: 400px;
    object-fit: cover;
    background: #fff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    @media screen and (max-width: 500px) {
      top: 70px;
      width: 100%;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  .cagura-iframe {
    z-index: 2;
    width: 100%;
    height: 100vh;
  }
  .announcement-content {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 50px;
    padding: 15px;
    background: #fff;
    z-index: 999999;
    display: flex;
    border-bottom-right-radius: 30px;
    border: 7px solid #2c313a;
    max-width: 600px;
    height: 190px;

    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      right: 0px;
      bottom: 0px;
      top: 0px;
      object-fit: cover;
    }
    .content {
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 99;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        49deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.4430147058823529) 65%
      );
      padding: 5px 10px;

      h3 {
        margin: 0px;
        color: #fff;
        border-bottom: 2px solid #949494;
      }
      p {
        color: #c7c7c7;
      }
    }
  }
  .cagura-annauncement {
    position: fixed;
    left: 0px;
    bottom: 0px;
    z-index: 9999;
    background: #fff;
    width: 400px;
    height: 248px;
    border-top-right-radius: 30px;
    border: 7px solid #2c313a;
    @media screen and (max-width: 500px) {
      width: 100%;
      height: 200px;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 25px;
      .cagura-logo {
        width: 154px;
        margin-left: -28px;
      }
    }
    .body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      img {
        width: 100px;
        margin-right: 10px;
        @media screen and (max-width: 500px) {
          width: 60px;
        }
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      padding: 10px 10px;
      @media screen and (max-width: 500px) {
        padding: 5px 10px;
      }
    }
  }
  .cagura-new-present {
    position: fixed;
    background: #fff;
    right: 0px;
    top: 70px;
    z-index: 999999;
    padding: 7px;
    display: flex;
    border-bottom-left-radius: 15px;
    border: 7px solid #2c313a;
    width: 400px;
    height: 190px;
    @media screen and (max-width: 500px) {
      width: 100%;
      height: 144px;
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      right: 0px;
      bottom: 0px;
      top: 0px;
      object-fit: cover;
    }
    .content {
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 99;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        49deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.4430147058823529) 65%
      );
      padding: 5px 10px;

      h3 {
        margin: 0px;
        color: #fff;
        border-bottom: 2px solid #949494;
        @media screen and (max-width: 500px) {
          font-size: 14px;
        }
      }
      p {
        color: #c7c7c7;
        padding-right: 80px;
        @media screen and (max-width: 500px) {
          font-size: 12px;
        }
      }
      .btn-skip-add {
        position: absolute;
        bottom: 5px;
        right: 9px;
        height: 24px;
        font-size: 9px;
        min-width: 70px;
        padding: 0px 1px;
      }
    }
  }
  .cagura-banner {
    position: fixed;
    bottom: 15px;
    right: 1px;
    z-index: 9;
    background: #fff;
    height: 53px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    padding: 5px 13px;
    cursor: pointer;
    width: 128px;

    cursor: pointer;
    .cagura-logo {
      width: 84px;
      position: relative;
      left: -8px;
    }
    p {
      font-size: 10px;
      color: #000;
    }
  }
}
</style>
