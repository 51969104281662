var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notification-item-widget"},[_c('div',{staticClass:"notification-item"},[_c('div',{staticClass:"notification-item-header"},[(_vm.item.status == 'new')?_c('span',{staticClass:"notification-dot"}):_vm._e(),_vm._m(0),_c('div',{staticClass:"notification-title"},[_vm._v("Cagura Affiliate")]),_c('div',{staticClass:"notification-date"},[_vm._v(" "+_vm._s(_vm.moment(_vm.item.created_at).format("dddd, MMM Do YYYY, h:mm A"))+" ")])]),_c('div',{staticClass:"notification-item-body"},[_c('div',{staticClass:"notification-content"},[(_vm.item.affiliate)?_c('div',{staticClass:"product-images"},[_c('img',{attrs:{"src":_vm.$store.state.ASSETS_BASE_URL +
              '/assets/uploaded/' +
              _vm.item.affiliate.image}})]):_vm._e(),(_vm.item.affiliate)?_c('div',{staticClass:"affiliate-title"},[_vm._v(" "+_vm._s(_vm.item.affiliate.title)+" ")]):_c('div',{staticClass:"affiliate-title"},[_c('i',[_vm._v("Item Not Available")])]),_c('p',{staticClass:"notification_desc"},[_vm._v(" "+_vm._s(_vm.$cutText(_vm.item.notification_message, 170))+" ")]),_c('p',{staticClass:"notification_desc"},[_vm._v(" That means link you have been sharing was expired, Now you can continue to make money by sharing other links we have provided. ")])]),_c('div',{staticClass:"profile-image"},[(_vm.item.user.profile_image)?_c('img',{attrs:{"src":_vm.$store.state.ASSETS_BASE_URL +
            '/assets/uploaded/' +
            _vm.item.user.profile_image}}):_c('img',{attrs:{"src":require("@/assets/images/icons/empty_profile.svg")}})])]),_c('div',{staticClass:"notification-item-footer"},[_c('button',{staticClass:"btn-view",on:{"click":function($event){return _vm.$router.push({
            name: 'affiliate',
          })}}},[_vm._v(" Continue ")]),(_vm.item.status == 'new')?_c('c-button',{staticClass:"btn-mark",attrs:{"loading":_vm.mark_notification_loading},on:{"click":function($event){return _vm.markNotification(_vm.item, 'seen')}}},[_vm._v("Mark as read")]):_c('c-button',{staticClass:"btn-mark",attrs:{"loading":_vm.mark_notification_loading},on:{"click":function($event){return _vm.markNotification(_vm.item, 'new')}}},[_vm._v("Mark as unread")]),_c('c-button',{staticClass:"btn-remove",attrs:{"loading":_vm.remove_notification_loading},on:{"click":function($event){return _vm.removeNotification(_vm.item)}}},[_vm._v("Remove")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notification-icon"},[_c('img',{attrs:{"src":require("@/assets/images/icons/affiliate-winner.svg")}})])
}]

export { render, staticRenderFns }