import { render, staticRenderFns } from "./AnnouncementModal.vue?vue&type=template&id=223afe42&scoped=true"
import script from "./AnnouncementModal.vue?vue&type=script&lang=js"
export * from "./AnnouncementModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_react-dom@18.3.1_react@18.3.1_vue-temp_btlxz3i55afq2w4yuozfcdpdny/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "223afe42",
  null
  
)

export default component.exports