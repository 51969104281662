<template>
  <div class="cagura-wrapper">
    <!-- Navbar -->
    <ClientHeader type="shopping"></ClientHeader>
    <!-- Navbar -->
    <div class="cagura-container">
      <!-- Side bar -->
      <side-bar />
      <!-- End of sidebar -->
      <!-- Content - body -->
      <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
        <vue-scroll ref="vs">
          <div class="under-scoll-container-widget">
            <div class="fit-container">
              <div class="cagura-products-widget" id="d1" v-if="product_item">
                <div class="products-widget">
                  <div class="products-widget-header">
                    <div class="call-actions">
                      <button
                        class="btn-back"
                        @click="
                          $router.push({ name: 'products' }).catch(() => {})
                        "
                      >
                        <img src="@/assets/images/icons/arrow-left.svg" />
                        <span class="text-uppercase">
                          {{ product_item.product_name }}
                        </span>
                      </button>
                    </div>
                  </div>
                  <div class="product-main-widget">
                    <div class="product-viewer">
                      <img
                        :src="product_url"
                        class="image-container"
                        @click="show_other_images = !show_other_images"
                      />
                      <loading
                        :height="40"
                        color="#8dc73f"
                        :opacity="1"
                        :width="40"
                        :active.sync="is_loading_image"
                        :is-full-page="false"
                        :can-cancel="false"
                        background-color="rgb(0 0 0 / 38%)"
                        class="loader"
                      ></loading>
                      <transition name="fadeanim">
                        <div
                          class="other-images-slider"
                          v-show="show_other_images"
                        >
                          <hooper
                            :infiniteScroll="true"
                            :itemsToShow="6"
                            :itemsToSlide="2"
                            :initialSlide="1"
                          >
                            <slide :class="{ active: product_item.active }">
                              <img
                                @click="viewImage(product_item)"
                                :src="
                                  $store.state.ASSETS_BASE_URL +
                                  '/assets/uploaded/' +
                                  product_item.main_image
                                "
                              />
                            </slide>
                            <slide
                              v-for="(
                                item, i
                              ) in product_item.other_product_images"
                              :class="{ active: item.active }"
                              :key="i"
                            >
                              <img
                                @click="viewImage(item)"
                                :src="
                                  $store.state.ASSETS_BASE_URL +
                                  '/assets/uploaded/' +
                                  item.main_image
                                "
                              />
                            </slide>
                            <hooper-navigation
                              slot="hooper-addons"
                            ></hooper-navigation>
                          </hooper>
                        </div>
                      </transition>
                    </div>
                    <div class="product-viewer-content">
                      <h1 class="product-name">
                        {{ product_item.product_name }}
                      </h1>
                      <p
                        class="product-price"
                        :class="{ 'has-discount': product_item.discount }"
                      >
                        FRW {{ $formatMoney(product_item.price) }}
                      </p>
                      <p class="new-price" v-if="product_item.discount">
                        {{
                          "FRW " +
                          $formatMoney(
                            product_item.price -
                              (product_item.price * product_item.discount) / 100
                          )
                        }}
                        <span class="discount-label">(Discount Price)</span>
                      </p>
                      <div class="product-desc">
                        {{ product_item.description }}
                      </div>
                      <div class="brand-section" v-if="product_item.brand">
                        <b>BRAND:</b>
                        {{ product_item.brand }}
                      </div>
                      <div class="product-options" v-if="!product_item.is_mine">
                        <button
                          class="btn-add-favourites"
                          @click="addWishList(product_item)"
                        >
                          ADD TO WISHLIST
                        </button>
                        <button
                          class="btn-contact-seller"
                          @click.prevent="shopContactForm()"
                        >
                          <img src="@/assets/images/icons/phone.svg" /> ORDER
                          NOW
                        </button>
                      </div>
                      <div class="product-options" v-else>
                        <h4 class="mine-product-status">
                          This is your product
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div class="product-shops">
                    <div class="header">
                      <h3 class="title">THIS PRODUCT COME FROM</h3>
                    </div>
                    <div
                      class="product-shop-item"
                      v-for="(item, index) in product_item.shops"
                      :key="index"
                    >
                      <div class="brand">
                        <img
                          class="cover-image"
                          :src="
                            $store.state.ASSETS_BASE_URL +
                            '/assets/uploaded/' +
                            item.cover_image
                          "
                        />
                        <img
                          class="logo-image"
                          :src="
                            $store.state.ASSETS_BASE_URL +
                            '/assets/uploaded/' +
                            item.shop_logo
                          "
                        />
                      </div>
                      <div class="content">
                        <div class="top-info">
                          <h4 class="shop-name">{{ item.shop_name }} shop</h4>
                          <div class="location-name">
                            {{ item.location_name }}
                          </div>
                        </div>
                        <div class="contant-info">
                          <div class="email"><b>Email:</b>{{ item.email }}</div>
                          <div class="phone">
                            <b>Phone:</b> {{ item.phone_number }}
                          </div>
                        </div>

                        <div class="working-hours">
                          <div class="opening-hour">
                            <b> Opening Hour:</b>
                            {{ moment(item.opening_hours).format("HH:mm") }}
                          </div>
                          <div class="closing-hour">
                            <b>Closing Hour:</b>
                            {{ moment(item.closing_hours).format("HH:mm") }}
                          </div>
                        </div>

                        <div class="working-days">
                          <h4>Working Days</h4>
                          <div class="days">
                            <div
                              class="day-item"
                              v-for="(item, index) in JSON.parse(
                                item.working_days
                              )"
                              :key="index"
                            >
                              {{ item }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="product-more-info">
                    <div class="tabs-widget">
                      <div class="tabs">
                        <div
                          class="tab"
                          v-if="product_item.properties.length > 0"
                          :class="{ active: tabs.properties }"
                          @click="goPropertyTab()"
                        >
                          Properties
                        </div>
                        <div
                          class="tab"
                          :class="{ active: tabs.reviews }"
                          @click="goReviewTab()"
                        >
                          Reviews
                        </div>
                      </div>
                      <div class="tabs-body">
                        <div
                          class="tab-body properties-tab"
                          v-if="tabs.properties"
                        >
                          <div class="properties-widget">
                            <div
                              class="property-item"
                              v-for="(item, i) in product_item.properties"
                              :key="i"
                            >
                              <div class="property-label">{{ item.name }}</div>
                              <div class="property-value">
                                <span
                                  v-for="(val, index) in item.values"
                                  :key="index"
                                >
                                  {{ val.value }}
                                  <i v-if="index < item.values.length - 1">,</i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-body reviews-tab" v-if="tabs.reviews">
                          <vue-scroll>
                            <div class="post-review-widget">
                              <div class="avatar-widget">
                                <img src="@/assets/images/icons/avatar.svg" />
                              </div>
                              <div class="content-widget">
                                <div class="post-text-area">
                                  <textarea
                                    v-model="review_form.message"
                                    placeholder="Add a review"
                                  ></textarea>
                                </div>
                                <div class="post-options">
                                  <div class="post-rating">
                                    <a-rate v-model="review_form.rate" />
                                  </div>
                                  <div class="post-btns">
                                    <c-button
                                      @click="sendReview()"
                                      :loading="review_loading"
                                      class="btn-post btn-success"
                                      >POST</c-button
                                    >
                                    <button
                                      class="btn-cancel"
                                      @click="review_form.message = ''"
                                    >
                                      CANCEL
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="post-items">
                              <div
                                class="post-item"
                                v-for="(item, i) in product_reviews"
                                :key="i"
                              >
                                <template v-if="item.user">
                                  <div class="avatar-widget">
                                    <img
                                      v-if="item.user.profile_image"
                                      :src="
                                        $store.state.ASSETS_BASE_URL +
                                        '/assets/uploaded/' +
                                        item.user.profile_image
                                      "
                                    />
                                    <img
                                      v-else
                                      src="@/assets/images/icons/avatar.svg"
                                    />
                                  </div>
                                  <div class="content-widget">
                                    <h5>
                                      {{ item.user.first_name }}
                                      {{ item.user.last_name }}
                                    </h5>
                                    <p>{{ item.message }}</p>
                                    <div class="options">
                                      <a-rate
                                        class="rate"
                                        size="10px"
                                        :disabled="true"
                                        :value="item.rate"
                                      />
                                      <div
                                        class="likes-widget"
                                        @click="toggleReviewLike(item)"
                                        :class="{ active: item.is_liked }"
                                      >
                                        <img
                                          src="@/assets/images/icons/thumb_up.svg"
                                        />
                                        {{ item.liked_count }}
                                      </div>
                                      <div
                                        class="unlikes-widget"
                                        @click="toggleReviewUnLike(item)"
                                        :class="{ active: item.is_unliked }"
                                      >
                                        <img
                                          src="@/assets/images/icons/thumb_down.svg"
                                        />
                                        {{ item.unliked_count }}
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </vue-scroll>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="similar-products">
                    <div class="products-widget" style="padding: 0px 0px">
                      <div class="products-widget-header">
                        <h3>RELATED PRODUCTS</h3>
                      </div>
                      <div class="products-widget-body">
                        <div
                          class="product-item"
                          v-for="(item, i) in product_item.related_products"
                          :key="i"
                        >
                          <div
                            class="product-announcement success"
                            v-if="item.discount"
                          >
                            {{ item.discount }}% OFF
                          </div>
                          <div
                            class="product-image"
                            @click="
                              $router
                                .push({ path: $getProductLink(item) })
                                .catch(() => {})
                            "
                          >
                            <img
                              :src="
                                $store.state.ASSETS_BASE_URL +
                                '/assets/uploaded/' +
                                item.main_image
                              "
                            />
                          </div>
                          <div class="product-content">
                            <h4>{{ item.product_name }}</h4>
                            <p>
                              FRW
                              {{
                                item.discount
                                  ? $formatMoney(
                                      item.price -
                                        (item.price * item.discount) / 100
                                    )
                                  : $formatMoney(item.price)
                              }}
                            </p>
                            <div
                              class="shop-viewer"
                              v-if="item.shop"
                              @click="
                                $router.push({ path: $getShopLink(item.shop) })
                              "
                            >
                              <img
                                class="shop-image"
                                :src="
                                  $store.state.ASSETS_BASE_URL +
                                  '/assets/uploaded/' +
                                  item.shop.shop_logo
                                "
                              />
                              <div class="shop-content">
                                <label class="shop-name">{{
                                  item.shop.shop_name
                                }}</label>
                                <span class="shop-location">{{
                                  item.shop.location_name
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="cagura-products-widget" v-if="!product_item">
                <div class="product-widget-widget">
                  <div class="empty-widget" v-if="!product_item && !is_loading">
                    <a-empty
                      :image="require('@/assets/images/icons/empty.svg')"
                      :image-style="{ height: '116px' }"
                    >
                      <span slot="description">
                        Sorry, Product data not found, Click Home to find other
                        products !!!
                      </span>
                    </a-empty>
                  </div>
                  <div
                    class="loader-widget"
                    style="height: 300px"
                    v-if="is_loading"
                  >
                    <div class="loading-panel">
                      <a-spin :spinning="true"></a-spin>
                      <span class="text">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
      <!-- End of content -->
    </div>

    <!--  Contact form -->
    <transition name="fade">
      <div class="overlay" v-if="show_contact_form">
        <div class="contact-form">
          <vue-scroll>
            <h2>Contact Seller</h2>
            <p>Buy now, We are glad to hear from you</p>
            <div class="input-group" v-if="!$getLoggedUser()">
              <div class="input-widget">
                <input
                  type="text"
                  class="bg-cagura-form-control"
                  name="text"
                  placeholder="Names"
                  v-model="contact_form.names"
                />
              </div>
            </div>
            <div class="input-group" v-if="!$getLoggedUser()">
              <div class="input-widget">
                <input
                  type="text"
                  class="bg-cagura-form-control"
                  name="text"
                  placeholder="Phone"
                  v-model="contact_form.phone"
                />
              </div>
            </div>
            <div class="input-group" v-if="!$getLoggedUser()">
              <div class="input-widget">
                <input
                  type="text"
                  class="bg-cagura-form-control"
                  name="text"
                  placeholder="Email (Optional)"
                  v-model="contact_form.email"
                />
              </div>
            </div>
            <div class="form-input">
              <textarea
                class="contant-input"
                v-model="contact_form.message"
                placeholder="Type your message like your Location and other delivery informaion."
              ></textarea>
            </div>
            <div class="form-options">
              <c-button
                :loading="send_order_loading"
                class="btn-send btn-success"
                @click="sendProductOrder()"
                >SEND</c-button
              >

              <button class="btn-default" @click="show_contact_form = false">
                CLOSE
              </button>
            </div>
          </vue-scroll>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ClientHeader from "../components/partials/header.vue";
import SideBar from "../components/partials/sidebar.vue";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
export default {
  name: "product-view",
  components: {
    ClientHeader,
    SideBar,
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {
    return {
      show_contact_form: false,
      tabs: {
        properties: true,
        reviews: false,
      },
      product_item: null,
      is_loading: true,
      product_url: "",
      show_other_images: true,
      contact_form: {
        names: "",
        phone: "",
        email: "",
        message: "",
        user_type: "guest",
        send_by: "sms",
        receiver_id: null,
        product_id: null,
      },
      send_order_loading: false,
      review_form: {
        message: "",
        rate: 3,
        product_id: null,
        receiver_id: null,
      },
      review_loading: false,
      product_reviews: [],
      is_loading_image: false,
      title: "",
    };
  },
  head: {
    title: function () {
      return {
        inner: this.title,
      };
    },
  },
  mounted() {
    let vm = this;
    if (vm.$route.params.token) {
      let product_id = vm.$decodeProductId(vm.$route.params.token);
      vm.getProductItem(product_id);
      vm.getProductReviews(product_id);
    } else {
      vm.is_loading = false;
    }
    if (vm.$getLoggedUser()) {
      vm.contact_form.user_type = "ours";
    } else {
      vm.contact_form.user_type = "guest";
    }
  },
  methods: {
    toggleReviewLike(item) {
      let vm = this;
      vm.$store
        .dispatch("PRODUCT_TOGGLE_LIKE", { id: item.id })
        .then((response) => {
          if (response.data.status == "added") {
            item.is_liked = true;
            item.liked_count += 1;
          } else if (response.data.status == "removed") {
            item.is_liked = false;
            item.liked_count -= 1;
          }
        })
        .catch(({ response }) => {
          console.error("PRODUCT_TOGGLE_LIKE", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    toggleReviewUnLike(item) {
      let vm = this;
      vm.$store
        .dispatch("PRODUCT_TOGGLE_UNLIKE", { id: item.id })
        .then((response) => {
          if (response.data.status == "added") {
            item.is_unliked = true;
            item.unliked_count += 1;
          } else if (response.data.status == "removed") {
            item.is_unliked = false;
            item.unliked_count -= 1;
          }
        })
        .catch(({ response }) => {
          console.error("PRODUCT_TOGGLE_UNLIKE", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    getProductReviews(product_id) {
      let vm = this;
      vm.$store
        .dispatch("PRODUCT_GET_PRODUCT_REVIEWS", { id: product_id })
        .then((response) => {
          if (response.data.status == "ok") {
            vm.product_reviews = response.data.return.map((item) => {
              if (vm.$getLoggedUser()) {
                let user = vm.$getLoggedUser().user;
                // likes
                if (
                  item.liked.map((likes) => likes.user_id).indexOf(user.id) !==
                  -1
                ) {
                  let index = item.liked
                    .map((likes) => likes.user_id)
                    .indexOf(user.id);
                  vm.$store.state.eventBus.$set(
                    item,
                    "is_liked",
                    item.liked[index].is_liked
                  );
                }
                // Unlikes
                if (
                  item.unliked
                    .map((unlikes) => unlikes.user_id)
                    .indexOf(user.id) !== -1
                ) {
                  let index = item.unliked
                    .map((unlikes) => unlikes.user_id)
                    .indexOf(user.id);
                  vm.$store.state.eventBus.$set(
                    item,
                    "is_unliked",
                    !item.unliked[index].is_liked
                  );
                }
              } else {
                vm.$store.state.eventBus.$set(item, "is_liked", false);
                vm.$store.state.eventBus.$set(item, "is_unliked", false);
              }
              vm.$store.state.eventBus.$set(
                item,
                "liked_count",
                item.liked.length
              );
              vm.$store.state.eventBus.$set(
                item,
                "unliked_count",
                item.unliked.length
              );
              return item;
            });
          }
        })
        .catch(({ response }) => {
          console.error("PRODUCT_GET_PRODUCT_REVIEWS", response);
        });
    },
    sendReview() {
      let vm = this;
      const rules = {
        message: {
          run: (value) => {
            if (value.length == 0) {
              return "Your review message can not be empty";
            } else if (value.length >= 500) {
              return "Your review message it too long, must have at least 500 charcters";
            } else {
              return false;
            }
          },
        },
      };
      if (vm.$isFieldsValidated(vm.review_form, rules)) {
        vm.review_loading = true;
        vm.review_form.product_id = vm.product_item.id;
        vm.review_form.receiver_id = vm.product_item.user_id;
        vm.$store
          .dispatch("PRODUCT_SEND_REVIEW", vm.review_form)
          .then((response) => {
            vm.review_loading = false;
            if (response.data.status == "ok") {
              vm.review_form.message = "";
              vm.getProductReviews(vm.product_item.id);
              vm.$notify({
                group: "status",
                type: "success",
                title: "Important message",
                text: response.data.message,
              });
            } else {
              vm.$notify({
                group: "status",
                type: "warn",
                title: "OOPS!!!",
                text: response.data.Message,
              });
            }
          })
          .catch(({ response }) => {
            vm.review_loading = false;
            console.error("PRODUCT_SEND_REVIEW", { response });
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
          });
      }
    },
    sendProductOrder() {
      let vm = this;
      let rules = null;
      if (!vm.$getLoggedUser()) {
        rules = {
          names: {
            run: (value) => {
              if (value.length > 0) {
                return false;
              }
              return "Names can not be empty";
            },
          },
          phone: {
            run: (value) => {
              if (value.length == 0) {
                return "Phone number can not be empty";
              } else {
                if (/^\d+$/.test(value)) {
                  if (value.match(/\d/g).length === 10) {
                    return false;
                  } else {
                    return "Phone number must be 10 digits";
                  }
                } else {
                  return "We don't accept any character on phone number, use only number";
                }
              }
            },
          },
          email: {
            run: (value) => {
              if (value) {
                const re =
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (re.test(String(value).toLowerCase())) {
                  return false;
                } else {
                  return "Email is invalid";
                }
              }
              return false;
            },
          },
          message: {
            run: (value) => {
              if (value.length >= 30 && value.length <= 1000) {
                return false;
              }
              return "Your message must have 30 - 1000 charcters";
            },
          },
        };
      } else {
        rules = {
          message: {
            run: (value) => {
              if (value.length >= 30 && value.length <= 1000) {
                return false;
              }
              return "Your message must have 30 - 1000 charcters";
            },
          },
        };
      }
      if (vm.$isFieldsValidated(vm.contact_form, rules)) {
        vm.send_order_loading = true;
        vm.$store
          .dispatch("PRODUCT_SEND_ORDER", vm.contact_form)
          .then((response) => {
            vm.send_order_loading = false;
            if (response.data.status == "ok") {
              vm.show_contact_form = false;
              vm.$notify({
                group: "status",
                type: "success",
                title: "Important message",
                duration: 15000,
                text: response.data.message,
              });
            } else {
              vm.$notify({
                group: "status",
                type: "warn",
                title: "OOPS!!!",
                text: response.data.Message,
              });
            }
          })
          .catch(({ response }) => {
            vm.send_order_loading = false;
            console.error("PRODUCT_SEND_ORDER", { response });
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
          });
      }
    },
    shopContactForm() {
      let vm = this;
      vm.show_contact_form = true;
      vm.contact_form.receiver_id = vm.product_item.user_id;
      vm.contact_form.product_id = vm.product_item.id;
    },
    addWishList(item) {
      let vm = this;
      vm.$store.commit("START_LOADER");
      vm.$store
        .dispatch("PRODUCT_ADDWISHLIST", { id: item.id })
        .then((response) => {
          vm.$store.commit("STOP_LOADER");
          if (response.data.status == "ok") {
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          } else {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.Message,
            });
          }
        })
        .catch(({ response }) => {
          vm.$store.commit("STOP_LOADER");
          console.error("PRODUCT_ADDWISHLIST", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    viewImage(item) {
      let vm = this;
      vm.product_item.active = false;
      vm.product_item.other_product_images.forEach((element) => {
        element.active = false;
      });
      item.active = true;
      vm.is_loading_image = true;
      let url =
        vm.$store.state.ASSETS_BASE_URL + "/assets/uploaded/" + item.main_image;
      vm.axios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          vm.is_loading_image = false;
          vm.product_url =
            "data:image/png;base64, " +
            Buffer.from(response.data, "binary").toString("base64");
        })
        .catch((error) => {
          console.log(error);
          vm.is_loading_image = false;
        });
    },
    getProductItem(product_id) {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("PRODUCT_GET_SINGLE_PRODUCT", { id: product_id })
        .then((response) => {
          vm.is_loading = false;
          if (response.data.status == "ok") {
            vm.product_item = response.data.return;
            vm.title = vm.product_item.product_name;

            // Check if product is mine
            if (vm.$getLoggedUser()) {
              let user = vm.$getLoggedUser().user;
              if (vm.product_item.user_id === user.id) {
                vm.$store.state.eventBus.$set(vm.product_item, "is_mine", true);
              } else {
                vm.$store.state.eventBus.$set(
                  vm.product_item,
                  "is_mine",
                  false
                );
              }
            } else {
              vm.$store.state.eventBus.$set(vm.product_item, "is_mine", false);
            }
            // End of check

            vm.$emit("updateHead");
            vm.$store.state.eventBus.$set(vm.product_item, "active", false);
            vm.$store.state.eventBus.$set(
              vm.product_item,
              "other_product_images",
              JSON.parse(vm.product_item.other_images)
            );
            vm.product_item.other_product_images =
              vm.product_item.other_product_images.map((item) => {
                return {
                  main_image: item,
                  active: false,
                };
              });
            if (vm.product_item.properties.length == 0) {
              vm.tabs.properties = false;
              vm.tabs.reviews = true;
            }
            vm.viewImage(vm.product_item);
            vm.$refs["vs"].scrollIntoView("#d1", 500);
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("PRODUCT_GET_SINGLE_PRODUCT", response);
        });
    },
    goPropertyTab() {
      let vm = this;
      vm.tabs.properties = true;
      vm.tabs.reviews = false;
    },
    goReviewTab() {
      let vm = this;
      vm.tabs.properties = false;
      vm.tabs.reviews = true;
    },
  },
  watch: {
    "$route.params.token"() {
      let vm = this;
      if (vm.$route.params.token) {
        let product_id = vm.$decodeProductId(vm.$route.params.token);
        vm.getProductItem(product_id);
        vm.getProductReviews(product_id);
      } else {
        vm.is_loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
    .cagura-body {
      .cagura-products-widget {
        height: 100%;
        padding-bottom: 50px;
        .products-widget {
          padding-left: 10px;
          padding-right: 20px;
          @media screen and (max-width: 500px) {
            padding-left: 10px;
            padding-right: 20px;
          }
          .product-main-widget {
            display: grid;
            grid-template-columns: 48% 48%;
            grid-column-gap: 20px;
            @media screen and (max-width: 500px) {
              grid-template-columns: 100%;
            }
            .product-viewer {
              position: relative;
              img.image-container {
                width: 100%;
                object-fit: contain;
                background: #000;
                height: 500px;
                @media screen and (max-width: 500px) {
                  height: 380px;
                }
              }
              .other-images-slider {
                position: absolute;
                bottom: 0px;
                left: 0px;
                right: 0px;
                height: 80px;
                background: rgb(0, 0, 0);
                background: linear-gradient(
                  357deg,
                  rgba(0, 0, 0, 1) 0%,
                  rgba(0, 0, 0, 0.14189425770308128) 100%
                );
                z-index: 50;
              }
            }
            .product-viewer-content {
              padding: 0px 20px;
              @media screen and (max-width: 500px) {
                padding: 30px 0px;
              }
              .product-name {
                margin-top: -10px;
                font-size: 25px;
                font-weight: 600;
                @media screen and (max-width: 500px) {
                  font-size: 20px;
                }
              }
              .product-price {
                font-size: 24px;
                @media screen and (max-width: 500px) {
                  font-size: 16px;
                }
                &.has-discount {
                  text-decoration: line-through;
                }
              }
              .new-price {
                font-size: 24px;
                margin-top: -22px;
                @media screen and (max-width: 500px) {
                  font-size: 16px;
                }
                .discount-label {
                  color: #8cc73f;
                  font-size: 13px;
                  font-weight: 800;
                }
              }
              .product-desc {
                text-align: justify;
              }
              .brand-section {
                border-top: 1px solid #ddd;
                margin-top: 20px;
                padding: 6px 0px;
                border-bottom: 1px solid #ddd;
              }
              .product-options {
                margin-top: 20px;
                display: flex;
                .btn-add-favourites {
                  font-size: 15px;
                  height: 46px;
                  background: #dddddd;
                  border-radius: 5px;
                  border: none;
                  padding: 0px 19px;
                  margin-right: 20px;
                  @media screen and (max-width: 500px) {
                    font-size: 9px;
                    height: 40px;
                    width: 128px;
                    font-weight: 800;
                  }
                }
                .btn-contact-seller {
                  font-size: 15px;
                  height: 46px;
                  background: #8dc73f;
                  border-radius: 5px;
                  border: none;
                  padding: 0px 19px;
                  margin-right: 20px;
                  color: #fff;
                  @media screen and (max-width: 500px) {
                    font-size: 9px;
                    height: 40px;
                    width: 128px;
                    font-weight: 800;
                  }
                  img {
                    @media screen and (max-width: 500px) {
                      width: 16px;
                      margin-right: 6px;
                    }
                  }
                }
                .mine-product-status {
                  color: #8cc73f;
                  border-left: 2px solid #8cc73f;
                  padding-left: 10px;
                }
              }
            }
          }
          .product-shops {
            margin-top: 30px;
            .header {
              .title {
                font-size: 16px;
              }
            }
            .product-shop-item {
              display: grid;
              grid-template-columns: 300px calc(100% - 300px);
              margin-bottom: 10px;
              padding: 15px;
              border: 1px solid #ddd;
              border-radius: 5px;
              @media screen and (max-width: 500px) {
                grid-template-columns: 100%;
              }
              .brand {
                position: relative;
                .cover-image {
                  width: 100%;
                  object-fit: cover;
                  height: 157px;
                }
                .logo-image {
                  position: absolute;
                  bottom: 9px;
                  left: 13px;
                  width: 86px;
                  height: 86px;
                  border: 6px solid #fff;
                  border-radius: 50%;
                }
              }
              .content {
                padding: 0px 15px;
                .top-info {
                  display: flex;
                  justify-content: space-between;
                  @media screen and (max-width: 500px) {
                    flex-direction: column;
                  }
                  .shop-name {
                    font-weight: 800;
                    font-size: 16px;
                  }
                  .location-name {
                  }
                }
                .working-hours {
                  display: flex;
                  justify-content: space-between;
                  border-top: 1px solid #ddd;
                  border-bottom: 1px solid #ddd;
                }
                .working-days {
                  margin-top: 5px;
                  .days {
                    display: flex;
                    flex-wrap: wrap;
                    .day-item {
                      background: #8dc73f;
                      color: #fff;
                      padding: 4px 21px;
                      margin-right: 10px;
                      border-radius: 5px;
                      font-size: 12px;
                      margin-bottom: 13px;
                    }
                  }
                }
              }
            }
          }
          .product-more-info {
            .tabs-widget {
              margin-top: 50px;
              margin-bottom: 50px;
              @media screen and (max-width: 500px) {
                margin-top: 10px;
                margin-bottom: 10px;
              }
              .tabs {
                height: 50px;
                width: 100%;
                display: flex;
                background: #f2f2f2;
                .tab {
                  height: 50px;
                  width: 200px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  font-weight: 500;
                  @media screen and (max-width: 500px) {
                    width: 100px;
                  }
                  &.active {
                    background: #fff;
                    color: #8dc73f;
                  }
                }
              }
              .tabs-body {
                background: #fff;
                .tab-body {
                  &.properties-tab {
                    min-height: 200px;
                    .properties-widget {
                      .property-item {
                        display: flex;
                        height: 50px;
                        align-items: center;
                        border-bottom: 1px solid #fbfbfb;
                        @media screen and (max-width: 500px) {
                          flex-direction: column;
                          height: unset;
                          margin-bottom: 18px;
                        }
                        .property-label {
                          width: 300px;
                          padding: 0px 20px;
                          font-weight: 800;
                          color: #000;
                          @media screen and (max-width: 500px) {
                            width: 100%;
                          }
                        }
                        .property-value {
                          width: calc(100% - 300px);
                          padding: 0px 20px;
                          color: #565555;
                          @media screen and (max-width: 500px) {
                            width: 100%;
                          }
                        }
                      }
                    }
                  }
                  &.reviews-tab {
                    min-height: 200px;
                    .post-review-widget {
                      width: 100%;
                      display: flex;
                      padding: 15px;
                      .avatar-widget {
                        width: 60px;
                        height: 60px;
                        object-fit: cover;
                        margin-right: 20px;
                        @media screen and (max-width: 500px) {
                          display: none;
                        }
                        img {
                          width: 100%;
                          border-radius: 50%;
                          object-fit: cover;
                        }
                      }
                      .content-widget {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        .post-text-area {
                          width: 100%;
                          textarea {
                            width: 100%;
                            height: 100px;
                            resize: none;
                            border: 1px solid #ddd;
                            border-radius: 5px;
                            padding: 15px;
                          }
                        }
                        .post-options {
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          @media screen and (max-width: 500px) {
                            flex-direction: column;
                            align-items: flex-start;
                          }
                          .post-rating {
                          }
                          .post-btns {
                            display: flex;
                            @media screen and (max-width: 500px) {
                              justify-content: space-between;
                              width: 100%;
                            }
                            .btn-post {
                              width: 100px;
                              height: 40px;
                            }
                            .btn-cancel {
                              margin-left: 20px;
                              width: 100px;
                              height: 40px;
                              background: #dddddd;
                              border-radius: 5px;
                              border: none;
                            }
                          }
                        }
                      }
                    }
                    .post-items {
                      .post-item {
                        width: 100%;
                        display: flex;
                        padding: 15px;
                        .avatar-widget {
                          width: 60px;
                          height: 60px;
                          object-fit: cover;
                          margin-right: 20px;
                          img {
                            width: 100%;
                            border-radius: 50%;
                            object-fit: cover;
                          }
                        }
                        .content-widget {
                          display: flex;
                          flex-direction: column;
                          width: 100%;
                          h5 {
                            font-size: 16px;
                          }
                          p {
                            text-align: justify;
                          }
                          .options {
                            display: flex;
                            align-items: center;
                            .rate {
                              font-size: 13px;
                            }
                            .likes-widget {
                              margin-left: 40px;
                              font-size: 11px;
                              display: flex;
                              align-items: center;
                              cursor: pointer;
                              &.active {
                                color: #8dc73f;
                              }
                              img {
                                margin-right: 5px;
                              }
                            }
                            .unlikes-widget {
                              margin-left: 15px;
                              font-size: 11px;
                              display: flex;
                              align-items: center;
                              cursor: pointer;
                              &.active {
                                color: #8dc73f;
                              }
                              img {
                                margin-right: 5px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .related-shops {
          }
          .similar-products {
          }
        }
        .product-widget-widget {
          padding: 0px 25px;
        }
      }
    }
  }
  .overlay {
    padding-top: 80px;
    .contact-form {
      margin: auto 0;
      width: 500px;
      background: #fff;
      border: 1px solid #ddd;
      padding: 20px;
      @media screen and (max-width: 500px) {
        width: 100%;
        border: none;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
      }
      h2 {
        font-size: 27px;
      }
      .form-input {
        width: 100%;
        .contant-input {
          width: 100%;
          height: 160px;
          margin-bottom: 20px;
          padding: 10px;
          resize: none;
          border-radius: 5px;
          background: #eeeeee;
          border: none;
        }
      }
      .form-options {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
</style>
