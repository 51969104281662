export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
export const VERIFY_EMAIL_FOR_RESET = "VERIFY_EMAIL_FOR_RESET";
export const UPDATE_ACCOUNT_PASSWORD = "UPDATE_ACCOUNT_PASSWORD";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const ACCOUNT_LOGIN = "ACCOUNT_LOGIN";
export const ACCOUNT_GOOGLE_AUTH = "ACCOUNT_GOOGLE_AUTH";
export const CONFIRM_GOOGLE_LOGIN_AUTH = "CONFIRM_GOOGLE_LOGIN_AUTH";
export const CONFIRM_GOOGLE_REGISTER_AUTH = "CONFIRM_GOOGLE_REGISTER_AUTH";
export const ACCOUNT_FACEBOOK_AUTH = "ACCOUNT_FACEBOOK_AUTH";
export const CONFIRM_FACEBOOK_LOGIN_AUTH = "CONFIRM_FACEBOOK_LOGIN_AUTH";
export const CONFIRM_FACEBOOK_REGISTER_AUTH = "CONFIRM_FACEBOOK_REGISTER_AUTH";
export const ACCOUNT_URL_CALLBACK = "ACCOUNT_URL_CALLBACK";
export const VERIFY_TOKEN = "VERIFY_TOKEN";
export const NOTIFICATION_GET_ALL = "NOTIFICATION_GET_ALL";
export const NOTIFICATION_MAKE_IT_SEEN = "NOTIFICATION_MAKE_IT_SEEN";
export const SEARCH_BASED_CATEGORY = "SEARCH_BASED_CATEGORY";
export const SEARCH_BASED_QUERY = "SEARCH_BASED_QUERY";
export const SAVE_SEARCH_TEXT = "SAVE_SEARCH_TEXT";
export const GET_SEARCH_TEXT = "GET_SEARCH_TEXT";
export const USER_UPDATE_INFO = "USER_UPDATE_INFO";
export const SEARCH_GET_HISTORY = "SEARCH_GET_HISTORY";
export const SEARCH_REMOVE_FROM_HISTORY = "SEARCH_REMOVE_FROM_HISTORY";
export const SEND_CONTACT_MESSAGE = "SEND_CONTACT_MESSAGE";
export const RESENT_VERIFIY_ACCOUNT_EMAIL = "RESENT_VERIFIY_ACCOUNT_EMAIL";
export const VERIFY_ACCOUNT_CODE = "VERIFY_ACCOUNT_CODE";
export const SKIP_WHATS_NEW = "SKIP_WHATS_NEW";
export const ACCEPT_AFFILIATE_PROGRAM = "ACCEPT_AFFILIATE_PROGRAM";
export const GET_AFFILIATE_LINKS = "GET_AFFILIATE_LINKS";
export const AFFILIATE_COPY_LINK = "AFFILIATE_COPY_LINK";
export const AFFILIATE_GET_COPYED_LINKS = "AFFILIATE_GET_COPYED_LINKS";
export const AFFILIATE_REDIRECT_TO_LINK = "AFFILIATE_REDIRECT_TO_LINK";
export const CASHOUT_AFFILIATE_MONEY = "CASHOUT_AFFILIATE_MONEY";
export const AFFILIATE_REWARD_LINK_OWNER = "AFFILIATE_REWARD_LINK_OWNER";
export const GET_ALL_AFFILIATE_ADS = "GET_ALL_AFFILIATE_ADS";
export const GET_FIXED_AFFILIATE_ADS = "GET_FIXED_AFFILIATE_ADS";
export const PAGE_SUBSCRIBE = "PAGE_SUBSCRIBE";
export const PAGE_CHECK_PAYMENT = "PAGE_CHECK_PAYMENT";
export const GET_IP = "GET_IP";
export const NODE_GET_VAPID = "NODE_GET_VAPID"
export const SAVE_USER_NOTIFICATION_CREDECTIALS = "SAVE_USER_NOTIFICATION_CREDECTIALS";
export const NOTIFICATION_REMOVE_ITEM = "NOTIFICATION_REMOVE_ITEM";
export const NOTIFICATION_MARK_ITEM = "NOTIFICATION_MARK_ITEM";
export const NOTIFICATION_CHECK_STATUS = "NOTIFICATION_CHECK_STATUS";
export const NOTIFICATION_GET_NOTIFICATION_ITEM = "NOTIFICATION_GET_NOTIFICATION_ITEM";