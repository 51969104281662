<template>
  <div class="overlay">
    <vue-scroll
      :ops="{
        rail: {
          background: '#eee',
          opacity: 1,
          size: '11px',
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: '0px',
          keepShow: false,
        },
      }"
    >
      <div class="help-form">
        <div class="help-header">
          <h1 class="title">What's happening</h1>
          <button @click="closeModal()" class="btn-close">&times;</button>
        </div>
        <div class="help-body">
          <ul class="list">
            <li
              v-for="(item, index) in help_form.help_feedback_content"
              :key="index"
            >
              <label class="check-box">
                <input v-model="item.active" type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <div class="issue-content">
                <h4>{{ item.label }}</h4>
                <p>{{ item.description }}</p>
              </div>
            </li>
          </ul>
          <div class="other-form">
            <div class="header">
              <label>Any more details?</label>
              <div class="optional">
                <label>(Optional)</label>
              </div>
            </div>
            <div class="body">
              <textarea
                v-model="help_form.details"
                class="other-comment"
              ></textarea>
            </div>
            <div class="footer">
              <c-button
                :loading="submit_loading"
                @click="submitFeedBack()"
                class="btn-success"
                >Submit Issue</c-button
              >
            </div>
          </div>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "cagura-report",
  components: {},
  props: {
    post: null,
  },
  data() {
    return {
      help_form: {
        help_feedback_content: [
          {
            label: "Nudity",
            description: "Contains sexual content or Involves a Child",
            active: false,
          },
          {
            label: "Violence",
            description: "Contains Violent Thread",
            active: false,
          },
          {
            label: "False information",
            description: "Contains incorrect information",
            active: false,
          },
          {
            label: "Spam",
            description: "Contains spam content",
            active: false,
          },
          {
            label: "Unauthorized Sales",
            description: "Is unauthorized Sales?",
            active: false,
          },
          {
            label: "Terrorism",
            description:
              "Acts of violence for political, religious or ideological reasons",
            active: false,
          },
          {
            label: "Sharing",
            description: "Sharing private images or videos",
            active: false,
          },
        ],
        details: "",
      },
      submit_loading: false,
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      let vm = this;
      vm.$emit("onSubmit", null);
    },
    submitFeedBack() {
      let vm = this;
      vm.submit_loading = true;
      let label_data = vm.help_form.help_feedback_content
        .map((item) => {
          if (item.active) {
            return {
              label: item.label,
              description: item.description,
            };
          }
        })
        .filter((item) => item);
      vm.$store
        .dispatch("FEEDS_REPORT_POST", {
          post_id: vm.post.id,
          labels: JSON.stringify(label_data),
          details: vm.help_form.details,
        })
        .then((response) => {
          vm.submit_loading = false;
          vm.$emit("onSubmit", response.data);
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          vm.submit_loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba($color: #000000, $alpha: 0.7);
  display: block;
  padding-top: 80px;
  @media screen and (max-width: 600px) {
    padding-top: 70px !important;
  }
  .help-form {
    width: 500px;
    background: #262626;
    border-radius: 5px;
    margin: 0 auto;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
    .help-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      border-bottom: 1px solid #3b3a3a;
      .title {
        color: #fff;
        margin: 0px;
      }
      .btn-close {
        background: transparent;
        border: 1px solid #8dc73f;
        font-size: 21px;
        color: #fff;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0px;
      }
    }
    .help-body {
      padding: 10px 20px;
      ul {
        list-style: none;
        padding-left: 0px;
        li {
          display: flex;
          margin-bottom: 15px;
          align-items: center;
          .check-box {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 26px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
              z-index: 9;
              height: 25px;
              width: 25px;
              z-index: 999;
            }
            .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 25px;
              width: 25px;
              background-color: #eee;
              border-radius: 3px;
            }
            &:hover input ~ .checkmark {
              background-color: #ccc;
              border-radius: 3px;
            }
            input:checked ~ .checkmark {
              background-color: #8dc73f;
              border-radius: 3px;
            }
            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }
            input:checked ~ .checkmark:after {
              display: block;
            }
            .checkmark:after {
              left: 11px;
              top: 7px;
              width: 5px;
              height: 10px;
              border: solid white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
          .issue-content {
            h4,
            p {
              margin: 0px;
            }
            h4 {
              color: #fff;
              font-size: 16px;
            }
            p {
              font-size: 14px;
              color: #8f8f8f;
            }
          }
        }
      }
      .other-form {
        .header {
          display: flex;
          align-items: center;
          label {
            color: #fff;
          }
          .optional {
            margin-left: 10px;
            label {
              color: #a3a2a2;
            }
          }
        }
        .body {
          .other-comment {
            width: 100%;
            padding: 10px;
            border-radius: 5px;
            background: #ddd;
            border: none;
            height: 100px;
            resize: none;
          }
        }
      }
    }
  }
}
</style>
