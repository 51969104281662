<template>
  <div class="cagura-wrapper">
    <!-- Navbar -->
    <ClientHeader type="shopping"></ClientHeader>
    <!-- Navbar -->
    <div class="cagura-container">
      <!-- Side bar -->
      <side-bar />
      <!-- End of sidebar -->
      <!-- Content - body -->
      <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
        <div class="cagura-products-widget">
          <vue-scroll>
            <!-- Shops -->
            <div class="shops-widget">
              <div class="shops-widget-header">
                <h3>MY SHOPS</h3>
                <div class="call-actions">
                  <button class="btn-success" @click="addNewShop()">
                    <img src="@/assets/images/icons/plus.svg" /> CREATE SHOP
                  </button>
                </div>
              </div>
              <div class="shops-widget-body" v-if="my_shops_data.length > 0">
                <div
                  class="shop-item"
                  v-for="(item, index) in my_shops_data"
                  :key="index"
                >
                  <div class="shop-icon">
                    <img src="@/assets/images/shop.png" />
                  </div>
                  <div
                    class="shop-image"
                    @click="$router.push({ path: $getShopLink(item) })"
                  >
                    <img
                      :src="
                        $store.state.ASSETS_BASE_URL +
                        '/assets/uploaded/' +
                        item.shop_logo
                      "
                    />
                  </div>
                  <div class="shop-content">
                    <h4>{{ item.shop_name }}</h4>
                    <dropdown-menu
                      v-model="item.is_menu_open"
                      :right="false"
                      :hover="false"
                      :interactive="false"
                      class="shop-menu"
                    >
                      <button class="dropdown-toggle">
                        <img src="@/assets/images/icons/menu.svg" />
                      </button>
                      <div slot="dropdown" class="drop-down-menu">
                        <a
                          class="dropdown-item"
                          @click.prevent="editShop(item)"
                        >
                          <img src="@/assets/images/icons/edit.svg" /> Edit Shop
                        </a>
                        <a
                          class="dropdown-item"
                          @click.prevent="deleteShop(item)"
                        >
                          <img src="@/assets/images/icons/trash.svg" /> Delete
                          Shop
                        </a>
                      </div>
                    </dropdown-menu>
                    <div class="sub-info">
                      <span class="categ">
                        <i
                          v-for="(c, i) in item.categories.slice(0, 3)"
                          :key="i"
                        >
                          {{ c.category_name }}
                          <span
                            v-if="i < item.categories.slice(0, 3).length - 1"
                            >,</span
                          >
                        </i>
                      </span>
                      <span class="location">
                        <img src="@/assets/images/icons/map-pin.svg" />
                        {{ item.location_name }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="empty-widget"
                v-if="my_shops_data.length == 0 && !is_loading"
              >
                <a-empty
                  :image="require('@/assets/images/icons/empty.svg')"
                  :image-style="{ height: '116px' }"
                >
                  <span slot="description"
                    >You don't have any shop, Create one !!!</span
                  >
                </a-empty>
              </div>
              <div
                class="loader-widget"
                style="height: 300px"
                v-if="is_loading"
              >
                <div class="loading-panel">
                  <a-spin :spinning="true"></a-spin>
                  <span class="text">Loading...</span>
                </div>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
      <!-- End of content -->
    </div>

    <!-- Add shop modal -->
    <transition name="fadeanim">
      <add-shop
        v-if="show_add_shop_modal"
        :value="selected_shop_item"
        @onSubmit="onSubmitShop()"
        @onClose="show_add_shop_modal = false"
      />
    </transition>

    <ask-auth
      @onCancel="ask_for_account = false"
      v-if="ask_for_account"
      message="to access to your shops"
    ></ask-auth>
  </div>
</template>

<script>
import ClientHeader from "../components/partials/header.vue";
import SideBar from "../components/partials/sidebar.vue";
import CaguraTags from "../components/partials/tags.vue";
import AddShop from "../components/modals/AddShop.vue";

export default {
  name: "my-shop",
  components: {
    ClientHeader,
    SideBar,
    CaguraTags,
    AddShop,
  },
  data() {
    return {
      show_add_shop_modal: false,
      is_loading: false,
      my_shops_data: [],
      selected_shop_item: null,
      ask_for_account: false,
    };
  },
  mounted() {
    let vm = this;
    if (vm.$getLoggedUser()) {
      vm.getMyShop();
    } else {
      vm.ask_for_account = true;
    }
  },
  methods: {
    onSubmitShop() {
      let vm = this;
      vm.show_add_shop_modal = false;
      vm.getMyShop();
    },
    getMyShop() {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("SHOP_GET_MY_SHOP")
        .then((response) => {
          vm.is_loading = false;
          if (response.data.status == "ok") {
            vm.my_shops_data = response.data.return.map((item) => {
              vm.$store.state.eventBus.$set(item, "is_menu_open", false);
              return item;
            });
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("SHOP_GET_MY_SHOP", response);
        });
    },
    addNewShop() {
      let vm = this;
      vm.show_add_shop_modal = true;
      vm.selected_shop_item = null;
    },
    editShop(item) {
      let vm = this;
      vm.show_add_shop_modal = true;
      vm.selected_shop_item = null;
      vm.selected_shop_item = item;
    },
    deleteShop(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure? This will delete all information related to this shop, But products from it will not be deleted`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmDeleteShop(item.id);
          }
        },
      });
    },
    confirmDeleteShop(shop_id) {
      let vm = this;
      vm.$store.commit("START_LOADER");
      vm.$store
        .dispatch("SHOP_DELETE", { id: shop_id })
        .then((response) => {
          vm.$store.commit("STOP_LOADER");
          if (response.data.status == "ok") {
            vm.getMyShop();
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          } else {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.Message,
            });
          }
        })
        .catch(({ response }) => {
          vm.$store.commit("STOP_LOADER");
          console.error("SHOP_DELETE", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
    display: block;
    .shop-menu {
      position: absolute;
      right: 10px;
    }
  }
}
</style>

