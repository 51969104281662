<template>
  <div class="feeds-wrapper">
    <!-- Feed form -->
    <div class="post-form">
      <div class="post-input">
        <img
          v-if="$getLoggedUser() && $getLoggedUser().user.profile_image"
          class="profile-image"
          :src="
            $store.state.ASSETS_BASE_URL +
            '/assets/uploaded/' +
            $getLoggedUser().user.profile_image
          "
        />
        <img
          v-else
          class="profile-image"
          src="@/assets/images/icons/empty_profile.svg"
          alt
        />
        <div class="input" @click="openAddPostModal('text')">
          <span class="name" v-if="$getLoggedUser()">{{
            $getLoggedUser().user.first_name + ", "
          }}</span>
          <span>
            Tell others what you are selling or what they can find for you</span
          >
        </div>
      </div>
      <div class="form-options">
        <button class="add-video" @click="openAddPostModal('video')">
          <img src="@/assets/images/icons/video-camera.svg" /> Add Video
        </button>
        <button class="add-image" @click="openAddPostModal('photo')">
          <img src="@/assets/images/icons/image.svg" /> Add Photo
        </button>
      </div>
    </div>
    <!-- End of feed form -->

    <!-- Post items -->
    <div class="posts-widget">
      <div class="post-item">
        <Adsense
          data-ad-client="ca-pub-8242653303172643"
          data-ad-slot="4073025340"
        >
        </Adsense>
      </div>
      <div
        class="post-item"
        v-for="(post, post_index) in posts.data"
        :key="post_index"
      >
        <div class="post-header">
          <div class="post-owner">
            <img
              v-if="post.user.profile_image"
              class="profile-image"
              :src="
                $store.state.ASSETS_BASE_URL +
                '/assets/uploaded/' +
                post.user.profile_image
              "
            />
            <img
              v-else
              class="profile-image"
              src="@/assets/images/icons/empty_profile.svg"
              alt
            />
            <div class="owner-info">
              <h3 class="names">
                {{ post.user.first_name + " " + post.user.last_name }}
              </h3>
              <p class="time">{{ moment(post.created_at).fromNow() }}</p>
            </div>
          </div>
          <div class="options">
            <span
              class="post-type-label"
              :class="{ selling: post.post_type == 'selling' }"
              v-if="post.post_type == 'selling'"
            >
              I am selling
            </span>
            <span
              class="post-type-label"
              :class="{ looking: post.post_type == 'looking' }"
              v-if="post.post_type == 'looking'"
            >
              Find for me
            </span>
            <dropdown-menu
              v-model="post.is_menu_open"
              :right="false"
              :hover="false"
              :interactive="false"
            >
              <button class="dropdown-toggle">
                <img src="@/assets/images/icons/menu.svg" />
              </button>
              <div slot="dropdown" class="drop-down-menu">
                <a class="dropdown-item" @click="reportPost(post)">
                  <img src="@/assets/images/icons/feedback.svg" />
                  Report
                </a>
                <a
                  v-if="
                    post.post_ref == 'cagura' &&
                    $getLoggedUser() &&
                    $getLoggedUser().user.id == post.user_id
                  "
                  class="dropdown-item"
                  @click="updatePost(post)"
                >
                  <img src="@/assets/images/icons/edit.svg" />
                  Update
                </a>
                <a
                  v-if="
                    post.post_ref == 'cagura' &&
                    $getLoggedUser() &&
                    $getLoggedUser().user.id == post.user_id
                  "
                  class="dropdown-item"
                  @click="deletePost(post, post_index)"
                >
                  <img src="@/assets/images/icons/trash.svg" />
                  Delete
                </a>
              </div>
            </dropdown-menu>
          </div>
        </div>
        <div class="post-body">
          <div v-if="post.show_readmore" class="post-desc">
            <transition name="slide-left-fade">
              <div
                v-if="!post.is_readingmore"
                v-html="post.less_text_content"
                @click="expandPost(post)"
              ></div>
            </transition>
            <transition name="slide-left-fade">
              <div
                @click="expandPost(post)"
                v-if="post.is_readingmore"
                v-html="post.html_content"
              ></div>
            </transition>
            <button
              @click="post.is_readingmore = !post.is_readingmore"
              class="btn-readmore"
            >
              <span v-if="!post.is_readingmore">Read More</span>
              <span v-else>Show Less</span>
            </button>
          </div>
          <div v-else class="post-desc" @click="expandPost(post)">
            <div v-html="post.less_html_content"></div>
          </div>
          <div
            class="post-images"
            v-if="post.file_type == 'image' && post.images.length > 0"
          >
            <feedImage
              @onViewMore="expandPost(post)"
              :imgs="post.images"
            ></feedImage>
          </div>
          <div
            class="post-video"
            v-if="post.file_type == 'video' && post.files"
          >
            <VideoPlayer
              class="video-widget"
              :src="$store.state.ASSETS_BASE_URL + '/feeds/' + post.files"
            ></VideoPlayer>
          </div>
        </div>
        <div class="post-options">
          <div class="option-one">
            <button
              class="like-btn"
              :class="{ liked: post.is_liked }"
              @click="likePost(post)"
            >
              <img
                v-if="!post.is_liked"
                src="@/assets/images/icons/thumb_up.svg"
              />
              <img v-else src="@/assets/images/icons/thumb_up_green.svg" />
              {{ post.likes_count }}
            </button>
            <button class="comments-btn">
              <img src="@/assets/images/icons/comment.svg" />
              {{ post.comments.total_items }}
            </button>
          </div>
          <div class="option-two">
            <button
              @click="showContactModal(post)"
              class="btn-success contact-btn"
            >
              Contact
            </button>
          </div>
        </div>
        <!-- Comment and likes section -->
        <div class="post-footer">
          <div class="post-comments">
            <div
              class="comment-item"
              v-for="(comment, comment_index) in post.comments.data"
              :key="comment_index"
            >
              <img
                v-if="comment.user.profile_image"
                class="profile-image"
                :src="
                  $store.state.ASSETS_BASE_URL +
                  '/assets/uploaded/' +
                  comment.user.profile_image
                "
              />
              <img
                v-else
                class="profile-image"
                src="@/assets/images/icons/empty_profile.svg"
                alt
              />

              <div class="comment-widget">
                <div class="comment-body">
                  <h4>
                    {{ comment.user.first_name + " " + comment.user.last_name }}
                  </h4>
                  <div class="comment-content">
                    {{ comment.content }}
                  </div>
                  <span class="comment-likes" v-if="comment.likes_count"
                    ><img src="@/assets/images/icons/thumb_up_white.svg" />
                    {{ comment.likes_count }}</span
                  >
                </div>
                <div class="comment-footer">
                  <button
                    :class="{ liked: comment.is_liked }"
                    @click="likeComment(comment)"
                    class="btn-like"
                  >
                    Like
                  </button>
                  <button @click="toggleReplyForm(comment)" class="btn-reply">
                    Reply
                  </button>
                  <button
                    v-if="
                      $getLoggedUser() &&
                      $getLoggedUser().user.id == comment.user_id
                    "
                    @click="
                      deleteComment(post.comments, comment, comment_index)
                    "
                    class="btn-delete"
                  >
                    Delete
                  </button>
                </div>

                <!-- Reply form -->
                <div
                  class="comment-reply-widget"
                  :class="{ empty_replies: comment.replies.data.length == 0 }"
                  v-if="comment.show_reply_widget"
                >
                  <div class="post-comment-form">
                    <div class="profile-widget">
                      <img
                        v-if="
                          $getLoggedUser() &&
                          $getLoggedUser().user.profile_image
                        "
                        class="profile-image"
                        :src="
                          $store.state.ASSETS_BASE_URL +
                          '/assets/uploaded/' +
                          $getLoggedUser().user.profile_image
                        "
                      />
                      <img
                        v-else
                        class="profile-image"
                        src="@/assets/images/icons/empty_profile.svg"
                        alt
                      />
                    </div>
                    <div class="comments-form">
                      <input
                        type="text"
                        placeholder="Type your reply..."
                        v-model="comment.comment_text"
                        class="comments-input"
                        @focus="validateUserOnComment('comment')"
                      />

                      <button
                        @click="clearComment(comment)"
                        class="btn-close"
                        title="Close"
                      >
                        <img src="@/assets/images/icons/close.svg" />
                      </button>
                      <c-button
                        @click="submitMainComment(comment, 'comment')"
                        :loading="comment.submit_comment_loading"
                        class="btn-send"
                      >
                        <img src="@/assets/images/icons/send.svg" />
                      </c-button>
                    </div>
                  </div>
                </div>
                <!-- Replies comments -->
                <div
                  class="comments-reply-list"
                  :class="{
                    not_replying: !comment.show_reply_widget,
                    no_more_comments:
                      comment.replies.current_page ==
                      comment.replies.total_page,
                  }"
                  v-if="comment.replies.data.length > 0"
                >
                  <div
                    class="comment-reply-item"
                    v-for="(reply, reply_index) in comment.replies.data"
                    :key="reply_index"
                  >
                    <div class="comment-body">
                      <img
                        v-if="reply.user.profile_image"
                        class="profile-image"
                        :src="
                          $store.state.ASSETS_BASE_URL +
                          '/assets/uploaded/' +
                          reply.user.profile_image
                        "
                      />
                      <img
                        v-else
                        class="profile-image"
                        src="@/assets/images/icons/empty_profile.svg"
                        alt
                      />
                      <div class="comment-container">
                        <h4>
                          {{
                            reply.user.first_name + " " + reply.user.last_name
                          }}
                        </h4>
                        <div class="comment-content">
                          {{ reply.content }}
                        </div>
                        <span v-if="reply.likes_count" class="comment-likes"
                          ><img
                            src="@/assets/images/icons/thumb_up_white.svg"
                          />
                          {{ reply.likes_count }}</span
                        >
                      </div>
                    </div>
                    <div class="comment-footer">
                      <button
                        :class="{ liked: reply.is_liked }"
                        @click="likeComment(reply)"
                        class="btn-like"
                      >
                        Like
                      </button>
                      <button
                        v-if="
                          $getLoggedUser() &&
                          $getLoggedUser().user.id == reply.user_id
                        "
                        @click="
                          deleteComment(comment.replies, reply, reply_index)
                        "
                        class="btn-delete"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="comments-reply-options"
                  v-if="
                    comment.replies.current_page < comment.replies.total_page
                  "
                  @click="getReplyComments(comment.replies.next_page, comment)"
                >
                  <button class="btn-show-more">Show More</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="post-comments-options"
            v-if="post.comments.current_page < post.comments.total_page"
            @click="getPostComments(post.comments.next_page, post)"
          >
            <button class="btn-show-more">More Comments</button>
          </div>
          <!-- Post comment form -->
          <div class="post-comment-form">
            <div class="profile-widget">
              <img
                v-if="$getLoggedUser() && $getLoggedUser().user.profile_image"
                class="profile-image"
                :src="
                  $store.state.ASSETS_BASE_URL +
                  '/assets/uploaded/' +
                  $getLoggedUser().user.profile_image
                "
              />
              <img
                v-else
                class="profile-image"
                src="@/assets/images/icons/empty_profile.svg"
                alt
              />
            </div>
            <div class="comments-form">
              <input
                type="text"
                placeholder="Type your comment..."
                @focus="validateUserOnComment('post')"
                v-model="post.comment_text"
                class="comments-input"
              />
              <c-button
                @click="submitMainComment(post, 'main')"
                :loading="post.submit_comment_loading"
                class="btn-send"
              >
                <img src="@/assets/images/icons/send.svg" />
              </c-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End of post items -->

    <!-- Loading post -->
    <div class="post-loading-widget" v-if="get_post_loading">
      <div class="post-loading-item" v-for="(item, index) in 2" :key="index">
        <div class="post-loading-header">
          <div class="profile-widget">
            <div class="profile-image pulse"></div>
          </div>
          <div class="profile-content">
            <div class="names pulse"></div>
            <div class="dates pulse"></div>
          </div>
          <div class="menu-btn"></div>
        </div>
        <div class="post-loading-body">
          <div class="discription-widget">
            <div class="disription-row bubble"></div>
            <div class="disription-row bubble"></div>
            <div class="disription-row bubble"></div>
          </div>
          <div class="posts-images">
            <div class="post-image-item pulse"></div>
            <div class="post-image-item pulse"></div>
            <div class="post-image-item pulse"></div>
            <div class="post-image-item pulse"></div>
          </div>
        </div>
        <div class="post-loading-footer"></div>
      </div>
    </div>
    <!-- End of loading post -->

    <!-- Post expanded modal -->
    <transition name="fade">
      <div class="overlay" v-if="show_post_modal">
        <div class="expanded-post-modal" v-if="post_expanded_item">
          <div class="post-media">
            <button @click="closePostModal()" class="btn-close">&times;</button>
            <span
              class="post-type-label"
              :class="{ selling: post_expanded_item.post_type == 'selling' }"
              v-if="post_expanded_item.post_type == 'selling'"
            >
              I am selling
            </span>
            <span
              class="post-type-label"
              :class="{ looking: post_expanded_item.post_type == 'looking' }"
              v-if="post_expanded_item.post_type == 'looking'"
            >
              Find for me
            </span>
            <div
              class="post-images"
              v-if="post_expanded_item.file_type == 'image'"
            >
              <hooper
                :infiniteScroll="true"
                :itemsToShow="1"
                :itemsToSlide="1"
                :initialSlide="1"
              >
                <slide v-for="(item, i) in post_expanded_item.images" :key="i">
                  <img :src="$store.state.ASSETS_BASE_URL + '/feeds/' + item" />
                </slide>
                <hooper-navigation slot="hooper-addons"></hooper-navigation>
              </hooper>
            </div>
            <div
              class="post-video"
              v-if="
                post_expanded_item.file_type == 'video' &&
                post_expanded_item.files
              "
            >
              <VideoPlayer
                class="video-widget"
                :src="
                  $store.state.ASSETS_BASE_URL +
                  '/feeds/' +
                  post_expanded_item.files
                "
              ></VideoPlayer>
            </div>
            <div
              class="post-announcement"
              v-if="
                $route.params.type &&
                $route.params.type == 'sms' &&
                !$getLoggedUser()
              "
            >
              <p>
                To view your notification please create account using your phone
                number: <br />
                <span class="phone">{{ post_expanded_item.user.phone }}</span>
              </p>
              <button
                @click.prevent="
                  $router.push({ name: 'register' }).catch(() => {})
                "
                class="btn-default"
              >
                CREATE ACCOUNT
              </button>
            </div>
            <div v-if="false" class="post-desc"></div>
          </div>
          <div class="post-content">
            <div class="post-header">
              <div class="post-user-info">
                <div class="post-owner">
                  <img
                    v-if="post_expanded_item.user.profile_image"
                    class="profile-image"
                    :src="
                      $store.state.ASSETS_BASE_URL +
                      '/assets/uploaded/' +
                      post_expanded_item.user.profile_image
                    "
                  />
                  <img
                    v-else
                    class="profile-image"
                    src="@/assets/images/icons/empty_profile.svg"
                    alt
                  />
                  <div class="owner-info">
                    <h3 class="names">
                      {{
                        post_expanded_item.user.first_name +
                        " " +
                        post_expanded_item.user.last_name
                      }}
                    </h3>
                    <p class="time">
                      {{ moment(post_expanded_item.created_at).fromNow() }}
                    </p>
                  </div>
                </div>
                <div class="options">
                  <dropdown-menu
                    v-model="post_expanded_item.is_menu_open"
                    :right="false"
                    :hover="false"
                    :interactive="false"
                  >
                    <button class="dropdown-toggle">
                      <img src="@/assets/images/icons/menu.svg" />
                    </button>
                    <div slot="dropdown" class="drop-down-menu">
                      <a
                        class="dropdown-item"
                        @click="reportPost(post_expanded_item)"
                      >
                        <img src="@/assets/images/icons/feedback.svg" />
                        Report
                      </a>
                      <a
                        v-if="
                          post_expanded_item.post_ref == 'cagura' &&
                          $getLoggedUser() &&
                          $getLoggedUser().user.id == post_expanded_item.user_id
                        "
                        class="dropdown-item"
                        @click="updatePost(post_expanded_item)"
                      >
                        <img src="@/assets/images/icons/edit.svg" />
                        Update
                      </a>
                      <a
                        v-if="
                          post_expanded_item.post_ref == 'cagura' &&
                          $getLoggedUser() &&
                          $getLoggedUser().user.id == post_expanded_item.user_id
                        "
                        class="dropdown-item"
                        @click="deletePost(post_expanded_item, 0)"
                      >
                        <img src="@/assets/images/icons/trash.svg" />
                        Delete
                      </a>
                    </div>
                  </dropdown-menu>
                </div>
              </div>
              <div class="post-options">
                <div class="option-one">
                  <button
                    class="like-btn"
                    :class="{ liked: post_expanded_item.is_liked }"
                    @click="likePost(post)"
                  >
                    <img
                      v-if="!post_expanded_item.is_liked"
                      src="@/assets/images/icons/thumb_up.svg"
                    />
                    <img
                      v-else
                      src="@/assets/images/icons/thumb_up_green.svg"
                    />
                    {{ post_expanded_item.likes_count }}
                  </button>
                  <button class="comments-btn">
                    <img src="@/assets/images/icons/comment.svg" />
                    {{ post_expanded_item.comments.total_items }}
                  </button>
                </div>
                <div class="option-two">
                  <button
                    @click="showContactModal(post_expanded_item)"
                    class="btn-success contact-btn"
                  >
                    Contact
                  </button>
                </div>
              </div>
            </div>

            <div class="post-body">
              <vue-scroll>
                <div class="comment-container">
                  <div class="post-comments">
                    <div
                      class="comment-item"
                      v-for="(comment, comment_index) in post_expanded_item
                        .comments.data"
                      :key="comment_index"
                    >
                      <img
                        v-if="comment.user.profile_image"
                        class="profile-image"
                        :src="
                          $store.state.ASSETS_BASE_URL +
                          '/assets/uploaded/' +
                          comment.user.profile_image
                        "
                      />
                      <img
                        v-else
                        class="profile-image"
                        src="@/assets/images/icons/empty_profile.svg"
                        alt
                      />

                      <div class="comment-widget">
                        <div class="comment-body">
                          <h4>
                            {{
                              comment.user.first_name +
                              " " +
                              comment.user.last_name
                            }}
                          </h4>
                          <div class="comment-content">
                            {{ comment.content }}
                          </div>
                          <span
                            v-if="comment.likes_count > 0"
                            class="comment-likes"
                            ><img
                              src="@/assets/images/icons/thumb_up_white.svg"
                            />
                            {{ comment.likes_count }}</span
                          >
                        </div>
                        <div class="comment-footer">
                          <button
                            :class="{ liked: comment.is_liked }"
                            @click="likeComment(comment)"
                            class="btn-like"
                          >
                            Like
                          </button>
                          <button
                            @click="toggleReplyForm(comment)"
                            class="btn-reply"
                          >
                            Reply
                          </button>
                          <button
                            v-if="
                              $getLoggedUser() &&
                              $getLoggedUser().user.id == comment.user_id
                            "
                            @click="
                              deleteComment(
                                post_expanded_item.comments,
                                comment,
                                comment_index
                              )
                            "
                            class="btn-delete"
                          >
                            Delete
                          </button>
                        </div>

                        <!-- Reply form -->
                        <div
                          class="comment-reply-widget"
                          :class="{
                            empty_replies: comment.replies.data.length == 0,
                          }"
                          v-if="comment.show_reply_widget"
                        >
                          <div class="post-comment-form">
                            <div class="profile-widget">
                              <img
                                v-if="
                                  $getLoggedUser() &&
                                  $getLoggedUser().user.profile_image
                                "
                                class="profile-image"
                                :src="
                                  $store.state.ASSETS_BASE_URL +
                                  '/assets/uploaded/' +
                                  $getLoggedUser().user.profile_image
                                "
                              />
                              <img
                                v-else
                                class="profile-image"
                                src="@/assets/images/icons/empty_profile.svg"
                                alt
                              />
                            </div>
                            <div class="comments-form">
                              <input
                                type="text"
                                placeholder="Type your reply..."
                                v-model="comment.comment_text"
                                class="comments-input"
                                @focus="validateUserOnComment('comment')"
                              />

                              <button
                                @click="clearComment(comment)"
                                class="btn-close"
                                title="Close"
                              >
                                <img src="@/assets/images/icons/close.svg" />
                              </button>
                              <c-button
                                @click="submitMainComment(comment, 'comment')"
                                :loading="comment.submit_comment_loading"
                                class="btn-send"
                              >
                                <img src="@/assets/images/icons/send.svg" />
                              </c-button>
                            </div>
                          </div>
                        </div>
                        <!-- Replies comments -->
                        <div
                          class="comments-reply-list"
                          :class="{
                            not_replying: !comment.show_reply_widget,
                            no_more_comments:
                              comment.replies.current_page ==
                              comment.replies.total_page,
                          }"
                          v-if="comment.replies.data.length > 0"
                        >
                          <div
                            class="comment-reply-item"
                            v-for="(reply, reply_index) in comment.replies.data"
                            :key="reply_index"
                          >
                            <div class="comment-body">
                              <img
                                v-if="reply.user.profile_image"
                                class="profile-image"
                                :src="
                                  $store.state.ASSETS_BASE_URL +
                                  '/assets/uploaded/' +
                                  reply.user.profile_image
                                "
                              />
                              <img
                                v-else
                                class="profile-image"
                                src="@/assets/images/icons/empty_profile.svg"
                                alt
                              />
                              <div class="comment-container">
                                <h4>
                                  {{
                                    reply.user.first_name +
                                    " " +
                                    reply.user.last_name
                                  }}
                                </h4>
                                <div class="comment-content">
                                  {{ reply.content }}
                                </div>
                                <span
                                  class="comment-likes"
                                  v-if="reply.likes_count > 0"
                                  ><img
                                    src="@/assets/images/icons/thumb_up_white.svg"
                                  />
                                  {{ reply.likes_count }}</span
                                >
                              </div>
                            </div>
                            <div class="comment-footer">
                              <button
                                :class="{ liked: reply.is_liked }"
                                @click="likeComment(reply)"
                                class="btn-like"
                              >
                                Like
                              </button>
                              <button
                                v-if="
                                  $getLoggedUser() &&
                                  $getLoggedUser().user.id == reply.user_id
                                "
                                @click="
                                  deleteComment(
                                    comment.replies,
                                    reply,
                                    reply_index
                                  )
                                "
                                class="btn-delete"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          class="comments-reply-options"
                          v-if="
                            comment.replies.current_page <
                            comment.replies.total_page
                          "
                          @click="
                            getReplyComments(comment.replies.next_page, comment)
                          "
                        >
                          <button class="btn-show-more">Show More</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="post-comments-options"
                    v-if="
                      post_expanded_item.comments.current_page <
                      post_expanded_item.comments.total_page
                    "
                    @click="
                      getPostComments(
                        post_expanded_item.comments.next_page,
                        post_expanded_item
                      )
                    "
                  >
                    <button class="btn-show-more">More Comments</button>
                  </div>
                </div>
              </vue-scroll>
            </div>
            <!-- Comment and likes section -->
            <div class="post-footer">
              <!-- Post comment form -->
              <div class="post-comment-form">
                <div class="profile-widget">
                  <img
                    v-if="
                      $getLoggedUser() && $getLoggedUser().user.profile_image
                    "
                    class="profile-image"
                    :src="
                      $store.state.ASSETS_BASE_URL +
                      '/assets/uploaded/' +
                      $getLoggedUser().user.profile_image
                    "
                  />
                  <img
                    v-else
                    class="profile-image"
                    src="@/assets/images/icons/empty_profile.svg"
                    alt
                  />
                </div>
                <div class="comments-form">
                  <input
                    type="text"
                    placeholder="Type your comment..."
                    @focus="validateUserOnComment('post')"
                    v-model="post_expanded_item.comment_text"
                    class="comments-input"
                  />
                  <c-button
                    @click="submitMainComment(post_expanded_item, 'main')"
                    :loading="post_expanded_item.submit_comment_loading"
                    class="btn-send"
                  >
                    <img src="@/assets/images/icons/send.svg" />
                  </c-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- End of expanded modal -->

    <!--  Contact form -->
    <transition name="fade">
      <div class="overlay" v-if="show_contact_form">
        <div class="contact-form">
          <vue-scroll>
            <h2>Contact this person</h2>
            <p v-if="contact_form.post_type == 'looking'">
              Help this person to find what he/she is looking for
            </p>
            <p v-if="contact_form.post_type == 'selling'">
              Tell this person everthing about how you can get this product
            </p>
            <div class="form-input">
              <textarea
                class="contant-input"
                v-model="contact_form.message"
                placeholder="Type your message"
              ></textarea>
            </div>
            <div class="form-options">
              <div class="btns">
                <c-button
                  :loading="contact_loading"
                  class="btn-send btn-success"
                  @click="contactPostOwner()"
                  >SEND</c-button
                >
                <button class="btn-default" @click="show_contact_form = false">
                  CLOSE
                </button>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
    </transition>

    <!-- Add modal -->
    <AddPostModal
      v-if="show_add_post"
      :options="add_post_options"
      @close="closeAddPostModal"
      @onSubmit="onAddPostResponce"
    ></AddPostModal>
    <!-- End of add modal -->
    <ask-auth
      @onCancel="onCloseAskAccount()"
      v-if="ask_for_account"
      :message="' ' + ask_for_account_message"
    ></ask-auth>
    <PostReportModal
      v-if="show_report_modal"
      :post="reported_post"
      @onSubmit="onCloseReportModal()"
    ></PostReportModal>
  </div>
</template>
<script>
import AddPostModal from "../../components/modals/AddPostModal.vue";
import feedImage from "../../components/partials/feedImage.vue";
import PostReportModal from "../../components/modals/PostReportModal.vue";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
export default {
  name: "feed",
  components: {
    AddPostModal,
    feedImage,
    PostReportModal,
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {
    return {
      posts: [],
      show_add_post: false,
      add_post_options: {
        type: "text",
        is_updating: false,
        updating_data: null,
      },
      ask_for_account: false,
      ask_for_account_message: "",
      show_report_modal: false,
      reported_post: null,
      post_expanded_item: null,
      show_post_modal: false,
      contact_form: {
        message: "",
        post_type: "selling",
        receiver_id: null,
        post_id: null,
      },
      show_contact_form: false,
      contact_loading: false,
      get_post_loading: false,
    };
  },
  watch: {
    "$route.params.id"(post_id) {
      let vm = this;
      if (vm.$route.params.type) {
        vm.showSinglePost(post_id);
      } else {
        vm.show_post_modal = false;
        vm.post_expanded_item = null;
      }
    },
  },
  mounted() {
    let vm = this;
    vm.$store.state.eventBus.$on("onPostScoll", (e) => {
      if (vm.posts.current_page < vm.posts.total_page) {
        if (!vm.get_post_loading) {
          vm.getPosts(vm.posts.next_page);
        }
      }
    });
    if (vm.$route.params.type && vm.$route.params.id) {
      vm.showSinglePost(vm.$route.params.id);
    }
    vm.getPosts(1);
  },
  methods: {
    showSinglePost(post_id) {
      let vm = this;
      vm.show_post_modal = true;
      vm.$store
        .dispatch("FEEDS_GET_SINGLE_POST", { post_id: post_id })
        .then((response) => {
          let item = response.data.return;
          vm.$store.state.eventBus.$set(item, "is_menu_open", false);
          vm.$store.state.eventBus.$set(item, "comment_text", "");
          vm.$store.state.eventBus.$set(item, "submit_comment_loading", false);
          // Process decription
          let max_content_length = 150;
          let { html, text } = vm.processPostDescription(item);
          vm.$store.state.eventBus.$set(item, "html_content", html);
          vm.$store.state.eventBus.$set(item, "text_content", text);
          if (text.length > max_content_length) {
            vm.$store.state.eventBus.$set(item, "show_readmore", true);
            vm.$store.state.eventBus.$set(item, "is_readingmore", false);
            vm.$store.state.eventBus.$set(
              item,
              "less_text_content",
              text.slice(0, max_content_length) + "..."
            );
            vm.$store.state.eventBus.$set(
              item,
              "less_html_content",
              html.slice(0, max_content_length) + "..."
            );
          } else {
            vm.$store.state.eventBus.$set(item, "show_readmore", false);
            vm.$store.state.eventBus.$set(item, "less_text_content", text);
            vm.$store.state.eventBus.$set(item, "less_html_content", html);
          }
          // Comments
          item.comments.data.forEach((comment) => {
            vm.$store.state.eventBus.$set(comment, "is_menu_open", false);
            vm.$store.state.eventBus.$set(comment, "show_reply_widget", false);
            vm.$store.state.eventBus.$set(comment, "comment_text", "");
            vm.$store.state.eventBus.$set(
              comment,
              "submit_comment_loading",
              false
            );
          });

          // Image Processing
          if (item.file_type == "image") {
            vm.$store.state.eventBus.$set(
              item,
              "images",
              JSON.parse(item.files)
            );
          }
          vm.post_expanded_item = item;
        })
        .catch(({ response }) => {});
    },
    showContactModal(post) {
      let vm = this;
      if (vm.$getLoggedUser()) {
        vm.contact_form.post_type = post.post_type;
        vm.contact_form.receiver_id = post.user_id;
        vm.contact_form.post_id = post.id;
        vm.show_contact_form = true;
      } else {
        vm.ask_for_account_message = "to contact this person";
        vm.ask_for_account = true;
      }
    },
    contactPostOwner() {
      let vm = this;
      let rules = {
        message: {
          run: (value) => {
            if (value.length >= 30 && value.length <= 1000) {
              return false;
            }
            return "Your message must have 30 - 1000 charcters";
          },
        },
      };
      if (vm.$isFieldsValidated(vm.contact_form, rules)) {
        vm.contact_loading = true;
        vm.$store
          .dispatch("FEEDS_CONTACT_POST_OWNER", vm.contact_form)
          .then((response) => {
            vm.contact_form.message = "";
            vm.contact_loading = false;
            vm.show_contact_form = false;
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.contact_loading = false;
            console.error("FEEDS_CONTACT_POST_OWNER", { response });
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
          });
      }
    },
    reportPost(post) {
      let vm = this;
      vm.reported_post = post;
      vm.show_report_modal = true;
    },
    onCloseReportModal() {
      let vm = this;
      vm.show_report_modal = false;
    },
    onCloseAskAccount() {
      let vm = this;
      vm.ask_for_account = false;
    },
    openAddPostModal(type) {
      let vm = this;
      vm.add_post_options = {
        type: type,
        is_updating: false,
        updating_data: null,
      };
      vm.show_add_post = true;
    },
    closeAddPostModal() {
      let vm = this;
      vm.show_add_post = false;
    },
    onAddPostResponce() {
      let vm = this;
      vm.show_add_post = false;
      vm.getPosts(1);
    },
    processPostDescription(post) {
      let post_el_content = document.createElement("html");
      post_el_content.innerHTML = "<div>" + post.content + "</div>";
      let text = post_el_content.innerText;
      return {
        html: post.content.replaceAll("�", ""),
        text: text.replaceAll("�", ""),
      };
    },
    getPosts(page) {
      let vm = this;
      vm.get_post_loading = true;
      vm.$store
        .dispatch("FEEDS_GET_POST", {
          page: page,
        })
        .then((response) => {
          vm.get_post_loading = false;
          if (page == 1) {
            vm.posts = response.data.return;
            vm.posts.data.forEach((item) => {
              vm.$store.state.eventBus.$set(item, "is_menu_open", false);
              vm.$store.state.eventBus.$set(item, "comment_text", "");
              vm.$store.state.eventBus.$set(
                item,
                "submit_comment_loading",
                false
              );
              // Process decription
              let max_content_length = 150;
              let { html, text } = vm.processPostDescription(item);
              vm.$store.state.eventBus.$set(item, "html_content", html);
              vm.$store.state.eventBus.$set(item, "text_content", text);
              if (text.length > max_content_length) {
                vm.$store.state.eventBus.$set(item, "show_readmore", true);
                vm.$store.state.eventBus.$set(item, "is_readingmore", false);
                vm.$store.state.eventBus.$set(
                  item,
                  "less_text_content",
                  text.slice(0, max_content_length) + "..."
                );
                vm.$store.state.eventBus.$set(
                  item,
                  "less_html_content",
                  html.slice(0, max_content_length) + "..."
                );
              } else {
                vm.$store.state.eventBus.$set(item, "show_readmore", false);
                vm.$store.state.eventBus.$set(item, "less_text_content", text);
                vm.$store.state.eventBus.$set(item, "less_html_content", html);
              }
              // Comments
              item.comments.data.forEach((comment) => {
                vm.$store.state.eventBus.$set(comment, "is_menu_open", false);
                vm.$store.state.eventBus.$set(
                  comment,
                  "show_reply_widget",
                  false
                );
                vm.$store.state.eventBus.$set(comment, "comment_text", "");
                vm.$store.state.eventBus.$set(
                  comment,
                  "submit_comment_loading",
                  false
                );
              });

              // Image Processing
              if (item.file_type == "image") {
                vm.$store.state.eventBus.$set(
                  item,
                  "images",
                  JSON.parse(item.files)
                );
              }
            });
          } else {
            vm.posts.current_page = response.data.return.current_page;
            vm.posts.end_item = response.data.return.end_item;
            vm.posts.item_per_page = response.data.return.item_per_page;
            vm.posts.next_page = response.data.return.next_page;
            vm.posts.previews_page = response.data.return.previews_page;
            vm.posts.start_item = response.data.return.start_item;
            vm.posts.total_items = response.data.return.total_items;
            vm.posts.total_page = response.data.return.total_page;
            response.data.return.data.forEach((item) => {
              vm.$store.state.eventBus.$set(item, "is_menu_open", false);
              vm.$store.state.eventBus.$set(item, "comment_text", "");
              vm.$store.state.eventBus.$set(
                item,
                "submit_comment_loading",
                false
              );
              // Process decription
              let max_content_length = 150;
              let { html, text } = vm.processPostDescription(item);
              vm.$store.state.eventBus.$set(item, "html_content", html);
              vm.$store.state.eventBus.$set(item, "text_content", text);
              if (text.length > max_content_length) {
                vm.$store.state.eventBus.$set(item, "show_readmore", true);
                vm.$store.state.eventBus.$set(item, "is_readingmore", false);
                vm.$store.state.eventBus.$set(
                  item,
                  "less_text_content",
                  text.slice(0, max_content_length) + "..."
                );
                vm.$store.state.eventBus.$set(
                  item,
                  "less_html_content",
                  html.slice(0, max_content_length) + "..."
                );
              } else {
                vm.$store.state.eventBus.$set(item, "show_readmore", false);
                vm.$store.state.eventBus.$set(item, "less_text_content", text);
                vm.$store.state.eventBus.$set(item, "less_html_content", html);
              }
              // Comments
              item.comments.data.forEach((comment) => {
                vm.$store.state.eventBus.$set(comment, "is_menu_open", false);
                vm.$store.state.eventBus.$set(
                  comment,
                  "show_reply_widget",
                  false
                );
                vm.$store.state.eventBus.$set(comment, "comment_text", "");
                vm.$store.state.eventBus.$set(
                  comment,
                  "submit_comment_loading",
                  false
                );
              });

              // Image Processing
              if (item.file_type == "image") {
                vm.$store.state.eventBus.$set(
                  item,
                  "images",
                  JSON.parse(item.files)
                );
              }
              vm.posts.data.push(item);
            });
          }
        })
        .catch((error) => {
          vm.get_post_loading = false;
          console.error("FEEDS_GET_POST", { error });
        });
    },
    submitMainComment(item, type) {
      let vm = this;
      if (vm.$getLoggedUser()) {
        const rules = {
          comment_text: {
            run: (value) => {
              if (value.length > 2) {
                return false;
              }
              return "Please add your comment";
            },
          },
        };
        if (vm.$isFieldsValidated(item, rules)) {
          item.submit_comment_loading = true;
          if (type == "main") {
            vm.$store
              .dispatch("FEEDS_SUBMIT_POST_COMMENT", {
                post_id: item.id,
                content: item.comment_text,
                type: type,
                receiver_id: item.user_id,
              })
              .then((response) => {
                item.comment_text = "";
                vm.getPostComments(1, item);
              })
              .catch(({ response }) => {
                item.submit_comment_loading = false;
                vm.$notify({
                  group: "status",
                  type: "warn",
                  title: "OOPS!!!",
                  text: "Something went wrong while commenting, Try again",
                });
                console.error("FEEDS_SUBMIT_POST_COMMENT", response);
              });
          }
          if (type == "comment") {
            vm.$store
              .dispatch("FEEDS_SUBMIT_POST_COMMENT", {
                post_id: item.post_id,
                comment_id: item.id,
                content: item.comment_text,
                type: type,
                receiver_id: item.user_id,
              })
              .then((response) => {
                vm.getReplyComments(1, item);
                item.comment_text = "";
                item.submit_comment_loading = false;
              })
              .catch(({ response }) => {
                item.submit_comment_loading = false;
                vm.$notify({
                  group: "status",
                  type: "warn",
                  title: "OOPS!!!",
                  text: "Something went wrong while commenting, Try again",
                });
                console.error("FEEDS_SUBMIT_POST_COMMENT", response);
              });
          }
        }
      } else {
        vm.ask_for_account_message = "to comment to this post";
        vm.ask_for_account = true;
      }
    },
    getPostComments(page, post) {
      let vm = this;
      vm.$store
        .dispatch("FEEDS_GET_POST_COMMENTS", {
          post_id: post.id,
          page: page,
        })
        .then((response) => {
          post.submit_comment_loading = false;
          if (page == 1) {
            post.comments = response.data.return;
            post.comments.data.map((item) => {
              vm.$store.state.eventBus.$set(item, "is_menu_open", false);
              vm.$store.state.eventBus.$set(item, "show_reply_widget", false);
              vm.$store.state.eventBus.$set(item, "comment_text", "");
              vm.$store.state.eventBus.$set(
                item,
                "submit_comment_loading",
                false
              );
              return item;
            });
          } else {
            post.comments.current_page = response.data.return.current_page;
            post.comments.end_item = response.data.return.end_item;
            post.comments.item_per_page = response.data.return.item_per_page;
            post.comments.next_page = response.data.return.next_page;
            post.comments.previews_page = response.data.return.previews_page;
            post.comments.start_item = response.data.return.start_item;
            post.comments.total_items = response.data.return.total_items;
            post.comments.total_page = response.data.return.total_page;
            response.data.return.data.forEach((item) => {
              vm.$store.state.eventBus.$set(item, "is_menu_open", false);
              vm.$store.state.eventBus.$set(item, "show_reply_widget", false);
              vm.$store.state.eventBus.$set(item, "comment_text", "");
              vm.$store.state.eventBus.$set(
                item,
                "submit_comment_loading",
                false
              );
              post.comments.data.push(item);
            });
          }
        })
        .catch(({ response }) => {
          post.submit_comment_loading = false;
        });
    },
    getReplyComments(page, comment) {
      let vm = this;
      vm.$store
        .dispatch("FEEDS_GET_POST_COMMENT_REPLIES", {
          post_id: comment.post_id,
          comment_id: comment.id,
          page: page,
        })
        .then((response) => {
          comment.submit_comment_loading = false;
          if (page == 1) {
            comment.replies = response.data.return;
            comment.replies.data.map((item) => {
              return item;
            });
          } else {
            comment.replies.current_page = response.data.return.current_page;
            comment.replies.end_item = response.data.return.end_item;
            comment.replies.item_per_page = response.data.return.item_per_page;
            comment.replies.next_page = response.data.return.next_page;
            comment.replies.previews_page = response.data.return.previews_page;
            comment.replies.start_item = response.data.return.start_item;
            comment.replies.total_items = response.data.return.total_items;
            comment.replies.total_page = response.data.return.total_page;
            response.data.return.data.forEach((item) => {
              comment.replies.data.push(item);
            });
          }
        })
        .catch(({ response }) => {
          comment.submit_comment_loading = false;
        });
    },
    deleteComment(comments, comment, index) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure? Your comment will be deleted`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.$store
              .dispatch("FEEDS_DELETE_POST_COMMENT", { id: comment.id })
              .then((response) => {
                comments.data.splice(index, 1);
                comments.total_items -= 1;
              })
              .catch(({ response }) => {
                console.error("FEEDS_DELETE_POST_COMMENT", response);
              });
          }
        },
      });
    },
    toggleReplyForm(comment) {
      comment.show_reply_widget = !comment.show_reply_widget;
    },
    clearComment(comment) {
      comment.comment_text = "";
    },
    deletePost(post, index) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure? This post will be deleted`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.$store
              .dispatch("FEEDS_DELETE_POST", { id: post.id })
              .then((response) => {
                vm.posts.data.splice(index, 1);
              })
              .catch(({ response }) => {
                console.error("FEEDS_DELETE_POST", response);
              });
          }
        },
      });
    },
    likePost(post) {
      let vm = this;
      if (vm.$getLoggedUser()) {
        post.is_liked = !post.is_liked;
        if (post.is_liked) {
          post.likes_count += 1;
        } else {
          post.likes_count -= 1;
        }
        vm.$store
          .dispatch("FEEDS_LIKE_POST", {
            id: post.id,
            is_liked: post.is_liked,
            receiver_id: post.user_id,
          })
          .then((response) => {})
          .catch(({ response }) => {
            console.error("FEEDS_LIKE_POST", response);
          });
      } else {
        vm.ask_for_account_message = "to like this post";
        vm.ask_for_account = true;
      }
    },
    likeComment(comment) {
      let vm = this;
      if (vm.$getLoggedUser()) {
        comment.is_liked = !comment.is_liked;
        if (comment.is_liked) {
          comment.likes_count += 1;
        } else {
          comment.likes_count -= 1;
        }
        vm.$store
          .dispatch("FEEDS_LIKE_POST_COMMENT", {
            id: comment.id,
            is_liked: comment.is_liked,
            post_id: comment.post_id,
            receiver_id: comment.user_id,
          })
          .then((response) => {})
          .catch(({ response }) => {
            console.error("FEEDS_LIKE_POST_COMMENT", response);
          });
      } else {
        vm.ask_for_account_message = "to like this comment";
        vm.ask_for_account = true;
      }
    },
    validateUserOnComment(type) {
      let vm = this;
      if (!vm.$getLoggedUser()) {
        if (type == "post") {
          vm.ask_for_account_message = "to add comment to this post";
        }
        if (type == "comment") {
          vm.ask_for_account_message = "to add reply to this post";
        }
        vm.ask_for_account = true;
      }
    },
    updatePost(post) {
      let vm = this;
      vm.add_post_options = {
        type: "text",
        is_updating: true,
        updating_data: post,
      };
      vm.show_add_post = true;
    },
    expandPost(post) {
      let vm = this;
      if (post.file_type) {
        vm.post_expanded_item = post;
        vm.$router.push({
          name: "post",
          params: { type: "view", id: post.id },
        });
        vm.show_post_modal = true;
      }
    },
    closePostModal() {
      let vm = this;
      vm.post_expanded_item = null;
      vm.show_post_modal = false;
      vm.$router.push({ name: "home" }).catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.feeds-wrapper {
  padding-left: 10px;
  margin-top: 10px;
  @media screen and (max-width: 800px) {
    padding-right: 10px;
  }
  .post-form {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    .post-input {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #f1f1f1;
      padding: 8px;
      .profile-image {
        width: 40px;
        height: 40px;
        margin-right: 6px;
        object-fit: cover;
        border: 1px solid #ccc;
        border-radius: 5px;
        background: #ddd;
        @media screen and (max-width: 500px) {
          width: 30px;
          height: 30px;
        }
      }
      .input {
        background: #fff;
        height: 70px;
        padding: 12px 0px;
        border-radius: 5px;
        width: calc(100% - 30px);
        cursor: pointer;
        margin-top: -7px;
        @media screen and (max-width: 500px) {
          height: 52px;
          padding: 2px 0px;
          span {
            font-size: 12px;
          }
        }
        .name {
          margin-right: 4px;
          color: #000;
          font-weight: 500;
        }
      }
    }
    .form-options {
      padding: 8px 8px;
      display: flex;
      align-items: center;
      .add-video {
        border: none;
        margin-right: 30px;
        background: transparent;
        @media screen and (max-width: 500px) {
          font-size: 12px;
        }
        img {
          width: 28px;
          margin-top: -3px;
          margin-right: 4px;
          @media screen and (max-width: 500px) {
            width: 18px;
          }
        }
      }
      .add-image {
        border: none;
        margin-right: 30px;
        background: transparent;
        @media screen and (max-width: 500px) {
          font-size: 12px;
        }
        img {
          width: 28px;
          margin-top: -3px;
          margin-right: 4px;
          @media screen and (max-width: 500px) {
            width: 18px;
          }
        }
      }
    }
  }
  .posts-widget {
    margin-top: 25px;
    width: 100%;
    .post-item {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
      width: 100%;
      margin-bottom: 10px;
      .post-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        position: relative;
        .post-owner {
          display: flex;
          align-items: center;
          .profile-image {
            width: 30px;
            height: 30px;
            object-fit: cover;
            border: 1px solid#8dc73f;
            border-radius: 5px;
            background: #ddd;
            margin-right: 10px;
            @media screen and (max-width: 500px) {
              width: 30px;
              height: 30px;
            }
          }
          .owner-info {
            display: flex;
            flex-direction: column;
            h3 {
              margin: 0px;
              font-size: 13px;
              @media screen and (max-width: 500px) {
                font-size: 12px;
              }
            }
            p {
              margin: 0px;
              font-size: 12px;
              color: #ccc;
              @media screen and (max-width: 500px) {
                font-size: 11px;
              }
            }
          }
        }
        .options {
          display: flex;
          align-items: center;
          .post-type-label {
            border-bottom-left-radius: 4px;
            margin-top: -13px;
            border-bottom-right-radius: 4px;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 13px;
            padding-bottom: 5px;
            @media screen and (max-width: 500px) {
              font-size: 11px;
              margin-top: -15px;
              padding-left: 8px;
              padding-right: 8px;
              padding-top: 8px;
            }
            &.selling {
              background: #4267b2;
              color: #fff;
            }
            &.looking {
              background: #ff8a8a;
              color: #fff;
            }
          }
        }
      }
      .post-body {
        width: 100%;
        .post-desc {
          padding: 5px 10px;
          cursor: pointer;
          word-break: break-all;
          @media screen and (max-width: 500px) {
            font-size: 12px;
          }
          .btn-readmore {
            background: transparent;
            border: none;
            color: #8dc73f;
            display: flex;
            justify-content: flex-end;
            padding: 0;
            margin-top: 8px;
            span {
              width: 100%;
            }
          }
        }
        .post-images {
          min-height: 150px;
          max-height: 500px;
          display: flex;
          background: rgb(245, 245, 245);
        }
        .post-video {
          min-height: 400px;
          max-height: 400px;
          display: flex;
          background: #000;
        }
      }
      .post-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px;
        .option-one {
          display: flex;
          align-items: center;
          .like-btn {
            background: transparent;
            border: none;
            font-size: 12px;
            color: rgb(161, 161, 161);
            display: flex;
            align-items: center;

            &.liked {
              color: #8dc73f;
            }
            img {
              width: 15px;
              margin-right: 3px;
              margin-top: -5px;
            }
          }
          .comments-btn {
            background: transparent;
            border: none;
            font-size: 13px;
            color: rgb(161, 161, 161);
            display: flex;
            align-items: center;
            img {
              width: 14px;
              margin-right: 3px;
              margin-top: 4px;
            }
          }
        }
        .option-two {
          .contact-btn {
          }
        }
      }
      .post-footer {
        .post-comments {
          display: flex;
          flex-direction: column;
          padding: 8px 10px;
          margin-right: 11px;

          .comment-item {
            display: flex;
            .profile-image {
              width: 35px;
              height: 35px;
              margin-right: 6px;
              margin-right: 10px;
              object-fit: cover;
              border: 1px solid #ccc;
              border-radius: 5px;
              background: #ddd;
              @media screen and (max-width: 500px) {
                width: 30px;
                height: 30px;
              }
            }
            .comment-widget {
              width: 100%;

              .comment-body {
                background: #f8f8f8;
                padding: 6px;
                border-radius: 5px;
                margin-bottom: 5px;
                position: relative;
                h4 {
                  margin: 0px;
                  @media screen and (max-width: 500px) {
                    font-size: 14px;
                  }
                }
                .comment-content {
                  color: rgb(87, 87, 87);
                  @media screen and (max-width: 500px) {
                    font-size: 12px;
                  }
                }
                .comment-likes {
                  position: absolute;
                  top: -12px;
                  height: 21px;
                  right: -10px;
                  background: #8dc73f;
                  color: #fff;
                  width: 35px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 4px;
                  font-size: 9px;
                  img {
                    margin-right: 4px;
                    margin-top: -1px;
                  }
                }
              }
              .comment-footer {
                margin-top: -2px;
                margin-bottom: 4px;
                .btn-like {
                  border: none;
                  background: transparent;
                  outline: none;
                  font-size: 12px;
                  &.liked {
                    color: #8dc73f;
                  }
                }
                .btn-reply {
                  border: none;
                  background: transparent;
                  outline: none;
                  font-size: 12px;
                }
                .btn-delete {
                  border: none;
                  background: transparent;
                  outline: none;
                  color: #c375cd;
                  font-size: 12px;
                }
              }
              .comment-reply-widget {
                &.empty_replies {
                  margin-bottom: 20px;
                }
                .post-comment-form {
                  border-radius: 5px;
                  .btn-close {
                    border: none;
                    background: transparent;
                  }
                }
              }
              .comments-reply-list {
                margin-top: 30px;
                &.not_replying {
                  margin-top: 10px;
                }
                &.no_more_comments {
                  margin-bottom: 20px;
                }
                .comment-reply-item {
                  .comment-body {
                    background: #f8f8f8;
                    padding: 6px;
                    border-radius: 5px;
                    margin-bottom: 5px;
                    position: relative;
                    display: flex;
                    .profile-image {
                      width: 35px;
                      height: 35px;
                      margin-right: 6px;
                      margin-right: 10px;
                      border-radius: 50%;
                      object-fit: cover;
                    }
                    .comment-container {
                      h4 {
                        margin: 0px;
                      }
                      .comment-content {
                        color: rgb(87, 87, 87);
                      }
                      .comment-likes {
                        position: absolute;
                        top: -12px;
                        height: 21px;
                        right: -10px;
                        background: #8dc73f;
                        color: #fff;
                        width: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 4px;
                        font-size: 9px;
                        img {
                          margin-right: 4px;
                          margin-top: -1px;
                        }
                      }
                    }
                  }
                  .comment-footer {
                    padding-left: 50px;
                  }
                }
              }
              .comments-reply-options {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 40px;
                margin-top: 10px;
                .btn-show-more {
                  background: transparent;
                  color: #817f7f;
                  border: 1px dashed #ddd;
                }
              }
            }
          }
        }
        .post-comments-options {
          padding-bottom: 12px;
          padding-left: 5px;
          .btn-show-more {
            background: transparent;
            border: none;
            color: #8dc73f;
          }
        }
        .post-comment-form {
          background: #f2f2f2;
          display: flex;
          align-items: center;
          padding-left: 14px;
          .profile-widget {
            margin-right: 10px;
            .profile-image {
              width: 30px;
              height: 30px;
              object-fit: cover;
              border-radius: 5px;
            }
          }
          .comments-form {
            display: flex;
            align-items: center;
            width: 100%;
            .comments-input {
              width: 100%;
              border: none;
              outline: none;
              background: transparent;
            }
            .btn-attachment {
              border: none;
              background: transparent;
              img {
                width: 20px;
              }
            }
            .btn-send {
              border: none;
              background: #dbd9d9;
              height: 57px;
              width: 65px;
              @media screen and (max-width: 330px) {
                height: 46px;
              }
              img {
                width: 20px;
              }
            }
          }
        }
      }
    }
  }
  // Loading widget
  .post-loading-widget {
    margin-top: 25px;
    width: 100%;

    .post-loading-item {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
      width: 100%;
      margin-bottom: 10px;
      .post-loading-header {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        position: relative;
        width: 100%;
        .profile-widget {
          margin-right: 10px;
          width: 40px;
          .profile-image {
            width: 40px;
            height: 40px;
            border: 1px solid #ccc;
            background: #ddd;
            border-radius: 5px;
          }
        }
        .profile-content {
          display: flex;
          flex-direction: column;
          width: calc(100% - 40px);
          .names {
            width: 80%;
            height: 15px;
            border: 1px solid #ccc;
            background: #ddd;
            border-radius: 2px;
            margin-bottom: 3px;
          }
          .dates {
            width: 50%;
            height: 15px;
            border: 1px solid #ccc;
            background: #ddd;
            border-radius: 2px;
          }
        }
        .menu-btn {
          position: absolute;
          top: 16px;
          right: 16px;
          width: 11px;
          height: 30px;
          border: 1px solid #ccc;
          background: #ddd;
          border-radius: 2px;
        }
      }
      .post-loading-body {
        padding: 10px 15px;
        .discription-widget {
          display: flex;
          flex-direction: column;
          .disription-row {
            width: 100%;
            height: 15px;
            border: 1px solid #ccc;
            background: #ddd;
            border-radius: 2px;
            margin-bottom: 6px;
          }
        }
        .posts-images {
          display: grid;
          grid-template-columns: 24% 24% 24% 24%;
          grid-gap: 8px;
          .post-image-item {
            height: 200px;
            border: 1px solid #ccc;
            background: #ddd;
            border-radius: 5px;
          }
        }
      }
      .post-loading-footer {
        margin-top: 20px;
        background: #ddd;
        height: 60px;
      }
    }
  }
  // exapanded modal
  .overlay {
    display: block;
    padding-top: 80px;
    .expanded-post-modal {
      background: #fff;
      width: 85%;
      min-height: 550px;
      height: 90%;
      margin: 0 auto;
      border-radius: 5px;
      display: flex;
      @media screen and (max-width: 800px) {
        display: block;
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
      }
      .post-media {
        position: relative;
        width: calc(100% - 360px);
        height: 100%;
        overflow: hidden;
        @media screen and (max-width: 800px) {
          position: fixed;
          top: 0px;
          bottom: 0px;
          left: 0px;
          right: 0px;
          padding-top: 80px;
          width: 100%;
        }
        .btn-close {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          background: #000;
          color: #fff;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          z-index: 9999;
          border: 2px solid #fff;
          top: 10px;
          left: 14px;
          @media screen and (max-width: 800px) {
            top: 75px;
          }
        }
        .post-type-label {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 13px;
          padding-bottom: 5px;
          position: absolute;
          top: 0px;
          right: 10px;
          z-index: 999;
          @media screen and (max-width: 800px) {
            top: 70px;
          }
          &.selling {
            background: #4267b2;
            color: #fff;
          }
          &.looking {
            background: #ff8a8a;
            color: #fff;
          }
        }
        .post-video {
          height: 100%;
          width: 100%;
          display: flex;
          .video-widget {
            height: 100%;
            width: 100%;
          }
        }
        .post-announcement {
          position: absolute;
          bottom: 0px;
          left: 0px;
          right: 0px;
          background: #fff;
          padding: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          p {
            text-align: center;
            color: rgb(92, 92, 92);
            .phone {
              color: #000;
              font-size: 16px;
            }
          }
        }

        .post-desc {
          position: absolute;
          bottom: 0px;
          left: 0px;
          right: 0px;
          background: black;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.676908) 57%,
            rgba(0, 0, 0, 0) 100%
          );
          padding: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          text-align: center;
          color: #fff;
          word-break: break-all;
          @media screen and (max-width: 800px) {
            display: none;
          }
        }
      }
      .post-content {
        width: 360px;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-left: 1px solid #000;
        background: #fff;
        @media screen and (max-width: 800px) {
          display: none;
        }
        .post-header {
          height: 117px;
          padding: 5px 10px;
          .post-user-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            padding: 7px 0px;
            .post-owner {
              display: flex;
              align-items: center;
              .profile-image {
                width: 40px;
                width: 40px;
                border-radius: 50%;
                object-fit: cover;
                border: 1px solid#8dc73f;
                margin-right: 10px;
              }
              .owner-info {
                display: flex;
                flex-direction: column;
                h3 {
                  margin: 0px;
                  font-size: 13px;
                  text-transform: capitalize;
                }
                p {
                  margin: 0px;
                  font-size: 12px;
                  color: #ccc;
                }
              }
            }
            .options {
              display: flex;
              align-items: center;
            }
          }
          .post-options {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0px;
            .option-one {
              display: flex;
              align-items: center;
              .like-btn {
                background: transparent;
                border: none;
                font-size: 12px;
                color: rgb(161, 161, 161);
                display: flex;
                align-items: center;

                &.liked {
                  color: #8dc73f;
                }
                img {
                  width: 15px;
                  margin-right: 3px;
                  margin-top: -5px;
                }
              }
              .comments-btn {
                background: transparent;
                border: none;
                font-size: 13px;
                color: rgb(161, 161, 161);
                display: flex;
                align-items: center;
                img {
                  width: 14px;
                  margin-right: 3px;
                  margin-top: 4px;
                }
              }
            }
            .option-two {
              .contact-btn {
              }
            }
          }
        }
        .post-body {
          width: 100%;
          height: calc(100% - 174px);
          .comment-container {
            padding-right: 15px;
            .post-comments {
              display: flex;
              flex-direction: column;
              padding: 8px 10px;
              margin-right: 11px;
              margin-top: 20px;
              .comment-item {
                display: flex;
                .profile-image {
                  width: 40px;
                  height: 40px;
                  margin-right: 6px;
                  margin-right: 10px;
                  border-radius: 50%;
                  object-fit: cover;
                }
                .comment-widget {
                  width: 100%;

                  .comment-body {
                    background: #f8f8f8;
                    padding: 6px;
                    border-radius: 5px;
                    margin-bottom: 5px;
                    position: relative;
                    h4 {
                      margin: 0px;
                    }
                    .comment-content {
                      color: rgb(87, 87, 87);
                    }
                    .comment-likes {
                      position: absolute;
                      top: -12px;
                      height: 21px;
                      right: -10px;
                      background: #8dc73f;
                      color: #fff;
                      width: 35px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 4px;
                      font-size: 9px;
                      img {
                        margin-right: 4px;
                        margin-top: -1px;
                      }
                    }
                  }
                  .comment-footer {
                    margin-top: -2px;
                    margin-bottom: 4px;
                    .btn-like {
                      border: none;
                      background: transparent;
                      outline: none;
                      font-size: 12px;
                      &.liked {
                        color: #8dc73f;
                      }
                    }
                    .btn-reply {
                      border: none;
                      background: transparent;
                      outline: none;
                      font-size: 12px;
                    }
                    .btn-delete {
                      border: none;
                      background: transparent;
                      outline: none;
                      color: #c375cd;
                      font-size: 12px;
                    }
                  }
                  .comment-reply-widget {
                    &.empty_replies {
                      margin-bottom: 20px;
                    }
                    .post-comment-form {
                      background: #efefef;
                      display: flex;
                      align-items: center;
                      padding-left: 14px;
                      border-radius: 5px;
                      .profile-widget {
                        margin-right: 10px;
                        .profile-image {
                          width: 30px;
                          height: 30px;
                          object-fit: cover;
                          border-radius: 5px;
                        }
                      }
                      .comments-form {
                        background: #efefef;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        .comments-input {
                          width: 100%;
                          border: none;
                          outline: none;
                          background: transparent;
                        }
                        .btn-close {
                          border: none;
                          background: transparent;
                        }
                        .btn-send {
                          border: none;
                          background: #dbd9d9;
                          height: 57px;
                          width: 65px;
                          img {
                            width: 20px;
                          }
                        }
                      }
                    }
                  }
                  .comments-reply-list {
                    margin-top: 30px;
                    &.not_replying {
                      margin-top: 10px;
                    }
                    &.no_more_comments {
                      margin-bottom: 20px;
                    }
                    .comment-reply-item {
                      .comment-body {
                        background: #f8f8f8;
                        padding: 6px;
                        border-radius: 5px;
                        margin-bottom: 5px;
                        position: relative;
                        display: flex;
                        .profile-image {
                          width: 40px;
                          height: 40px;
                          margin-right: 6px;
                          margin-right: 10px;
                          border-radius: 50%;
                          object-fit: cover;
                        }
                        .comment-container {
                          h4 {
                            margin: 0px;
                          }
                          .comment-content {
                            color: rgb(87, 87, 87);
                          }
                          .comment-likes {
                            position: absolute;
                            top: -12px;
                            height: 21px;
                            right: -10px;
                            background: #8dc73f;
                            color: #fff;
                            width: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 4px;
                            font-size: 9px;
                            img {
                              margin-right: 4px;
                              margin-top: -1px;
                            }
                          }
                        }
                      }
                      .comment-footer {
                        padding-left: 50px;
                      }
                    }
                  }
                  .comments-reply-options {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 40px;
                    margin-top: 10px;
                    .btn-show-more {
                      background: transparent;
                      color: #817f7f;
                      border: 1px dashed #ddd;
                    }
                  }
                }
              }
            }
            .post-comments-options {
              padding-bottom: 12px;
              padding-left: 5px;
              .btn-show-more {
                background: transparent;
                border: none;
                color: #8dc73f;
              }
            }
          }
        }
        .post-footer {
          height: 57px;
          .post-comment-form {
            background: #efefef;
            display: flex;
            align-items: center;
            padding-left: 14px;
            .profile-widget {
              margin-right: 10px;
              .profile-image {
                width: 30px;
                height: 30px;
                object-fit: cover;
                border-radius: 5px;
              }
            }
            .comments-form {
              background: #efefef;
              display: flex;
              align-items: center;
              width: 100%;
              .comments-input {
                width: 100%;
                border: none;
                outline: none;
                background: transparent;
              }
              .btn-attachment {
                border: none;
                background: transparent;
                img {
                  width: 20px;
                }
              }
              .btn-send {
                border: none;
                background: #dbd9d9;
                height: 57px;
                width: 65px;
                img {
                  width: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
  // Contact form
  .overlay {
    .contact-form {
      width: 500px;
      background: #fff;
      border: 1px solid #ddd;
      display: block;
      padding: 20px;
      border-radius: 5px;
      margin: 0 auto;
      @media screen and (max-width: 500px) {
        width: 100%;
        border: none;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        padding-top: 80px;
      }
      h2 {
        font-size: 27px;
      }
      .form-input {
        width: 100%;
        .contant-input {
          width: 100%;
          height: 160px;
          margin-bottom: 20px;
          padding: 10px;
          resize: none;
          border-radius: 5px;
          background: #eeeeee;
          border: none;
        }
      }
      .form-options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 500px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .btns {
          width: 100%;
          display: flex;
          justify-content: space-between;
          @media screen and (max-width: 500px) {
            width: 100%;
            margin-top: 30px;
            justify-content: space-between;
          }
          .btn-send {
          }
        }
      }
    }
  }
}
</style> 