var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cagura-wrapper"},[_c('ClientHeader',{attrs:{"type":"shopping"}}),_c('div',{staticClass:"cagura-container"},[_c('side-bar',{attrs:{"type":"shopping"}}),_c('div',{staticClass:"cagura-body",class:{ nosidebar: !_vm.$store.state.sidebar }},[_c('div',{staticClass:"tags-widget"},[_c('cagura-tags')],1),_c('div',{staticClass:"cagura-products-widget has-tags"},[_c('vue-scroll',{on:{"handle-scroll":_vm.handleScroll}},[_c('div',{staticClass:"under-scoll-container-widget"},[_c('div',{staticClass:"fit-container"},[_c('div',{staticClass:"products-widget"},[_c('div',{staticClass:"products-widget-header"},[_c('h3',[_vm._v("SPONSORED PRODUCTS")]),_c('div',{staticClass:"call-actions"})]),(_vm.products_sponsored_data.length > 0)?_c('div',{staticClass:"products-widget-body"},_vm._l((_vm.products_sponsored_data.slice(0, 100)),function(item,i){return _c('div',{key:i,staticClass:"product-item"},[(item.discount)?_c('div',{staticClass:"product-announcement success"},[_vm._v(" "+_vm._s(item.discount)+"% OFF ")]):_vm._e(),_c('div',{staticClass:"product-image",on:{"click":function($event){_vm.$router
                          .push({ path: _vm.$getProductLink(item) })
                          .catch(() => {})}}},[_c('img',{attrs:{"src":_vm.$store.state.ASSETS_BASE_URL +
                          '/assets/uploaded/' +
                          item.main_image}})]),_c('div',{staticClass:"product-content"},[_c('h4',[_vm._v(_vm._s(item.product_name))]),_c('p',[_vm._v(" FRW "+_vm._s(item.discount ? _vm.$formatMoney( item.price - (item.price * item.discount) / 100 ) : _vm.$formatMoney(item.price))+" ")]),(item.shop)?_c('div',{staticClass:"shop-viewer",on:{"click":function($event){_vm.$router.push({ path: _vm.$getShopLink(item.shop) })}}},[_c('img',{staticClass:"shop-image",attrs:{"src":_vm.$store.state.ASSETS_BASE_URL +
                            '/assets/uploaded/' +
                            item.shop.shop_logo}}),_c('div',{staticClass:"shop-content"},[_c('label',{staticClass:"shop-name"},[_vm._v(_vm._s(item.shop.shop_name))]),_c('span',{staticClass:"shop-location"},[_vm._v(_vm._s(item.shop.location_name))])])]):_vm._e()])])}),0):_vm._e(),(_vm.sponsored_loading)?_c('div',{staticClass:"product-loading-widget"},_vm._l((4),function(item,index){return _c('div',{key:index,staticClass:"product-loading-item pulse"},[_c('div',{staticClass:"product-loading-image"}),_c('div',{staticClass:"product-loading-content"},[_c('div',{staticClass:"product-loading-title"}),_c('div',{staticClass:"product-loading-price"})]),_c('div',{staticClass:"product-loading-shop"})])}),0):_vm._e()]),(_vm.products_data)?_c('div',{staticClass:"categorized-product-widget"},[_vm._l((_vm.products_data.data),function(category,index){return [(category.type == 'list')?_c('div',{key:index,staticClass:"products-widget"},[_c('div',{staticClass:"products-widget-header"},[_c('div',{staticClass:"products-labels"},[_c('img',{staticClass:"category-image",attrs:{"src":_vm.$store.state.ASSETS_BASE_URL +
                            '/assets/uploaded/' +
                            category.image}}),_c('h3',{staticClass:"category-name"},[_vm._v(_vm._s(category.name))])]),_c('div',{staticClass:"call-actions"},[_c('button',{staticClass:"view-all-btn",on:{"click":function($event){_vm.$router
                              .push({
                                name: 'category',
                                params: { id: category.id },
                              })
                              .catch(() => {})}}},[_vm._v(" VIEW ALL ")])])]),_c('div',{staticClass:"products-widget-body"},_vm._l((category.products),function(item,i){return _c('div',{key:i,staticClass:"product-item"},[(item.discount)?_c('div',{staticClass:"product-announcement success"},[_vm._v(" "+_vm._s(item.discount)+"% OFF ")]):_vm._e(),_c('div',{staticClass:"product-image",on:{"click":function($event){_vm.$router
                              .push({ path: _vm.$getProductLink(item) })
                              .catch(() => {})}}},[_c('img',{attrs:{"src":_vm.$store.state.ASSETS_BASE_URL +
                              '/assets/uploaded/' +
                              item.main_image}})]),_c('div',{staticClass:"product-content"},[_c('h4',[_vm._v(_vm._s(item.product_name))]),_c('p',[_vm._v(" FRW "+_vm._s(item.discount ? _vm.$formatMoney( item.price - (item.price * item.discount) / 100 ) : _vm.$formatMoney(item.price))+" ")]),(item.shop)?_c('div',{staticClass:"shop-viewer",on:{"click":function($event){_vm.$router.push({ path: _vm.$getShopLink(item.shop) })}}},[_c('img',{staticClass:"shop-image",attrs:{"src":_vm.$store.state.ASSETS_BASE_URL +
                                '/assets/uploaded/' +
                                item.shop.shop_logo}}),_c('div',{staticClass:"shop-content"},[_c('label',{staticClass:"shop-name"},[_vm._v(_vm._s(item.shop.shop_name))]),_c('span',{staticClass:"shop-location"},[_vm._v(_vm._s(item.shop.location_name))])])]):_vm._e()])])}),0)]):_vm._e(),(category.type == 'group')?_c('div',{key:index,staticClass:"products-group-widget"},_vm._l((category.categories),function(group,i){return _c('div',{key:i,staticClass:"products-group"},[_c('div',{staticClass:"products-group-header"},[_c('div',{staticClass:"products-group-name"},[_c('img',{staticClass:"group-image",attrs:{"src":_vm.$store.state.ASSETS_BASE_URL +
                              '/assets/uploaded/' +
                              group.image}}),_c('h3',{staticClass:"group-name"},[_vm._v(_vm._s(group.name))])]),_c('div',{staticClass:"products-group-option"},[_c('button',{staticClass:"view-all-btn",on:{"click":function($event){_vm.$router
                                .push({
                                  name: 'category',
                                  params: { id: group.id },
                                })
                                .catch(() => {})}}},[_vm._v(" VIEW ALL ")])])]),_c('div',{staticClass:"products-group-body"},_vm._l((group.products),function(item,i){return _c('div',{key:i,staticClass:"product-item"},[(false)?_c('div',{staticClass:"product-announcement danger"},[_vm._v(" NEW ")]):_vm._e(),_c('div',{staticClass:"product-image",on:{"click":function($event){_vm.$router
                                .push({ path: _vm.$getProductLink(item) })
                                .catch(() => {})}}},[_c('img',{attrs:{"src":_vm.$store.state.ASSETS_BASE_URL +
                                '/assets/uploaded/' +
                                item.main_image}})]),_c('div',{staticClass:"product-content"},[_c('h4',[_vm._v(_vm._s(item.product_name))]),_c('p',[_vm._v(" FRW "+_vm._s(item.discount ? _vm.$formatMoney( item.price - (item.price * item.discount) / 100 ) : _vm.$formatMoney(item.price))+" ")]),(item.shop)?_c('div',{staticClass:"shop-viewer",on:{"click":function($event){_vm.$router.push({
                                  path: _vm.$getShopLink(item.shop),
                                })}}},[_c('img',{staticClass:"shop-image",attrs:{"src":_vm.$store.state.ASSETS_BASE_URL +
                                  '/assets/uploaded/' +
                                  item.shop.shop_logo}}),_c('div',{staticClass:"shop-content"},[_c('label',{staticClass:"shop-name"},[_vm._v(_vm._s(item.shop.shop_name))]),_c('span',{staticClass:"shop-location"},[_vm._v(_vm._s(item.shop.location_name))])])]):_vm._e()])])}),0)])}),0):_vm._e()]})],2):_vm._e(),(_vm.home_loading)?_c('div',{staticClass:"products-widget"},[_c('div',{staticClass:"product-loading-widget"},_vm._l((8),function(item,index){return _c('div',{key:index,staticClass:"product-loading-item pulse"},[_c('div',{staticClass:"product-loading-image"}),_c('div',{staticClass:"product-loading-content"},[_c('div',{staticClass:"product-loading-title"}),_c('div',{staticClass:"product-loading-price"})]),_c('div',{staticClass:"product-loading-shop"})])}),0)]):_vm._e()])])])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }