<template>
  <div class="cagura-wrapper">
    <!-- Navbar -->
    <client-header />
    <!-- Navbar -->
    <div class="cagura-container">
      <!-- Side bar -->
      <transition
        name="bounce"
        enter-active-class="bounceInLeft"
        leave-active-class="bounceOutLeft"
      >
        <side-bar v-if="$store.state.sidebar" />
      </transition>
      <!-- End of sidebar -->
      <!-- Content - body -->
      <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
        <vue-scroll>
          <div class="cagura-help-widget">
            <div class="help-widget">
              <div class="help-widget-header">
                <div class="call-actions">
                  <button
                    class="btn-back"
                    @click="$router.push({ name: 'home' }).catch(() => {})"
                  >
                    <img src="@/assets/images/icons/arrow-left.svg" /> CONTACT
                    US
                  </button>
                </div>
              </div>
              <!-- BODY -->
              <div class="content-widget">
                <p>
                  If You have any feedback or problem don't hasitate to contact
                  us, use form below to send a message to us or Read more
                  <a
                    href="/help/about"
                    @click.prevent="
                      $router.push({ name: 'about' }).catch(() => {})
                    "
                    >about us</a
                  >
                </p>
                <div class="form-widget">
                  <input
                    type="email"
                    v-model="form.email"
                    placeholder="Your email"
                    class="email-input"
                  />
                  <textarea
                    class="message-input"
                    v-model="form.message"
                    placeholder="Type your message here..."
                  ></textarea>
                  <c-button
                    class="btn-success btn-send"
                    :loading="is_loading"
                    @click="sendMessage()"
                    >SEND</c-button
                  >
                </div>
                <h4 class="title">For more information</h4>
                <div class="contact-info">
                  <p><b>Email:</b>support@wecodefy.com, info@wecodefy.com</p>
                  <p><b>Location:</b> Rwanda,Kigali,Kicukiro, Masaka</p>
                </div>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
      <!-- End of content -->
    </div>
  </div>
</template>

<script>
import ClientHeader from "../../components/partials/header.vue";
import SideBar from "../../components/partials/sidebar.vue";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
export default {
  name: "about",
  components: {
    ClientHeader,
    SideBar,
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {
    return {
      form: {
        email: "",
        message: "",
      },
      is_loading: false,
    };
  },
  methods: {
    sendMessage() {
      let vm = this;
      const rules = {
        email: {
          run: (value) => {
            if (value.length == 0) {
              return "Email can not be empty";
            } else {
              const re =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (re.test(String(value).toLowerCase())) {
                return false;
              }
            }
            return "Email is invalid";
          },
        },
        message: {
          run: (value) => {
            if (value.length >= 10) {
              return false;
            }
            return "Your message is too small";
          },
        },
      };
      if (vm.$isFieldsValidated(vm.form, rules)) {
        vm.is_loading = true;

        vm.$store
          .dispatch("SEND_CONTACT_MESSAGE", vm.form)
          .then((response) => {
            vm.is_loading = false;
            if (response.data.status == "ok") {
              vm.$notify({
                group: "status",
                type: "success",
                title: "Important message",
                text: response.data.message,
              });
              vm.form = {
                email: "",
                message: "",
              };
            }
          })
          .catch(({ response }) => {
            vm.is_loading = false;
            console.error("SEND_CONTACT_MESSAGE", { response });
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
    .cagura-body {
      .cagura-help-widget {
        height: 100%;
        padding-bottom: 50px;
        .help-widget {
          padding: 0px 0px;
          .help-widget-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0px;
            .call-actions {
              .btn-back {
                height: 36px;
                border: none;
                border-radius: 5px;
                background: transparent;
                outline: none;
                display: flex;
                align-items: center;
                img {
                  margin-right: 15px;
                }
              }
            }
          }
          .content-widget {
            padding: 15px;
            display: flex;
            flex-direction: column;
            .form-widget {
              display: flex;
              flex-direction: column;
              width: 500px;
              margin-bottom: 30px;
              @media screen and (max-width: 500px) {
                width: 100%;
              }
              .email-input {
                height: 45px;
                margin-bottom: 15px;
                padding: 0px 11px;
                border: 1px solid #b8b8b8;
                border-radius: 5px;
              }
              .message-input {
                margin-bottom: 20px;
                height: 200px;
                border: 1px solid #bbb;
                background: #fff;
                resize: none;
                border-radius: 5px;
                padding: 11px;
              }
              .btn-send {
                width: 70px;
              }
            }
            .title {
              color: #8cc73f;
              font-weight: 800;
              border-bottom: 4px solid #8cc73f;
              display: initial;
              padding-bottom: 4px;
              margin-bottom: 6px;
              width: 300px;
            }
          }
        }
      }
    }
  }
}
</style>

