import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import EasySlider from 'vue-easy-slider'
import vuescroll from "vuescroll/dist/vuescroll-native";
import "vuescroll/dist/vuescroll.css";
import "./scss/main.scss";
import {
    Select,
    Progress,
    Alert,
    Input,
    Icon,
    DatePicker,
    Button,
    Tabs,
    Empty,
    Spin,
    TimePicker,
    Checkbox,
    Rate,
    Popconfirm,
    Tooltip,
    Slider
} from 'ant-design-vue';
import "./scss/ant.scss";
require('vue2-animate/dist/vue2-animate.min.css')
import * as VueGoogleMaps from 'vue2-google-maps';
import AppMix from './mixin/appMix.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Notifications from 'vue-notification';
import VueLocalStorage from 'vue-localstorage';
import VueClosable from 'vue-closable';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CButton from './components/partials/CButton.vue';
import DropdownMenu from "@innologica/vue-dropdown-menu";
import VueConfirmDialog from 'vue-confirm-dialog'
import VueClipboard from 'vue-clipboard2'
import AskAuth from "./components/modals/AskAuth.vue";
import Uploader from './components/modals/upload-helper';
import GoogleMap from './components/modals/map-helper';
import Help from "./components/partials/Help.vue";
import VuePlayerPlugin, { Player } from 'vue-youtube-iframe-api'
import CaguraApps from "./components/partials/CaguraApps.vue"
import progressBar from "./components/partials/progressBar.vue"
import VideoPlayer from "./components/partials/VideoPlayer.vue";
import TagsInput from "./components/partials/TagsInput.vue";
import VueGtag from "vue-gtag";
import Ads from 'vue-google-adsense'


Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

Vue.use(VueGtag, {
    config: { id: "UA-98328458-3" },
    disableScriptLoad: false,
    pageTrackerTemplate(to) {
        return {
            page_title: to.name,
            page_path: to.path
        }
    }
}, router);

Vue.use(VuePlayerPlugin);
Vue.use(GoogleMap);
Vue.use(Uploader);
Vue.use(Progress);
Vue.use(Alert);
Vue.use(Input);
Vue.use(Icon);
Vue.use(DatePicker);
Vue.use(Button);
Vue.use(Tabs);
Vue.use(Spin);
Vue.use(Empty);
Vue.use(TimePicker);
Vue.use(Checkbox);
Vue.use(Rate);
Vue.use(Popconfirm);
Vue.use(Tooltip);
Vue.use(Slider);
Vue.use(Select);
Vue.use(VueConfirmDialog);
Vue.use(Loading);
Vue.use(VueClosable);
Vue.use(VueLocalStorage);
Vue.use(Notifications);
Vue.use(VueAxios, axios);
Vue.use(VueClipboard);
Vue.mixin(AppMix);
Vue.use(VueGoogleMaps, {
    load: {
        key: store.state.GOOGLE_MAP_KEY,
        libraries: 'places',
    }
});
Vue.use(vuescroll, {
    ops: {
        rail: {
            background: '#e2e2e2',
            opacity: 1,
            size: window.innerWidth < 1040 ? '4px' : '11px',
            specifyBorderRadius: '0px',
            gutterOfEnds: null,
            gutterOfSide: '0px',
            keepShow: false,
        },
        bar: {
            showDelay: 200,
            onlyShowBarOnScroll: true,
            keepShow: true,
            background: '#8dc73f',
            opacity: 1,
            specifyBorderRadius: false,
            minSize: 0,
            size: window.innerWidth < 1040 ? '4px' : '11px',
            disable: false
        }
    }
});
Vue.use(EasySlider)



Vue.component('AskAuth', AskAuth);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.component('CButton', CButton);
Vue.component('Loading', Loading);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('Help', Help);
Vue.component('YoutubePlayer', Player);
Vue.component('CaguraApps', CaguraApps);
Vue.component('progressBar', progressBar);
Vue.component('VideoPlayer', VideoPlayer);
Vue.component('TagsInput', TagsInput);




Vue.config.productionTip = false

// Setting for sidebar on load app
if (window.innerWidth > 900) {
    if (Vue.localStorage.get("is_sidebar")) {
        store.state.sidebar =
            Vue.localStorage.get("is_sidebar") === "true" ? true : false;
    } else {
        store.state.sidebar = true;
    }
} else {
    store.state.sidebar = false;
}


// Validate every axios request
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    try {
        if (error.response.status === 401) {
            store.commit("SET_TOKEN", null);
            Vue.localStorage.remove("user_data");
        }
    } catch (e) { }
    return Promise.reject(error);
});


// Setting for auth on load app
if (Vue.localStorage.get("user_data")) {
    let user_data = JSON.parse(Vue.localStorage.get("user_data"));
    store.commit("SET_TOKEN", user_data.access_token);
    store.dispatch("VERIFY_TOKEN", {
        token: user_data.access_token
    }).then(response => {
        if (response.data.status == 'ok') {
            store.commit("SET_TOKEN", response.data.access_token);
            let user = {
                access_token: response.data.access_token,
                user: response.data.user,
            };
            Vue.localStorage.set("user_data", JSON.stringify(user));
        } else if (response.data.status == 'remove') {
            Vue.localStorage.remove("user_data");
            window.location.reload();
        }
    });
}
// Router validation
router.beforeEach((to, from, next) => {
    if (window.innerWidth < 1040) {
        store.state.sidebar = false;
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (Vue.localStorage.get("user_data")) {
            let user_data = JSON.parse(Vue.localStorage.get("user_data"));
            store.commit("SET_TOKEN", user_data.access_token);
            store.dispatch("VERIFY_TOKEN", {
                token: user_data.access_token
            }).then(response => {
                if (response.data.status == 'ok') {
                    store.commit("SET_TOKEN", response.data.access_token);
                    let user = {
                        access_token: response.data.access_token,
                        user: response.data.user,
                    };
                    Vue.localStorage.set("user_data", JSON.stringify(user));
                } else if (response.data.status == 'remove') {
                    Vue.notify({
                        group: "status",
                        type: "warn",
                        title: "Error message",
                        text: 'Your session expired, Please login again',
                    });
                    store.commit("SET_TOKEN", null);
                    Vue.localStorage.remove("user_data");
                }
            });
        } else {
            Vue.notify({
                group: "status",
                type: "warn",
                title: "Error message",
                text: 'Your session expired, Please login again',
            });
            store.commit("SET_TOKEN", null);
            Vue.localStorage.remove("user_data");
        }
    } else {
        next()
        // make sure to always call next()!
    }

});


if (document.querySelector('.l-progress-bar')) {
    document.querySelector('.l-progress-bar').style.width = "100%";
    document.querySelector('#loader-overlay').style.display = "none";
}
const el = document.querySelector('#app');
if (el) {
    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app')
    if (process.env.NODE_ENV === "development") {
        console.log('Vue app is running in development mode');
    }
}