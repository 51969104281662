<template>
  <div class="notification-item-widget">
    <div class="notification-item">
      <div class="notification-item-header">
        <span v-if="item.status == 'new'" class="notification-dot"></span>
        <div class="notification-icon">
          <img src="@/assets/images/icons/notification-post-comment.svg" />
        </div>
        <div class="notification-title">
          <b v-if="item.user_type == 'guest'">{{ item.user.names }}</b>
          <b v-else>{{ item.user.first_name }} {{ item.user.last_name }}</b>
          sent message on your <b>Post</b>
        </div>
        <div class="notification-date">
          {{ moment(item.created_at).format("dddd, MMM Do YYYY, h:mm A") }}
        </div>
      </div>

      <div class="notification-item-body">
        <div class="notification-content">
          <div class="product-images" v-if="item.post">
            <img
              v-for="(image, index) in item.post.images"
              :key="index"
              :src="$store.state.ASSETS_BASE_URL + '/feeds/' + image"
            />
          </div>
          <div v-else class="product-images">
            <i>Post Not Available</i>
          </div>
          <p class="notification_desc">
            {{ $cutText(item.notification_message, 170) }}
          </p>
        </div>
        <div class="profile-image">
          <img
            v-if="item.user.profile_image"
            :src="
              $store.state.ASSETS_BASE_URL +
              '/assets/uploaded/' +
              item.user.profile_image
            "
          />
          <img v-else src="@/assets/images/icons/empty_profile.svg" />
        </div>
      </div>

      <div class="notification-item-footer">
        <button
          class="btn-view"
          v-if="item.post"
          @click="
            $router.push({
              name: 'post',
              params: { type: 'view', id: item.post.id },
            })
          "
        >
          View Post
        </button>
        <c-button
          v-if="item.status == 'new'"
          :loading="mark_notification_loading"
          @click="markNotification(item, 'seen')"
          class="btn-mark"
          >Mark as read</c-button
        >
        <c-button
          v-else
          :loading="mark_notification_loading"
          @click="markNotification(item, 'new')"
          class="btn-mark"
          >Mark as unread</c-button
        >
        <c-button @click="removeNotification(item)" class="btn-remove"
          >Remove</c-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "notification-item",
  components: {},
  props: {
    item: {},
  },
  data() {
    return {
      remove_notification_loading: false,
      mark_notification_loading: false,
    };
  },
  mounted() {},
  methods: {
    markNotification(item, type) {
      let vm = this;
      vm.mark_notification_loading = true;
      vm.$store
        .dispatch("NOTIFICATION_MARK_ITEM", {
          id: item.id,
          type: type,
        })
        .then((response) => {
          item.status = type;
          vm.mark_notification_loading = false;
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          vm.$notify({
            group: "status",
            type: "warn",
            title: "Important message",
            text: response.data.message,
          });
          vm.mark_notification_loading = false;
        });
    },

    removeNotification(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure? This will delete your notification`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.remove_notification_loading = true;
            vm.$store
              .dispatch("NOTIFICATION_REMOVE_ITEM", {
                id: item.id,
              })
              .then((response) => {
                vm.remove_notification_loading = false;
                vm.$emit("remove", null);
                vm.$notify({
                  group: "status",
                  type: "success",
                  title: "Important message",
                  text: response.data.message,
                });
              })
              .catch(({ response }) => {
                vm.$notify({
                  group: "status",
                  type: "warn",
                  title: "Important message",
                  text: response.data.message,
                });
                vm.remove_notification_loading = false;
              });
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-item-widget {
  width: 100%;
  .notification-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #ddd;
    position: relative;
    cursor: pointer;
    background: #fff;
    margin-bottom: 20px;
    border-left: 5px solid rgb(224, 45, 0);
    &:hover {
      background: #f9f9f9;
    }
    .notification-item-header {
      display: flex;
      border-bottom: 1px solid #ddd;
      height: 40px;
      align-items: center;
      justify-content: space-between;
      padding: 0px 15px;
      position: relative;
      @media screen and (max-width: 800px) {
        flex-wrap: wrap;
        height: 60px;
      }
      .notification-dot {
        position: absolute;
        height: 8px;
        width: 8px;
        animation: babo 500ms infinite;
        background: #ff3e3e;
        top: 15px;
        left: 2px;
        border-radius: 50%;
      }
      .notification-icon {
        margin-right: 10px;
        img {
          width: 20px;
        }
      }
      .notification-title {
        width: 66%;
        font-weight: 500;
        @media screen and (max-width: 800px) {
          width: calc(100% - 40px);
        }
      }
      .notification-date {
        width: 35%;
        text-align: right;
        @media screen and (max-width: 800px) {
          width: 100%;
          text-align: left;
          font-size: 12px;
          color: rgb(87, 87, 87);
        }
      }
    }
    .notification-item-body {
      display: flex;
      padding: 6px 15px;
      .notification-content {
        width: calc(100% - 70px);
        .date {
          font-size: 10.5352px;
          color: #4f4f4f;
          margin-bottom: 2px;
        }
        .content {
          color: #000;
          margin-bottom: 4px;
          font-size: 14px;
          .link {
            color: #000;
            font-weight: 800;
          }
        }
        .product-images {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px;
          img {
            width: 40px;
            height: 40px;
            border-radius: 3px;
            border: 1px solid #ddd;
            margin-right: 10px;
            object-fit: cover;
          }
        }
        .notification_desc {
          font-size: 13px;
        }
      }
      .profile-image {
        width: 70px;
        position: relative;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 2px solid #ddd;
        }
      }
    }
    .notification-special-info {
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      .email {
        height: 35px;
        display: flex;
        align-items: center;
        padding: 0px 15px;
      }
      .phone {
        height: 35px;
        display: flex;
        align-items: center;
        padding: 0px 15px;
      }
      .price {
        height: 35px;
        display: flex;
        align-items: center;
        padding: 0px 15px;
        border-top: 1px solid #ddd;
        color: #8dc73f;
      }
    }
    .notification-item-footer {
      display: flex;
      border-top: 1px solid #ddd;
      height: 40px;
      .btn-view {
        border: none;
        width: 50%;
        border-right: 1px solid #ddd;
        height: 100%;
        &:hover {
          background: #f9f9f9;
        }
      }
      .btn-mark {
        border: none;
        width: 50%;
        border-right: 1px solid #ddd;
        height: 100%;
        &:hover {
          background: #f9f9f9;
        }
      }
      .btn-remove {
        border: none;
        width: 50%;
        &:hover {
          background: #f9f9f9;
        }
      }
    }
  }
}
</style>

