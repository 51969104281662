<template>
  <div class="cagura-wrapper">
    <!-- Navbar -->
    <ClientHeader type="shopping"></ClientHeader>
    <!-- Navbar -->
    <div class="cagura-container">
      <!-- Side bar -->
      <side-bar />
      <!-- End of sidebar -->
      <!-- Content - body -->
      <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
        <vue-scroll>
          <div class="cagura-shop-widget">
            <!-- Shop product -->
            <div class="shop-product">
              <div class="shop-product-header">
                <div class="title">
                  <h3>MY PRODUCTS</h3>
                </div>
                <div class="shop-options">
                  <div class="search-widget">
                    <div class="search-input">
                      <input
                        type="text"
                        placeholder="Search in this products..."
                        v-model="search_product_input"
                      />
                      <img src="@/assets/images/icons/search-grey.svg" alt />
                    </div>
                  </div>
                  <div class="btns-options">
                    <button class="btn-cagura" @click="addNewProduct()">
                      <img src="@/assets/images/icons/plus.svg" />
                    </button>
                    <button
                      class="btn-cagura"
                      @click="show_filter_widget = !show_filter_widget"
                    >
                      <img src="@/assets/images/icons/filter.svg" />
                    </button>
                  </div>
                </div>
              </div>

              <!-- filter - wigdet -->
              <transition name="fadeanim">
                <div class="filter-widget" v-if="show_filter_widget">
                  <div class="filter-options">
                    <div class="category-widget">
                      <vue-scroll>
                        <h3>CATEGORY</h3>
                        <ul class="categories-items">
                          <li
                            v-for="(item, i) in my_products_categories"
                            :key="i"
                            :class="{ active: item.active }"
                            @click="searchProductBasedCategory(item)"
                          >
                            <span>{{ item.category_name }}</span>
                          </li>
                        </ul>
                      </vue-scroll>
                    </div>
                    <div class="price-range-widget">
                      <h3>PRICE RANGE</h3>
                      <a-slider
                        style="color: #000"
                        range
                        :default-value="[500, 500000]"
                        :min="500"
                        :max="1000000"
                        :marks="price_range_marks"
                        :tip-formatter="filterFormatter"
                        @change="onPriceFilterChange"
                      />
                    </div>
                  </div>
                  <div class="filter-option">
                    <div class="sort-widget">
                      <label>Sort by:</label>
                      <button
                        class="filter-btn"
                        @click="softByPrice()"
                        :class="{ down: filter.sort.price == 'down' }"
                      >
                        Price
                        <img src="@/assets/images/icons/allow.svg" />
                      </button>
                      <button
                        class="filter-btn"
                        v-if="false"
                        @click="softByPopularity()"
                        :class="{ down: filter.sort.popularity == 'down' }"
                      >
                        Popularity
                        <img src="@/assets/images/icons/allow.svg" />
                      </button>
                    </div>
                    <div class="filter-btn">
                      <button
                        @click="show_filter_widget = false"
                        class="btn-success"
                      >
                        CLOSE
                      </button>
                    </div>
                  </div>
                </div>
              </transition>
              <!-- Product list -->
              <div class="product-items" v-if="my_products_data.length">
                <div
                  class="product-item"
                  v-for="(item, i) in my_products_data"
                  :key="i"
                >
                  <div
                    class="product-image-widget"
                    @click="
                      $router
                        .push({ path: $getProductLink(item) })
                        .catch(() => {})
                    "
                  >
                    <img
                      :src="
                        $store.state.ASSETS_BASE_URL +
                        '/assets/uploaded/' +
                        item.main_image
                      "
                    />
                  </div>
                  <div class="product-content">
                    <div class="header">
                      <h2>{{ item.product_name }}</h2>
                      <dropdown-menu
                        v-model="item.is_menu_open"
                        :right="false"
                        :hover="false"
                        :interactive="false"
                      >
                        <button class="dropdown-toggle">
                          <img src="@/assets/images/icons/menu.svg" />
                        </button>
                        <div slot="dropdown" class="drop-down-menu">
                          <a
                            class="dropdown-item"
                            @click.prevent="editProduct(item)"
                          >
                            <img src="@/assets/images/icons/edit.svg" /> Edit
                            Product
                          </a>
                          <a
                            class="dropdown-item"
                            @click.prevent="getSharelableLink(item)"
                          >
                            <img src="@/assets/images/icons/share.svg" /> Get
                            shareble link
                          </a>
                          <a
                            class="dropdown-item"
                            @click.prevent="deleteProduct(item)"
                          >
                            <img src="@/assets/images/icons/trash.svg" /> Delete
                            product
                          </a>
                        </div>
                      </dropdown-menu>
                    </div>
                    <div
                      class="content"
                      @click="
                        $router
                          .push({ path: $getProductLink(item) })
                          .catch(() => {})
                      "
                    >
                      <p>{{ $cutText(item.description, 130) }}</p>
                      <div class="brand">
                        <label class="brand-label">Brand:</label>
                        <span>{{ item.brand }}</span>
                      </div>
                      <div class="product-options">
                        <div
                          class="price"
                          v-if="item.discount"
                          :class="{ strikethrough: item.discount }"
                        >
                          <strike>FRW {{ $formatMoney(item.price) }}</strike>
                        </div>

                        <div class="price" v-else>
                          FRW {{ $formatMoney(item.price) }}
                        </div>

                        <div class="price" v-if="item.discount">
                          {{
                            "FRW " +
                            $formatMoney(
                              item.price - (item.price * item.discount) / 100
                            )
                          }}
                        </div>
                        <div class="rate">
                          <a-rate
                            :value="item.rating"
                            :disabled="false"
                            style="font-size: 14px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="empty-widget"
                v-if="my_products_data.length == 0 && !is_loading"
              >
                <a-empty
                  :image="require('@/assets/images/icons/empty.svg')"
                  :image-style="{ height: '116px' }"
                >
                  <span slot="description"
                    >You don't have any product, Create one !!!</span
                  >
                </a-empty>
              </div>
              <div
                class="loader-widget"
                style="height: 300px"
                v-if="is_loading"
              >
                <div class="loading-panel">
                  <a-spin :spinning="true"></a-spin>
                  <span class="text">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
      <!-- End of content -->
    </div>

    <!-- Add product modal -->
    <transition name="fadeanim">
      <add-product
        v-if="show_add_product_modal"
        @onSubmit="onProductSubmit()"
        :value="selected_item"
        @onClose="show_add_product_modal = false"
      />
    </transition>

    <transition name="fadeanim">
      <div class="overlay" v-if="show_share_product_link_modal">
        <div class="product-link-form">
          <h2>Get sharelable link</h2>
          <div class="form-input">
            <input
              type="text"
              v-model="product_link"
              readonly
              class="link-input"
            />
            <button
              class="btn-copy"
              title="Copy"
              v-clipboard:copy="product_link"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <a-icon type="copy" />
            </button>
          </div>
          <div class="form-option">
            <button
              @click="show_share_product_link_modal = false"
              class="btn-success"
            >
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </transition>

    <ask-auth
      @onCancel="ask_for_account = false"
      v-if="ask_for_account"
      message="to access to your products"
    ></ask-auth>
  </div>
</template>

<script>
import ClientHeader from "../components/partials/header.vue";
import SideBar from "../components/partials/sidebar.vue";
import AddProduct from "../components/modals/AddProduct.vue";

export default {
  name: "my_products",
  components: {
    ClientHeader,
    SideBar,
    AddProduct,
  },
  data() {
    return {
      show_add_product_modal: false,
      show_contact_form: false,
      product_link: "",
      price_range_marks: {
        500: {
          style: {
            color: "#4b4a4a",
          },
          label: "FRW 500",
        },
        500000: {
          style: {
            color: "#4b4a4a",
          },
          label: "FRW 500,000",
        },
      },
      show_filter_widget: false,
      filter: {
        price: {
          min: 0,
          max: 500000,
        },
        categories: null,
        sort: {
          price: "up",
          popularity: "down",
        },
      },
      my_products_data: [],
      store_products_data: [],
      search_product_input: "",
      is_loading: false,
      my_products_categories: [],
      selected_item: null,
      show_share_product_link_modal: false,
      ask_for_account: false,
    };
  },
  created() {},
  mounted() {
    let vm = this;
    if (vm.$getLoggedUser()) {
      vm.getMyProducts();
      vm.getProductCategories();
    } else {
      vm.ask_for_account = true;
    }
  },
  methods: {
    onCopy: function (e) {
      let vm = this;
      vm.$notify({
        group: "status",
        type: "success",
        title: "Important message",
        text: "Link copied",
      });
    },
    onError: function (e) {
      vm.$notify({
        group: "status",
        type: "warn",
        title: "Important message",
        text: "Failed to copy",
      });
    },
    getSharelableLink(item, type) {
      let vm = this;
      let product_name = item.product_name.toLowerCase().replace(/\s/g, "_");
      let product_id = vm.$encodeProductId(item.id);
      vm.product_link =
        vm.$store.state.BASE_URL +
        "product/view/" +
        product_id +
        "/" +
        product_name;
      vm.show_share_product_link_modal = true;
    },
    deleteProduct(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure? This will delete all information related to this product, But shop from it will not be deleted`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmDeleteProduct(item.id);
          }
        },
      });
    },
    confirmDeleteProduct(product_id) {
      let vm = this;
      vm.$store.commit("START_LOADER");
      vm.$store
        .dispatch("PRODUCT_DELETE", { id: product_id })
        .then((response) => {
          vm.$store.commit("STOP_LOADER");
          if (response.data.status == "ok") {
            vm.getMyProducts();
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          } else {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.Message,
            });
          }
        })
        .catch(({ response }) => {
          vm.$store.commit("STOP_LOADER");
          console.error("PRODUCT_DELETE", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    addNewProduct() {
      let vm = this;
      vm.show_add_product_modal = true;
      vm.selected_item = null;
    },
    editProduct(item) {
      let vm = this;
      vm.selected_item = null;
      vm.selected_item = item;
      vm.show_add_product_modal = true;
    },
    onProductSubmit() {
      let vm = this;
      vm.show_add_product_modal = false;
      vm.getMyProducts();
    },
    filterProduct() {
      let vm = this;
      vm.my_products_data = vm.store_products_data
        .filter((item) => {
          let cat_product_ids = vm.filter.categories
            ? vm.filter.categories
            : [item.id];
          return (
            cat_product_ids.includes(item.id) &&
            parseInt(item.price) >= vm.filter.price.min &&
            parseInt(item.price) <= vm.filter.price.max
          );
        })
        .sort((a, b) => {
          return vm.filter.sort.price == "down"
            ? parseInt(a.price) - parseInt(b.price)
            : parseInt(b.price) - parseInt(a.price);
        });
    },
    onPriceFilterChange(value) {
      let vm = this;
      vm.filter.price.min = value[0];
      vm.filter.price.max = value[1];
      vm.filterProduct();
    },
    searchProductBasedCategory(item) {
      let vm = this;
      vm.my_products_categories.forEach((item) => {
        item.active = false;
      });
      item.active = !item.active;
      vm.filter.categories = item.product_ids.map((item) => item.product_id);
      vm.filterProduct();
    },
    softByPopularity() {
      let vm = this;
      vm.filter.sort.popularity == "up"
        ? (vm.filter.sort.popularity = "down")
        : (vm.filter.sort.popularity = "up");
      vm.filterProduct();
    },
    softByPrice() {
      let vm = this;
      vm.filter.sort.price == "up"
        ? (vm.filter.sort.price = "down")
        : (vm.filter.sort.price = "up");
      vm.filterProduct();
    },
    getProductCategories() {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("PRODUCT_GET_MY_CATEGORIES")
        .then((response) => {
          vm.is_loading = false;
          if (response.data.status == "ok") {
            vm.my_products_categories = response.data.return.map((item) => {
              vm.$store.state.eventBus.$set(item, "active", false);
              return item;
            });
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("PRODUCT_GET_MY_CATEGORIES", response);
        });
    },
    getMyProducts() {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("PRODUCT_GET_MY_PRODUCT")
        .then((response) => {
          vm.is_loading = false;
          if (response.data.status == "ok") {
            vm.my_products_data = response.data.return.map((item) => {
              vm.$store.state.eventBus.$set(item, "is_menu_open", false);
              return item;
            });
            vm.store_products_data = vm.my_products_data;
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("PRODUCT_GET_MY_PRODUCT", response);
        });
    },
    filterFormatter(val) {
      let vm = this;
      return `FRW ${vm.$formatMoney(val)}`;
    },
    searchProduct(query) {
      let vm = this;
      vm.my_products_data = vm.store_products_data.filter((item) => {
        if (
          item.product_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          item.description.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          item.brand.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          item.price <= query
        ) {
          return true;
        }
        return false;
      });
    },
  },
  watch: {
    search_product_input: function (query) {
      let vm = this;
      vm.searchProduct(query);
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
    .cagura-body {
      margin-left: 282px;
      padding-top: 0px;
      background: rgb(251, 251, 251);
      height: 100vh;
      display: flex;
      flex-direction: column;
      transition: 700ms ease-in-out;
      .cagura-shop-widget {
        height: 100%;
        .shop-product {
          padding-top: 26px !important;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 96px !important;
          .shop-product-header {
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 500px) {
              flex-direction: column;
            }
            .shop-options {
              display: flex;
              justify-content: space-between;
              align-items: center;
              @media screen and (max-width: 500px) {
                align-items: flex-start;
                position: relative;
              }
              .search-widget {
                padding-right: 20px;
                @media screen and (max-width: 500px) {
                  margin-bottom: 15px;
                  padding-right: 0px;
                }
                .search-input {
                  border: 1px solid #b8b8b8;
                  height: 40px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0px 13px;
                  border-radius: 5px;
                  input {
                    border: none;
                    background: transparent;
                    width: 240px;
                    @media screen and (max-width: 500px) {
                      width: 100%;
                    }
                  }
                  img {
                    width: 15px;
                  }
                }
              }
              .btns-options {
                display: flex;
                .btn-cagura {
                  width: 39.89px;
                  height: 39.89px;
                  background: #8dc73f;
                  border-radius: 6.93797px;
                  border: none;
                  margin-left: 15px;
                }
              }
            }
          }
          .filter-widget {
            background: #fff;
            padding: 25px;
            margin-bottom: 15px;
            margin-top: 15px;
            border-radius: 5px;
            @media screen and (max-width: 500px) {
              padding: 15px;
            }
            .filter-options {
              display: grid;
              grid-template-columns: 100%;
              grid-column-gap: 20px;

              @media screen and (max-width: 500px) {
                grid-template-columns: 100%;
              }
              .price-range-widget {
                margin-bottom: 15px;
                h3 {
                  color: #000;
                }
              }

              .category-widget {
                max-height: 200px;
                overflow-y: auto;
                .categories-items {
                  list-style: none;
                  padding-left: 0px;
                  display: flex;
                  flex-wrap: wrap;
                  li {
                    margin-right: 15px;
                    border: 1px solid #dddddd;
                    box-sizing: border-box;
                    border-radius: 40px;
                    font-size: 12px;
                    padding: 3px 16px;
                    margin-bottom: 11px;
                    cursor: pointer;
                    &:hover {
                      border: 1px solid rgb(125, 125, 125);
                    }
                    &.active {
                      color: #8dc73f;
                      border: 1px solid #8dc73f;
                      font-weight: 800;
                    }
                    span {
                      text-transform: uppercase;
                    }
                  }
                }
              }
            }
            .filter-option {
              display: flex;
              justify-content: space-between;
              align-items: center;
              @media screen and (max-width: 500px) {
                flex-direction: column;
                align-items: flex-start;
              }
              .sort-widget {
                display: flex;
                align-items: center;
                @media screen and (max-width: 500px) {
                  margin-bottom: 15px;
                }
                label {
                  padding-right: 15px;
                }
                .filter-btn {
                  border: none;
                  background: #fbfbfb;
                  padding: 2px 15px;
                  border-radius: 5px;
                  display: flex;
                  align-items: center;
                  height: 27px;
                  margin-right: 20px;
                  &.down {
                    img {
                      transform: rotateZ(182deg);
                      transition: 500ms ease-in-out;
                    }
                  }
                  img {
                    margin-left: 10px;
                    transition: 500ms ease-in-out;
                  }
                }
              }
            }
          }
          .product-items {
            display: grid;
            grid-template-columns: 49% 49%;
            grid-column-gap: 20px;
            padding-top: 20px;
            grid-row-gap: 20px;
            @media screen and (max-width: 500px) {
              grid-template-columns: 100%;
            }
            .product-item {
              border: 1px solid #ddd;
              background: #fff;
              border-radius: 5px;
              display: grid;
              grid-template-columns: 200px calc(99% - 200px);
              grid-column-gap: 10px;
              @media screen and (max-width: 1040px) {
                grid-template-columns: 119px calc(99% - 119px);
              }
              @media screen and (max-width: 500px) {
                grid-template-columns: 100%;
                border: 1px solid #ddd;
                padding: 12px;
              }
              cursor: pointer;
              .product-image-widget {
                img {
                  height: 200px;
                  width: 200px;
                  object-fit: cover;
                  border-bottom-left-radius: 5px;
                  border-top-left-radius: 5px;
                  @media screen and (max-width: 1040px) {
                    width: 100%;
                    object-fit: contain;
                    background: #000;
                  }
                  @media screen and (max-width: 500px) {
                    height: 150px;
                    width: 100%;
                    object-fit: contain;
                    background: #000;
                  }
                }
              }
              .product-content {
                padding-right: 15px;
                .header {
                  display: flex;
                  justify-content: space-between;
                  position: relative;
                  align-items: center;
                  margin-bottom: 8px;
                  margin-top: 11px;
                  h2 {
                    margin: 0px;
                    text-transform: uppercase;
                    font-size: 13px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                    -webkit-line-clamp: 2;
                    line-height: 17px;
                    max-height: 4.4rem;
                    @media screen and (max-width: 500px) {
                      font-size: 12px;
                    }
                  }
                }
                .shop-name {
                  color: rgba(42, 140, 229, 0.71);
                  font-weight: 800;
                  @media screen and (max-width: 500px) {
                    font-size: 14px;
                  }
                }
                .content {
                  p {
                    text-align: justify;
                    margin-bottom: 1px;
                    @media screen and (max-width: 500px) {
                      font-size: 14px;
                    }
                  }
                  .brand {
                    display: flex;
                    margin-top: 7px;
                    margin-bottom: 6px;
                    border-bottom: 1px solid #ddd;
                    border-top: 1px solid #ddd;
                    padding: 4px 0px;
                    .brand-label {
                      text-transform: uppercase;
                    }
                  }
                  .product-options {
                    display: flex;
                    justify-content: space-between;
                    @media screen and (max-width: 1040px) {
                      flex-direction: column;
                    }
                    @media screen and (max-width: 500px) {
                      flex-direction: column;
                    }
                    .price {
                      font-weight: 800;
                    }
                  }
                  .product-option {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;
                    @media screen and (max-width: 500px) {
                      flex-direction: column;
                    }
                    .btn-contact-seller {
                      border: none;
                      background: transparent;
                      display: flex;
                      align-items: center;
                      color: #939393;
                      padding: 0px;
                      @media screen and (max-width: 500px) {
                        padding: 0px;
                        padding-bottom: 15px;
                      }
                      img {
                        margin-right: 6px;
                      }
                    }
                    .btn-add-fovourites {
                      border: none;
                      background: transparent;
                      display: flex;
                      align-items: center;
                      color: #8cc73f;
                      @media screen and (max-width: 500px) {
                        padding: 0px;
                        padding-bottom: 15px;
                      }
                      img {
                        margin-right: 6px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

