<template>
  <div class="overlay" v-if="!show_add_shop_modal">
    <vue-scroll>
      <div class="modal-wrapper">
        <div class="modal-widget">
          <loading
            :height="30"
            color="#000"
            :opacity="1"
            :width="30"
            :active.sync="is_loading_new_data"
            :is-full-page="false"
            :can-cancel="false"
            background-color="rgb(255 255 255 / 45%)"
            class="loader"
          ></loading>
          <div class="modal-header">
            <div class="modal-title" v-if="!form.id">CREATE A PRODUCT</div>
            <div class="modal-title" v-else>UPDATE PRODUCT</div>
            <div class="modal-tab-header">
              <div class="modal-tab-title">{{ tab_title }}</div>
              <div class="modal-tab-buttons">
                <div
                  class="button-item"
                  v-for="({ tab }, i) in tabs"
                  :key="i"
                  :class="{ active: tab.finish }"
                ></div>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <vue-scroll>
              <transition
                name="bounce"
                enter-active-class="bounceInLeft"
                leave-active-class="bounceOutLeft"
                :duration="{ enter: 600, leave: 800 }"
              >
                <div class="tab modal-content" v-show="tabs[0].tab.active">
                  <div class="input-group">
                    <div class="input-widget">
                      <input
                        type="text"
                        class="bg-cagura-form-control"
                        name="product_name"
                        placeholder="Product name"
                        @focus="playSound('product_name')"
                        v-model="form.product_name"
                      />
                    </div>
                  </div>
                  <div class="input-group">
                    <div class="input-widget">
                      <input
                        type="text"
                        class="bg-cagura-form-control"
                        name="brand"
                        placeholder="Brand"
                        @focus="playSound('brand')"
                        v-model="form.brand"
                      />
                    </div>
                  </div>
                  <input
                    type="checkbox"
                    id="discount-input"
                    style="margin-right: 3px"
                    v-model="enable_discount"
                  /><label for="discount-input">Add Discount(Optional)</label>
                  <div class="input-group">
                    <div class="input-widget">
                      <input
                        type="text"
                        class="bg-cagura-form-control"
                        name="price"
                        placeholder="Price (Rwf)"
                        v-model="form.price"
                        @focus="playSound('price')"
                      />
                    </div>
                  </div>
                  <label v-if="enable_discount">Discount</label>
                  <div class="pair-input" v-if="enable_discount">
                    <div class="input-group discount">
                      <div class="input-widget">
                        <input
                          type="text"
                          class="bg-cagura-form-control"
                          name="discount"
                          placeholder="Discount (%)"
                          @focus="playSound('discount')"
                          v-model="form.discount"
                        />
                      </div>
                    </div>
                    <img
                      class="separator"
                      src="@/assets/images/icons/forward.svg"
                    />
                    <div class="input-group">
                      <div class="input-widget">
                        <input
                          type="text"
                          style="background: #fdfdfd"
                          readonly
                          class="bg-cagura-form-control"
                          name="discount-price"
                          placeholder="Discount Price (RWf)"
                          v-model="new_discount_price"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="input-group">
                    <div class="input-widget">
                      <select
                        v-model="form.category_id"
                        class="bg-cagura-form-control"
                      >
                        <option value="">Select Category</option>
                        <template v-for="(item, index) in all_categories">
                          <option :key="index" :value="item.id">
                            {{ item.name }}
                          </option>
                        </template>
                      </select>
                    </div>
                  </div>
                  <div class="input-group">
                    <label>Main Image</label>
                    <div class="input-widget">
                      <upload-trigger
                        :open="false"
                        :enableinput="false"
                        :required="false"
                        :multiple="false"
                        location="/assets/uploaded/"
                        :url="$store.state.ASSETS_BASE_URL"
                        :uploadurl="$store.state.BASE_URL + 'upload'"
                        name="main-image"
                        :value="form.main_image"
                        @click="playSound('single-upload')"
                        @onFileSelected="onProductMainImageSelected"
                      >
                        <div class="image-upload-widget">
                          Choose Image to upload
                        </div>
                      </upload-trigger>
                    </div>
                  </div>
                  <div class="input-group">
                    <label>Additional Images</label>
                    <div class="input-widget">
                      <upload-trigger
                        :open="false"
                        :enableinput="false"
                        :required="false"
                        :multiple="true"
                        location="/assets/uploaded/"
                        :url="$store.state.ASSETS_BASE_URL"
                        :uploadurl="$store.state.BASE_URL + 'upload'"
                        name="other-images"
                        :value="form.other_images"
                        @click="playSound('milt-upload')"
                        @onFileSelected="onOtherImagesSelected"
                      >
                        <div class="additional-image-upload-widget">
                          Upload Additional Images
                        </div>
                      </upload-trigger>
                    </div>
                  </div>
                </div>
              </transition>
              <transition
                name="bounce"
                enter-active-class="bounceInLeft"
                leave-active-class="bounceOutLeft"
                :duration="{ enter: 600, leave: 800 }"
              >
                <div class="tab modal-content" v-show="tabs[1].tab.active">
                  <div class="input-group">
                    <label
                      >Description [{{ form.description.length }} Char][Needed
                      50 - 2000]</label
                    >
                    <div class="input-widget">
                      <textarea
                        class="bg-cagura-textarea-form-control"
                        name="description"
                        placeholder="Product Description"
                        v-model="form.description"
                        @focus="playSound('description')"
                        @blur="playSound('create-properties')"
                      ></textarea>
                    </div>
                  </div>
                  <div class="input-group">
                    <label>Add Specifications (Optional)</label>
                    <div class="input-widget">
                      <div class="properties-table">
                        <div
                          class="property-row"
                          v-for="(item, i) in form.product_property"
                          :key="i"
                        >
                          <button
                            class="remove-property-btn"
                            @click="removeProperty(i)"
                          >
                            <img src="@/assets/images/icons/close.svg" alt />
                          </button>
                          <div class="property-label">
                            <input
                              type="text"
                              placeholder="Ex: Size"
                              v-model="item.label"
                            />
                          </div>
                          <div class="property-input">
                            <TagsInput v-model="item.items"></TagsInput>
                          </div>
                        </div>
                        <div class="properties-table-option">
                          <button
                            class="btn-add-property"
                            @click="addProperty(), playSound('on-add-specif')"
                          >
                            Create
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <transition
                name="bounce"
                enter-active-class="bounceInLeft"
                leave-active-class="bounceOutLeft"
                :duration="{ enter: 600, leave: 800 }"
              >
                <div class="tab modal-content" v-show="tabs[2].tab.active">
                  <div class="select-all-input" v-if="my_shops_data.length > 0">
                    <input
                      type="checkbox"
                      id="shop-select-all"
                      v-model="select_all_shop"
                    />
                    <label for="shop-select-all">Select All</label>
                  </div>
                  <div class="show-list" v-if="my_shops_data.length > 0">
                    <div
                      class="shop-item"
                      v-for="(item, i) in my_shops_data"
                      :key="i"
                      :for="'shop_' + i"
                    >
                      <input
                        type="checkbox"
                        v-model="item.active"
                        :id="'shop_' + i"
                      />
                      <label :for="'shop_' + i">{{ item.shop_name }}</label>
                    </div>
                  </div>

                  <div class="empty-widget" v-else>
                    <p>No shop found to select, create one</p>
                    <button class="btn-success" @click="addNewShop()">
                      ADD NEW SHOP
                    </button>
                  </div>
                </div>
              </transition>
            </vue-scroll>
          </div>
          <div class="modal-footer">
            <button
              class="btn-default"
              v-show="tab_current_index > 0"
              @click="prevTab(tab_current_index)"
            >
              BACK
            </button>
            <button
              class="btn-default"
              v-show="tabs[0].tab.active"
              @click="closeModal()"
            >
              CANCEL
            </button>
            <button
              class="btn-success"
              v-show="tab_current_index < 1"
              @click="nextTab(tab_current_index)"
            >
              NEXT
            </button>
            <button
              class="btn-success"
              v-show="
                tab_current_index < 2 && tab_current_index >= 1 && !shop_id
              "
              @click="nextTab(tab_current_index)"
            >
              NEXT
            </button>
            <c-button
              class="btn-success"
              @click="addProductAssignShops()"
              :loading="is_loading"
              v-show="tabs[1].tab.active && shop_id"
              ><span v-if="!form.id">ADD PRODUCT</span>
              <span v-else>UPDATE PRODUCT</span></c-button
            >
            <c-button
              class="btn-success"
              v-show="tabs[2].tab.active"
              @click="addProductAssignShops()"
              :loading="is_loading"
            >
              <span v-if="!form.id">ADD PRODUCT</span>
              <span v-else>UPDATE PRODUCT</span>
            </c-button>
          </div>
        </div>
      </div>
    </vue-scroll>
    <ask-auth
      @onCancel="onCloseAskAccount()"
      v-if="ask_for_account"
      message=" to add product"
    ></ask-auth>
  </div>
  <!-- Add shop modal -->
  <transition v-else name="fadeanim">
    <add-shop
      v-if="show_add_shop_modal"
      :value="null"
      @onSubmit="onSubmitShop()"
      @onClose="show_add_shop_modal = false"
    />
  </transition>
</template>
<script>
import AddShop from "./AddShop.vue";
export default {
  name: "add-product",
  components: {
    AddShop,
  },
  props: {
    shop_id: {
      type: Number,
      default: null,
    },
    value: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  computed: {
    searchProductPropertyValues() {
      let vm = this;
      return vm.all_product_values
        .filter((i) => {
          return (
            i.text.toLowerCase().indexOf(vm.property_value.toLowerCase()) !== -1
          );
        })
        .slice(0, 10);
    },
  },
  data() {
    return {
      ask_for_account: false,
      enable_discount: false,
      property_value: "",
      show_add_shop_modal: false,
      tab_title: "Product Basic Information",
      tab_current_index: 0,
      tabs: [
        {
          tab: {
            title: "Product Basic Information",
            active: true,
            finish: true,
          },
        },
        {
          tab: {
            title: "Product Description and Properties",
            active: false,
            finish: false,
          },
        },
        {
          tab: {
            title: "Select Shops",
            active: false,
            finish: false,
          },
        },
      ],
      is_loading: false,
      all_categories: [],
      category_name: "",
      categories: [],
      new_discount_price: "",
      form: {
        id: null,
        product_name: "",
        brand: "",
        price: "",
        discount: "",
        category_id: "",
        main_image: "",
        other_images: "",
        description: "",
        product_property: [],
        product_shop: [],
      },
      my_shops_data: [],
      select_all_shop: false,
      all_product_values: [],
      onload: false,
      is_loading_new_data: false,
      audio: {
        product_name: null,
        brand: null,
        price: null,
        discount: null,
        discount_click: null,
        categories: null,
        main_image: null,
        other_images: null,
        description: null,
        product_property: null,
        product_shop: null,
        no_shops: null,
      },
      player: null,
    };
  },
  mounted() {
    let vm = this;
    vm.onload = true;
    if (vm.$getLoggedUser()) {
      vm.getMyShop();
      vm.getAllCategories();
      vm.getAllproductsValue();
      if (vm.value) {
        vm.processItemForUpdate(vm.value);
      } else {
        vm.resetInput();
      }
      // Get Audio sound
      vm.audio.product_name = new Audio("/assets/audio/product-name.mp3");
      vm.audio.brand = new Audio("/assets/audio/bland.mp3");
      vm.audio.price = new Audio("/assets/audio/price.mp3");
      vm.audio.discount = new Audio("/assets/audio/discount.mp3");
      vm.audio.discount_click = new Audio("/assets/audio/discount-click.mp3");
      vm.audio.categories = new Audio("/assets/audio/category.mp3");
      vm.audio.main_image = new Audio("/assets/audio/upload-single-onopen.mp3");
      vm.audio.other_images = new Audio(
        "/assets/audio/upload-miltiple-onopen.mp3"
      );
      vm.audio.description = new Audio("/assets/audio/description.mp3");
      vm.audio.product_property = new Audio(
        "/assets/audio/add-specification-create.mp3"
      );
      vm.audio.add_spec_product_property = new Audio(
        "/assets/audio/add-specification.mp3"
      );
      vm.audio.product_shop = new Audio("/assets/audio/shop.mp3");
      vm.audio.no_shops = new Audio("/assets/audio/when-no-shop.mp3");
    } else {
      vm.ask_for_account = true;
    }
  },
  methods: {
    onCloseAskAccount() {
      let vm = this;
      vm.ask_for_account = false;
      vm.closeModal();
    },
    playSound(type) {
      let vm = this;
      if (type == "product_name") {
        if (vm.player) {
          vm.player.pause();
          vm.player.currentTime = 0;
        }
        vm.player = vm.audio.product_name;
        vm.player.play();
      } else if (type == "brand") {
        if (vm.player) {
          vm.player.pause();
          vm.player.currentTime = 0;
        }
        vm.player = vm.audio.brand;
        vm.player.play();
      } else if (type == "price") {
        if (vm.player) {
          vm.player.pause();
          vm.player.currentTime = 0;
        }
        vm.player = vm.audio.price;
        vm.player.play();
      } else if (type == "discount") {
        if (vm.player) {
          vm.player.pause();
          vm.player.currentTime = 0;
        }
        vm.player = vm.audio.discount;
        vm.player.play();
      } else if (type == "categories") {
        if (vm.player) {
          vm.player.pause();
          vm.player.currentTime = 0;
        }
        vm.player = vm.audio.categories;
        vm.player.play();
      } else if (type == "description") {
        if (vm.player) {
          vm.player.pause();
          vm.player.currentTime = 0;
        }
        vm.player = vm.audio.description;
        vm.player.play();
      } else if (type == "create-properties") {
        if (vm.player) {
          vm.player.pause();
          vm.player.currentTime = 0;
        }
        vm.player = vm.audio.product_property;
        vm.player.play();
      } else if (type == "on-add-specif") {
        if (vm.form.product_property.length == 1) {
          if (vm.player) {
            vm.player.pause();
            vm.player.currentTime = 0;
          }
          vm.player = vm.audio.add_spec_product_property;
          vm.player.play();
        }
      } else if (type == "select-shop") {
        if (vm.player) {
          vm.player.pause();
          vm.player.currentTime = 0;
        }
        vm.player = vm.audio.product_shop;
        vm.player.play();
      } else if (type == "no-select-shop") {
        if (vm.player) {
          vm.player.pause();
          vm.player.currentTime = 0;
        }
        vm.player = vm.audio.no_shops;
        vm.player.play();
      }
    },
    getAllproductsValue() {
      let vm = this;
      vm.$store
        .dispatch("PRODUCT_GET_ALL_PROPERTIES_VALUES")
        .then((response) => {
          if (response.data.status == "ok") {
            vm.all_product_values = response.data.return.map((item) => {
              return {
                text: item.value,
              };
            });
          }
        })
        .catch(({ response }) => {
          console.error("PRODUCT_GET_ALL_PROPERTIES_VALUES", { response });
        });
    },
    processItemForUpdate(data) {
      let vm = this;
      vm.is_loading_new_data = true;
      vm.$store
        .dispatch("PRODUCT_GET_SINGLE_ITEM_FOR_EDIT", { id: data.id })
        .then((response) => {
          vm.is_loading_new_data = false;
          if (response.data.status == "ok") {
            let item = response.data.return;
            vm.form.id = item.id;
            vm.form.product_name = item.product_name;
            vm.form.brand = item.brand;
            vm.form.price = item.price + "";
            vm.form.discount = item.discount;
            vm.form.category_id = item.category_id;
            vm.form.main_image = item.main_image;
            vm.form.other_images = item.other_images;
            vm.form.description = item.description;
            vm.form.product_shop = item.shops.map((item) => item.id);
            vm.form.product_property = item.properties.map((product_item) => {
              return {
                label: product_item.name,
                items: product_item.values.map((data) => {
                  return data.value;
                }),
              };
            });
          }
        })
        .catch(({ response }) => {
          vm.is_loading_new_data = false;
          console.error("PRODUCT_GET_SINGLE_ITEM_FOR_EDIT", { response });
        });
      vm.getMyShop();
    },
    resetInput() {
      let vm = this;
      vm.form = {
        id: null,
        product_name: "",
        brand: "",
        price: "",
        discount: "",
        category_id: "",
        main_image: "",
        other_images: "",
        description: "",
        product_property: [],
        product_shop: [],
      };
      if (vm.shop_id) {
        vm.form.product_shop.push(vm.shop_id);
      }
    },
    ProductPropertyValue(data) {
      let vm = this;
      vm.property_value = data;
    },
    addSelectShopOnForm() {
      let vm = this;
      vm.form.product_shop = [];
      vm.my_shops_data.forEach((item) => {
        if (item.active) {
          if (!vm.form.product_shop.includes(item.id)) {
            vm.form.product_shop.push(item.id);
          }
        }
      });
    },
    addProductAssignShops() {
      let vm = this;
      vm.addSelectShopOnForm();
      const rules = {
        product_name: {
          run: (value) => {
            if (value.length >= 4 && value.length <= 65) {
              return false;
            }
            return "Product name must have 4 - 65 charcters";
          },
        },
        price: {
          run: (value) => {
            if (value.length == 0) {
              return "Price can not be empty";
            } else {
              if (/^\d+$/.test(value)) {
                if (value.match(/\d/g).length <= 8) {
                  if (value >= 500) {
                    return false;
                  } else {
                    return "Price must be greater or equal FRW 500";
                  }
                } else {
                  return "Price must be under or equal to 8 digits";
                }
              } else {
                return "We don't accept text on price, use only number";
              }
            }
          },
        },
        discount: {
          run: (value) => {
            if (value) {
              if (/^\d+$/.test(value)) {
                if (value >= 0 && value <= 100) {
                  return false;
                } else {
                  return "Discount can not be greater to 100 or below 0";
                }
              } else {
                return "We don't accept text on discount, use only number";
              }
            } else {
              return false;
            }
          },
        },
        category_id: {
          run: (value) => {
            if (value) {
              return false;
            }
            return "You have to select category";
          },
        },
        main_image: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "Please add product image";
          },
        },
        other_images: {
          run: (value) => {
            if (value.length > 0) {
              if (JSON.parse(value).length >= 2) {
                return false;
              } else {
                return "Please add additional product images at least 2 images";
              }
            }
            return "Please add additional product images";
          },
        },
        description: {
          run: (value) => {
            if (value.length >= 50 && value.length <= 2000) {
              return false;
            }
            return "Product description  must have 50 - 2000 charcters";
          },
        },
        product_property: {
          run: (value) => {
            if (value.length > 0) {
              let is_error = false;
              value.forEach((item) => {
                if (item.items.length == 0) {
                  is_error = true;
                }
                if (item.label.length == 0) {
                  is_error = true;
                }
              });
              if (is_error) {
                return "Some of product additional property is empty";
              }
              return false;
            }
            return false;
          },
        },
        product_shop: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "Please select at least one shop";
          },
        },
      };
      if (vm.$isFieldsValidated(vm.form, rules)) {
        vm.is_loading = true;
        if (!vm.form.id) {
          // Add new product
          vm.$store
            .dispatch("PRODUCT_ADD_NEW", vm.form)
            .then((response) => {
              vm.is_loading = false;
              if (response.data.status == "ok") {
                vm.$localStorage.remove("product_data");
                vm.$notify({
                  group: "status",
                  type: "success",
                  title: "Important message",
                  text: response.data.message,
                });
                vm.$emit("onSubmit", null);
              } else {
                vm.$notify({
                  group: "status",
                  type: "warn",
                  title: "OOPS!!!",
                  text: response.data.Message,
                });
              }
            })
            .catch(({ response }) => {
              vm.is_loading = false;
              console.error("PRODUCT_ADD_NEW", { response });
              vm.$notify({
                group: "status",
                type: "warn",
                title: "OOPS!!!",
                text: response.data.message,
              });
            });
        } else {
          // Update product
          vm.$store
            .dispatch("PRODUCT_UPDATE", vm.form)
            .then((response) => {
              vm.is_loading = false;
              if (response.data.status == "ok") {
                vm.$localStorage.remove("product_data");
                vm.$notify({
                  group: "status",
                  type: "success",
                  title: "Important message",
                  text: response.data.message,
                });
                vm.$emit("onSubmit", null);
              } else {
                vm.$notify({
                  group: "status",
                  type: "warn",
                  title: "OOPS!!!",
                  text: response.data.Message,
                });
              }
            })
            .catch(({ response }) => {
              vm.is_loading = false;
              console.error("PRODUCT_UPDATE", { response });
              vm.$notify({
                group: "status",
                type: "warn",
                title: "OOPS!!!",
                text: response.data.message,
              });
            });
        }
      }
    },
    getAllCategories() {
      let vm = this;
      vm.$store
        .dispatch("PRODUCT_GET_MAIN_CATEGORIES")
        .then((response) => {
          if (response.data.status == "ok") {
            vm.all_categories = response.data.return;
          }
        })
        .catch(({ response }) => {
          console.error("PRODUCT_GET_MAIN_CATEGORIES", { response });
        });
    },
    onOtherImagesSelected(data) {
      let vm = this;
      vm.form.other_images = data;
    },
    onProductMainImageSelected(file_name) {
      let vm = this;
      vm.form.main_image = file_name;
    },
    onSubmitShop() {
      let vm = this;
      vm.show_add_shop_modal = false;
      vm.getMyShop();
    },
    addNewShop() {
      let vm = this;
      vm.show_add_shop_modal = true;
    },
    getMyShop() {
      let vm = this;
      vm.$store
        .dispatch("SHOP_GET_MY_SHOP")
        .then((response) => {
          if (response.data.status == "ok") {
            vm.my_shops_data = response.data.return.map((item) => {
              vm.$store.state.eventBus.$set(item, "active", false);
              return item;
            });
            vm.my_shops_data.map((shop_item) => {
              if (vm.form.product_shop.includes(shop_item.id)) {
                shop_item.active = true;
              } else {
                shop_item.active = false;
              }
              return shop_item;
            });
          }
        })
        .catch(({ response }) => {
          console.error("SHOP_GET_MY_SHOP", response);
        });
    },
    removeProperty(index) {
      let vm = this;
      vm.form.product_property.splice(index, 1);
    },
    addProperty() {
      let vm = this;
      vm.form.product_property.push({
        label: "",
        items: [],
      });
    },
    closeModal() {
      this.$emit("onClose", null);
    },
    nextTab(num) {
      let vm = this;
      const rules = {
        product_name: {
          run: (value) => {
            if (value.length >= 4 && value.length <= 65) {
              return false;
            }
            return "Product name must have 4 - 65 charcters";
          },
        },
        price: {
          run: (value) => {
            if (value.length == 0) {
              return "Price can not be empty";
            } else {
              if (/^\d+$/.test(value)) {
                if (value.match(/\d/g).length <= 8) {
                  if (value >= 500) {
                    return false;
                  } else {
                    return "Price must be greater or equal FRW 500";
                  }
                } else {
                  return "Price must be under or equal to 8 digits";
                }
              } else {
                return "We don't accept text on price, use only number";
              }
            }
          },
        },
        discount: {
          run: (value) => {
            if (value) {
              if (/^\d+$/.test(value)) {
                if (value >= 0 && value <= 100) {
                  return false;
                } else {
                  return "Discount can not be greater to 100 or below 0";
                }
              } else {
                return "We don't accept text on discount, use only number";
              }
            } else {
              return false;
            }
          },
        },
        category_id: {
          run: (value) => {
            if (value) {
              return false;
            }
            return "You have to select category";
          },
        },
        main_image: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "Please add product image";
          },
        },
        other_images: {
          run: (value) => {
            if (value.length > 0) {
              if (JSON.parse(value).length >= 2) {
                return false;
              } else {
                return "Please add additional product images at least 2 images";
              }
            }
            return "Please add additional product images";
          },
        },
        description: {
          run: (value) => {
            if (value.length >= 50 && value.length <= 2000) {
              return false;
            }
            return "Product description  must have 50 - 2000 charcters";
          },
        },
        product_property: {
          run: (value) => {
            if (value.length > 0) {
              let is_error = false;
              value.forEach((item) => {
                if (item.items.length == 0) {
                  is_error = true;
                }
                if (item.label.length == 0) {
                  is_error = true;
                }
              });
              if (is_error) {
                return "Some of product additional property is empty";
              }
              return false;
            }
            return false;
          },
        },
        product_shop: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "Please select at least one shop";
          },
        },
      };

      if (num == 0) {
        if (!vm.$isFieldsValidated(vm.form, rules, 7)) {
          return;
        }
      }
      if (num == 1) {
        if (!vm.$isFieldsValidated(vm.form, rules, 9)) {
          return;
        }
      }

      if (num < vm.tabs.length) {
        vm.tabs[num].tab.active = false;
        vm.tabs[num].tab.finish = true;
        if (vm.tabs[num + 1]) {
          vm.tabs[num + 1].tab.active = true;
          vm.tabs[num + 1].tab.finish = true;
          vm.tab_title = vm.tabs[num + 1].tab.title;
          vm.tab_current_index += 1;
        }
      }
      if (vm.tabs[2].tab.active) {
        if (vm.my_shops_data.length > 0) {
          vm.playSound("select-shop");
        } else {
          vm.playSound("no-select-shop");
        }
      }
    },
    prevTab(num) {
      let vm = this;
      if (num > -1) {
        vm.tabs[num].tab.active = false;
        vm.tabs[num].tab.finish = false;
        if (vm.tabs[num - 1]) {
          vm.tabs[num - 1].tab.active = true;
          vm.tabs[num - 1].tab.finish = true;
          vm.tab_title = vm.tabs[num - 1].tab.title;
          vm.tab_current_index -= 1;
        }
      }
    },
  },
  watch: {
    enable_discount(val) {
      let vm = this;
      if (val) {
        if (vm.player) {
          vm.player.pause();
          vm.player.currentTime = 0;
        }
        vm.player = vm.audio.discount_click;
        vm.player.play();
      }
    },
    form: {
      handler: function (data) {
        let vm = this;
        if (!vm.value) {
          if (!vm.onload) {
            vm.$localStorage.set("product_data", JSON.stringify(data));
          } else {
            if (vm.$localStorage.get("product_data")) {
              vm.form = JSON.parse(vm.$localStorage.get("product_data"));
            }
          }
          vm.onload = false;
        }
      },
      deep: true,
    },
    "form.discount": function (val) {
      let vm = this;
      if (vm.form.price && val) {
        if (/^\d+$/.test(vm.form.price)) {
          if (vm.form.price.match(/\d/g).length <= 7) {
            if (/^\d+$/.test(val)) {
              if (val >= 0 && val <= 100) {
                vm.enable_discount = true;
                vm.new_discount_price =
                  "FRW " + (vm.form.price - (vm.form.price * val) / 100);
              } else {
                vm.$notify({
                  group: "status",
                  type: "warn",
                  title: "OOPS!!!",
                  text: "Discount can not be greater to 100 or below 0",
                });
              }
            } else {
              vm.$notify({
                group: "status",
                type: "warn",
                title: "OOPS!!!",
                text: "We don't accept text on discount, use only number",
              });
            }
          } else {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: "Price must be under or equal to 7 digits",
            });
          }
        } else {
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: "We don't accept text on price, use only number",
          });
        }
      } else {
        vm.new_discount_price = "";
      }
    },
    select_all_shop: function (val) {
      let vm = this;
      if (val) {
        vm.my_shops_data.map((item) => {
          item.active = true;
          return item;
        });
      } else {
        vm.my_shops_data.map((item) => {
          item.active = false;
          return item;
        });
      }
    },
    value: function (new_value) {
      let vm = this;
      if (new_value) {
        vm.processItemForUpdate(new_value);
      } else {
        vm.resetInput();
      }
    },
    shop_id: function (new_value) {
      let vm = this;
      if (vm.shop_id) {
        vm.form.product_shop.push(vm.shop_id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.overlay {
  position: fixed;
  z-index: 999999999999999;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(#fff, 0.9);

  .modal-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 14px;
    .modal-widget {
      width: 650px;
      background: #fff;
      height: 700px;
      border: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      position: relative;
      @media screen and (max-width: 500px) {
        width: 100%;
        position: fixed;
        z-index: 999999999999999;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        height: auto;
      }
      .modal-header {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 160px;
        padding-top: 64px;
        padding-right: 64px;
        padding-left: 64px;
        padding-bottom: 40px;
        @media screen and (max-width: 500px) {
          padding-right: 14px;
          padding-left: 14px;
          height: 95px;
          align-items: flex-start;
          padding-top: 18px;
          padding-bottom: 18px;
        }
        .modal-title {
          font-size: 24px;
          font-weight: 600;
          @media screen and (max-width: 500px) {
            font-size: 18px;
          }
        }
        .modal-tab-header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 30px 0px;
          @media screen and (max-width: 500px) {
            padding: 6px 0px;
          }
          .modal-tab-title {
            font-size: 16px;
          }
          .modal-tab-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .button-item {
              width: 24px;
              height: 4px;
              display: block;
              background: #f8efef;
              margin-left: 9px;
              transition: 500ms ease-in-out;
              &.active {
                background: #8dc73f;
                transition: 500ms ease-in-out;
              }
            }
          }
        }
      }
      .modal-body {
        height: calc(100% - 220px);
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 500px) {
          height: calc(100% - 155px);
        }
        .modal-content {
          padding-top: 10px;
          padding-right: 64px;
          padding-left: 64px;
          @media screen and (max-width: 500px) {
            padding-right: 33px;
            padding-left: 14px;
          }
          .input-group {
            .input-widget {
              .properties-table {
                width: 100%;
                .property-row {
                  display: flex;
                  margin-bottom: 12px;
                  position: relative;
                  align-items: center;
                  .remove-property-btn {
                    position: absolute;
                    top: -10px;
                    right: -15px;
                    z-index: 999;
                    height: 36px;
                    width: 32px;
                    background: transparent;
                    border: none;
                  }
                  .property-label {
                    margin-right: 8px;
                    width: 49%;
                    input {
                      height: 50px;
                      width: 100%;
                      padding: 0px 15px;
                      background: #eeeeee;
                      border: none;
                      border-radius: 5px;
                      outline: none;
                      font-size: 16px;
                      font-weight: 500;
                    }
                  }
                  .property-input {
                    width: 49%;
                  }
                }
                .properties-table-option {
                  .btn-add-property {
                    font-size: 16.6667px;
                    line-height: 20px;
                    color: #8dc73f;
                    background: #fff;
                    border: none;
                    margin-top: 11px;
                  }
                }
              }
            }
          }
          .select-all-input {
            padding-bottom: 10px;
            border-bottom: 3px solid #ddd;
            background: #ddd;
            display: flex;
            align-items: center;
            padding: 10px 10px;
            input {
              margin-right: 10px;
            }
          }
          .show-list {
            .shop-item {
              display: flex;
              align-items: center;
              border: 1px solid #ddd;
              margin: 10px 0px;
              padding: 10px 9px;
              border-radius: 4px;
              input {
                margin-right: 10px;
              }
              label {
                font-size: 16px;
                text-transform: uppercase;
              }
            }
          }
          .empty-widget {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 400px;
          }
        }
      }
      .modal-footer {
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
        padding-left: 20px;
        height: 60px;
        align-items: center;
        border-top: 1px solid #ddd;
        button {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>