var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upload-trigger"},[(!_vm.input_value)?_c('div',{class:_vm.classlist,on:{"click":function($event){return _vm.OpenUpload()}}},[_vm._t("default")],2):_vm._e(),_c('div',{staticClass:"selected-widget"},[(_vm.selected_files.length !== 0 && _vm.multiple)?_c('div',{staticClass:"selected-container"},[_c('div',{staticClass:"ant-upload-list ant-upload-list-picture-card",staticStyle:{"width":"100%"}},[_vm._l((_vm.selected_files),function(file,index){return _c('div',{key:index + 'selected_file',staticClass:"ant-upload-list-item ant-upload-list-item-done"},[_c('div',{staticClass:"ant-upload-list-item-info"},[_c('span',[_c('div',{staticClass:"ant-upload-list-item-thumbnail"},[(
                    _vm.GetExt(file) == 'jpeg' ||
                    _vm.GetExt(file) == 'jpg' ||
                    _vm.GetExt(file) == 'png' ||
                    _vm.GetExt(file) == 'gif'
                  )?_c('img',{attrs:{"src":`${_vm.url + _vm.location}${file}`}}):_vm._e(),(
                    _vm.GetExt(file) == 'mp3' ||
                    _vm.GetExt(file) == 'ogg' ||
                    _vm.GetExt(file) == 'acc'
                  )?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/audio.png`}}):_vm._e(),(
                    _vm.GetExt(file) == 'mp4' ||
                    _vm.GetExt(file) == 'avi' ||
                    _vm.GetExt(file) == 'vob' ||
                    _vm.GetExt(file) == 'mov'
                  )?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/video.png`}}):_vm._e(),(_vm.GetExt(file) == 'pdf')?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/pdf.png`}}):_vm._e(),(_vm.GetExt(file) == 'docs' || _vm.GetExt(file) == 'doc')?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/word.png`}}):_vm._e(),(
                    _vm.GetExt(file) == 'xls' ||
                    _vm.GetExt(file) == 'xlsx' ||
                    _vm.GetExt(file) == 'xlsm' ||
                    _vm.GetExt(file) == 'xlt' ||
                    _vm.GetExt(file) == 'xltx' ||
                    _vm.GetExt(file) == 'xltm' ||
                    _vm.GetExt(file) == 'xla' ||
                    _vm.GetExt(file) == 'xlam'
                  )?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/excel.png`}}):_vm._e()])])]),_c('span',{staticClass:"ant-upload-list-item-actions"},[_c('a-popconfirm',{attrs:{"title":"Are you sure？","okText":"Yes","cancelText":"No"},on:{"confirm":function($event){return _vm.removeFileFromSelected(index)}}},[_c('a-icon',{staticStyle:{"color":"red"},attrs:{"slot":"icon","type":"question-circle-o"},slot:"icon"}),_c('a-icon',{staticStyle:{"color":"#fff"},attrs:{"type":"delete"}})],1)],1)])}),_c('div',{staticClass:"ant-upload-list-item ant-upload-list-item-done",on:{"click":function($event){return _vm.OpenUpload()}}},[_c('div',{staticClass:"ant-upload-list-item-thumbnail add-card"},[_c('a-icon',{staticStyle:{"color":"#ccc"},attrs:{"type":"plus"}})],1)])],2)]):_vm._e(),(_vm.input_value && !_vm.multiple)?_c('div',{staticClass:"selected-container"},[_c('div',{staticClass:"image-widget"},[(
            _vm.GetExt(_vm.input_value) == 'jpeg' ||
            _vm.GetExt(_vm.input_value) == 'jpg' ||
            _vm.GetExt(_vm.input_value) == 'png' ||
            _vm.GetExt(_vm.input_value) == 'gif'
          )?_c('img',{attrs:{"src":`${_vm.url + _vm.location}${_vm.input_value}`}}):_vm._e(),(
            _vm.GetExt(_vm.input_value) == 'mp3' ||
            _vm.GetExt(_vm.input_value) == 'ogg' ||
            _vm.GetExt(_vm.input_value) == 'acc'
          )?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/audio.png`}}):_vm._e(),(
            _vm.GetExt(_vm.input_value) == 'mp4' ||
            _vm.GetExt(_vm.input_value) == 'avi' ||
            _vm.GetExt(_vm.input_value) == 'vob' ||
            _vm.GetExt(_vm.input_value) == 'mov'
          )?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/video.png`}}):_vm._e(),(_vm.GetExt(_vm.input_value) == 'pdf')?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/pdf.png`}}):_vm._e(),(_vm.GetExt(_vm.input_value) == 'docs' || _vm.GetExt(_vm.input_value) == 'doc')?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/word.png`}}):_vm._e(),(
            _vm.GetExt(_vm.input_value) == 'xls' ||
            _vm.GetExt(_vm.input_value) == 'xlsx' ||
            _vm.GetExt(_vm.input_value) == 'xlsm' ||
            _vm.GetExt(_vm.input_value) == 'xlt' ||
            _vm.GetExt(_vm.input_value) == 'xltx' ||
            _vm.GetExt(_vm.input_value) == 'xltm' ||
            _vm.GetExt(_vm.input_value) == 'xla' ||
            _vm.GetExt(_vm.input_value) == 'xlam'
          )?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/excel.png`}}):_vm._e(),_c('a-popconfirm',{attrs:{"title":"Are you sure？","okText":"Yes","cancelText":"No"},on:{"confirm":function($event){return _vm.removeFileFromSelected(_vm.index)}}},[_c('button',{staticClass:"remove-selected-btn",on:{"click":function($event){return _vm.removeSingleSelected()}}},[_c('a-icon',{staticStyle:{"color":"red"},attrs:{"type":"delete"}})],1)])],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }