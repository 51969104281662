<template>
  <div class="announcement-section">
    <!-- What New Modal -->
    <div class="overlay" v-if="show_what_new_modal">
      <div class="whatsnew-modal">
        <div class="header">
          <img src="@/assets/images/icons/movies.svg" />
          <h3>WHAT’S NEW</h3>
        </div>
        <div class="body">
          <div class="intro">
            <p style="font-size: 16px">
              Now you can watch movies on Cagura (PK Agasobanuye) while you are
              doing bussiness.
            </p>
          </div>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/PH_se3vU3YA?autoplay=1"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="footer">
          <c-button
            @click.prevent="$router.push({ name: 'movies' }).catch(() => {})"
            class="btn-success"
            >WATCH NOW</c-button
          >
          <c-button @click="skipWhatsNew()" class="btn-default">SKIP</c-button>
        </div>
      </div>
    </div>
    <!-- Subscribe to notification -->
    <div class="overlay" v-if="show_notification_subscribe_modal">
      <div class="subscribe-notification-modal">
        <div class="header">
          <h3>THIS IS IMPORTANT</h3>
        </div>
        <div class="body">
          <img class="intro-image" src="@/assets/images/icons/ring.svg" />
          <div class="intro">
            <p style="font-size: 16px">
              If you need to receive notification from someone who
              <b>ordered</b> your products, <b>live match</b> and
              <b>trending movies</b> you can watch on Cagura, Please allow
              notification
            </p>
          </div>
        </div>
        <div class="footer">
          <c-button
            :loading="enable_notification_loading"
            @click.prevent="enableNotification()"
            class="btn-success"
          >
            ENABLE NOTIFICATION
          </c-button>
          <c-button @click="skipEnableNotification()" class="btn-default"
            >SKIP</c-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "announcement",
  components: {},
  data() {
    return {
      show_what_new_modal: false,
      show_whats_new_loading: false,
      show_skip_whats_new_loading: false,
      whats_new_session: "whats_new_shown_v_2",
      server_public_key: "",
      enable_notification_loading: false,
      show_notification_subscribe_modal: false,
      validate_user_timer: null,
    };
  },
  mounted() {
    let vm = this;
    vm.checkWhatsNew();
    vm.validate_user_timer = setInterval(() => {
      if (vm.$getLoggedUser()) {
        setTimeout(() => {
          let permission = Notification.permission;
          if (permission == "default") {
            vm.$localStorage.remove("subscribed_notification");
          }
          if (!vm.$localStorage.get("subscribed_notification")) {
            vm.checkNotificationPermssion();
          }
        }, 1000 * 1);
        clearInterval(vm.validate_user_timer);
      }
    }, 500);
  },
  methods: {
    checkNotificationPermssion() {
      let vm = this;
      let permission = Notification.permission;
      if (permission == "granted") {
        vm.show_notification_subscribe_modal = false;
      } else if (permission == "default") {
        vm.getServerPublicKey();
      }
    },
    getServerPublicKey() {
      let vm = this;
      vm.$store
        .dispatch("NODE_GET_VAPID")
        .then((response) => {
          vm.server_public_key = response.data.publicKey;
          vm.show_notification_subscribe_modal = true;
        })
        .catch(({ response }) => {});
    },
    enableNotification() {
      let vm = this;
      if (vm.server_public_key) {
        vm.enable_notification_loading = true;
        Notification.requestPermission((permission) => {
          if (permission == "granted") {
            navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
              if (serviceWorkerRegistration.pushManager) {
                var options = {
                  userVisibleOnly: true,
                  applicationServerKey: vm.server_public_key,
                };
                serviceWorkerRegistration.pushManager.subscribe(options).then(
                  (pushSubscription) => {
                    vm.$store
                      .dispatch("SAVE_USER_NOTIFICATION_CREDECTIALS", {
                        data: JSON.stringify(pushSubscription),
                        app_type: vm.getCurrentBrowser(),
                        app: "web",
                      })
                      .then((response) => {
                        vm.enable_notification_loading = false;
                        vm.show_notification_subscribe_modal = false;
                        vm.$localStorage.set("subscribed_notification", true);
                      })
                      .catch(({ response }) => {
                        vm.enable_notification_loading = false;
                        vm.$notify({
                          group: "status",
                          type: "warn",
                          title: "OOPS!!!",
                          text: "Please try again",
                        });
                      });
                  },
                  (error) => {
                    vm.enable_notification_loading = false;
                    console.log(error);
                  }
                );
              } else {
                vm.enable_notification_loading = false;
                vm.show_notification_subscribe_modal = false;
                vm.$localStorage.set("subscribed_notification", true);
              }
            });
          }
        });
      } else {
        vm.getServerPublicKey();
        vm.$notify({
          group: "status",
          type: "warn",
          title: "OOPS!!!",
          text: "Please try again",
        });
      }
    },
    skipEnableNotification() {
      let vm = this;
      vm.$localStorage.set("subscribed_notification", false);
      vm.show_notification_subscribe_modal = false;
    },

    checkWhatsNew() {
      let vm = this;
      if (vm.$localStorage.get(vm.whats_new_session)) {
        vm.show_what_new_modal = false;
      } else {
        vm.$localStorage.set(vm.whats_new_session, true);
        // Switch
        vm.show_what_new_modal = false;
      }
    },
    skipWhatsNew() {
      let vm = this;
      vm.$localStorage.set(vm.whats_new_session, true);
      vm.show_what_new_modal = false;
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
</style>