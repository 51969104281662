<template>
  <div class="cagura-wrapper">
    <div class="cagura-nav-bar">
      <img
        @click.prevent="$router.push({ name: 'home' }).catch(() => {})"
        src="@/assets/images/logo.png"
        alt="Cagura Logo"
        class="cagura-logo"
      />
    </div>
    <div class="body">
      <div class="reset-wrapper">
        <div class="image-section">
          <img src="@/assets/images/verify-img.svg" alt />
        </div>
        <div class="reset-section">
          <div class="reset-header">
            <h2>Hello there, you can now reset your Password</h2>
          </div>
          <div class="form-widget">
            <div class="input-group">
              <div class="input-widget">
                <input
                  type="password"
                  class="bg-cagura-form-control"
                  name="password"
                  placeholder="New Password"
                  v-model="form.password"
                />
              </div>
            </div>

            <div class="input-group">
              <div class="input-widget">
                <input
                  type="password"
                  class="bg-cagura-form-control"
                  name="comfirm-password"
                  placeholder="Comfirm Password"
                  v-model="form.confirm_password"
                />
              </div>
            </div>

            <div class="form-option">
              <c-button
                class="reset-btn"
                :loading="is_loading"
                @click="updatePassword()"
                >RESET PASSWORD</c-button
              >
              <div class="have-account-section">
                <p>
                  Remember Password?
                  <a
                    @click.prevent="
                      $router.push({ name: 'login' }).catch(() => {})
                    "
                    >SIGN IN</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "cagura-reset",
  components: {},
  data() {
    return {
      form: {
        id: null,
        password: "",
        confirm_password: "",
        token: "",
      },
      is_loading: false,
    };
  },
  mounted() {
    let vm = this;
    if (vm.$getLoggedUser()) {
      vm.$router.push({ name: "home" }).catch(() => {});
    } else {
      if (vm.$route.params.token) {
        vm.form.token = vm.$route.params.token;
      } else {
        vm.$router.push({ name: "home" }).catch(() => {});
      }
    }
  },
  methods: {
    updatePassword() {
      let vm = this;
      vm.is_loading = true;
      if (vm.form.token) {
        if (vm.form.password == vm.form.confirm_password) {
          vm.$store
            .dispatch("UPDATE_ACCOUNT_PASSWORD", vm.form)
            .then((response) => {
              vm.$notify({
                group: "status",
                type: "success",
                title: "Important message",
                text: response.data.message,
              });
              vm.is_loading = false;
              vm.$router.push({ name: "login" });
            })
            .catch(({ response }) => {
              vm.is_loading = false;
              vm.$notify({
                group: "status",
                type: "warn",
                title: "OOPS!!!",
                text: response.data.message,
              });
              console.log("UPDATE_ACCOUNT_PASSWORD", { error });
            });
        } else {
          vm.is_loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: "Password does not match",
          });
        }
      } else {
        vm.is_loading = false;
        vm.$notify({
          group: "status",
          type: "warn",
          title: "OOPS!!!",
          text: "Unknown error found, try again to verify your email",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  padding: 30px 40px;
  width: 100%;
  .cagura-nav-bar {
    .cagura-logo {
      width: 200px;
      margin-left: -10px;
      @media screen and (max-width: 500px) {
        margin-left: -26px;
      }
    }
  }
  .body {
    margin-top: 30px;
    .reset-wrapper {
      display: grid;
      grid-template-columns: 46% 46%;
      grid-column-gap: 40px;
      @media screen and (max-width: 500px) {
        grid-template-columns: 100%;
      }
      .image-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @media screen and (max-width: 500px) {
          display: none;
        }
        img {
          width: 100%;
        }
      }
      .reset-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .reset-header {
          display: flex;
          h2 {
            font-size: 30px;
            color: #8dc73f;
            @media screen and (max-width: 500px) {
              font-size: 18px;
            }
          }
        }
        .form-widget {
          border: 1px solid #ddd;
          padding: 60px 60px;
          @media screen and (max-width: 500px) {
            padding: 10px 0px;
            border: none;
          }
          .twin-input {
            display: grid;
            grid-template-columns: 48% 48%;
            grid-column-gap: 20px;
            .input-group {
              width: 100%;
              margin-bottom: 15px;
              .input-widget {
                display: flex;
                .bg-cagura-form-control {
                  height: 50px;
                  width: 100%;
                  padding: 0px 15px;
                  background: #eeeeee;
                  border: none;
                  border-radius: 5px;
                  outline: none;
                  font-size: 16px;
                  font-weight: 500;
                  &::placeholder {
                    color: #b2b2b2;
                  }
                }
              }
            }
          }
          .input-group {
            width: 100%;
            margin-bottom: 15px;
            .input-widget {
              display: flex;
              .bg-cagura-form-control {
                height: 50px;
                width: 100%;
                padding: 0px 15px;
                background: #eeeeee;
                border: none;
                border-radius: 5px;
                outline: none;
                font-size: 16px;
                font-weight: 500;
                &::placeholder {
                  color: #b2b2b2;
                }
              }
            }
            .account-type {
              display: flex;
              padding: 20px 0px;
              .type-item {
                margin-right: 20px;
              }
            }
          }
          .form-option {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: 500px) {
              flex-direction: column;
              margin-bottom: 20px;
            }
            .reset-btn {
              width: 154px;
              height: 45px;
              background: #8dc73f;
              border-radius: 5px;
              color: #fff;
              border: none;
              @media screen and (max-width: 500px) {
                margin-bottom: 20px;
              }
            }
            .have-account-section {
              a {
                color: #8dc73f;
                text-decoration: none;
                cursor: pointer;
              }
            }
          }

          .other-reset-option {
            display: flex;
            justify-content: space-between;
            padding-top: 15px;
            @media screen and (max-width: 500px) {
              margin-bottom: 20px;
            }
            .option {
              display: flex;
              align-items: center;
              background: #ffffff;
              box-shadow: 0px 2px 5.7px rgba(20, 20, 20, 0.21);
              border-radius: 5px;
              padding: 10px 10px;
              cursor: pointer;
              .logo {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
