<template>
  <div class="cagura-wrapper">
    <!-- Navbar -->
    <client-header />
    <!-- Navbar -->
    <div class="cagura-container">
      <!-- Side bar -->
      <transition
        name="bounce"
        enter-active-class="bounceInLeft"
        leave-active-class="bounceOutLeft"
      >
        <side-bar v-if="$store.state.sidebar" />
      </transition>
      <!-- End of sidebar -->
      <!-- Content - body -->
      <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
        <vue-scroll>
          <div class="cagura-help-widget">
            <div class="help-widget">
              <div class="help-widget-header">
                <div class="call-actions">
                  <button
                    class="btn-back"
                    @click="$router.push({ name: 'home' }).catch(() => {})"
                  >
                    <img src="@/assets/images/icons/arrow-left.svg" /> ABOUT
                  </button>
                </div>
              </div>
              <!-- BODY -->
              <div class="content-widget">
                <h4 class="title">Introduction</h4>
                <p>
                  Cagura website and Cagura App was built under Wecodefy LTD
                  company, Visit
                  <span
                    @click.prevent="
                      $router.push({ name: 'contact' }).catch(() => {})
                    "
                    class="link"
                    >contact</span
                  >
                  page to know where we are located.
                </p>

                <div class="sub-content">
                  <h4 class="sub-title">What is Cagura?</h4>
                  <p>
                    We need you to have everything related to business on one
                    platform.
                  </p>
                  <p>
                    Cagura will help you to do anything related to selling and
                    buying products or services, this may include other services
                    and features we can provide to you in our platform to
                    maximize the way you are selling or buying.
                  </p>

                  <p>For more understanding watch this video below:</p>

                  <div class="video-widget">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/dLEYLwLF6_o"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>

                <h4 class="title">Our Service</h4>
                <p>
                  Our Services allows you to buy and sell different products
                  from different shops sometimes based on your location if you
                  want to.
                </p>

                <p>This our services you can access now:</p>
                <ul>
                  <li>Feeds</li>
                  <li>Shopping</li>
                </ul>
                <div class="sub-content">
                  <p>
                    <b> Feeds:</b> This service can be used by anyone who needs
                    something to buy or Sell, on this page you will find
                    different posts of products, there are two types of posts,
                    you can differentiate those posts based on their label.
                  </p>
                  <div class="sub-content">
                    <p>
                      <b>I am selling:</b> This is the post of someone who is
                      selling something
                    </p>
                    <p>
                      <b> Find for me:</b> This is the post of someone who need
                      something to buy
                    </p>
                  </div>
                  <p>
                    <b>Shopping:</b> This service can help any one who want to
                    buy products, but those products are not owned by Cagura
                    there are from different sellers, so we recommend
                    experienced seller to use this option while there are adding
                    products but for someone who want to add one product we
                    recommend him or her to use Feeds section.
                  </p>
                </div>
                <p>
                  We also have an <b> Affiliate program.</b> <br />
                  When you become our affiliate by accepting
                  <span
                    @click.prevent="
                      $router.push({ name: 'terms' }).catch(() => {})
                    "
                    class="link"
                  >
                    affiliate agreement</span
                  >
                  you can make money using different options we provide in that
                  section, for more info watch this video
                </p>
                <div class="sub-content">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/98gFqEoDcJg"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <p>
                  Thank you for using Cagura platform, services and features we
                  make available to you as part of the platform.
                </p>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
      <!-- End of content -->
    </div>
  </div>
</template>

<script>
import ClientHeader from "../../components/partials/header.vue";
import SideBar from "../../components/partials/sidebar.vue";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
export default {
  name: "about",
  components: {
    ClientHeader,
    SideBar,
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {
    return {
      tabs: {
        properties: true,
        reviews: false,
      },
    };
  },
  methods: {
    goPropertyTab() {
      let vm = this;
      vm.tabs.properties = true;
      vm.tabs.reviews = false;
    },
    goReviewTab() {
      let vm = this;
      vm.tabs.properties = false;
      vm.tabs.reviews = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
    .cagura-body {
      .cagura-help-widget {
        height: 100%;
        padding-bottom: 50px;
        .help-widget {
          padding: 0px 20px;
          .help-widget-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0px;
            .call-actions {
              .btn-back {
                height: 36px;
                border: none;
                border-radius: 5px;
                background: transparent;
                outline: none;
                display: flex;
                align-items: center;
                img {
                  margin-right: 15px;
                }
              }
            }
          }
          .content-widget {
            display: flex;
            flex-direction: column;
            width: 800px;
            background: #fff;
            padding: 25px;
            border-radius: 5px;
            @media screen and (max-width: 800px) {
              width: 100%;
            }
            .title {
              color: #8cc73f;
              font-weight: 800;
              font-size: 20px;
              display: initial;
              padding-bottom: 4px;
              margin-bottom: 6px;
            }
            .sub-title {
              color: #000;
              font-weight: 800;
              font-size: 16px;
              display: initial;
              padding-bottom: 4px;
              margin-bottom: 6px;
              width: 300px;
            }
            p {
              color: rgb(69, 69, 69);
              margin-bottom: 8px;
              margin-top: 3px;
            }
            .sub-content {
              padding-left: 30px;
              margin-bottom: 20px;
            }
            .link {
              color: #8cc73f;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>

