var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cagura-wrapper"},[_c('ClientHeader',{attrs:{"type":"shopping"}}),_c('div',{staticClass:"cagura-container"},[_c('side-bar'),_c('div',{staticClass:"cagura-body",class:{ nosidebar: !_vm.$store.state.sidebar }},[_c('div',{staticClass:"tags-widget"},[_c('cagura-tags')],1),_c('div',{staticClass:"cagura-products-widget has-tags"},[_c('vue-scroll',[_c('div',{staticClass:"products-widget"},[(_vm.products_data)?_c('div',{staticClass:"products-widget-header"},[_c('div',{staticClass:"call-actions"},[_c('button',{staticClass:"btn-back",on:{"click":function($event){_vm.$router.push({ name: 'home' }).catch(() => {})}}},[_c('img',{attrs:{"src":require("@/assets/images/icons/arrow-left.svg")}}),_vm._v(" "+_vm._s(_vm.products_data.name)+" ")])])]):_vm._e(),(_vm.products_data && _vm.products_data.products.length > 0)?_c('div',{staticClass:"products-widget-body"},_vm._l((_vm.products_data.products),function(item,i){return _c('div',{key:i,staticClass:"product-item",on:{"click":function($event){_vm.$router
                    .push({ path: _vm.$getProductLink(item) })
                    .catch(() => {})}}},[(item.discount)?_c('div',{staticClass:"product-announcement success"},[_vm._v(" "+_vm._s(item.discount)+"% OFF ")]):_vm._e(),_c('div',{staticClass:"product-image"},[_c('img',{attrs:{"src":_vm.$store.state.ASSETS_BASE_URL +
                      '/assets/uploaded/' +
                      item.main_image}})]),_c('div',{staticClass:"product-content"},[_c('h4',[_vm._v(_vm._s(item.product_name))]),_c('p',[_vm._v(" FRW "+_vm._s(item.discount ? _vm.$formatMoney( item.price - (item.price * item.discount) / 100 ) : _vm.$formatMoney(item.price))+" ")]),(item.shop)?_c('div',{staticClass:"shop-viewer",on:{"click":function($event){_vm.$router.push({ path: _vm.$getShopLink(item.shop) })}}},[_c('img',{staticClass:"shop-image",attrs:{"src":_vm.$store.state.ASSETS_BASE_URL +
                        '/assets/uploaded/' +
                        item.shop.shop_logo}}),_c('div',{staticClass:"shop-content"},[_c('label',{staticClass:"shop-name"},[_vm._v(_vm._s(item.shop.shop_name))]),_c('span',{staticClass:"shop-location"},[_vm._v(_vm._s(item.shop.location_name))])])]):_vm._e()])])}),0):_vm._e(),(_vm.loading)?_c('div',{staticClass:"product-loading-widget"},_vm._l((12),function(item,index){return _c('div',{key:index,staticClass:"product-loading-item pulse"},[_c('div',{staticClass:"product-loading-image"}),_c('div',{staticClass:"product-loading-content"},[_c('div',{staticClass:"product-loading-title"}),_c('div',{staticClass:"product-loading-price"})]),_c('div',{staticClass:"product-loading-shop"})])}),0):_vm._e()])])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }