var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overlay"},[_c('vue-scroll',{attrs:{"ops":{
      rail: {
        background: '#eee',
        opacity: 1,
        size: '11px',
        specifyBorderRadius: false,
        gutterOfEnds: null,
        gutterOfSide: '0px',
        keepShow: false,
      },
    }}},[_c('div',{staticClass:"help-form"},[_c('div',{staticClass:"help-header"},[_c('h1',{staticClass:"title"},[_vm._v("What's happening")]),_c('button',{staticClass:"btn-close",on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("×")])]),_c('div',{staticClass:"help-body"},[_c('ul',{staticClass:"list"},_vm._l((_vm.help_form.help_feedback_content),function(item,index){return _c('li',{key:index},[_c('label',{staticClass:"check-box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.active),expression:"item.active"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(item.active)?_vm._i(item.active,null)>-1:(item.active)},on:{"change":function($event){var $$a=item.active,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "active", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "active", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "active", $$c)}}}}),_c('span',{staticClass:"checkmark"})]),_c('div',{staticClass:"issue-content"},[_c('h4',[_vm._v(_vm._s(item.label))]),_c('p',[_vm._v(_vm._s(item.description))])])])}),0),_c('div',{staticClass:"other-form"},[_c('div',{staticClass:"header"},[_c('label',[_vm._v("Any more details?")]),_c('div',{staticClass:"optional"},[_c('label',[_vm._v("(Optional)")])])]),_c('div',{staticClass:"body"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.help_form.details),expression:"help_form.details"}],staticClass:"other-comment",domProps:{"value":(_vm.help_form.details)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.help_form, "details", $event.target.value)}}})]),_c('div',{staticClass:"footer"},[_c('c-button',{staticClass:"btn-success",attrs:{"loading":_vm.submit_loading},on:{"click":function($event){return _vm.submitFeedBack()}}},[_vm._v("Submit Issue")])],1)])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }