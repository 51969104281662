<template>
  <div class="cagura-wrapper">
    <ClientHeader type="live"></ClientHeader>
    <SideBar type="live"></SideBar>
    <div
      class="cagura-container"
      :class="{ sidebar: !$store.getters.getSideBar }"
    >
      <div class="announcement-widget">
        <img src="@/assets/images/icons/notification-not-logged.svg" />
        <h1>DON'T BE SURPRISED</h1>
        <p>
          We have moved this <b> Live section</b> to new website
          (<b>www.watch.rw</b>), so then you can enjoy more, don't worry you
          will continue to use your cagura account
        </p>
        <button class="btn-default btn-sign-in" @click="goToWebsite()">
          GO TO NEW WEBSITE
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ClientHeader from "../components/partials/header.vue";
import SideBar from "../components/partials/sidebar.vue";
export default {
  name: "live-home",
  components: {
    ClientHeader,
    SideBar,
  },
  data() {
    return {};
  },
  watch: {},
  head: {
    title: function () {
      return {
        inner: "Cagura - Live",
      };
    },
  },
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    goToWebsite() {
      let vm = this;
      let url = "https://watch.rw";
      window.open(url, "_blank").focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-top: 70px;
  transition: 500ms ease-in-out;
  @media screen and (max-width: 800px) {
    padding-top: 67px;
  }

  .cagura-container {
    height: 100%;
    width: 100%;
    display: flex;
    transition: 500ms ease-in-out;
    padding-left: 282px;
    justify-content: center;
    padding-top: 50px;
    &.sidebar {
      padding-left: 0px;
      transition: 500ms ease-in-out;
    }
    .announcement-widget {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 400px;
      img {
        width: 200px;
      }
      h1 {
        margin: 20px 0px;
        font-size: 30px;
      }
      p {
        text-align: center;
        color: rgb(114, 113, 113);
        margin-bottom: 30px;
      }
    }
  }
}
</style>

