<template>
  <div class="cagura-wrapper">
    <!-- Navbar -->
    <ClientHeader type="shopping"></ClientHeader>
    <!-- Navbar -->
    <div class="cagura-container">
      <!-- Side bar -->
      <side-bar type="shopping" />
      <!-- End of sidebar -->
      <!-- Content - body -->
      <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
        <div class="tags-widget">
          <cagura-tags />
        </div>
        <div class="cagura-products-widget has-tags">
          <vue-scroll @handle-scroll="handleScroll">
            <div class="under-scoll-container-widget">
              <div class="fit-container">
                <!-- Sponsored products -->
                <div class="products-widget">
                  <div class="products-widget-header">
                    <h3>SPONSORED PRODUCTS</h3>
                    <div class="call-actions"></div>
                  </div>
                  <div
                    class="products-widget-body"
                    v-if="products_sponsored_data.length > 0"
                  >
                    <div
                      class="product-item"
                      v-for="(item, i) in products_sponsored_data.slice(0, 100)"
                      :key="i"
                    >
                      <div
                        class="product-announcement success"
                        v-if="item.discount"
                      >
                        {{ item.discount }}% OFF
                      </div>
                      <div
                        class="product-image"
                        @click="
                          $router
                            .push({ path: $getProductLink(item) })
                            .catch(() => {})
                        "
                      >
                        <img
                          :src="
                            $store.state.ASSETS_BASE_URL +
                            '/assets/uploaded/' +
                            item.main_image
                          "
                        />
                      </div>
                      <div class="product-content">
                        <h4>{{ item.product_name }}</h4>
                        <p>
                          FRW
                          {{
                            item.discount
                              ? $formatMoney(
                                  item.price -
                                    (item.price * item.discount) / 100
                                )
                              : $formatMoney(item.price)
                          }}
                        </p>
                        <div
                          class="shop-viewer"
                          v-if="item.shop"
                          @click="
                            $router.push({ path: $getShopLink(item.shop) })
                          "
                        >
                          <img
                            class="shop-image"
                            :src="
                              $store.state.ASSETS_BASE_URL +
                              '/assets/uploaded/' +
                              item.shop.shop_logo
                            "
                          />
                          <div class="shop-content">
                            <label class="shop-name">{{
                              item.shop.shop_name
                            }}</label>
                            <span class="shop-location">{{
                              item.shop.location_name
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="product-loading-widget" v-if="sponsored_loading">
                    <div
                      class="product-loading-item pulse"
                      v-for="(item, index) in 4"
                      :key="index"
                    >
                      <div class="product-loading-image"></div>
                      <div class="product-loading-content">
                        <div class="product-loading-title"></div>
                        <div class="product-loading-price"></div>
                      </div>
                      <div class="product-loading-shop"></div>
                    </div>
                  </div>
                </div>
                <div class="categorized-product-widget" v-if="products_data">
                  <template v-for="(category, index) in products_data.data">
                    <!-- List Categorization -->
                    <div
                      class="products-widget"
                      :key="index"
                      v-if="category.type == 'list'"
                    >
                      <div class="products-widget-header">
                        <div class="products-labels">
                          <img
                            class="category-image"
                            :src="
                              $store.state.ASSETS_BASE_URL +
                              '/assets/uploaded/' +
                              category.image
                            "
                          />
                          <h3 class="category-name">{{ category.name }}</h3>
                        </div>
                        <div class="call-actions">
                          <button
                            class="view-all-btn"
                            @click="
                              $router
                                .push({
                                  name: 'category',
                                  params: { id: category.id },
                                })
                                .catch(() => {})
                            "
                          >
                            VIEW ALL
                          </button>
                        </div>
                      </div>

                      <div class="products-widget-body">
                        <div
                          class="product-item"
                          v-for="(item, i) in category.products"
                          :key="i"
                        >
                          <div
                            class="product-announcement success"
                            v-if="item.discount"
                          >
                            {{ item.discount }}% OFF
                          </div>
                          <div
                            class="product-image"
                            @click="
                              $router
                                .push({ path: $getProductLink(item) })
                                .catch(() => {})
                            "
                          >
                            <img
                              :src="
                                $store.state.ASSETS_BASE_URL +
                                '/assets/uploaded/' +
                                item.main_image
                              "
                            />
                          </div>
                          <div class="product-content">
                            <h4>{{ item.product_name }}</h4>
                            <p>
                              FRW
                              {{
                                item.discount
                                  ? $formatMoney(
                                      item.price -
                                        (item.price * item.discount) / 100
                                    )
                                  : $formatMoney(item.price)
                              }}
                            </p>
                            <div
                              class="shop-viewer"
                              v-if="item.shop"
                              @click="
                                $router.push({ path: $getShopLink(item.shop) })
                              "
                            >
                              <img
                                class="shop-image"
                                :src="
                                  $store.state.ASSETS_BASE_URL +
                                  '/assets/uploaded/' +
                                  item.shop.shop_logo
                                "
                              />
                              <div class="shop-content">
                                <label class="shop-name">{{
                                  item.shop.shop_name
                                }}</label>
                                <span class="shop-location">{{
                                  item.shop.location_name
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Group Categorization -->
                    <div
                      class="products-group-widget"
                      :key="index"
                      v-if="category.type == 'group'"
                    >
                      <div
                        class="products-group"
                        v-for="(group, i) in category.categories"
                        :key="i"
                      >
                        <div class="products-group-header">
                          <div class="products-group-name">
                            <img
                              :src="
                                $store.state.ASSETS_BASE_URL +
                                '/assets/uploaded/' +
                                group.image
                              "
                              class="group-image"
                            />
                            <h3 class="group-name">{{ group.name }}</h3>
                          </div>
                          <div class="products-group-option">
                            <button
                              class="view-all-btn"
                              @click="
                                $router
                                  .push({
                                    name: 'category',
                                    params: { id: group.id },
                                  })
                                  .catch(() => {})
                              "
                            >
                              VIEW ALL
                            </button>
                          </div>
                        </div>
                        <div class="products-group-body">
                          <div
                            class="product-item"
                            v-for="(item, i) in group.products"
                            :key="i"
                          >
                            <div
                              v-if="false"
                              class="product-announcement danger"
                            >
                              NEW
                            </div>
                            <div
                              class="product-image"
                              @click="
                                $router
                                  .push({ path: $getProductLink(item) })
                                  .catch(() => {})
                              "
                            >
                              <img
                                :src="
                                  $store.state.ASSETS_BASE_URL +
                                  '/assets/uploaded/' +
                                  item.main_image
                                "
                              />
                            </div>
                            <div class="product-content">
                              <h4>{{ item.product_name }}</h4>
                              <p>
                                FRW
                                {{
                                  item.discount
                                    ? $formatMoney(
                                        item.price -
                                          (item.price * item.discount) / 100
                                      )
                                    : $formatMoney(item.price)
                                }}
                              </p>
                              <div
                                class="shop-viewer"
                                v-if="item.shop"
                                @click="
                                  $router.push({
                                    path: $getShopLink(item.shop),
                                  })
                                "
                              >
                                <img
                                  class="shop-image"
                                  :src="
                                    $store.state.ASSETS_BASE_URL +
                                    '/assets/uploaded/' +
                                    item.shop.shop_logo
                                  "
                                />
                                <div class="shop-content">
                                  <label class="shop-name">{{
                                    item.shop.shop_name
                                  }}</label>
                                  <span class="shop-location">{{
                                    item.shop.location_name
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="products-widget" v-if="home_loading">
                  <div class="product-loading-widget">
                    <div
                      class="product-loading-item pulse"
                      v-for="(item, index) in 8"
                      :key="index"
                    >
                      <div class="product-loading-image"></div>
                      <div class="product-loading-content">
                        <div class="product-loading-title"></div>
                        <div class="product-loading-price"></div>
                      </div>
                      <div class="product-loading-shop"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
      <!-- End of content -->
    </div>
  </div>
</template>

<script>
import ClientHeader from "../components/partials/header.vue";
import SideBar from "../components/partials/sidebar.vue";
import CaguraTags from "../components/partials/tags.vue";
export default {
  name: "home",
  components: {
    ClientHeader,
    SideBar,
    CaguraTags,
  },
  data() {
    return {
      products_data: null,
      products_sponsored_data: [],
      home_loading: false,
      sponsored_loading: false,
    };
  },
  head: {
    title: function () {
      return {
        inner: "Cagura - Shopping",
      };
    },
  },
  mounted() {
    let vm = this;
    if (vm.$route.params.type == "notification" && vm.$route.params.id) {
      vm.$router
        .push(
          {
            name: "notification-viewer",
          },
          { params: { id: vm.$route.params.id } }
        )
        .catch(() => {});
    }
    vm.$emit("updateHead");
    vm.getCategorizedProducts(1, 2);
    vm.getSponsoredProducts();
  },
  methods: {
    handleScroll(vertical, horizontal, nativeEvent) {
      let vm = this;
      if (
        nativeEvent.target.scrollTop + 5 >
        nativeEvent.target.scrollHeight - nativeEvent.target.offsetHeight
      ) {
        if (
          vm.categorized_products_data.current_page <
          vm.categorized_products_data.total_page
        ) {
          if (!vm.home_loading) {
            vm.getCategorizedProducts(
              vm.categorized_products_data.next_page,
              2
            );
          }
        }
      }
    },
    getCategorizedProducts(page, item_per_page) {
      let vm = this;
      vm.home_loading = true;
      vm.$store
        .dispatch("PRODUCT_GET_CATEGORIZED", {
          page: page,
          item_per_page: item_per_page,
        })
        .then((response) => {
          vm.home_loading = false;
          vm.categorized_products_data = response.data.return;
          if (page == 1) {
            vm.products_data = response.data.return;
            vm.products_data.data.forEach((item) => {
              item.products.sort((a, b) => {
                return parseInt(b.prio) - parseInt(a.prio);
              });
              item.categories.map((category) => {
                category.products.sort((a, b) => {
                  return parseInt(b.prio) - parseInt(a.prio);
                });
              });
              return item;
            });
          } else {
            vm.products_data.current_page = response.data.return.current_page;
            vm.products_data.end_item = response.data.return.end_item;
            vm.products_data.item_per_page = response.data.return.item_per_page;
            vm.products_data.next_page = response.data.return.next_page;
            vm.products_data.previews_page = response.data.return.previews_page;
            vm.products_data.start_item = response.data.return.start_item;
            vm.products_data.total_items = response.data.return.total_items;
            vm.products_data.total_page = response.data.return.total_page;
            response.data.return.data.forEach((item) => {
              vm.products_data.data.push(item);
            });
          }
        })
        .catch(({ response }) => {
          vm.home_loading = false;
          console.error("PRODUCT_GET_SPONSORED", response);
        });
    },
    getSponsoredProducts() {
      let vm = this;
      vm.sponsored_loading = true;
      vm.$store
        .dispatch("PRODUCT_GET_SPONSORED")
        .then((response) => {
          vm.sponsored_loading = false;
          if (response.data.status == "ok") {
            vm.products_sponsored_data = response.data.return.sort((a, b) => {
              return parseInt(b.prio) - parseInt(a.prio);
            });
          }
        })
        .catch(({ response }) => {
          vm.sponsored_loading = false;
          console.error("PRODUCT_GET_SPONSORED", response);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
    display: block;
    .cagura-products-widget {
      .notify-user-section {
        padding-right: 20px;
        padding-left: 10px;
        display: grid;
        grid-template-columns: 49% 49%;
        grid-gap: 20px;
        margin-top: 15px;
        justify-content: space-between;
        @media screen and (max-width: 500px) {
          grid-template-columns: 100%;
        }
        .notify-item {
          display: flex;
          align-items: center;
          background: #fff;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid #ddd;
          .progress {
            width: 120px;
          }
          .message {
            padding: 0px 20px;
          }
        }
      }
    }
  }
}
</style>

