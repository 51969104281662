<template>
  <div class="cagura-apps-widget">
    <div
      class="action"
      ref="widget-action"
      @click="show_app_widget = !show_app_widget"
    >
      <button class="btn-apps">
        <slot></slot>
      </button>
    </div>

    <div
      class="apps-widget"
      v-closable="{ exclude: ['widget-action'], handler: 'hideAppWidget' }"
      v-show="show_app_widget"
    >
      <div
        class="app-item"
        @click="$router.push({ name: 'home' }).catch(() => {})"
      >
        <img src="@/assets/images/icon.png" />
        <label>Feeds</label>
      </div>
      <div
        class="app-item"
        @click="$router.push({ name: 'products' }).catch(() => {})"
      >
        <img src="@/assets/images/shopping-icon.svg" />
        <label>Shopping</label>
      </div>
      <div
        class="app-item movies"
        @click="$router.push({ name: 'movies' }).catch(() => {})"
      >
        <img
          class="movie-logo"
          src="@/assets/images/cagura-movie-logo-icon.svg"
        />
        <label>Movies</label>
      </div>
      <div
        class="app-item movies"
        @click="$router.push({ name: 'live' }).catch(() => {})"
      >
        <img
          class="movie-logo"
          src="@/assets/images/cagura-live-logo-icon.svg"
        />
        <label>Live</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "apps",
  components: {},
  data() {
    return {
      show_app_widget: false,
    };
  },
  mounted() {},
  methods: {
    hideAppWidget() {
      let vm = this;
      vm.show_app_widget = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-apps-widget {
  position: relative;
  .action {
    .btn-apps {
      background: transparent;
      border: none;
      outline: none;
      padding: 0px;
      margin-right: 4px;
      img {
        width: 23px;
        @media screen and (max-width: 340px) {
          width: 18px;
        }
      }
    }
  }
  .apps-widget {
    position: absolute;
    top: 42px;
    right: 9px;
    width: 270px;
    background: #fff;
    border-radius: 5px;
    z-index: 999999;
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap: 10px;
    box-shadow: 0px 0px 5px #d5d5d5;
    padding: 10px;
    .app-item {
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid #aeaeae;
      background: rgb(245, 245, 245);
      &:hover {
      }
      &.movies {
        background: #000;
        border: 1px solid #000;
        label {
          font-size: 12px;
          white-space: nowrap;
          color: #fff;
        }
      }
      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .movie-logo {
        width: 40px;
        height: 40px;
        margin: 5px 0px;
      }
      label {
        font-size: 12px;
        white-space: nowrap;
      }
    }
  }
}
</style>

