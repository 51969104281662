var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cagura-wrapper"},[_c('client-header'),_c('div',{staticClass:"cagura-container"},[_c('side-bar'),_c('div',{staticClass:"cagura-body",class:{ nosidebar: !_vm.$store.state.sidebar }},[_c('div',{staticClass:"cagura-products-widget"},[_vm._m(0),_c('vue-scroll',{on:{"handle-scroll":_vm.handlePostScroll}},[_c('div',{staticClass:"under-scoll-container-widget"},[_c('div',{staticClass:"notification-fit-container"},[_c('div',{staticClass:"notification-widget"},[(
                    _vm.$getLoggedUser() &&
                    !_vm.is_loading &&
                    _vm.notification_list &&
                    _vm.notification_list.data.length > 0
                  )?_c('div',{staticClass:"body"},[_vm._l((_vm.notification_list.data),function(item,i){return [(
                        item.notification_type == 'product_review' &&
                        item.user
                      )?_c('ReviewWidget',{key:i,attrs:{"item":item},on:{"remove":function($event){return _vm.removeNotification(i)}}}):_vm._e(),(
                        item.notification_type == 'product_order' && item.user
                      )?_c('OrderWidget',{key:i,attrs:{"item":item},on:{"remove":function($event){return _vm.removeNotification(i)}}}):_vm._e(),(
                        item.notification_type == 'shop_message' && item.user
                      )?_c('shopMessageWidget',{key:i,attrs:{"item":item},on:{"remove":function($event){return _vm.removeNotification(i)}}}):_vm._e(),(
                        item.notification_type == 'post_contact' && item.user
                      )?_c('postMessageWidget',{key:i,attrs:{"item":item},on:{"remove":function($event){return _vm.removeNotification(i)}}}):_vm._e(),(
                        item.notification_type == 'comment_like' && item.user
                      )?_c('CommentLikeWidget',{key:i,attrs:{"item":item},on:{"remove":function($event){return _vm.removeNotification(i)}}}):_vm._e(),(
                        item.notification_type == 'post_like' && item.user
                      )?_c('PostLikeWidget',{key:i,attrs:{"item":item},on:{"remove":function($event){return _vm.removeNotification(i)}}}):_vm._e(),(
                        item.notification_type == 'post_comment' && item.user
                      )?_c('PostCommentWidget',{key:i,attrs:{"item":item},on:{"remove":function($event){return _vm.removeNotification(i)}}}):_vm._e(),(
                        item.notification_type == 'comment_reply' && item.user
                      )?_c('CommentReplyWidget',{key:i,attrs:{"item":item},on:{"remove":function($event){return _vm.removeNotification(i)}}}):_vm._e(),(
                        item.notification_type == 'affiliate_winner' &&
                        item.user
                      )?_c('AffiliateWinnerWidget',{key:i,attrs:{"item":item},on:{"remove":function($event){return _vm.removeNotification(i)}}}):_vm._e()]})],2):_vm._e(),(
                    _vm.$getLoggedUser() &&
                    !_vm.is_loading &&
                    _vm.notification_list &&
                    _vm.notification_list.data.length == 0
                  )?_c('div',{staticClass:"body"},[_c('div',{staticClass:"not-logged-widget"},[_c('img',{attrs:{"src":require("@/assets/images/icons/notification-not-logged.svg")}}),_c('p',[_vm._v(" You don't have any notification, Start posting or add some products ")])])]):_vm._e(),(_vm.is_loading)?_c('div',{staticClass:"body"},_vm._l((4),function(item,index){return _c('div',{key:index,staticClass:"notification-loading-widget"},[_c('div',{staticClass:"notification-loading-header pulse"}),_c('div',{staticClass:"notification-loading-body pulse"}),_c('div',{staticClass:"notification-loading-footer"},[_c('div',{staticClass:"notification-loading-footer-option pulse"}),_c('div',{staticClass:"notification-loading-footer-option pulse"})])])}),0):_vm._e(),(!_vm.$getLoggedUser() && !_vm.is_loading)?_c('div',{staticClass:"body"},[_c('div',{staticClass:"not-logged-widget"},[_c('img',{attrs:{"src":require("@/assets/images/icons/notification-not-logged.svg")}}),_c('p',[_vm._v(" Please login to see your notification, click on button below to login ")]),_c('button',{staticClass:"btn-default btn-sign-in",on:{"click":function($event){_vm.$router.push({ name: 'login' }).catch(() => {})}}},[_vm._v(" SIGN IN ")])])]):_vm._e()])])])])],1)])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notification-widget-header"},[_c('h3',[_vm._v("NOTIFICATIONS")])])
}]

export { render, staticRenderFns }