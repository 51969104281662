<template>
  <div class="cagura-wrapper">
    <!-- Navbar -->
    <ClientHeader type="shopping"></ClientHeader>
    <!-- Navbar -->
    <div class="cagura-container">
      <!-- Side bar -->
      <side-bar />
      <!-- End of sidebar -->
      <!-- Content - body -->
      <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
        <div class="tags-widget">
          <cagura-tags />
        </div>
        <div class="cagura-products-widget has-tags">
          <vue-scroll>
            <!-- Discount section -->
            <div class="products-widget">
              <div class="products-widget-header" v-if="products_data">
                <div class="call-actions">
                  <button
                    class="btn-back"
                    @click="$router.push({ name: 'home' }).catch(() => {})"
                  >
                    <img src="@/assets/images/icons/arrow-left.svg" />
                    {{ products_data.name }}
                  </button>
                </div>
              </div>
              <div
                class="products-widget-body"
                v-if="products_data && products_data.products.length > 0"
              >
                <div
                  class="product-item"
                  v-for="(item, i) in products_data.products"
                  :key="i"
                  @click="
                    $router
                      .push({ path: $getProductLink(item) })
                      .catch(() => {})
                  "
                >
                  <div
                    class="product-announcement success"
                    v-if="item.discount"
                  >
                    {{ item.discount }}% OFF
                  </div>
                  <div class="product-image">
                    <img
                      :src="
                        $store.state.ASSETS_BASE_URL +
                        '/assets/uploaded/' +
                        item.main_image
                      "
                    />
                  </div>
                  <div class="product-content">
                    <h4>{{ item.product_name }}</h4>
                    <p>
                      FRW
                      {{
                        item.discount
                          ? $formatMoney(
                              item.price - (item.price * item.discount) / 100
                            )
                          : $formatMoney(item.price)
                      }}
                    </p>
                    <div
                      class="shop-viewer"
                      v-if="item.shop"
                      @click="$router.push({ path: $getShopLink(item.shop) })"
                    >
                      <img
                        class="shop-image"
                        :src="
                          $store.state.ASSETS_BASE_URL +
                          '/assets/uploaded/' +
                          item.shop.shop_logo
                        "
                      />
                      <div class="shop-content">
                        <label class="shop-name">{{
                          item.shop.shop_name
                        }}</label>
                        <span class="shop-location">{{
                          item.shop.location_name
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="product-loading-widget" v-if="loading">
                <div
                  class="product-loading-item pulse"
                  v-for="(item, index) in 12"
                  :key="index"
                >
                  <div class="product-loading-image"></div>
                  <div class="product-loading-content">
                    <div class="product-loading-title"></div>
                    <div class="product-loading-price"></div>
                  </div>
                  <div class="product-loading-shop"></div>
                </div>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
      <!-- End of content -->
    </div>
  </div>
</template>

<script>
import ClientHeader from "../components/partials/header.vue";
import SideBar from "../components/partials/sidebar.vue";
import CaguraTags from "../components/partials/tags.vue";
export default {
  name: "discount",
  components: {
    ClientHeader,
    SideBar,
    CaguraTags,
  },
  data() {
    return {
      products_data: null,
      loading: false,
    };
  },
  mounted() {
    let vm = this;
    vm.getProducts();
  },
  methods: {
    getProducts() {
      let vm = this;
      vm.loading = true;
      if (vm.$route.params.id) {
        vm.$store
          .dispatch("PRODUCT_GET_CATEGORY_PRODUCTS", {
            id: vm.$route.params.id,
          })
          .then((response) => {
            vm.loading = false;
            if (response.data.status == "ok") {
              vm.products_data = response.data.return;
            }
          })
          .catch(({ response }) => {
            vm.loading = false;
            console.error("PRODUCT_GET_DISCOUNT_PRODUCTS", response);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
  }
}
</style>

