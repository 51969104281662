<template>
  <div class="cagura-wrapper">
    <!-- Navbar -->
    <client-header />
    <!-- Navbar -->
    <div class="cagura-container">
      <!-- Side bar -->
      <side-bar />
      <!-- End of sidebar -->
      <!-- Content - body -->
      <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
        <div class="cagura-products-widget">
          <div class="notification-widget-header">
            <h3>NOTIFICATIONS</h3>
          </div>
          <vue-scroll @handle-scroll="handlePostScroll">
            <div class="under-scoll-container-widget">
              <div class="notification-fit-container">
                <div class="notification-widget">
                  <!-- notification list -->
                  <div
                    class="body"
                    v-if="
                      $getLoggedUser() &&
                      !is_loading &&
                      notification_list &&
                      notification_list.data.length > 0
                    "
                  >
                    <template v-for="(item, i) in notification_list.data">
                      <ReviewWidget
                        :key="i"
                        :item="item"
                        v-if="
                          item.notification_type == 'product_review' &&
                          item.user
                        "
                        @remove="removeNotification(i)"
                      ></ReviewWidget>
                      <OrderWidget
                        :key="i"
                        :item="item"
                        v-if="
                          item.notification_type == 'product_order' && item.user
                        "
                        @remove="removeNotification(i)"
                      ></OrderWidget>
                      <shopMessageWidget
                        :key="i"
                        :item="item"
                        v-if="
                          item.notification_type == 'shop_message' && item.user
                        "
                        @remove="removeNotification(i)"
                      ></shopMessageWidget>
                      <postMessageWidget
                        :key="i"
                        :item="item"
                        v-if="
                          item.notification_type == 'post_contact' && item.user
                        "
                        @remove="removeNotification(i)"
                      ></postMessageWidget>
                      <CommentLikeWidget
                        :key="i"
                        :item="item"
                        v-if="
                          item.notification_type == 'comment_like' && item.user
                        "
                        @remove="removeNotification(i)"
                      ></CommentLikeWidget>
                      <PostLikeWidget
                        :key="i"
                        :item="item"
                        v-if="
                          item.notification_type == 'post_like' && item.user
                        "
                        @remove="removeNotification(i)"
                      ></PostLikeWidget>
                      <PostCommentWidget
                        :key="i"
                        :item="item"
                        v-if="
                          item.notification_type == 'post_comment' && item.user
                        "
                        @remove="removeNotification(i)"
                      ></PostCommentWidget>
                      <CommentReplyWidget
                        :key="i"
                        :item="item"
                        v-if="
                          item.notification_type == 'comment_reply' && item.user
                        "
                        @remove="removeNotification(i)"
                      ></CommentReplyWidget>
                      <AffiliateWinnerWidget
                        :key="i"
                        :item="item"
                        v-if="
                          item.notification_type == 'affiliate_winner' &&
                          item.user
                        "
                        @remove="removeNotification(i)"
                      ></AffiliateWinnerWidget>
                    </template>
                  </div>
                  <!-- When no notification -->

                  <div
                    class="body"
                    v-if="
                      $getLoggedUser() &&
                      !is_loading &&
                      notification_list &&
                      notification_list.data.length == 0
                    "
                  >
                    <div class="not-logged-widget">
                      <img
                        src="@/assets/images/icons/notification-not-logged.svg"
                      />
                      <p>
                        You don't have any notification, Start posting or add
                        some products
                      </p>
                    </div>
                  </div>
                  <!-- Loading widget -->
                  <div class="body" v-if="is_loading">
                    <div
                      class="notification-loading-widget"
                      v-for="(item, index) in 4"
                      :key="index"
                    >
                      <div class="notification-loading-header pulse"></div>
                      <div class="notification-loading-body pulse"></div>
                      <div class="notification-loading-footer">
                        <div
                          class="notification-loading-footer-option pulse"
                        ></div>
                        <div
                          class="notification-loading-footer-option pulse"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <!-- When you are not logged in -->
                  <div class="body" v-if="!$getLoggedUser() && !is_loading">
                    <div class="not-logged-widget">
                      <img
                        src="@/assets/images/icons/notification-not-logged.svg"
                      />
                      <p>
                        Please login to see your notification, click on button
                        below to login
                      </p>
                      <button
                        class="btn-default btn-sign-in"
                        @click="$router.push({ name: 'login' }).catch(() => {})"
                      >
                        SIGN IN
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
      <!-- End of content -->
    </div>
  </div>
</template>

<script>
import ClientHeader from "../components/partials/header.vue";
import SideBar from "../components/partials/sidebar.vue";
import ReviewWidget from "../views/notification/ReviewWidget.vue";
import OrderWidget from "../views/notification/OrderWidget.vue";
import shopMessageWidget from "../views/notification/shopMessageWidget.vue";
import postMessageWidget from "./notification/postMessageWidget.vue";
import CommentLikeWidget from "../views/notification/CommentLikeWidget.vue";
import PostLikeWidget from "../views/notification/PostLikeWidget.vue";
import PostCommentWidget from "../views/notification/PostCommentWidget.vue";
import CommentReplyWidget from "../views/notification/CommentReplyWidget.vue";
import AffiliateWinnerWidget from "../views/notification/AffiliateWinnerWidget.vue";
export default {
  name: "home",
  components: {
    ClientHeader,
    SideBar,
    ReviewWidget,
    OrderWidget,
    shopMessageWidget,
    postMessageWidget,
    CommentLikeWidget,
    PostLikeWidget,
    PostCommentWidget,
    AffiliateWinnerWidget,
    CommentReplyWidget,
  },
  data() {
    return {
      notification_list: null,
      is_unreaded_message: false,
      is_loading: false,
    };
  },
  head: {
    title: function () {
      return {
        inner: "Cagura - Notification",
      };
    },
  },
  mounted() {
    let vm = this;
    vm.$emit("updateHead");
    if (vm.$getLoggedUser()) {
      vm.getAllNotification(1);
    }
  },
  methods: {
    removeNotification(index) {
      let vm = this;
      vm.notification_list.data.splice(index, 1);
    },
    handlePostScroll(vertical, horizontal, nativeEvent) {
      let vm = this;
      if (
        nativeEvent.target.scrollTop + 0 >
        nativeEvent.target.scrollHeight - nativeEvent.target.offsetHeight
      ) {
        if (
          vm.notification_list.current_page < vm.notification_list.total_page
        ) {
          if (!vm.is_loading) {
            vm.getAllNotification(vm.notification_list.next_page);
          }
        }
      }
    },
    getAllNotification(page) {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("NOTIFICATION_GET_ALL", {
          page: page,
        })
        .then((response) => {
          vm.is_loading = false;
          if (page == 1) {
            vm.notification_list = response.data.return;
            vm.notification_list.data = response.data.return.data.map(
              (item) => {
                if (item.post && item.post.file_type == "image") {
                  vm.$store.state.eventBus.$set(
                    item.post,
                    "images",
                    JSON.parse(item.post.files)
                  );
                }
                if (item.product) {
                  vm.$store.state.eventBus.$set(
                    item.product,
                    "images",
                    JSON.parse(item.product.other_images)
                  );
                }

                return item;
              }
            );
          } else {
            vm.notification_list.current_page =
              response.data.return.current_page;
            vm.notification_list.end_item = response.data.return.end_item;
            vm.notification_list.item_per_page =
              response.data.return.item_per_page;
            vm.notification_list.next_page = response.data.return.next_page;
            vm.notification_list.previews_page =
              response.data.return.previews_page;
            vm.notification_list.start_item = response.data.return.start_item;
            vm.notification_list.total_items = response.data.return.total_items;
            vm.notification_list.total_page = response.data.return.total_page;
            response.data.return.data.forEach((item) => {
              if (item.post && item.post.file_type == "image") {
                vm.$store.state.eventBus.$set(
                  item.post,
                  "images",
                  JSON.parse(item.post.files)
                );
              }
              if (item.product) {
                vm.$store.state.eventBus.$set(
                  item.product,
                  "images",
                  JSON.parse(item.product.other_images)
                );
              }

              vm.notification_list.data.push(item);
            });
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("NOTIFICATION_GET_ALL", { response });
        });
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
    display: block;
    .cagura-products-widget {
      .notification-widget-header {
        border-bottom: 1px solid #ddd;
        background: #fff;
        height: 50px;
        display: flex;
        align-items: center;
        h3 {
          margin: 0px;
          padding-left: 10px;
        }
      }
      .notification-widget {
        padding-bottom: 50px;
        padding-top: 10px;
        @media screen and (max-width: 800px) {
          padding-left: 10px;
          padding-right: 10px;
        }
        .body {
          .not-logged-widget {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img {
              width: 300px;
              margin-bottom: 30px;
            }
            p {
              text-align: center;
            }
            .btn-sign-in {
              margin-top: 30px;
            }
          }
          .notification-loading-widget {
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 5px;
            padding: 15px;
            margin-bottom: 20px;
            .notification-loading-header {
              background: rgb(240, 240, 240);
              height: 25px;
              border-radius: 3px;
              margin-bottom: 20px;
            }
            .notification-loading-body {
              background: rgb(240, 240, 240);
              height: 180px;
              border-radius: 3px;
              margin-bottom: 20px;
            }
            .notification-loading-footer {
              display: grid;
              grid-template-columns: 48% 48%;
              grid-gap: 20px;
              .notification-loading-footer-option {
                background: rgb(240, 240, 240);
                height: 25px;
                border-radius: 3px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

