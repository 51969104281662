export const FEEDS_SAVE_POST = "FEEDS_SAVE_POST";
export const FEEDS_GET_POST = "FEEDS_GET_POST";
export const FEEDS_DELETE_POST = "FEEDS_DELETE_POST";
export const FEEDS_SUBMIT_POST_COMMENT = "FEEDS_SUBMIT_POST_COMMENT";
export const FEEDS_GET_POST_COMMENTS = "FEEDS_GET_POST_COMMENTS";
export const FEEDS_GET_POST_COMMENT_REPLIES = "FEEDS_GET_POST_COMMENT_REPLIES";
export const FEEDS_DELETE_POST_COMMENT = "FEEDS_DELETE_POST_COMMENT";
export const FEEDS_LIKE_POST = "FEEDS_LIKE_POST";
export const FEEDS_LIKE_POST_COMMENT = "FEEDS_LIKE_POST_COMMENT";
export const FEEDS_UPDATE_POST = "FEEDS_UPDATE_POST";
export const FEEDS_REPORT_POST = "FEEDS_REPORT_POST";
export const FEEDS_CONTACT_POST_OWNER = "FEEDS_CONTACT_POST_OWNER";
export const FEEDS_GET_SINGLE_POST = "FEEDS_GET_SINGLE_POST";
export const FEEDS_GET_ALL_STORIES = "FEEDS_GET_ALL_STORIES";
export const FEEDS_ALL_POPULAR_PRODUCTS = "FEEDS_ALL_POPULAR_PRODUCTS";
export const FEEDS_GET_POPULAR_SHOPS = "FEEDS_GET_POPULAR_SHOPS";
export const FEEDS_VALIDATE_POST = "FEEDS_VALIDATE_POST";