import {
    FEEDS_SAVE_POST,
    FEEDS_GET_POST,
    FEEDS_DELETE_POST,
    FEEDS_SUBMIT_POST_COMMENT,
    FEEDS_GET_POST_COMMENTS,
    FEEDS_GET_POST_COMMENT_REPLIES,
    FEEDS_DELETE_POST_COMMENT,
    FEEDS_LIKE_POST,
    FEEDS_LIKE_POST_COMMENT,
    FEEDS_UPDATE_POST,
    FEEDS_REPORT_POST,
    FEEDS_CONTACT_POST_OWNER,
    FEEDS_GET_SINGLE_POST,
    FEEDS_GET_ALL_STORIES,
    FEEDS_ALL_POPULAR_PRODUCTS,
    FEEDS_GET_POPULAR_SHOPS,
    FEEDS_VALIDATE_POST
} from "../actions/feeds";

import {
    API_BASE_URL,
    FEEDS_SAVE_POST_URL,
    FEEDS_GET_POST_URL,
    FEEDS_DELETE_POST_URL,
    FEEDS_SUBMIT_POST_COMMENT_URL,
    FEEDS_GET_POST_COMMENTS_URL,
    FEEDS_GET_POST_COMMENT_REPLIES_URL,
    FEEDS_DELETE_POST_COMMENT_URL,
    FEEDS_LIKE_POST_URL,
    FEEDS_LIKE_POST_COMMENT_URL,
    FEEDS_UPDATE_POST_URL,
    FEEDS_REPORT_POST_URL,
    FEEDS_CONTACT_POST_OWNER_URL,
    FEEDS_GET_SINGLE_POST_URL,
    FEEDS_GET_ALL_STORIES_URL,
    FEEDS_ALL_POPULAR_PRODUCTS_URL,
    FEEDS_GET_POPULAR_SHOPS_URL,
    FEEDS_VALIDATE_POST_URL
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [FEEDS_VALIDATE_POST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_VALIDATE_POST_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [FEEDS_GET_POPULAR_SHOPS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_GET_POPULAR_SHOPS_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [FEEDS_ALL_POPULAR_PRODUCTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_ALL_POPULAR_PRODUCTS_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [FEEDS_GET_ALL_STORIES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_GET_ALL_STORIES_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [FEEDS_GET_SINGLE_POST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_GET_SINGLE_POST_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [FEEDS_CONTACT_POST_OWNER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_CONTACT_POST_OWNER_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [FEEDS_REPORT_POST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_REPORT_POST_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [FEEDS_UPDATE_POST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_UPDATE_POST_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [FEEDS_LIKE_POST_COMMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_LIKE_POST_COMMENT_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [FEEDS_LIKE_POST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_LIKE_POST_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [FEEDS_DELETE_POST_COMMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_DELETE_POST_COMMENT_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [FEEDS_GET_POST_COMMENT_REPLIES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_GET_POST_COMMENT_REPLIES_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [FEEDS_GET_POST_COMMENTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_GET_POST_COMMENTS_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [FEEDS_SUBMIT_POST_COMMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_SUBMIT_POST_COMMENT_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [FEEDS_DELETE_POST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_DELETE_POST_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [FEEDS_GET_POST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_GET_POST_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [FEEDS_SAVE_POST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + FEEDS_SAVE_POST_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
};


export default {
    mutations,
    actions,
    getters,
    state
};