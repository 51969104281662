var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cagura-wrapper"},[_c('client-header'),_c('div',{staticClass:"cagura-container"},[_c('side-bar'),_c('div',{staticClass:"cagura-body",class:{ nosidebar: !_vm.$store.state.sidebar }},[_c('div',{staticClass:"cagura-products-widget"},[_c('div',{staticClass:"notification-widget-header"},[_c('button',{staticClass:"btn-back",on:{"click":function($event){_vm.$router.push({ name: 'notification' }).catch(() => {})}}},[_c('img',{attrs:{"src":require("@/assets/images/icons/arrow-left.svg")}})]),_c('h3',[_vm._v("NOTIFICATION")])]),_c('vue-scroll',[_c('div',{staticClass:"under-scoll-container-widget"},[_c('div',{staticClass:"notification-fit-container"},[_c('div',{staticClass:"notification-widget"},[(_vm.$getLoggedUser() && !_vm.is_loading && _vm.notification_item)?_c('div',{staticClass:"body"},[(
                      _vm.notification_item.notification_type ==
                        'product_review' && _vm.notification_item.user
                    )?_c('ReviewWidget',{attrs:{"item":_vm.notification_item},on:{"remove":function($event){return _vm.removeNotification()}}}):_vm._e(),(
                      _vm.notification_item.notification_type ==
                        'product_order' && _vm.notification_item.user
                    )?_c('OrderWidget',{attrs:{"item":_vm.notification_item},on:{"remove":function($event){return _vm.removeNotification()}}}):_vm._e(),(
                      _vm.notification_item.notification_type == 'shop_message' &&
                      _vm.notification_item.user
                    )?_c('shopMessageWidget',{attrs:{"item":_vm.notification_item},on:{"remove":function($event){return _vm.removeNotification()}}}):_vm._e(),(
                      _vm.notification_item.notification_type == 'post_contact' &&
                      _vm.notification_item.user
                    )?_c('postMessageWidget',{attrs:{"item":_vm.notification_item},on:{"remove":function($event){return _vm.removeNotification()}}}):_vm._e(),(
                      _vm.notification_item.notification_type == 'comment_like' &&
                      _vm.notification_item.user
                    )?_c('CommentLikeWidget',{attrs:{"item":_vm.notification_item},on:{"remove":function($event){return _vm.removeNotification()}}}):_vm._e(),(
                      _vm.notification_item.notification_type == 'post_like' &&
                      _vm.notification_item.user
                    )?_c('PostLikeWidget',{attrs:{"item":_vm.notification_item},on:{"remove":function($event){return _vm.removeNotification()}}}):_vm._e(),(
                      _vm.notification_item.notification_type == 'post_comment' &&
                      _vm.notification_item.user
                    )?_c('PostCommentWidget',{attrs:{"item":_vm.notification_item},on:{"remove":function($event){return _vm.removeNotification()}}}):_vm._e(),(
                      _vm.notification_item.notification_type ==
                        'comment_reply' && _vm.notification_item.user
                    )?_c('CommentReplyWidget',{attrs:{"item":_vm.notification_item},on:{"remove":function($event){return _vm.removeNotification()}}}):_vm._e(),(
                      _vm.notification_item.notification_type ==
                        'affiliate_winner' && _vm.notification_item.user
                    )?_c('AffiliateWinnerWidget',{attrs:{"item":_vm.notification_item},on:{"remove":function($event){return _vm.removeNotification()}}}):_vm._e()],1):_vm._e(),(_vm.$getLoggedUser() && !_vm.is_loading && !_vm.notification_item)?_c('div',{staticClass:"body"},[_c('div',{staticClass:"not-logged-widget"},[_c('img',{attrs:{"src":require("@/assets/images/icons/notification-not-logged.svg")}}),_c('p',[_vm._v("Notification not found")])])]):_vm._e(),(_vm.is_loading)?_c('div',{staticClass:"body"},[_c('div',{staticClass:"notification-loading-widget"},[_c('div',{staticClass:"notification-loading-header pulse"}),_c('div',{staticClass:"notification-loading-body pulse"}),_c('div',{staticClass:"notification-loading-footer"},[_c('div',{staticClass:"notification-loading-footer-option pulse"}),_c('div',{staticClass:"notification-loading-footer-option pulse"})])])]):_vm._e(),(!_vm.$getLoggedUser() && !_vm.is_loading)?_c('div',{staticClass:"body"},[_c('div',{staticClass:"not-logged-widget"},[_c('img',{attrs:{"src":require("@/assets/images/icons/notification-not-logged.svg")}}),_c('p',[_vm._v(" Please login to see your notification, click on button below to login ")]),_c('button',{staticClass:"btn-default btn-sign-in",on:{"click":function($event){_vm.$router.push({ name: 'login' }).catch(() => {})}}},[_vm._v(" SIGN IN ")])])]):_vm._e()])])])])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }