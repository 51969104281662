<script>
const SECONDS_PER_DAY = 86400;
const HOURS_PER_DAY = 24;
import { hasDeepValue, has } from "has-deep-value";
import moment from "moment";
import CryptoJS from "crypto-js";
const queryString = require("query-string");
import SHA256 from "crypto-js/sha256";

export default {
  mounted() {
    Array.prototype.shuffle = function () {
      var currentIndex = this.length,
        temporaryValue,
        randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = this[currentIndex];
        this[currentIndex] = this[randomIndex];
        this[randomIndex] = temporaryValue;
      }
      return this;
    };
  },
  methods: {
    moment,
    hasDeepValue,
    $getShopLink(item) {
      let vm = this;
      let shop_name = item.shop_name
        .toLowerCase()
        .replace(/\s/g, "_")
        .replace(/\//g, "_")
        .replace(/\-/g, "_");
      let shop_id = vm.$encodeProductId(item.id);
      let shop_link = "/shop/view/" + shop_id + "/" + shop_name;
      return shop_link;
    },
    $getProductLink(item) {
      let vm = this;
      let product_name = item.product_name
        .toLowerCase()
        .replace(/\s/g, "_")
        .replace(/\//g, "_")
        .replace(/\-/g, "_");
      let product_id = vm.$encodeProductId(item.id);
      let product_link = "/product/view/" + product_id + "/" + product_name;
      return product_link;
    },
    $encodeProductId(id) {
      // let encode = id + 2020 * 2020;
      // encode = encode
      //   .toString()
      //   .replace(/(.{1})/g, "$1c")
      //   .replace(/(.{1})/g, "$1a");
      return id;
    },
    $decodeProductId(encode) {
      // let decode = encode.toString().split("a").join("");
      // decode = decode.split("c").join("");
      // decode = parseInt(decode) - 2020 * 2020;
      return encode;
    },
    $formatMoney(amount, decimalCount = 0, decimal = ".", thousands = ",") {
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(
          (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
          negativeSign +
          (j ? i.substr(0, j) + thousands : "") +
          i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
          (decimalCount
            ? decimal +
              Math.abs(amount - i)
                .toFixed(decimalCount)
                .slice(2)
            : "")
        );
      } catch (e) {
        console.log(e);
      }
    },
    $cutText(str, maxLen, separator = " ") {
      if (str.length <= maxLen) return str;
      return str.substr(0, str.lastIndexOf(separator, maxLen)) + " ...";
    },
    $fetchFromObject(obj, prop) {
      let vm = this;
      if (typeof obj === "undefined") return false;
      var _index = prop.indexOf(".");
      if (_index > -1) {
        return vm.$fetchFromObject(
          obj[prop.substring(0, _index)],
          prop.substr(_index + 1)
        );
      }
      if (obj[prop] && obj[prop] != "null" && obj[prop] != "") {
        return true;
      } else {
        return false;
      }
    },
    $hasKey(obj, keyString) {
      let vm = this;
      if (vm.hasDeepValue(obj, keyString)) {
        if (vm.$fetchFromObject(obj, keyString)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    $isRouter(name) {
      if (this.$route.name === name) {
        return true;
      } else {
        return false;
      }
    },
    $getUserAccessToken() {
      let vm = this;
      if (vm.$localStorage.get("user_data")) {
        let user_data = JSON.parse(vm.$localStorage.get("user_data"));
        return user_data.access_token;
      } else {
        return "";
      }
    },
    $getLoggedUser() {
      let vm = this;
      if (vm.$localStorage.get("user_data")) {
        let user_data = JSON.parse(vm.$localStorage.get("user_data"));
        return user_data;
      } else {
        return false;
      }
    },
    $updateLoggedUser(user_data) {
      let vm = this;
      if (vm.$localStorage.get("user_data")) {
        let new_user_data = JSON.parse(vm.$localStorage.get("user_data"));
        vm.$store.state.eventBus.$set(new_user_data, "user", user_data);
        vm.$localStorage.set("user_data", JSON.stringify(new_user_data));
        return new_user_data;
      } else {
        return false;
      }
    },
    $saveLoginInfo(data) {
      let vm = this;
      vm.$localStorage.set("user_data", JSON.stringify(data));
      vm.$store.commit("SET_TOKEN", data.access_token);
    },
    $setRedirect(path) {
      let vm = this;
      vm.$localStorage.set("redirect_path", path);
    },
    $appRedirect() {
      let vm = this;
      if (vm.$localStorage.get("redirect_path")) {
        vm.$router.push({ path: vm.$localStorage.get("redirect_path") });
        vm.$localStorage.remove("redirect_path");
      } else {
        vm.$router.push({ name: "home" });
      }
    },
    $isFieldsValidated(form, rules, length = 10000) {
      let vm = this;
      let is_error = false;
      Object.keys(rules).forEach((key, index) => {
        if (index < length - 1) {
          if (rules[key].run(form[key])) {
            let error_message = rules[key].run(form[key]);
            if (error_message) {
              if (!is_error) {
                vm.$notify({
                  group: "status",
                  type: "warn",
                  title: "OOPS!!!",
                  text: error_message,
                });
              }
              is_error = true;
            }
          }
        }
      });
      return !is_error;
    },
    token(length = 1) {
      let token = "";
      for (let i = 0; i < length; i++) {
        token += Math.random().toString(36).substr(2);
      }
      return Date.now() + token;
    },
    secondsToHms(seconds) {
      const days = Math.floor(seconds / SECONDS_PER_DAY);
      const remainderSeconds = seconds % SECONDS_PER_DAY;
      const hms = new Date(remainderSeconds * 1000)
        .toISOString()
        .substring(11, 19);
      return hms.replace(/^(\d+)/, (h) =>
        `${Number(h) + days * HOURS_PER_DAY}`.padStart(2, "0")
      );
    },
    Encryption() {
      class Encryption {
        /**
         * @var integer Return encrypt method or Cipher method number. (128, 192, 256)
         */
        get encryptMethodLength() {
          var encryptMethod = this.encryptMethod;
          // get only number from string.
          // @link https://stackoverflow.com/a/10003709/128761 Reference.
          var aesNumber = encryptMethod.match(/\d+/)[0];
          return parseInt(aesNumber);
        } // encryptMethodLength

        /**
         * @var integer Return cipher method divide by 8. example: AES number 256 will be 256/8 = 32.
         */
        get encryptKeySize() {
          var aesNumber = this.encryptMethodLength;
          return parseInt(aesNumber / 8);
        } // encryptKeySize

        /**
         * @link http://php.net/manual/en/function.openssl-get-cipher-methods.php Refer to available methods in PHP if we are working between JS & PHP encryption.
         * @var string Cipher method.
         *              Recommended AES-128-CBC, AES-192-CBC, AES-256-CBC
         *              due to there is no `openssl_cipher_iv_length()` function in JavaScript
         *              and all of these methods are known as 16 in iv_length.
         */
        get encryptMethod() {
          return "AES-256-CBC";
        } // encryptMethod

        /**
         * Decrypt string.
         *
         * @link https://stackoverflow.com/questions/41222162/encrypt-in-php-openssl-and-decrypt-in-javascript-cryptojs Reference.
         * @link https://stackoverflow.com/questions/25492179/decode-a-base64-string-using-cryptojs Crypto JS base64 encode/decode reference.
         * @param string encryptedString The encrypted string to be decrypt.
         * @param string key The key.
         * @return string Return decrypted string.
         */
        decrypt(encryptedString, key) {
          var json = JSON.parse(
            CryptoJS.enc.Utf8.stringify(
              CryptoJS.enc.Base64.parse(encryptedString)
            )
          );

          var salt = CryptoJS.enc.Hex.parse(json.salt);
          var iv = CryptoJS.enc.Hex.parse(json.iv);

          var encrypted = json.ciphertext; // no need to base64 decode.

          var iterations = parseInt(json.iterations);
          if (iterations <= 0) {
            iterations = 999;
          }
          var encryptMethodLength = this.encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
          var hashKey = CryptoJS.PBKDF2(key, salt, {
            hasher: CryptoJS.algo.SHA512,
            keySize: encryptMethodLength / 8,
            iterations: iterations,
          });

          var decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
            mode: CryptoJS.mode.CBC,
            iv: iv,
          });

          return decrypted.toString(CryptoJS.enc.Utf8);
        } // decrypt

        /**
         * Encrypt string.
         *
         * @link https://stackoverflow.com/questions/41222162/encrypt-in-php-openssl-and-decrypt-in-javascript-cryptojs Reference.
         * @link https://stackoverflow.com/questions/25492179/decode-a-base64-string-using-cryptojs Crypto JS base64 encode/decode reference.
         * @param string string The original string to be encrypt.
         * @param string key The key.
         * @return string Return encrypted string.
         */
        encrypt(string, key) {
          var iv = CryptoJS.lib.WordArray.random(16); // the reason to be 16, please read on `encryptMethod` property.

          var salt = CryptoJS.lib.WordArray.random(256);
          var iterations = 999;
          var encryptMethodLength = this.encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
          var hashKey = CryptoJS.PBKDF2(key, salt, {
            hasher: CryptoJS.algo.SHA512,
            keySize: encryptMethodLength / 8,
            iterations: iterations,
          });

          var encrypted = CryptoJS.AES.encrypt(string, hashKey, {
            mode: CryptoJS.mode.CBC,
            iv: iv,
          });
          var encryptedString = CryptoJS.enc.Base64.stringify(
            encrypted.ciphertext
          );

          var output = {
            ciphertext: encryptedString,
            iv: CryptoJS.enc.Hex.stringify(iv),
            salt: CryptoJS.enc.Hex.stringify(salt),
            iterations: iterations,
          };

          return CryptoJS.enc.Base64.stringify(
            CryptoJS.enc.Utf8.parse(JSON.stringify(output))
          );
        } // encrypt
      }

      return new Encryption();
    },
    addCountries(url, a, b) {
      var tempUrl = url;
      if (a != null) {
        var tempUrlOne = new URL(tempUrl);
        tempUrl +=
          (tempUrlOne.search == "" ? "?" : "&") + "token_countries=" + a;
      }
      if (b != null) {
        var tempUrlTwo = new URL(tempUrl);
        tempUrl +=
          (tempUrlTwo.search == "" ? "?" : "&") +
          "token_countries_blocked=" +
          b;
      }
      return tempUrl;
    },
    BunnCDNSignUrl(
      url,
      securityKey,
      expirationTime = 3600,
      userIp,
      isDirectory = false,
      pathAllowed,
      countriesAllowed,
      countriesBlocked
    ) {
      var parameterData = "",
        parameterDataUrl = "",
        signaturePath = "",
        hashableBase = "",
        token = "";
      var expires = Math.floor(new Date() / 1000) + expirationTime;
      var url = this.addCountries(url, countriesAllowed, countriesBlocked);
      var parsedUrl = new URL(url);
      var parameters = new URL(url).searchParams;
      if (pathAllowed != null) {
        signaturePath = pathAllowed;
        parameters.set("token_path", signaturePath);
      } else {
        signaturePath = parsedUrl.pathname;
      }
      parameters.sort();
      if (Array.from(parameters).length > 0) {
        parameters.forEach(function (value, key) {
          if (parameterData.length > 0) {
            parameterData += "&";
          }
          parameterData += key + "=" + value;
          parameterDataUrl += "&" + key + "=" + queryString.stringify(value);
        });
      }
      hashableBase =
        securityKey +
        signaturePath +
        expires +
        (userIp != null ? userIp : "") +
        parameterData;
      token = SHA256(hashableBase).toString(CryptoJS.enc.Base64);
      token = token
        .replace(/\n/g, "")
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "");
      if (isDirectory) {
        return (
          parsedUrl.protocol +
          "//" +
          parsedUrl.host +
          "/bcdn_token=" +
          token +
          parameterDataUrl +
          "&expires=" +
          expires +
          parsedUrl.pathname
        );
      } else {
        return (
          parsedUrl.protocol +
          "//" +
          parsedUrl.host +
          parsedUrl.pathname +
          "?token=" +
          token +
          parameterDataUrl +
          "&expires=" +
          expires
        );
      }
    },
    serialize(obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    },
    getUrlPath(url) {
      var el = document.createElement("a");
      el.href = url;
      return el.pathname;
    },
    getFileName(url) {
      return url.split("/").pop();
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim();
    },
    processResponce(responce) {
      let vm = this;
      let encryption = vm.Encryption();
      var decrypted = encryption.decrypt(
        responce,
        "C*gtbU(|SnTgjf}#9b))GT.]5Bmfg)Y0YDwkTm.uYAHn{Fi78A6m@fcjfB/|YzF"
      );
      return decrypted;
    },
    getCurrentBrowser() {
      var browser = (function () {
        var test = function (regexp) {
          return regexp.test(window.navigator.userAgent);
        };
        switch (true) {
          case test(/edg/i):
            return "Microsoft Edge";
          case test(/trident/i):
            return "Microsoft Internet Explorer";
          case test(/firefox|fxios/i):
            return "Mozilla Firefox";
          case test(/opr\//i):
            return "Opera";
          case test(/ucbrowser/i):
            return "UC Browser";
          case test(/samsungbrowser/i):
            return "Samsung Browser";
          case test(/chrome|chromium|crios/i):
            return "Google Chrome";
          case test(/safari/i):
            return "Apple Safari";
          default:
            return "Other";
        }
      })();
      return browser;
    },
  },
};
</script>
