<template>
  <div class="cagura-wrapper">
    <div class="body">
      <div class="login-wrapper">
        <div class="login-section">
          <div class="logo-section">
            <img
              src="@/assets/images/logo.png"
              alt="Cagura Logo"
              @click.prevent="$router.push({ name: 'home' }).catch(() => {})"
              class="cagura-logo"
            />
          </div>
          <div class="login-header">
            <h2>Hello there, Sign into your account</h2>
          </div>
          <div class="form-widget">
            <div class="input-group">
              <div class="input-widget">
                <input
                  type="text"
                  class="bg-cagura-form-control"
                  name="email"
                  placeholder="Phone or Email"
                  v-model="form.email_phone"
                />
              </div>
            </div>
            <div class="input-group">
              <div class="">
                <VuePassword
                  v-model="form.password"
                  placeholder="Password"
                  class="password-input"
                  :disableStrength="true"
                  type="password"
                  @keypress.enter="onLogin()"
                />
              </div>
            </div>
            <div class="form-option">
              <c-button class="login-btn" :loading="loading" @click="onLogin()"
                >LOG IN</c-button
              >
              <a
                href
                class="forgot-link"
                @click.prevent="
                  $router.push({ name: 'account-verify' }).catch(() => {})
                "
                >Forgot Password ?</a
              >
            </div>
            <div class="create-account-section">
              <p>
                New User:
                <a
                  @click.prevent="
                    $router.push({ name: 'register' }).catch(() => {})
                  "
                  >Create Account</a
                >
              </p>
            </div>

            <div class="other-login-laber">Or Sign in with:</div>
            <div class="other-login-option">
              <div class="option" @click="loginWithSocialMedia('google')">
                <img src="@/assets/images/google.svg" class="logo" alt />
                <span class="name">GOOGLE</span>
              </div>
              <div class="option" @click="loginWithSocialMedia('facebook')">
                <img src="@/assets/images/facebook.svg" class="logo" alt />
                <span class="name">FACEBOOK</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import VuePassword from "vue-password";
export default {
  name: "cagura-login",
  components: {
    VuePassword,
  },
  data() {
    return {
      loading: false,
      form: {
        email_phone: "",
        password: "",
      },
    };
  },
  mounted() {
    let vm = this;
    if (vm.$route.query.app) {
      vm.$localStorage.set("redirection_app", vm.$route.query.app);
    } else {
      if (vm.$getLoggedUser()) {
        vm.$router.push({ name: "home" }).catch(() => {});
      }
    }
  },
  methods: {
    loginWithSocialMedia(type) {
      let vm = this;
      vm.loading = true;
      if (type == "google") {
        vm.$store
          .dispatch("ACCOUNT_GOOGLE_AUTH", { type: "login" })
          .then((response) => {
            vm.loading = false;
            let url = response.data.url;
            window.location.href = url;
          })
          .catch(({ response }) => {
            vm.loading = false;
            console.log("ACCOUNT_GOOGLE_AUTH", { error });
          });
      } else if (type == "facebook") {
        vm.$store
          .dispatch("ACCOUNT_FACEBOOK_AUTH", { type: "login" })
          .then((response) => {
            vm.loading = false;
            let url = response.data.url;
            window.location.href = url;
          })
          .catch(({ response }) => {
            vm.loading = false;
            console.log("ACCOUNT_FACEBOOK_AUTH", response);
          });
      }
    },
    getLocation(href) {
      var l = document.createElement("a");
      l.href = href;
      return l;
    },
    onLogin() {
      let vm = this;
      vm.loading = true;
      vm.$store
        .dispatch("ACCOUNT_LOGIN", vm.form)
        .then((response) => {
          vm.loading = false;
          if (vm.$localStorage.get("redirection_app")) {
            vm.accountCallBackUrl(
              vm.$localStorage.get("redirection_app"),
              response.data.access_token
            );
          } else {
            let user = {
              access_token: response.data.access_token,
              user: response.data.user,
            };
            vm.$saveLoginInfo(user);
            vm.$appRedirect();
          }
        })
        .catch(({ response }) => {
          vm.loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
          console.log("ACCOUNT_LOGIN", response);
        });
    },
    accountCallBackUrl(app, access_token) {
      let vm = this;
      vm.loading = true;
      vm.$store
        .dispatch("ACCOUNT_URL_CALLBACK", {
          app: app,
        })
        .then((response) => {
          vm.$localStorage.remove("redirection_app");
          if (response.data.url) {
            window.location.replace(
              response.data.url + "?code=" + access_token
            );
          }
        })
        .catch(({ response }) => {
          vm.loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
          console.log("ACCOUNT_LOGIN", response);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  padding: 30px 0px;
  overflow: auto;
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgb(243, 245, 247);
  .cagura-nav-bar {
    @media screen and (max-width: 1040px) {
      display: flex;
      justify-content: center;
    }
    .cagura-logo {
      width: 200px;
      margin-left: -10px;
      @media screen and (max-width: 1040px) {
        margin-left: -26px;
      }
    }
  }
  .body {
    margin-top: 30px;
    @media screen and (max-width: 1040px) {
      display: flex;
      justify-content: center;
      padding: 20px;
    }
    .login-wrapper {
      display: flex;
      justify-content: center;
      .login-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .logo-section {
          margin-bottom: 30px;
          img {
            width: 230px;
            object-fit: contain;
          }
        }
        .login-header {
          h2 {
            font-size: 20px;
            color: #8dc73f;
            margin-top: -11px;
            text-align: center;
            @media screen and (max-width: 1040px) {
              font-size: 18px;
              text-align: center;
            }
          }
        }
        .form-widget {
          border: 1px solid #ddd;
          padding: 30px 30px;
          border-radius: 4px;
          background: rgb(249, 249, 249);
          @media screen and (max-width: 1040px) {
            padding: 10px 0px;
            border: none;
          }

          .form-option {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: 1040px) {
              flex-direction: column;
            }
            .login-btn {
              width: 154px;
              height: 45px;
              background: #8dc73f;
              border-radius: 5px;
              color: #fff;
              border: none;
            }
            .forgot-link {
              color: #8dc73f;
              text-decoration: none;
              @media screen and (max-width: 1040px) {
                margin-top: 20px;
              }
            }
          }
          .create-account-section {
            padding-top: 30px;
            a {
              color: #8dc73f;
              text-decoration: none;
              cursor: pointer;
            }
          }
          .other-login-laber {
            padding-top: 20px;
            text-transform: capitalize;
          }
          .other-login-option {
            display: grid;
            grid-template-columns: 142px 142px;
            grid-column-gap: 13px;
            @media screen and (max-width: 1040px) {
              grid-template-columns: 120px 120px;
            }
            .option {
              display: flex;
              align-items: center;
              background: #ffffff;
              border-radius: 5px;
              padding: 10px 10px;
              cursor: pointer;
              justify-content: center;
              border: 1px solid #ddd;
              &:hover {
                background: #f3ffe4;
              }

              .logo {
                margin-right: 5px;
                width: 17px;
              }
              .name {
                @media screen and (max-width: 1040px) {
                  font-size: 10px;
                  text-align: center;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
