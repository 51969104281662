import Vue from 'vue'
import VueRouter from 'vue-router'
import VueHead from 'vue-head'
import Home from '../views/Home.vue';
import Feeds from '../views/Feeds.vue';
import AccountLogin from '../views/AccountLogin.vue'
import AccountRegister from '../views/AccountRegister.vue'
import AccountResetpassword from '../views/AccountReset.vue'
import AccountVerify from '../views/AccountVerify.vue'
import AccountActivation from '../views/AccountActivation.vue'
import CategoryProducts from '../views/CategoryProducts.vue'
import ProductView from '../views/ProductView.vue'
import ShopView from '../views/ShopView.vue';
import History from '../views/History.vue';
import Wishlist from '../views/Wishlist.vue';
import Myshop from '../views/Myshop.vue';
import Myproducts from '../views/Myproducts.vue';
import Search from '../views/Search.vue';
import About from '../views/help/About.vue';
import Contact from '../views/help/Contact.vue';
import Terms from '../views/help/Terms.vue';
import Privacy from '../views/help/PrivacyPolicy.vue';
import AccountAuth from '../views/AccountAuth.vue'
import NotFound from '../views/NotFound.vue'
import Affiliate from "../views/Affiliate.vue";
import AffiliateItemViewer from "../views/AffiliateItemViewer.vue";
import Movies from "../views/Movies.vue";
import Live from "../views/Live.vue";
import Notification from "../views/Notification.vue";
import NotificationViewer from "../views/NotificationViewer.vue";

Vue.use(VueRouter)
Vue.use(VueHead);
const routes = [{
    path: '/',
    name: 'home',
    component: Feeds,
},
{
    path: '/posts/:type?/:id',
    name: 'post',
    component: Feeds,
},
{
    path: '/products',
    name: 'products',
    component: Home,
},
{
    path: '/feeds',
    name: 'feeds',
    component: Feeds,
},
{
    path: '/home/:type/:id',
    name: 'home',
    component: Home,
},
{
    path: '/notification',
    name: 'notification',
    component: Notification,
},
{
    path: '/notification/:id',
    name: 'notification-viewer',
    component: NotificationViewer,
},
{
    path: '/account/login',
    name: 'login',
    component: AccountLogin
},
{
    path: '/account/register',
    name: 'register',
    component: AccountRegister
},
{
    path: '/account/reset/password/:token?',
    name: 'reset-password',
    component: AccountResetpassword
},
{
    path: '/account/verify',
    name: 'account-verify',
    component: AccountVerify
},
{
    path: '/account/activation/:type/:token?',
    name: 'account-activate',
    component: AccountActivation
},
{
    path: '/account/auth/:type/:app',
    name: 'account-auth',
    component: AccountAuth
},
{
    path: '/category/products/:id',
    name: 'category',
    component: CategoryProducts
},
{
    path: '/product/view/:token/:product',
    name: 'product-view',
    component: ProductView
},
{
    path: '/shop/view/:token/:shop',
    name: 'product-view',
    component: ShopView
},
{
    path: '/account/history',
    name: 'history',
    component: History
},
{
    path: '/account/wishlist',
    name: 'wishlist',
    component: Wishlist
},
{
    path: '/account/myshop',
    name: 'myshop',
    component: Myshop
},
{
    path: '/account/myproducts',
    name: 'myproducts',
    component: Myproducts
},
{
    path: '/search',
    name: 'search',
    component: Search
},
{
    path: '/help/about',
    name: 'about',
    component: About
},
{
    path: '/help/contact/us',
    name: 'contact',
    component: Contact
},
{
    path: '/help/terms',
    name: 'terms',
    component: Terms
},
{
    path: '/help/privacy/policy/safety',
    name: 'privacy',
    component: Privacy
},
{
    path: '/affiliate',
    name: 'affiliate',
    component: Affiliate
},
{
    path: '/movies',
    name: 'movies',
    component: Movies
},
{
    path: '/live',
    name: 'live',
    component: Live
},

{
    path: '/af/:token',
    name: 'affiliate-item-viewer',
    component: AffiliateItemViewer
},
{
    path: '*',
    component: NotFound,
    name: 'notfound',
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    duplicateNavigationPolicy: 'ignore',
})

export default router