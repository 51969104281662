<template>
  <div class="cagura-wrapper">
    <!-- Navbar -->
    <ClientHeader type="shopping"></ClientHeader>
    <!-- Navbar -->
    <div class="cagura-container">
      <!-- Side bar -->
      <side-bar />
      <!-- End of sidebar -->
      <!-- Content - body -->
      <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
        <vue-scroll>
          <div class="cagura-shop-widget" v-if="shop_item">
            <div
              class="cover-widget"
              :style="`background:#ccc url('${
                $store.state.ASSETS_BASE_URL +
                '/assets/uploaded/' +
                shop_item.cover_image
              }');background-position: center;background-size: cover;`"
            >
              <div class="profile-widget">
                <img
                  :src="
                    $store.state.ASSETS_BASE_URL +
                    '/assets/uploaded/' +
                    shop_item.shop_logo
                  "
                  class="shop-logo"
                />
              </div>
            </div>
            <div class="cover-content">
              <div class="shop-info">
                <h2 class="shop-name">{{ shop_item.shop_name }}</h2>
                <p class="shop-location">{{ shop_item.location_name }}</p>
              </div>
              <div class="shop-option">
                <button
                  class="btn-contact"
                  v-if="!shop_item.is_addeble"
                  @click="shopContactForm()"
                >
                  <img src="@/assets/images/icons/mail.svg" /> CONTACT
                </button>
                <button class="btn-success" @click="showShareShop()">
                  SHARE
                </button>
              </div>
            </div>
            <!-- Shop product -->
            <div class="shop-product">
              <div class="shop-product-header">
                <div class="title">
                  <h3>SHOP PRODUCTS</h3>
                </div>
                <div class="shop-options">
                  <div class="search-widget">
                    <div class="search-input">
                      <input
                        type="text"
                        v-model="search_product_input"
                        placeholder="Search in this shop"
                      />
                      <img src="@/assets/images/icons/search-grey.svg" alt />
                    </div>
                  </div>
                  <div class="btns-options">
                    <button
                      class="btn-cagura"
                      v-if="shop_item.is_addeble"
                      @click="addNewProduct(shop_item.id)"
                    >
                      <img src="@/assets/images/icons/plus.svg" />
                    </button>
                    <button
                      class="btn-cagura"
                      @click="show_filter_widget = !show_filter_widget"
                    >
                      <img src="@/assets/images/icons/filter.svg" />
                    </button>
                  </div>
                </div>
              </div>

              <!-- filter - wigdet -->
              <transition name="fadeanim">
                <div class="filter-widget" v-if="show_filter_widget">
                  <div class="filter-options">
                    <div class="category-widget">
                      <vue-scroll>
                        <h3>CATEGORY</h3>
                        <ul class="categories-items">
                          <li @click="getShopProduct(shop_item.id)">
                            <span>All</span>
                          </li>
                          <li
                            v-for="(item, i) in shop_item.categories"
                            :key="i"
                            :class="{ active: item.active }"
                            @click="searchProductBasedCategory(item)"
                          >
                            <span>{{ item.category_name }}</span>
                          </li>
                        </ul>
                      </vue-scroll>
                    </div>
                    <div class="price-range-widget">
                      <h3>PRICE RANGE</h3>
                      <a-slider
                        style="color: #000"
                        range
                        :default-value="[500, 500000]"
                        :min="500"
                        :max="1000000"
                        :marks="price_range_marks"
                        :tip-formatter="filterFormatter"
                        @change="onPriceFilterChange"
                      />
                    </div>
                  </div>
                  <div class="filter-option">
                    <div class="sort-widget">
                      <label>Sort by:</label>
                      <button
                        class="filter-btn"
                        @click="softByPrice()"
                        :class="{ down: filter.sort.price == 'down' }"
                      >
                        Price
                        <img src="@/assets/images/icons/allow.svg" />
                      </button>
                      <button
                        class="filter-btn"
                        v-if="false"
                        @click="softByPopularity()"
                        :class="{ down: filter.sort.popularity == 'down' }"
                      >
                        Popularity
                        <img src="@/assets/images/icons/allow.svg" />
                      </button>
                    </div>
                    <div class="filter-btn">
                      <button
                        @click="show_filter_widget = false"
                        class="btn-success"
                      >
                        CLOSE
                      </button>
                    </div>
                  </div>
                </div>
              </transition>
              <!-- Product list -->
              <div class="product-items" v-if="shop_item.products.length > 0">
                <div
                  class="product-item"
                  v-for="(item, i) in shop_item.products"
                  :key="i"
                >
                  <div
                    class="product-image-widget"
                    @click="
                      $router
                        .push({ path: $getProductLink(item) })
                        .catch(() => {})
                    "
                  >
                    <img
                      :src="
                        $store.state.ASSETS_BASE_URL +
                        '/assets/uploaded/' +
                        item.main_image
                      "
                    />
                  </div>
                  <div class="product-content">
                    <div class="header">
                      <h2>{{ item.product_name }}</h2>
                      <dropdown-menu
                        v-model="item.is_menu_open"
                        :right="false"
                        :hover="false"
                        :interactive="false"
                      >
                        <button class="dropdown-toggle">
                          <img src="@/assets/images/icons/menu.svg" />
                        </button>
                        <div slot="dropdown" class="drop-down-menu">
                          <a
                            class="dropdown-item"
                            v-if="shop_item.is_editable"
                            @click.prevent="editProduct(item)"
                          >
                            <img src="@/assets/images/icons/edit.svg" /> Edit
                            Product
                          </a>
                          <a
                            class="dropdown-item"
                            @click.prevent="getSharelableLink(item)"
                          >
                            <img src="@/assets/images/icons/share.svg" /> Get
                            shareble link
                          </a>
                          <a
                            class="dropdown-item"
                            v-if="shop_item.is_deletable"
                            @click.prevent="deleteProduct(item)"
                          >
                            <img src="@/assets/images/icons/trash.svg" /> Delete
                            product
                          </a>
                        </div>
                      </dropdown-menu>
                    </div>
                    <div
                      class="content"
                      @click="
                        $router
                          .push({ path: $getProductLink(item) })
                          .catch(() => {})
                      "
                    >
                      <p>{{ $cutText(item.description, 130) }}</p>
                      <div class="brand">
                        <label class="brand-label">Brand:</label>
                        <span>{{ item.brand }}</span>
                      </div>
                      <div class="product-options">
                        <div
                          class="price"
                          v-if="item.discount"
                          :class="{ strikethrough: item.discount }"
                        >
                          <strike>FRW {{ $formatMoney(item.price) }}</strike>
                        </div>

                        <div class="price" v-else>
                          FRW {{ $formatMoney(item.price) }}
                        </div>

                        <div class="price" v-if="item.discount">
                          {{
                            "FRW " +
                            $formatMoney(
                              item.price - (item.price * item.discount) / 100
                            )
                          }}
                        </div>
                        <div class="rate">
                          <a-rate
                            :value="item.rating"
                            :disabled="false"
                            style="font-size: 14px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="empty-widget"
                v-if="shop_item.products.length == 0 && !is_product_loading"
              >
                <a-empty
                  :image="require('@/assets/images/icons/empty.svg')"
                  :image-style="{ height: '116px' }"
                >
                  <span slot="description"
                    >Product in this shop are not available!!!</span
                  >
                </a-empty>
              </div>
              <div
                class="loader-empty-widgets"
                v-if="shop_item.products.length == 0 && is_product_loading"
              >
                <div class="loader-widget" style="height: 300px">
                  <div class="loading-panel">
                    <a-spin :spinning="true"></a-spin>
                    <span class="text">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="loader-empty-widgets">
            <div class="empty-widget" v-if="!shop_item && !is_loading">
              <a-empty
                :image="require('@/assets/images/icons/empty.svg')"
                :image-style="{ height: '116px' }"
              >
                <span slot="description">Shop data are not available!!!</span>
              </a-empty>
            </div>
            <div class="loader-widget" style="height: 300px" v-if="is_loading">
              <div class="loading-panel">
                <a-spin :spinning="true"></a-spin>
                <span class="text">Loading...</span>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
      <!-- End of content -->
    </div>

    <!--  Contact form -->
    <transition name="fadeanim">
      <div class="overlay" v-if="show_contact_form">
        <div class="contact-form">
          <h2>Contact Us</h2>
          <div class="form-input">
            <textarea
              class="contant-input"
              v-model="contact_form.message"
              placeholder="Type your message here..."
            ></textarea>
          </div>
          <div class="form-options">
            <button
              class="btn-send btn-default"
              @click="show_contact_form = false"
            >
              CLOSE
            </button>
            <c-button
              class="btn-send btn-success"
              :loading="contact_form_loading"
              @click="contactShop()"
              >SEND</c-button
            >
          </div>
        </div>
      </div>
    </transition>
    <!-- Add product modal -->
    <transition name="fadeanim">
      <add-product
        v-if="show_add_product_modal && shop_item"
        :shop_id="shop_item.id"
        :value="selected_item"
        @onSubmit="onProductSubmit()"
        @onClose="show_add_product_modal = false"
      />
    </transition>

    <!-- Share link modal -->
    <transition name="fadeanim">
      <div class="overlay" v-if="show_share_link_input_modal">
        <div class="product-link-form">
          <h2>Get sharelable link</h2>
          <div class="form-input">
            <input
              type="text"
              v-model="share_link_input"
              readonly
              class="link-input"
            />
            <button
              class="btn-copy"
              title="Copy"
              v-clipboard:copy="share_link_input"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <a-icon type="copy" />
            </button>
          </div>
          <div class="form-option">
            <button
              @click="show_share_link_input_modal = false"
              class="btn-success"
            >
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ClientHeader from "../components/partials/header.vue";
import SideBar from "../components/partials/sidebar.vue";
import DropdownMenu from "@innologica/vue-dropdown-menu";
import AddProduct from "../components/modals/AddProduct.vue";

export default {
  name: "shop_view",
  components: {
    ClientHeader,
    SideBar,
    DropdownMenu,
    AddProduct,
  },
  data() {
    return {
      show_add_product_modal: false,
      show_contact_form: false,
      shop_item: null,
      is_loading: false,
      share_link_input: "",
      price_range_marks: {
        500: {
          style: {
            color: "#4b4a4a",
          },
          label: "FRW 500",
        },
        500000: {
          style: {
            color: "#4b4a4a",
          },
          label: "FRW 500,000",
        },
      },
      show_filter_widget: false,
      filter: {
        price: {
          min: 0,
          max: 500000,
        },
        categories: null,
        sort: {
          price: "up",
          popularity: "down",
        },
      },
      store_products_data: [],
      search_product_input: "",
      selected_item: null,
      show_share_link_input_modal: false,
      contact_form: {
        message: "",
        receiver_id: null,
        shop_id: null,
      },
      contact_form_loading: false,
      title: "",
    };
  },
  head: {
    title: function () {
      return {
        inner: this.title,
      };
    },
  },
  mounted() {
    let vm = this;
    if (vm.$route.params.token) {
      let product_id = vm.$decodeProductId(vm.$route.params.token);
      vm.getShopItem(product_id);
    } else {
      vm.is_loading = false;
    }
  },
  methods: {
    showShareShop() {
      let vm = this;
      vm.show_share_link_input_modal = true;
      vm.share_link_input = window.location.href.replace(
        "http://localhost/",
        "https://cagura.rw/"
      );
    },
    contactShop() {
      let vm = this;
      const rules = {
        message: {
          run: (value) => {
            if (value.length >= 5 && value.length <= 1000) {
              return false;
            }
            return "Your message must have 5 - 1000 charcters";
          },
        },
      };
      if (vm.$isFieldsValidated(vm.contact_form, rules)) {
        vm.contact_form_loading = true;
        vm.$store
          .dispatch("SHOP_SEND_MESSAGE", vm.contact_form)
          .then((response) => {
            vm.contact_form_loading = false;
            if (response.data.status == "ok") {
              vm.show_contact_form = false;
              vm.$notify({
                group: "status",
                type: "success",
                title: "Important message",
                duration: 15000,
                text: response.data.message,
              });
            } else {
              vm.$notify({
                group: "status",
                type: "warn",
                title: "OOPS!!!",
                text: response.data.Message,
              });
            }
          })
          .catch(({ response }) => {
            vm.contact_form_loading = false;
            console.error("SHOP_SEND_MESSAGE", { response });
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
          });
      }
    },
    shopContactForm() {
      let vm = this;
      vm.show_contact_form = true;
      vm.contact_form.receiver_id = vm.shop_item.user_id;
      vm.contact_form.shop_id = vm.shop_item.id;
    },
    onProductSubmit() {
      let vm = this;
      vm.show_add_product_modal = false;
      let shop_id = vm.shop_item.id;
      vm.getShopItem(shop_id);
    },
    getShopItem(shop_id) {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("SHOP_GET_SINGLE_SHOP", { id: shop_id })
        .then((response) => {
          vm.is_loading = false;
          if (response.data.status == "ok") {
            vm.shop_item = response.data.return;
            vm.title = vm.shop_item.shop_name;
            vm.$store.state.eventBus.$set(vm.shop_item, "products", []);
            vm.$store.state.eventBus.$set(vm.shop_item, "categories", []);
            // Get Shop Product
            vm.getShopProduct(vm.shop_item.id);
            // Get Shop Product categories
            vm.getShopProductCategories(vm.shop_item.id);
            // ====
            vm.$emit("updateHead");
            if (vm.$getLoggedUser()) {
              let user = vm.$getLoggedUser().user;
              if (vm.shop_item.user_id === user.id) {
                vm.$store.state.eventBus.$set(
                  vm.shop_item,
                  "is_editable",
                  true
                );
                vm.$store.state.eventBus.$set(
                  vm.shop_item,
                  "is_deletable",
                  true
                );
                vm.$store.state.eventBus.$set(vm.shop_item, "is_addeble", true);
              } else {
                vm.$store.state.eventBus.$set(
                  vm.shop_item,
                  "is_editable",
                  false
                );
                vm.$store.state.eventBus.$set(
                  vm.shop_item,
                  "is_deletable",
                  false
                );
                vm.$store.state.eventBus.$set(
                  vm.shop_item,
                  "is_addeble",
                  false
                );
              }
            } else {
              vm.$store.state.eventBus.$set(vm.shop_item, "is_editable", false);
              vm.$store.state.eventBus.$set(
                vm.shop_item,
                "is_deletable",
                false
              );
              vm.$store.state.eventBus.$set(vm.shop_item, "is_addeble", false);
            }
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("SHOP_GET_SINGLE_SHOP", response);
        });
    },
    getShopProductCategories(shop_id) {
      let vm = this;
      vm.$store
        .dispatch("SHOP_GET_ALL_PRODUCT_CATEGORIES", { shop_id: shop_id })
        .then((response) => {
          if (response.data.status == "ok") {
            vm.$store.state.eventBus.$set(
              vm.shop_item,
              "categories",
              response.data.return
            );
            vm.shop_item.categories.map((item) => {
              vm.$store.state.eventBus.$set(item, "active", false);
              return item;
            });
          }
        })
        .catch(({ response }) => {
          console.error("SHOP_GET_ALL_PRODUCT_CATEGORIES", { response });
        });
    },
    getShopProduct(shop_id) {
      let vm = this;
      vm.is_product_loading = true;
      vm.$store
        .dispatch("SHOP_GET_ALL_PRODUCT", { shop_id: shop_id })
        .then((response) => {
          vm.is_product_loading = false;
          if (response.data.status == "ok") {
            vm.store_products_data = response.data.return;
            vm.$store.state.eventBus.$set(
              vm.shop_item,
              "products",
              response.data.return
            );
          }
        })
        .catch(({ response }) => {
          vm.is_product_loading = false;
          console.error("SHOP_GET_ALL_PRODUCT", { response });
        });
    },
    onCopy: function (e) {
      let vm = this;
      vm.$notify({
        group: "status",
        type: "success",
        title: "Important message",
        text: "Link copied",
      });
    },
    onError: function (e) {
      let vm = this;
      vm.$notify({
        group: "status",
        type: "warn",
        title: "Important message",
        text: "Failed to copy",
      });
    },
    getSharelableLink(item, type) {
      let vm = this;
      let product_name = item.product_name.toLowerCase().replace(/\s/g, "_");
      let product_id = vm.$encodeProductId(item.id);
      vm.share_link_input =
        vm.$store.state.BASE_URL +
        "product/view/" +
        product_id +
        "/" +
        product_name;
      vm.show_share_link_input_modal = true;
    },
    deleteProduct(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure? This will delete all information related to this product, But shop from it will not be deleted`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmDeleteProduct(item.id);
          }
        },
      });
    },
    confirmDeleteProduct(product_id) {
      let vm = this;
      vm.$store.commit("START_LOADER");
      vm.$store
        .dispatch("PRODUCT_DELETE", { id: product_id })
        .then((response) => {
          vm.$store.commit("STOP_LOADER");
          if (response.data.status == "ok") {
            vm.onProductSubmit();
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          } else {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.Message,
            });
          }
        })
        .catch(({ response }) => {
          vm.$store.commit("STOP_LOADER");
          console.error("PRODUCT_DELETE", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    addNewProduct(id) {
      let vm = this;
      vm.show_add_product_modal = true;
      vm.selected_item = null;
    },
    editProduct(item) {
      let vm = this;
      vm.selected_item = null;
      vm.selected_item = item;
      vm.show_add_product_modal = true;
    },
    filterProduct() {
      let vm = this;
      vm.shop_item.products = vm.store_products_data
        .filter((item) => {
          let cat_product_ids = vm.filter.categories
            ? vm.filter.categories
            : [item.id];
          return (
            cat_product_ids.includes(item.id) &&
            parseInt(item.price) >= vm.filter.price.min &&
            parseInt(item.price) <= vm.filter.price.max
          );
        })
        .sort((a, b) => {
          return vm.filter.sort.price == "down"
            ? parseInt(a.price) - parseInt(b.price)
            : parseInt(b.price) - parseInt(a.price);
        });
    },
    onPriceFilterChange(value) {
      let vm = this;
      vm.filter.price.min = value[0];
      vm.filter.price.max = value[1];
      vm.filterProduct();
    },
    searchProductBasedCategory(item) {
      let vm = this;
      vm.shop_item.categories.forEach((item) => {
        item.active = false;
      });
      item.active = !item.active;
      vm.filter.categories = item.product_ids.map((item) => item.product_id);
      vm.filterProduct();
    },
    softByPopularity() {
      let vm = this;
      vm.filter.sort.popularity == "up"
        ? (vm.filter.sort.popularity = "down")
        : (vm.filter.sort.popularity = "up");
      vm.filterProduct();
    },
    softByPrice() {
      let vm = this;
      vm.filter.sort.price == "up"
        ? (vm.filter.sort.price = "down")
        : (vm.filter.sort.price = "up");
      vm.filterProduct();
    },
    filterFormatter(val) {
      let vm = this;
      return `FRW ${vm.$formatMoney(val)}`;
    },
    searchProduct(query) {
      let vm = this;
      vm.shop_item.products = vm.store_products_data.filter((item) => {
        if (
          item.product_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          item.description.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          item.brand.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          item.price <= query
        ) {
          return true;
        }
        return false;
      });
    },
  },
  watch: {
    search_product_input: function (query) {
      let vm = this;
      vm.searchProduct(query);
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
    .cagura-body {
      margin-left: 282px;
      padding-top: 0px;
      background: rgb(251, 251, 251);
      height: 100vh;
      display: flex;
      flex-direction: column;
      transition: 700ms ease-in-out;

      .cagura-shop-widget {
        height: 100%;
        padding-bottom: 62px;
        .cover-widget {
          height: 200px;
          position: relative;
          @media screen and (max-width: 500px) {
            height: 120px;
          }
          .profile-widget {
            height: 160px;
            width: 160px;
            .shop-logo {
              height: 160px;
              width: 160px;
              position: absolute;
              bottom: -65px;
              left: 69px;
              border-radius: 50%;
              border: 7px solid #fff;
              object-fit: cover;
              @media screen and (max-width: 500px) {
                height: 93px;
                width: 93px;
                bottom: -56px;
                left: 11px;
              }
            }
          }
        }
        .cover-content {
          padding-left: 249px;
          display: flex;
          justify-content: space-between;
          padding-top: 13px;
          padding-right: 19px;
          align-items: center;
          @media screen and (max-width: 500px) {
            padding-left: 116px;
            padding-top: 2px;
            flex-direction: column;
            align-items: flex-end;
            padding-right: 19px;
          }
          .shop-info {
            .shop-name {
              margin-bottom: 0px;
              margin-top: 0px;
              text-align: left;
              @media screen and (max-width: 500px) {
                font-size: 16px;
                text-align: right;
              }
            }
            .shop-location {
              margin-bottom: 0px;
              margin-top: 0px;
              @media screen and (max-width: 500px) {
                font-size: 13px;
                text-align: right;
              }
            }
          }
          .shop-option {
            display: flex;
            @media screen and (max-width: 500px) {
              padding-top: 20px;
            }
            .btn-contact {
              border: none;
              background: transparent;
              display: flex;
              align-items: center;
              font-size: 12px;

              img {
                width: 16px;
                margin-right: 5px;
              }
            }
          }
        }
        .shop-product {
          padding-top: 40px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 40px;
          .shop-product-header {
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 500px) {
              flex-direction: column;
            }
            .title {
              h3 {
                @media screen and (max-width: 500px) {
                  font-size: 13px;
                  font-weight: 800;
                }
              }
            }
            .shop-options {
              display: flex;
              justify-content: space-between;
              align-items: center;
              @media screen and (max-width: 500px) {
                align-items: flex-start;
                position: relative;
              }
              .search-widget {
                padding-right: 20px;
                @media screen and (max-width: 500px) {
                  margin-bottom: 15px;
                  padding-right: 0px;
                }
                .search-input {
                  border: 1px solid #b8b8b8;
                  height: 40px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0px 13px;
                  border-radius: 5px;
                  input {
                    border: none;
                    background: transparent;
                    width: 240px;
                    @media screen and (max-width: 500px) {
                      width: 100%;
                    }
                  }
                  img {
                    width: 15px;
                  }
                }
              }
              .btns-options {
                display: flex;
                @media screen and (max-width: 500px) {
                  display: flex;
                }
                .btn-cagura {
                  width: 39.89px;
                  height: 39.89px;
                  background: #8dc73f;
                  border-radius: 6.93797px;
                  border: none;
                  margin-left: 15px;
                }
              }
            }
          }
          .filter-widget {
            background: #fff;
            padding: 25px;
            margin-bottom: 15px;
            margin-top: 15px;
            border-radius: 5px;
            @media screen and (max-width: 500px) {
              padding: 15px;
            }
            .filter-options {
              display: grid;
              grid-template-columns: 100%;
              grid-column-gap: 20px;
              @media screen and (max-width: 500px) {
                grid-template-columns: 100%;
              }

              .price-range-widget {
                margin-bottom: 15px;
                h3 {
                  color: #000;
                }
              }

              .category-widget {
                max-height: 200px;
                overflow-y: auto;
                .categories-items {
                  list-style: none;
                  padding-left: 0px;
                  display: flex;
                  flex-wrap: wrap;
                  li {
                    margin-right: 15px;
                    border: 1px solid #dddddd;
                    box-sizing: border-box;
                    border-radius: 40px;
                    font-size: 12px;
                    padding: 3px 16px;
                    margin-bottom: 11px;
                    cursor: pointer;
                    &.active {
                      color: #8dc73f;
                    }
                  }
                }
              }
            }
            .filter-option {
              display: flex;
              justify-content: space-between;
              align-items: center;
              @media screen and (max-width: 500px) {
                flex-direction: column;
                align-items: flex-start;
              }
              .sort-widget {
                display: flex;
                align-items: center;
                @media screen and (max-width: 500px) {
                  margin-bottom: 15px;
                }
                label {
                  padding-right: 15px;
                }
                .filter-btn {
                  border: none;
                  background: #fbfbfb;
                  padding: 2px 15px;
                  border-radius: 5px;
                  display: flex;
                  align-items: center;
                  height: 27px;
                  margin-right: 20px;
                  &.down {
                    img {
                      transform: rotateZ(182deg);
                      transition: 500ms ease-in-out;
                    }
                  }
                  img {
                    margin-left: 10px;
                    transition: 500ms ease-in-out;
                  }
                }
              }
            }
          }
          .product-items {
            display: grid;
            grid-template-columns: 48% 48%;
            grid-column-gap: 40px;
            padding-top: 20px;
            grid-row-gap: 20px;
            @media screen and (max-width: 500px) {
              grid-template-columns: 100%;
            }
            .product-item {
              border: 1px solid #ddd;
              background: #fff;
              border-radius: 5px;
              display: grid;
              grid-template-columns: 200px calc(99% - 200px);
              grid-column-gap: 10px;
              @media screen and (max-width: 1040px) {
                grid-template-columns: 119px calc(99% - 119px);
              }
              @media screen and (max-width: 500px) {
                grid-template-columns: 100%;
                border: 1px solid #ddd;
                padding: 12px;
              }
              cursor: pointer;
              .product-image-widget {
                img {
                  height: 200px;
                  width: 200px;
                  object-fit: cover;
                  border-bottom-left-radius: 5px;
                  border-top-left-radius: 5px;
                  @media screen and (max-width: 1040px) {
                    width: 100%;
                    object-fit: contain;
                    background: #000;
                  }
                  @media screen and (max-width: 500px) {
                    height: 150px;
                    width: 100%;
                    object-fit: contain;
                    background: #000;
                  }
                }
              }
              .product-content {
                padding-right: 15px;
                .header {
                  display: flex;
                  justify-content: space-between;
                  position: relative;
                  align-items: center;
                  margin-bottom: 8px;
                  margin-top: 11px;
                  h2 {
                    margin: 0px;
                    text-transform: uppercase;
                    font-size: 13px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                    -webkit-line-clamp: 2;
                    line-height: 17px;
                    max-height: 4.4rem;
                    @media screen and (max-width: 500px) {
                      font-size: 12px;
                    }
                  }
                }
                .shop-name {
                  color: rgba(42, 140, 229, 0.71);
                  font-weight: 800;
                  @media screen and (max-width: 500px) {
                    font-size: 14px;
                  }
                }
                p {
                  text-align: justify;
                  @media screen and (max-width: 500px) {
                    font-size: 14px;
                  }
                }
                .product-options {
                  display: flex;
                  justify-content: space-between;
                  @media screen and (max-width: 1040px) {
                    flex-direction: column;
                  }
                  @media screen and (max-width: 500px) {
                    flex-direction: column;
                  }
                  .price {
                    font-weight: 800;
                  }
                }
                .product-option {
                  display: flex;
                  justify-content: space-between;
                  margin-top: 10px;
                  @media screen and (max-width: 500px) {
                    flex-direction: column;
                  }
                  .btn-contact-seller {
                    border: none;
                    background: transparent;
                    display: flex;
                    align-items: center;
                    color: #939393;
                    padding: 0px;
                    @media screen and (max-width: 500px) {
                      padding: 0px;
                      padding-bottom: 15px;
                    }
                    img {
                      margin-right: 6px;
                    }
                  }
                  .btn-add-fovourites {
                    border: none;
                    background: transparent;
                    display: flex;
                    align-items: center;
                    color: #8cc73f;
                    @media screen and (max-width: 500px) {
                      padding: 0px;
                      padding-bottom: 15px;
                    }
                    img {
                      margin-right: 6px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba($color: #fff, $alpha: 0.9);
    z-index: 99999;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 500px) {
      background: #fff;
    }
    .contact-form {
      width: 500px;
      background: #fff;
      border: 1px solid #ddd;
      display: inline-table;
      padding: 20px;
      @media screen and (max-width: 500px) {
        width: 100%;
        border: none;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
      }
      h2 {
        font-size: 27px;
      }
      .form-input {
        width: 100%;
        .contant-input {
          width: 100%;
          height: 160px;
          margin-bottom: 20px;
          padding: 10px;
          resize: none;
          border-radius: 5px;
          background: #eeeeee;
          border: none;
        }
      }
      .form-options {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
