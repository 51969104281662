 <template>
  <transition name="fade">
    <div class="auth-overlay">
      <div class="auth-widget">
        <div class="content">
          <h2>
            <span class="action">Sign In</span>
            {{ message }}
          </h2>
        </div>
        <div class="option">
          <button class="btn-success" @click="confirm()">Okay</button>
          <button class="btn-default" @click="cancel()">Cancel</button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "Ask-Auth",
  components: {},
  props: {
    message: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
    back: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    cancel() {
      let vm = this;
      if (vm.back) {
        vm.$router.push({ name: vm.back });
      } else {
        vm.$router.push({ name: "home" });
      }
      vm.$emit("onCancel");
    },
    confirm() {
      let vm = this;
      if (vm.path) {
        vm.$setRedirect(vm.path);
      }
      vm.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-overlay {
  position: fixed;
  z-index: 999999999999999;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(#fff, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  .auth-widget {
    width: 300px;
    border: 1px solid #ddd;
    padding: 20px;
    background: #fff;
    .content {
      .action {
        color: #8dc73f;
      }
    }
    .option {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
    }
  }
}
</style>