<template>
  <div class="cagura-wrapper">
    <!-- Navbar -->
   <ClientHeader type="shopping"></ClientHeader>
    <!-- Navbar -->
    <div class="cagura-container">
      <!-- Side bar -->
      <side-bar />
      <!-- End of sidebar -->
      <!-- Content - body -->
      <div class="cagura-body" :class="{ nosidebar: !$store.state.sidebar }">
        <div class="tags-widget">
          <cagura-tags />
        </div>
        <div class="cagura-products-widget has-tags">
          <vue-scroll>
            <div class="under-scoll-container-widget">
              <div class="fit-container">
                <!-- Search result -->
                <div class="products-widget">
                  <div class="products-widget-header">
                    <h3>
                      PRODUCT SEARCH RESULT FOR
                      <i v-if="$route.query.q">"{{ $route.query.q }}"</i>
                      <i v-if="$route.query.tag">"{{ $route.query.tag }}"</i>
                    </h3>
                  </div>

                  <div
                    class="products-widget-body"
                    v-if="result.products.length > 0 && !is_loading"
                  >
                    <div
                      class="product-item"
                      v-for="(item, i) in result.products.slice(0, 30)"
                      :key="i"
                    >
                      <div
                        class="product-announcement success"
                        v-if="item.discount"
                      >
                        {{ item.discount }}% OFF
                      </div>
                      <div
                        class="product-image"
                        @click="
                          $router
                            .push({ path: $getProductLink(item) })
                            .catch(() => {})
                        "
                      >
                        <img
                          :src="
                            $store.state.ASSETS_BASE_URL +
                            '/assets/uploaded/' +
                            item.main_image
                          "
                        />
                      </div>
                      <div class="product-content">
                        <h4>{{ item.product_name }}</h4>
                        <p>
                          FRW
                          {{
                            item.discount
                              ? $formatMoney(
                                  item.price -
                                    (item.price * item.discount) / 100
                                )
                              : $formatMoney(item.price)
                          }}
                        </p>
                        <div
                          class="shop-viewer"
                          v-if="item.shop"
                          @click="
                            $router.push({ path: $getShopLink(item.shop) })
                          "
                        >
                          <img
                            class="shop-image"
                            :src="
                              $store.state.ASSETS_BASE_URL +
                              '/assets/uploaded/' +
                              item.shop.shop_logo
                            "
                          />
                          <div class="shop-content">
                            <label class="shop-name">{{
                              item.shop.shop_name
                            }}</label>
                            <span class="shop-location">{{
                              item.shop.location_name
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class v-if="result.products.length == 0 && !is_loading">
                    <div class="empty-widget">
                      <a-empty
                        :image="require('@/assets/images/icons/empty.svg')"
                        :image-style="{ height: '116px' }"
                      >
                        <span slot="description"
                          >No Products Available !!!!</span
                        >
                      </a-empty>
                    </div>
                  </div>

                  <div class="product-loading-widget" v-if="is_loading">
                    <div
                      class="product-loading-item pulse"
                      v-for="(item, index) in 12"
                      :key="index"
                    >
                      <div class="product-loading-image"></div>
                      <div class="product-loading-content">
                        <div class="product-loading-title"></div>
                        <div class="product-loading-price"></div>
                      </div>
                      <div class="product-loading-shop"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
      <!-- End of content -->
    </div>
  </div>
</template>

<script>
import ClientHeader from "../components/partials/header.vue";
import SideBar from "../components/partials/sidebar.vue";
import CaguraTags from "../components/partials/tags.vue";
export default {
  name: "home",
  components: {
    ClientHeader,
    SideBar,
    CaguraTags,
  },
  data() {
    return {
      result: {
        products: [],
        shops: [],
      },
      is_loading: false,
    };
  },
  mounted() {
    let vm = this;
    if (vm.$route.query.tag) {
      vm.getProductAndShopBasedOnCategory(vm.$route.query.tag);
    }
    if (vm.$route.query.q) {
      vm.getProductAndShopBasedOnQuery(vm.$route.query.q);
    }
  },
  methods: {
    saveSearchQuery(val) {
      let vm = this;
      if (vm.$getLoggedUser()) {
        vm.$store
          .dispatch("SAVE_SEARCH_TEXT", {
            text: val,
            from: vm.$route.query.from,
          })
          .then((response) => {
            if (response.data.status == "ok") {
            }
          })
          .catch(({ response }) => {
            console.error("SAVE_SEARCH_TEXT", response);
          });
      }
    },
    getProductAndShopBasedOnQuery(query) {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("SEARCH_BASED_QUERY", { query: query })
        .then((response) => {
          vm.is_loading = false;
          if (response.data.status == "ok") {
            vm.result = response.data.return;
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("SEARCH_BASED_QUERY", response);
        });
      vm.saveSearchQuery(query);
    },
    getProductAndShopBasedOnCategory(category_name) {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("SEARCH_BASED_CATEGORY", { name: category_name })
        .then((response) => {
          vm.is_loading = false;
          if (response.data.status == "ok") {
            vm.result = response.data.return;
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("SEARCH_BASED_CATEGORY", response);
        });
    },
  },
  watch: {
    "$route.query.tag"(val) {
      let vm = this;
      vm.getProductAndShopBasedOnCategory(val);
    },
    "$route.query.q"(val) {
      let vm = this;
      vm.getProductAndShopBasedOnQuery(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
  }
}
</style>

