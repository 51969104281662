<template>
  <div class="overlay">
    <div class="post-modal">
      <div class="header">
        <h3>{{ options.is_updating ? "UPDATE YOUR POST" : "CREATE POST" }}</h3>
        <button class="btn-close" @click="closeModal()">
          <img src="@/assets/images/icons/close-x.svg" />
        </button>
      </div>
      <div class="body">
        <div class="post-type" v-if="!is_posting">
          <div class="post-type-header">
            <h1>Select below</h1>
          </div>
          <div class="post-type-body">
            <div
              v-for="(item, index) in post_types"
              :key="index"
              class="type-item"
              :class="{ active: item.value == form.type }"
              @click="selectPostType(item)"
            >
              <img src="@/assets/images/icons/checked.svg" />
              <label for="som">{{ item.name }}</label>
            </div>
          </div>
        </div>
        <div v-else class="post-widget">
          <div class="post-form">
            <div class="profile">
              <img
                v-if="$getLoggedUser() && $getLoggedUser().user.profile_image"
                class="profile-image"
                :src="
                  $store.state.ASSETS_BASE_URL +
                  '/assets/uploaded/' +
                  $getLoggedUser().user.profile_image
                "
              />
              <img
                v-else
                class="profile-image"
                src="@/assets/images/icons/empty_profile.svg"
                alt
              />
            </div>
            <div class="form">
              <div class="post-input">
                <textarea
                  :placeholder="
                    form.type == 'selling'
                      ? 'What are you selling?'
                      : 'What are you looking for?'
                  "
                  class="post-textarea"
                  v-model="form.content"
                ></textarea>
              </div>
              <div class="options">
                <button class="add-image" @click="AddImage()">
                  <img src="@/assets/images/icons/image.svg" />
                </button>
                <button class="add-video" @click="AddVideo()">
                  <img src="@/assets/images/icons/video-camera.svg" />
                </button>
              </div>
            </div>
          </div>
          <div class="uploads-view">
            <div class="uploading-list" v-if="form.file_type == 'image'">
              <template v-for="(file, index) in files_on_update">
                <div class="uploading-item" :key="index + 'update'">
                  <img :src="$store.state.ASSETS_BASE_URL + '/feeds/' + file" />
                  <button
                    @click="removeFile(index, 'update')"
                    class="btn-remove"
                  >
                    Remove
                  </button>
                </div>
              </template>
              <template v-for="(file, index) in files">
                <div
                  class="uploading-item"
                  :key="index + 'add'"
                  :class="{ error: file.error, success: file.success }"
                  v-if="isImage(file.name)"
                >
                  <img :src="file.blob" />
                  <button
                    v-if="!file.success"
                    @click="removeFile(file, 'add')"
                    class="btn-remove"
                  >
                    Remove
                  </button>
                  <progressBar
                    v-if="posting_loading"
                    class="progress-widget"
                    v-model="file.progress"
                  ></progressBar>
                </div>
              </template>
              <div
                v-if="files.length < 8 && files.length > 0"
                class="uploading-item add-widget"
                @click="AddImage()"
              >
                <button class="btn-add">+</button>
              </div>
            </div>
            <div class="uploading-video" v-if="form.file_type == 'video'">
              <template v-for="(file, index) in files">
                <div
                  :key="index"
                  class="video-container"
                  v-if="isVideo(file.name)"
                >
                  <button
                    v-if="!file.success"
                    @click="removeFile(file, 'add')"
                    class="btn-remove"
                  >
                    Remove
                  </button>
                  <progressBar
                    v-if="posting_loading"
                    class="progress-widget"
                    v-model="file.progress"
                  ></progressBar>
                  <VideoPlayer
                    class="video-widget"
                    :src="file.blob"
                  ></VideoPlayer>
                </div>
              </template>
              <div class="video-container" v-if="files_on_update">
                <button @click="removeFile('', 'update')" class="btn-remove">
                  Remove
                </button>

                <VideoPlayer
                  class="video-widget"
                  :src="
                    $store.state.ASSETS_BASE_URL + '/feeds/' + files_on_update
                  "
                ></VideoPlayer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="post-option">
          <c-button
            v-if="is_posting"
            :loading="posting_loading"
            @click="submitPost()"
            class="btn-success btn-post"
          >
            {{ options.is_updating ? "UPDATE POST" : "POST" }}
          </c-button>
          <button v-else @click="startPosting()" class="btn-success btn-next">
            NEXT
          </button>
          <button
            v-if="is_posting"
            @click="goBack()"
            class="btn-default btn-back"
          >
            {{ options.is_updating ? "CHANGE TYPE" : "BACK" }}
          </button>
        </div>
      </div>
    </div>

    <file-upload
      ref="upload"
      input-id="feed-file-input"
      v-model="files"
      name="file"
      :multiple="form.file_type == 'image' ? true : false"
      put-action=""
      :maximum="8"
      :custom-action="customAction"
      @input-file="inputFile"
      @input-filter="inputFilter"
      @input="updatetValue"
      :headers="{
        AccessKey: '9de4212d-5d04-483a-93852d1a67c5-9bef-445e',
      }"
    >
    </file-upload>

    <ask-auth
      @onCancel="onCloseAskAccount()"
      v-if="ask_for_account"
      message=" to create post"
    ></ask-auth>
  </div>
</template>

<script>
import VueUploadComponent from "vue-upload-component";
export default {
  name: "add-post-modal",
  components: {
    FileUpload: VueUploadComponent,
  },
  props: {
    options: {
      type: Object,
      default: {
        type: "text",
        is_updating: false,
        updating_data: null,
      },
    },
  },
  data() {
    return {
      is_posting: false,
      files: [],
      post_types: [
        {
          name: "Am selling",
          value: "selling",
        },
        {
          name: "I need something",
          value: "looking",
        },
      ],
      form: {
        id: null,
        content: "",
        type: "selling",
        file_name: "",
        file_type: "",
      },
      posting_loading: false,
      uploaded_images: [],
      files_on_update: "",
      ask_for_account: false,
    };
  },
  mounted() {
    let vm = this;
    if (!vm.$getLoggedUser()) {
      vm.ask_for_account = true;
    } else {
      if (vm.options.is_updating) {
        vm.is_posting = true;
        vm.form.id = vm.options.updating_data.id;
        let post_el_content = document.createElement("html");
        post_el_content.innerHTML = vm.options.updating_data.content;
        vm.form.content = post_el_content.innerText;
        vm.form.type = vm.options.updating_data.post_type;
        vm.form.file_name = vm.options.updating_data.files;
        vm.form.file_type = vm.options.updating_data.file_type;
        if (vm.form.file_type == "image") {
          vm.files_on_update = JSON.parse(vm.form.file_name);
        } else {
          vm.files_on_update = vm.form.file_name;
        }
      }
    }
  },
  methods: {
    onCloseAskAccount() {
      let vm = this;
      vm.closeModal();
    },
    submitPost() {
      let vm = this;
      const rules = {
        content: {
          run: (value) => {
            if (value.length >= 12) {
              return false;
            }
            document.querySelector(".post-textarea").focus();
            return vm.form.type == "selling"
              ? "Please explain what you are selling"
              : "Please explain what you are looking for";
          },
        },
        type: {
          run: (value) => {
            if (vm.form.type == "looking") {
              return false;
            }
            if (vm.files.length > 0) {
              return false;
            } else {
              return "Please add image of what you are selling";
            }
          },
        },
      };
      if (vm.$isFieldsValidated(vm.form, rules)) {
        vm.$store
          .dispatch("FEEDS_VALIDATE_POST", vm.form)
          .then((response) => {
            if (vm.files.length > 0) {
              vm.posting_loading = true;
              vm.$refs.upload.active = true;
            } else {
              vm.posting_loading = true;
              if (!vm.options.is_updating) {
                vm.form.file_name = "";
                vm.form.file_type = "";
              }
              vm.savePost();
            }
          })
          .catch(({ response }) => {
            vm.posting_loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
          });
      }
    },
    savePost() {
      let vm = this;
      if (vm.form.file_type == "image") {
        if (Array.isArray(vm.files_on_update)) {
          vm.files_on_update.forEach((file) => {
            vm.uploaded_images.push(file);
          });
        }
        vm.form.file_name = JSON.stringify(vm.uploaded_images);
      }
      if (!vm.options.is_updating) {
        vm.$store
          .dispatch("FEEDS_SAVE_POST", vm.form)
          .then((response) => {
            vm.$emit("onSubmit", response.data);
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
            vm.posting_loading = false;
          })
          .catch(({ response }) => {
            vm.posting_loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.error("FEEDS_SAVE_POST", response);
          });
      } else {
        vm.$store
          .dispatch("FEEDS_UPDATE_POST", vm.form)
          .then((response) => {
            vm.$emit("onSubmit", response.data);
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
            vm.posting_loading = false;
          })
          .catch(({ response }) => {
            vm.posting_loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.error("FEEDS_UPDATE_POST", response);
          });
      }
    },
    removeFile(file, type) {
      let vm = this;
      if (vm.form.file_type == "image") {
        if (type == "add") {
          vm.$refs.upload.remove(file);
        } else if (type == "update") {
          vm.files_on_update.splice(file, 1);
        }
      }
      if (vm.form.file_type == "video") {
        if (type == "add") {
          vm.$refs.upload.remove(file);
        } else if (type == "update") {
          vm.form.file_name = "";
          vm.files_on_update = "";
        }
      }
    },
    startPosting() {
      let vm = this;
      if (!vm.options.is_updating) {
        vm.is_posting = true;
        vm.form.content = "";
        vm.form.file_name = "";
        vm.form.file_type = "";
        if (vm.options.type == "photo") {
          vm.AddImage();
        }
        if (vm.options.type == "video") {
          vm.AddVideo();
        }
        setTimeout(() => {
          document.querySelector(".post-textarea").focus();
        }, 500);
      } else {
        vm.is_posting = true;
      }
    },
    goBack() {
      let vm = this;
      vm.is_posting = false;
    },
    selectPostType(item) {
      let vm = this;
      vm.form.type = item.value;
    },
    async customAction(file, component) {
      return await component.uploadPut(file);
    },
    AddImage() {
      let vm = this;
      if (vm.form.file_type == "video") {
        vm.files_on_update = "";
        vm.$refs.upload.clear();
      }
      vm.form.file_type = "image";
      if (vm.files.length >= 8) {
        vm.$notify({
          group: "status",
          type: "warn",
          title: "OOPS!!!",
          text: "You can select only 8 images",
        });
        return;
      }
      setTimeout(() => {
        let file_el = document.querySelector("#feed-file-input");
        file_el.accept = "image/*";
        file_el.click();
      }, 300);
    },
    AddVideo() {
      let vm = this;
      if (vm.form.file_type == "image") {
        vm.files_on_update = "";
      }
      vm.form.file_type = "video";
      vm.$refs.upload.clear();
      setTimeout(() => {
        let file_el = document.querySelector("#feed-file-input");
        file_el.accept = "video/*";
        file_el.click();
      }, 300);
    },
    inputFile(newFile, oldFile) {
      let vm = this;
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        if (newFile.xhr) {
          if (vm.form.file_type == "image") {
            if (newFile.xhr.status == 201) {
              vm.uploaded_images.push(vm.getFileName(newFile.putAction));
            }
          }
          if (vm.form.file_type == "video") {
            if (newFile.xhr.status == 201) {
              vm.form.file_name = vm.getFileName(newFile.putAction);
            }
          }
        }
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      let vm = this;
      if (newFile && !oldFile) {
        if (vm.form.file_type == "image") {
          if (!vm.isImage(newFile.name)) {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: "One of file selected are not image format",
            });
            return prevent();
          }
        }
        if (vm.form.file_type == "video") {
          if (!vm.isVideo(newFile.name)) {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: "One of file selected are not video format",
            });
            return prevent();
          }
        }
        if (vm.files.map((item) => item.name).includes(newFile.name)) {
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: "Image you selected is already exists",
          });
          return prevent();
        }

        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        let file_name = vm.token(8);
        if (vm.form.file_type == "video") {
          vm.form.file_name = file_name;
          vm.files_on_update = "";
        }
        newFile.putAction =
          "https://storage.bunnycdn.com/cagura/feeds/" +
          file_name +
          "." +
          vm.GetExt(newFile.name);
      }

      if (newFile && oldFile) {
        if (!newFile.version) {
          newFile.version = 0;
        }
        newFile.version++;
      }
    },
    updatetValue(value) {
      let vm = this;
      if (vm.files.length > 0) {
        if (vm.$refs.upload && vm.$refs.upload.uploaded) {
          setTimeout(() => {
            vm.savePost();
          }, 500);
        }
      }
    },
    GetExt(file) {
      return /(?:\.([^.]+))?$/.exec(file)[1];
    },
    isVideo(filename) {
      return /\.(webm|mp4|mkv)$/i.test(filename);
    },
    isImage(filename) {
      return /\.(jpeg|jpe|jpg|gif|png|webp|svg)$/i.test(filename);
    },
    closeModal() {
      let vm = this;
      vm.$emit("close", null);
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.overlay {
  background: rgba($color: #fff, $alpha: 0.9);
  align-items: unset;
  display: block;
  padding-top: 63px;
  .post-modal {
    width: 500px;
    max-height: 600px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    @media screen and (max-width: 800px) {
      width: 100%;
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      max-height: unset;
      padding-top: 70px;
    }
    .header {
      height: 60px;
      display: flex;
      align-items: center;
      padding: 0px 15px;
      border-bottom: 1px solid #ccc;
      justify-content: space-between;
      img {
        width: 36px;
      }
      h3 {
        margin: 0px;
        font-size: 17px;
      }
      .btn-close {
        background: transparent;
        border: none;
        outline: none;
        img {
          width: 20px;
        }
      }
    }
    .body {
      padding: 15px 15px;
      height: calc(100% - 60px);
      overflow-y: auto;
      @media screen and (max-width: 800px) {
        height: calc(100% - 60px);
      }
      .post-type {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border: 1px solid #ddd;
        border-radius: 5px;
        background: #f4f4f4;
        .post-type-header {
          padding: 10px 14px;
          h1 {
            margin: 0px;
            font-size: 20px;
          }
        }
        .post-type-body {
          display: grid;
          grid-template-columns: 48% 48%;
          grid-gap: 20px;
          padding: 10px 14px;
          padding-bottom: 30px;
          .type-item {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 100px;
            border: 1px solid #ddd;
            cursor: pointer;
            border-radius: 5px;
            &:hover {
              border: 1px solid #8dc73f;
            }
            img {
              width: 20px;
              position: absolute;
              top: 10px;
              left: 10px;
              display: none;
            }
            &.active {
              border: 1px solid #8dc73f;
              color: #8dc73f;
              img {
                display: block;
              }
            }
          }
        }
      }
      .post-widget {
        .post-form {
          display: flex;
          padding: 10px;
          background: #f2f2f2;
          border: 1px solid #ddd;
          margin-top: 2px;
          border-radius: 5px;
          .profile {
            width: 40px;
            margin-right: 10px;
            .profile-image {
              width: 40px;
              height: 40px;
              object-fit: cover;
              border-radius: 50%;
            }
          }
          .form {
            width: calc(99% - 40px);
            display: flex;
            flex-direction: column;
            padding-top: 6px;
            position: relative;
            .post-input {
              .post-textarea {
                width: 100%;
                min-height: 100px;
                max-height: 300px;
                background: #f2f2f2;
                border: none;
                resize: none;
              }
            }
            .options {
              display: flex;
              justify-content: flex-end;
              .add-image {
                margin-right: 15px;
                border: none;
                background: transparent;
                img {
                  width: 25px;
                }
              }
              .add-video {
                border: none;
                background: transparent;
                img {
                  width: 25px;
                }
              }
            }
          }
        }
        .uploads-view {
          padding: 10px 0px;
          .uploading-list {
            display: grid;
            grid-template-columns: 23% 23% 23% 23%;
            grid-gap: 13px;
            @media screen and (max-width: 800px) {
              grid-template-columns: 32.333% 32.333% 32.333%;
            }
            .uploading-item {
              height: 100px;
              border-radius: 5px;
              border: 1px solid #ddd;
              padding: 9px;
              position: relative;
              &.error {
                border: 1px solid rgb(211, 4, 4);
              }
              &.success {
                border: 1px solid #8dc73f;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border: 1px solid #ddd;
                border-radius: 5px;
              }
              .progress-widget {
                position: absolute;
                right: 0px;
                bottom: 0px;
                left: 0px;
              }
              &.add-widget {
                border: 1px dashed #cfcfcf;
                .btn-add {
                  width: 100%;
                  font-size: 51px;
                  border: none;
                  background: #f2f2f2;
                  height: 100%;
                  border-radius: 5px;
                }
              }
              .btn-remove {
                position: absolute;
                bottom: 10px;
                left: 10px;
                padding: 4px;
                background: #8d38ee;
                color: #fff;
                border: none;
                font-size: 12px;
                border-radius: 3px;
              }
            }
          }
        }
        .uploading-video {
          padding: 10px 0px;
          .video-container {
            position: relative;
            width: 100%;
            .btn-remove {
              position: absolute;
              top: 10px;
              left: 10px;
              padding: 4px;
              background: #8d38ee;
              color: #fff;
              border: none;
              font-size: 12px;
              z-index: 99999;
              border-radius: 3px;
            }
            .progress-widget {
              position: absolute;
              right: 0px;
              bottom: -18px;
              left: 0px;
            }
            .video-widget {
              height: 204px;
              background: #000;
            }
          }
        }
      }
    }
    .footer {
      padding: 15px 15px;
      @media screen and (max-width: 800px) {
        border-top: 1px solid #ccc;
        height: 60px;
      }
      .post-option {
        display: flex;
        align-items: center;
        .btn-post {
          width: 70%;
          margin-right: 10px;
        }
        .btn-next {
          width: 100%;
        }
        .btn-back {
          width: calc(30% - 10px);
        }
      }
    }
  }
}
</style>

