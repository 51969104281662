import { render, staticRenderFns } from "./postMessageWidget.vue?vue&type=template&id=751333b5&scoped=true"
import script from "./postMessageWidget.vue?vue&type=script&lang=js"
export * from "./postMessageWidget.vue?vue&type=script&lang=js"
import style0 from "./postMessageWidget.vue?vue&type=style&index=0&id=751333b5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_react-dom@18.3.1_react@18.3.1_vue-temp_btlxz3i55afq2w4yuozfcdpdny/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "751333b5",
  null
  
)

export default component.exports