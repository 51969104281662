const getHostUrl = () => {
    let host = window.location.host;
    return host;
}
const isLocalHost = getHostUrl().startsWith("localhost");

const base_url = isLocalHost ? "http://127.0.0.1:8000/" : "https://api.cagura.rw/";
const node_base_url = isLocalHost ? "http://127.0.0.1:3000/" : "https://ws.cagura.rw/";

export const API_BASE_URL = base_url + 'api/v1';
export const API_NODE_BASE_URL = node_base_url + "api";
export const BASE_URL = base_url;
export const HLS_PROCESSOR_BASE_URL = "https://streaming.jahwin.xyz/"
export const GOOGLE_API_KEY = 'AIzaSyCSgy2lCrl7LrZoX5lvBDdP6ar0m6l6wb4';
export const GOOGLE_MAP_KEY = 'AIzaSyC4scenSTK8lTrQMFF1GwaB9JICHEras34';


/* ---------------------------------------------------------------
 * API LIST
 ----------------------------------------------------------------*/
// Auth api
export const VERIFY_ACCOUNT_URL = "/account/auth/verify";
export const VERIFY_EMAIL_FOR_RESET_URL = "/account/email/verify";
export const UPDATE_ACCOUNT_PASSWORD_URL = "/account/reset/password";
export const CREATE_ACCOUNT_URL = "/account/create";
export const ACCOUNT_LOGIN_URL = "/account/login";
export const ACCOUNT_GOOGLE_AUTH_URL = "/account/auth/google";
export const CONFIRM_GOOGLE_LOGIN_AUTH_URL = "/account/auth/login/google";
export const CONFIRM_GOOGLE_REGISTER_AUTH_URL = "/account/auth/register/google";
export const ACCOUNT_FACEBOOK_AUTH_URL = "/account/auth/facebook";
export const CONFIRM_FACEBOOK_LOGIN_AUTH_URL = "/account/auth/login/facebook";
export const CONFIRM_FACEBOOK_REGISTER_AUTH_URL = "/account/auth/register/facebook";
export const ACCOUNT_URL_CALLBACK_URL = "/account/auth/apps/url/callback";
export const VERIFY_TOKEN_URL = "/account/verify/token";
export const USER_UPDATE_INFO_URL = "/account/update";
export const RESENT_VERIFIY_ACCOUNT_EMAIL_URL = "/account/resend/verify/email";
export const VERIFY_ACCOUNT_CODE_URL = "/account/verify/code";
export const SKIP_WHATS_NEW_URL = "/account/skip/updates"
export const ACCEPT_AFFILIATE_PROGRAM_URL = "/account/affiliate/accept"
export const GET_AFFILIATE_LINKS_URL = "/account/affiliate/get/links";
export const AFFILIATE_COPY_LINK_URL = "/account/affiliate/copy/link";
export const AFFILIATE_GET_COPYED_LINKS_URL = "/account/affiliate/get/copied/links";
export const AFFILIATE_REDIRECT_TO_LINK_URL = "/account/affiliate/redirect/link";
export const CASHOUT_AFFILIATE_MONEY_URL = "/account/affiliate/cashout";
export const AFFILIATE_REWARD_LINK_OWNER_URL = "/account/affiliate/reward/owner";
export const GET_ALL_AFFILIATE_ADS_URL = "/account/affiliate/get/ads";
export const GET_FIXED_AFFILIATE_ADS_URL = "/account/affiliate/get/ads/fixed";
export const SAVE_USER_NOTIFICATION_CREDECTIALS_URL = "/account/save/notification/credectials"

// Upload api
export const UPLOAD_GET_ALL_FILES_URL = "upload/all-files";
export const UPLOAD_DELETE_FILE_URL = "upload/delete";
export const UPLOAD_SEARCH_FILE_URL = "upload/search";
export const UPLOAD_SEARCH_FILE_BY_TYPE_URL = "upload/search-by-type";
export const UPLOAD_UPDATE_FILE_URL = "upload/update-file";
export const UPLOAD_SAVE_UPLOADED_FILE_URL = "upload/save/filename";

// Shop api
export const SHOP_ADD_NEW_URL = "/shop/create";
export const SHOP_GET_MY_SHOP_URL = "/shop/mine";
export const SHOP_UPDATE_URL = "/shop/update";
export const SHOP_DELETE_URL = "/shop/delete";
export const SHOP_SEARCH_CATEGORIES_URL = "/shop/search/category";
export const SHOP_GET_ALL_CATEGORIES_URL = "/shop/all/category";
export const SHOP_GET_SINGLE_SHOP_URL = "/shop/item";
export const SHOP_SEND_MESSAGE_URL = "/shop/send/message";
export const SHOP_GET_ALL_URL = "/shop/all";
export const SHOP_GET_ALL_PRODUCT_URL = "/shop/item/products";
export const SHOP_GET_ALL_PRODUCT_CATEGORIES_URL = "/shop/products/categories";

// Product api
export const PRODUCT_ADD_NEW_URL = "/product/create";
export const PRODUCT_GET_MY_PRODUCT_URL = "/product/mine";
export const PRODUCT_GET_MY_CATEGORIES_URL = "/product/mine/categories";
export const PRODUCT_UPDATE_URL = "/product/update";
export const PRODUCT_DELETE_URL = "/product/delete";
export const PRODUCT_GET_ALL_CATEGORIES_URL = "/product/all/category";
export const PRODUCT_GET_ALL_PROPERTIES_VALUES_URL = "/product/all/properties/values";
export const PRODUCT_GET_SINGLE_PRODUCT_URL = "/product/item";
export const PRODUCT_ADDWISHLIST_URL = "/product/add/wishlist";
export const PRODUCT_SEND_ORDER_URL = "/product/send/order";
export const PRODUCT_SEND_REVIEW_URL = "/product/send/review";
export const PRODUCT_GET_PRODUCT_REVIEWS_URL = "/product/all/review";
export const PRODUCT_TOGGLE_LIKE_URL = "/product/toggle/like";
export const PRODUCT_TOGGLE_UNLIKE_URL = "/product/toggle/unlike";
export const PRODUCT_GET_MY_WISHLIST_URL = "/product/mine/wishlist";
export const PRODUCT_REMOVE_FROM_WISHLIST_URL = "/product/remove/wishlist";
export const PRODUCT_GET_DISCOUNT_PRODUCTS_URL = "/product/all/discount";
export const PRODUCT_GET_NEW_PRODUCTS_URL = "/product/all/new";
export const PRODUCT_GET_HISTORY_URL = "/product/mine/history";
export const PRODUCT_REMOVE_FROM_HISTORY_URL = "/product/remove/history";
export const PRODUCT_GET_SINGLE_ITEM_FOR_EDIT_URL = "/product/item/edit";
export const PRODUCT_GET_SPONSORED_URL = "/product/all/sponsored";
export const PRODUCT_GET_CATEGORIZED_URL = "/product/categorized";
export const PRODUCT_GET_CATEGORY_PRODUCTS_URL = "/category/products";
export const PRODUCT_GET_MAIN_CATEGORIES_URL = "/product/main/categories";

// Notification api
export const NOTIFICATION_GET_ALL_URL = "/notification/mine";
export const NOTIFICATION_MAKE_IT_SEEN_URL = "/notification/make/seen";
export const NOTIFICATION_REMOVE_ITEM_URL = "/notification/remove";
export const NOTIFICATION_MARK_ITEM_URL = "/notification/mark";
export const NOTIFICATION_CHECK_STATUS_URL = "/notification/check/status";
export const NOTIFICATION_GET_NOTIFICATION_ITEM_URL = "/notification/view/item";

// Other api
export const SEARCH_BASED_CATEGORY_URL = "/search/based/category";
export const SEARCH_BASED_QUERY_URL = "/search/based/query";
export const SAVE_SEARCH_TEXT_URL = "/search/save/text";
export const GET_SEARCH_TEXT_URL = "/search/get/text";
export const SEARCH_GET_HISTORY_URL = "/search/mine/history";
export const SEARCH_REMOVE_FROM_HISTORY_URL = "/search/remove/history";
export const SEND_CONTACT_MESSAGE_URL = "/send/contact/message"

// Movies api
export const GET_ALL_MOVIES_URL = "/movies/list/";
export const GET_MAIN_MOVIE_URL = "/movies/show/main";
export const GET_MOVIE_INFO_URL = "/movies/item/info";
export const GET_MOVIE_EPISODES_URL = "/movies/find/episodes";
export const GET_MOVIE_ITEM_TO_PLAY_URL = "/movie/play/item";
export const ADD_MOVIE_MYLIST_URL = "/movie/mylist/add";
export const GET_MYLIST_MOVIES_URL = "/movie/mylist";
export const SEARCH_MOVIES_URL = "/movies/search";
export const LIKE_MOVIE_URL = "/movie/like";
export const UNLIKE_MOVIE_URL = "/movie/unlike";
export const SAVE_MOVIE_WACTCHING_HISTORY_URL = "/movie/watching/history/save";
export const GET_WATCHING_HISTORY_URL = "/movie/watching/history";
export const REMOVE_WATCHING_HISTORY_URL = "/movie/watching/history/delete";
export const SUBMIT_MOVIE_FEEDBACK_URL = "/movie/save/feedback";
export const MOVIE_SUBSCRIBE_URL = "/movie/pay";
export const MOVIE_CHECK_PAYMENT_URL = "/movie/check/payment";
export const PAGE_SUBSCRIBE_URL = "/page/pay";
export const PAGE_CHECK_PAYMENT_URL = "/page/check/payment";
export const MOVIES_GET_ALL_CATEGORIZED_MOVIES_URL = "/movies/get/categorized";

// Live
export const STREAMING_GET_MAIN_CHANNEL_URL = "/streaming/get/main";
export const STREAMING_GET_ALL_CHANNEL_URL = "/streaming/get/all";

// Feeds
export const FEEDS_SAVE_POST_URL = "/feeds/posts/add";
export const FEEDS_GET_POST_URL = "/feeds/posts";
export const FEEDS_DELETE_POST_URL = "/feeds/posts/delete";
export const FEEDS_SUBMIT_POST_COMMENT_URL = "/feeds/post/comment/add";
export const FEEDS_GET_POST_COMMENTS_URL = "/feeds/post/comments";
export const FEEDS_GET_POST_COMMENT_REPLIES_URL = "/feeds/post/comment/replies";
export const FEEDS_DELETE_POST_COMMENT_URL = "/feeds/post/comment/delete";
export const FEEDS_LIKE_POST_URL = "/feeds/post/like";
export const FEEDS_LIKE_POST_COMMENT_URL = "/feeds/post/comment/like";
export const FEEDS_UPDATE_POST_URL = "/feeds/posts/update";
export const FEEDS_REPORT_POST_URL = "/feeds/posts/report";
export const FEEDS_CONTACT_POST_OWNER_URL = "/feeds/post/contact/owner";
export const FEEDS_GET_SINGLE_POST_URL = "/feeds/view/post";
export const FEEDS_GET_ALL_STORIES_URL = "/feeds/stories/all";
export const FEEDS_ALL_POPULAR_PRODUCTS_URL = "/feeds/products/pupular";
export const FEEDS_GET_POPULAR_SHOPS_URL = "/feeds/shops/pupular";
export const FEEDS_VALIDATE_POST_URL = "/feeds/validate/post";

// NODE api
export const NODE_GET_VAPID_URL = "/key/vapid";