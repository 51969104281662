<template>
  <div class="shops-popular-container">
    <hooper
      v-if="!shop_loading"
      :settings="hooperSettings"
      :itemsToSlide="3"
      :initialSlide="1"
    >
      <template v-for="(item, i) in shop_data">
        <slide :key="i">
          <div
            class="shops-item"
            @click="
              $router.push({
                path: $getShopLink(item),
              })
            "
          >
            <div class="shop-image">
              <img
                :src="
                  $store.state.ASSETS_BASE_URL +
                  '/assets/uploaded/' +
                  item.shop_logo
                "
              />
            </div>
            <div class="show-info">
              <h4>{{ item.shop_name }}</h4>
              <p>{{ item.location_name }}</p>
            </div>
          </div>
        </slide>
      </template>
      <hooper-navigation slot="hooper-addons"></hooper-navigation>
    </hooper>

    <div v-if="shop_loading" class="shop-loading-widget">
      <div class="shop-loading-body">
        <div
          class="shop-loading-item bubble"
          v-for="(item, index) in 3"
          :key="index"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
export default {
  name: "story-widget",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {
    return {
      hooperSettings: {
        infiniteScroll: true,
        centerMode: true,
        autoPlay: false,
        playSpeed: 5000,
        hoverPause: true,
        trimWhiteSpace: true,
        mouseDrag: false,
        touchDrag: false,
        wheelControl: false,
        breakpoints: {
          2400: {
            itemsToShow: 3,
          },
          1800: {
            itemsToShow: 3,
          },
          1500: {
            itemsToShow: 3,
          },
          1100: {
            itemsToShow: 3,
          },
          700: {
            itemsToShow: 3,
          },
          500: {
            itemsToShow: 3,
          },
          400: {
            itemsToShow: 2,
          },
          0: {
            itemsToShow: 2,
          },
        },
      },
      shop_data: [],
      shop_loading: false,
    };
  },
  mounted() {
    let vm = this;
    vm.getPopularShops();
  },
  methods: {
    getPopularShops() {
      let vm = this;
      vm.shop_loading = true;
      vm.$store
        .dispatch("FEEDS_GET_POPULAR_SHOPS")
        .then((response) => {
          vm.shop_loading = false;
          if (response.data.status == "ok") {
            vm.shop_data = response.data.return;
          }
        })
        .catch(({ response }) => {
          console.error("FEEDS_GET_POPULAR_SHOPS", { response });
        });
    },
  },
};
</script>

<style lang="scss" >
.shops-popular-container {
  @media screen and (max-width: 800px) {
    display: none;
  }
  .bubble {
    animation: bubble-animation 1s infinite;
    transition: 500 ease-in-out;
  }
  @keyframes bubble-animation {
    0% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  .shop-loading-widget {
    width: 100%;
    display: flex;
    .shop-loading-body {
      width: 100%;
      display: grid;
      grid-template-columns: 32.333% 32.333% 32.333%;
      grid-gap: 8px;
      .shop-loading-item {
        height: 280px;
        border: 1px solid #ccc;
        background: #ddd;
        border-radius: 5px;
      }
    }
  }
}
</style>

