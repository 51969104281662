var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fadeanim"}},[(_vm.upload_widget)?_c('section',{staticClass:"upload-overlay",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;_vm.upload_widget = false}}},[_c('div',{staticClass:"upload-window"},[_c('div',{staticClass:"upload-header"},[_c('div',{staticClass:"upload-ribbon"},[_c('span',{staticClass:"title"},[_c('i',{staticClass:"mdi mdi-file-document"}),_vm._v(" File manager ")]),_c('a',{staticClass:"upload-close-btn",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.upload_widget = false}}},[_vm._v("×")])])]),_c('div',{staticClass:"upload-body"},[_c('a-tabs',{attrs:{"defaultActiveKey":_vm.default_tab},on:{"change":_vm.onTabChange}},[_c('a-tab-pane',{key:"1"},[_c('span',{attrs:{"slot":"tab"},on:{"click":function($event){return _vm.playSound('upload')}},slot:"tab"},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v("UPLOAD ")],1),_c('div',{staticClass:"upload-container"},[_c('div',{staticClass:"upload-section"},[_c('div',{staticClass:"uploading-list"},[(_vm.files.length > 0)?_c('ul',_vm._l((_vm.files),function(file,index){return _c('li',{key:index,staticClass:"uploading-item",class:{ error: file.error, success: file.success }},[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":file.blob,"width":"50","height":"50"}})]),_c('span',{staticClass:"file-name"},[_vm._v(_vm._s(file.name))]),_c('div',{staticClass:"progress-widget"},[_c('a-progress',{attrs:{"percent":_vm.parseInteger(file.progress),"status":file.progress == 100 && file.success
                              ? 'success'
                              : 'active',"format":(percent) => `${parseInt(percent)}%`}})],1),_c('div',{staticClass:"status"},[(file.success)?_c('a-alert',{attrs:{"message":"Success","type":"success","show-icon":""}}):_vm._e(),(
                            file.progress == 0 && !file.success && !file.error
                          )?_c('a-alert',{attrs:{"message":"Pending","type":"info","show-icon":""}}):_vm._e(),(
                            file.progress > 0 && !file.success && !file.error
                          )?_c('a-alert',{attrs:{"message":"Uploading","type":"warning","show-icon":""}}):_vm._e(),(file.error)?_c('a-alert',{attrs:{"message":"Failed","type":"error","show-icon":""}}):_vm._e()],1),_c('div',{staticClass:"action"},[_c('button',{staticClass:"btn btn-default",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.removeFileFromUploadList(file)}}},[_vm._v(" Remove ")])])])}),0):_c('div',{staticClass:"widget-empty"},[_c('p',[_vm._v("No image selected, Click [Add image] button below")])])]),_c('div',{staticClass:"uploading-option"},[_c('button',{staticClass:"btn-add-file",attrs:{"type":"button"},on:{"click":function($event){return _vm.startUpload()}}},[_c('file-upload',{ref:"upload",attrs:{"input-id":"main-file-upload-input","name":"file","multiple":true,"put-action":"","custom-action":_vm.customAction,"headers":{
                        AccessKey:
                          '9de4212d-5d04-483a-93852d1a67c5-9bef-445e',
                      }},on:{"input-file":_vm.inputFile,"input-filter":_vm.inputFilter,"input":_vm.updatetValue},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v("Add image ")],1)],1),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$refs.upload || !_vm.$refs.upload.active),expression:"!$refs.upload || !$refs.upload.active"}],staticClass:"btn-start-upload-file",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();_vm.$refs.upload.active = true}}},[_vm._v(" Start upload ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.$refs.upload && _vm.$refs.upload.active),expression:"$refs.upload && $refs.upload.active"}],staticClass:"btn-stop-upload-file",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();_vm.$refs.upload.active = false}}},[_vm._v(" Stop upload ")])])])])]),_c('a-tab-pane',{key:"2"},[_c('span',{attrs:{"slot":"tab"},slot:"tab"},[_c('a-icon',{attrs:{"type":"hdd"}}),_vm._v("FILES ")],1),_c('div',{staticClass:"upload-container"},[_c('div',{staticClass:"uploade-list"},[_c('div',{staticClass:"ant-upload-list ant-upload-list-picture-card",on:{"scroll":_vm.onScollFileList}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},_vm._l((_vm.uploaded_files),function(file,index){return _c('a-tooltip',{key:index + 'uploaded_file'},[_c('template',{slot:"title"},[_vm._v(_vm._s(file.virtual_name))]),_c('div',{staticClass:"ant-upload-list-item ant-upload-list-item-done",class:{ active: file.active }},[(_vm.multiple)?_c('a-checkbox',{staticClass:"file-checkbox",on:{"change":function($event){return _vm.onSelectFile($event, file, index)}}}):_vm._e(),_c('div',{staticClass:"ant-upload-list-item-info",on:{"click":function($event){return _vm.viewFile(file)}}},[_c('span',[_c('div',{staticClass:"ant-upload-list-item-thumbnail"},[(
                                  file.ext == 'jpeg' ||
                                  file.ext == 'jpg' ||
                                  file.ext == 'png' ||
                                  file.ext == 'gif'
                                )?_c('img',{attrs:{"src":`${_vm.url + _vm.location}${file.file_name}`}}):_vm._e(),(
                                  file.ext == 'mp3' ||
                                  file.ext == 'ogg' ||
                                  file.ext == 'acc'
                                )?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/audio.png`}}):_vm._e(),(
                                  file.ext == 'mp4' ||
                                  file.ext == 'avi' ||
                                  file.ext == 'vob' ||
                                  file.ext == 'mov'
                                )?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/video.png`}}):_vm._e(),(file.ext == 'pdf')?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/pdf.png`}}):_vm._e(),(file.ext == 'docs' || file.ext == 'doc')?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/word.png`}}):_vm._e(),(
                                  file.ext == 'xls' ||
                                  file.ext == 'xlsx' ||
                                  file.ext == 'xlsm' ||
                                  file.ext == 'xlt' ||
                                  file.ext == 'xltx' ||
                                  file.ext == 'xltm' ||
                                  file.ext == 'xla' ||
                                  file.ext == 'xlam'
                                )?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/excel.png`}}):_vm._e()])])])],1)],2)}),1),(_vm.uploaded_files.length === 0)?_c('a-empty',{staticStyle:{"padding-top":"50px"}}):_vm._e()],1),_c('div',{staticClass:"file-list-option"},[_c('div',{staticClass:"select-widget"},[(_vm.selected_files.length !== 0 && _vm.multiple)?_c('c-button',{staticClass:"btn btn-success",attrs:{"loading":_vm.update_loading},on:{"click":function($event){return _vm.approveMultipleSelect()}}},[_vm._v(" SELECT "),_c('span',[_vm._v("("+_vm._s(_vm.selected_files.length)+")")])]):_vm._e(),(_vm.selected_file && !_vm.multiple)?_c('c-button',{staticClass:"btn btn-success",attrs:{"loading":_vm.update_loading},on:{"click":function($event){return _vm.approveSingleSelect()}}},[_vm._v("SELECT")]):_vm._e(),(_vm.selected_file && !_vm.multiple)?_c('a-popconfirm',{attrs:{"title":"Are you sure to delete?","okText":"Yes","cancelText":"No"},on:{"confirm":function($event){return _vm.DeleteFile(_vm.selected_file.id)}}},[_c('c-button',{staticClass:"btn-default",attrs:{"loading":_vm.update_loading}},[_vm._v("DELETE")])],1):_vm._e()],1),_c('a-input-search',{staticClass:"search-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Search file name","size":"large"},on:{"change":_vm.onSearch}})],1)]),_c('div',{staticClass:"file-details"},[(_vm.selected_file)?_c('div',{staticClass:"file-info"},[_c('div',{staticClass:"file-preview"},[(
                        _vm.selected_file.ext == 'jpeg' ||
                        _vm.selected_file.ext == 'jpg' ||
                        _vm.selected_file.ext == 'png' ||
                        _vm.selected_file.ext == 'JPG' ||
                        _vm.selected_file.ext == 'gif'
                      )?_c('img',{attrs:{"src":`${_vm.url + _vm.location}${_vm.selected_file.file_name}`}}):_vm._e(),(_vm.selected_file.ext == 'mp3')?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/audio.png`}}):_vm._e(),(_vm.selected_file.ext == 'mp4')?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/video.png`}}):_vm._e(),(_vm.selected_file.ext == 'pdf')?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/pdf.png`}}):_vm._e(),(_vm.selected_file.ext == 'docs')?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/word.png`}}):_vm._e(),(
                        _vm.selected_file.ext == 'xls' ||
                        _vm.selected_file.ext == 'xlsx' ||
                        _vm.selected_file.ext == 'xlsm' ||
                        _vm.selected_file.ext == 'xlt' ||
                        _vm.selected_file.ext == 'xltx' ||
                        _vm.selected_file.ext == 'xltm' ||
                        _vm.selected_file.ext == 'xla' ||
                        _vm.selected_file.ext == 'xlam'
                      )?_c('img',{attrs:{"src":`${_vm.url}assets/images/ext/excel.png`}}):_vm._e()]),_c('div',{staticClass:"file-metadata"},[_c('div',{staticClass:"file-name"},[_c('strong',[_vm._v("File name")]),_c('p',[_vm._v(_vm._s(_vm.selected_file.virtual_name))])]),_c('div',{staticClass:"file-size"},[_c('strong',[_vm._v("File size")]),_c('p',[_vm._v(_vm._s(_vm.BytesToSize(_vm.selected_file.size)))])])])]):_vm._e(),(!_vm.selected_file)?_c('div',{staticClass:"no-file-selected"},[_c('a-icon',{staticClass:"icon",attrs:{"type":"file"}}),_c('p',[_vm._v("No file selected")])],1):_vm._e(),_c('div',{staticClass:"select-widget"},[(_vm.selected_files.length !== 0 && _vm.multiple)?_c('c-button',{staticClass:"btn btn-success",attrs:{"loading":_vm.update_loading},on:{"click":function($event){return _vm.approveMultipleSelect()}}},[_vm._v(" SELECT "),_c('span',[_vm._v("("+_vm._s(_vm.selected_files.length)+")")])]):_vm._e(),(_vm.selected_file && !_vm.multiple)?_c('c-button',{staticClass:"btn btn-success",attrs:{"loading":_vm.update_loading},on:{"click":function($event){return _vm.approveSingleSelect()}}},[_vm._v("SELECT")]):_vm._e(),(_vm.selected_file && !_vm.multiple)?_c('a-popconfirm',{attrs:{"title":"Are you sure to delete?","okText":"Yes","cancelText":"No"},on:{"confirm":function($event){return _vm.DeleteFile(_vm.selected_file.id)}}},[_c('c-button',{staticClass:"btn-default",attrs:{"loading":_vm.update_loading}},[_vm._v("DELETE")])],1):_vm._e()],1)])])])],1)],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }