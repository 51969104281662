<template>
  <div class="help-wrapper">
    <button
      class="help-btn"
      @click="show_help_content = !show_help_content"
      @mouseover="showHelp()"
      @mouseleave="hideHelp()"
    >
      <img src="@/assets/images/icons/help-circle.svg" />
    </button>
    <div class="content" v-if="show_help_content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "help",
  components: {},
  data() {
    return {
      show_help_content: false,
    };
  },
  mounted() {},
  methods: {
    showHelp() {
      let vm = this;
      vm.show_help_content = true;
    },
    hideHelp() {
      let vm = this;
      vm.show_help_content = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.help-wrapper {
  position: relative;
  .help-btn {
    background: transparent;
    outline: none;
    border: none;
    img {
      width: 17px;
    }
  }
  .content {
    position: absolute;
    background: #fff;
    box-shadow: 0px 0px 7px #ccc;
    border-radius: 5px;
    padding: 10px;
    right: 0px;
    top: 26px;
    width: 250px;
  }
}
</style>

