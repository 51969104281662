var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cagura-wrapper"},[_c('client-header'),_c('div',{staticClass:"cagura-container"},[_c('side-bar'),_c('div',{staticClass:"cagura-body",class:{ nosidebar: !_vm.$store.state.sidebar }},[_c('vue-scroll',[_c('div',{staticClass:"cagura-history-widget"},[_c('div',{staticClass:"history-product"},[_c('div',{staticClass:"history-product-header"},[_c('div',{staticClass:"title"},[_c('h3',[_vm._v("VIEW HISTORY")])]),_c('div',{staticClass:"history-options"},[_c('div',{staticClass:"search-widget"},[_c('div',{staticClass:"search-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_history_input),expression:"search_history_input"}],attrs:{"type":"text","placeholder":"Search in History"},domProps:{"value":(_vm.search_history_input)},on:{"input":function($event){if($event.target.composing)return;_vm.search_history_input=$event.target.value}}}),_c('img',{attrs:{"src":require("@/assets/images/icons/search-grey.svg"),"alt":""}})])]),_c('div',{staticClass:"search-type-widget"},[_c('a-select',{staticClass:"input",staticStyle:{"width":"100%"},attrs:{"default-value":"product_view_history","size":"large"},model:{value:(_vm.history_type),callback:function ($$v) {_vm.history_type=$$v},expression:"history_type"}},[_c('a-select-option',{attrs:{"value":"product_view_history"}},[_vm._v("Product View history")]),_c('a-select-option',{attrs:{"value":"search_history"}},[_vm._v("Search history")])],1)],1)])]),(_vm.history_type == 'product_view_history')?_c('div',{staticClass:"product-items"},_vm._l((_vm.product_history),function(item,i){return _c('div',{key:i,staticClass:"product-item"},[_c('div',{staticClass:"product-image-widget",on:{"click":function($event){_vm.$router.push({ path: _vm.$getProductLink(item.product) })}}},[_c('img',{attrs:{"src":_vm.$store.state.ASSETS_BASE_URL +
                      '/assets/uploaded/' +
                      item.product.main_image}})]),_c('div',{staticClass:"product-content"},[_c('div',{staticClass:"header"},[_c('h2',[_vm._v(_vm._s(item.product.product_name))]),_c('dropdown-menu',{attrs:{"right":false,"hover":false,"interactive":false},model:{value:(item.is_menu_open),callback:function ($$v) {_vm.$set(item, "is_menu_open", $$v)},expression:"item.is_menu_open"}},[_c('button',{staticClass:"dropdown-toggle"},[_c('img',{attrs:{"src":require("@/assets/images/icons/menu.svg")}})]),_c('div',{staticClass:"drop-down-menu",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){$event.preventDefault();return _vm.removeProductFromHistory(item, i)}}},[_c('img',{attrs:{"src":require("@/assets/images/icons/trash.svg")}}),_vm._v(" Remove from History ")])])])],1),_c('div',{staticClass:"content",on:{"click":function($event){_vm.$router.push({ path: _vm.$getProductLink(item.product) })}}},[_c('p',[_vm._v(_vm._s(_vm.$cutText(item.product.description, 130)))]),_c('div',{staticClass:"product-options"},[(item.product.discount)?_c('div',{staticClass:"price",class:{ strikethrough: item.product.discount }},[_c('strike',[_vm._v("FRW "+_vm._s(_vm.$formatMoney(item.product.price)))])],1):_c('div',{staticClass:"price"},[_vm._v(" FRW "+_vm._s(_vm.$formatMoney(item.product.price))+" ")]),(item.product.discount)?_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s("FRW " + _vm.$formatMoney( item.product.price - (item.product.price * item.product.discount) / 100 ))+" ")]):_vm._e(),_c('div',{staticClass:"rate"},[_c('a-rate',{staticStyle:{"font-size":"14px"},attrs:{"value":item.product.rating,"disabled":false}})],1)]),_c('div',{staticClass:"product-option"},[_c('p',[_vm._v(" "+_vm._s(_vm.moment(item.created_at).format( "dddd, MMM Do YYYY, h:mm A" ))+" ")])])])])])}),0):_vm._e(),(
                _vm.history_type == 'product_view_history' &&
                _vm.product_history.length == 0 &&
                !_vm.is_loading
              )?_c('div',{staticClass:"empty-widget"},[_c('a-empty',{attrs:{"image":require('@/assets/images/icons/empty.svg'),"image-style":{ height: '116px' }}},[_c('span',{attrs:{"slot":"description"},slot:"description"},[_vm._v("No product history views available !!!")])])],1):_vm._e(),(_vm.is_loading)?_c('div',{staticClass:"loader-widget",staticStyle:{"height":"300px"}},[_c('div',{staticClass:"loading-panel"},[_c('a-spin',{attrs:{"spinning":true}}),_c('span',{staticClass:"text"},[_vm._v("Loading...")])],1)]):_vm._e(),(_vm.history_type == 'search_history')?_c('div',{staticClass:"search-items"},_vm._l((_vm.search_history),function(item,i){return _c('div',{key:i,staticClass:"search-item"},[_c('span',{staticClass:"text",on:{"click":function($event){return _vm.$router.push({ name: 'search', query: { q: item.text } })}}},[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"option"},[_c('dropdown-menu',{attrs:{"right":false,"hover":false,"interactive":false},model:{value:(item.is_menu_open),callback:function ($$v) {_vm.$set(item, "is_menu_open", $$v)},expression:"item.is_menu_open"}},[_c('button',{staticClass:"dropdown-toggle"},[_c('img',{attrs:{"src":require("@/assets/images/icons/menu.svg")}})]),_c('div',{staticClass:"drop-down-menu",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){$event.preventDefault();return _vm.removeSeachItemFromHistory(item, i)}}},[_c('img',{attrs:{"src":require("@/assets/images/icons/trash.svg")}}),_vm._v(" Remove from History ")])])])],1)])}),0):_vm._e()])])])],1)],1),(_vm.ask_for_account)?_c('ask-auth',{attrs:{"message":"to access to History"},on:{"onCancel":function($event){_vm.ask_for_account = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }