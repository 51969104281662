<template>
  <div class="option-item">
    <button
      class="btn-show-profile"
      ref="profile-action"
      @click="show_profile_dropdown = !show_profile_dropdown"
      v-if="$getLoggedUser()"
    >
      <img
        v-if="form.profile_image"
        :src="
          $store.state.ASSETS_BASE_URL +
          '/assets/uploaded/' +
          form.profile_image
        "
      />
      <img v-else src="@/assets/images/icons/empty_profile.svg" alt />
    </button>
    <button class="btn-sign-in" @click="login()" v-else><slot></slot></button>

    <div
      slot="dropdown"
      class="drop-down-menu"
      v-if="show_profile_dropdown"
      @click="show_profile_dropdown = false"
      v-closable="{ exclude: ['profile-action'], handler: 'hideDropdown' }"
    >
      <a
        class="dropdown-item"
        @click="$router.push({ name: 'notification' }).catch(() => {})"
      >
        <img src="@/assets/images/icons/ring.svg" /> Notification
      </a>
      <a class="dropdown-item" @click.prevent="show_profile_widget = true">
        <img src="@/assets/images/icons/user.svg" /> My Profile
      </a>
      <a class="dropdown-item" href="#" @click.prevent="signOut()">
        <img src="@/assets/images/icons/log-out.svg" /> Sign Out
      </a>
    </div>

    <!-- Profile Popup -->
    <div class="profile-widget" v-if="show_profile_widget">
      <div class="header" v-if="!show_profile_full_content">
        <h3 class="title">My profile</h3>
        <button class="btn-close" @click="show_profile_widget = false">
          <img src="@/assets/images/icons/close_grey.svg" />
        </button>
      </div>
      <div class="body">
        <vue-scroll>
          <div class="body-container">
            <div class="input-group">
              <div class="input-widget">
                <input
                  type="text"
                  class="bg-cagura-form-control"
                  name="first_name"
                  v-model="form.first_name"
                  placeholder="First name"
                />
              </div>
            </div>
            <div class="input-group">
              <div class="input-widget">
                <input
                  type="text"
                  class="bg-cagura-form-control"
                  name="last_name"
                  v-model="form.last_name"
                  placeholder="Last name"
                />
              </div>
            </div>
            <div class="input-group">
              <div class="input-widget">
                <input
                  type="text"
                  class="bg-cagura-form-control"
                  name="Phone"
                  v-model="form.phone_number"
                  placeholder="Phone number"
                />
              </div>
            </div>
            <div class="input-group">
              <div class="input-widget">
                <input
                  type="text"
                  class="bg-cagura-form-control"
                  name="Phone"
                  v-model="form.email"
                  placeholder="Email"
                />
              </div>
            </div>
            <div class="input-group">
              <label>Profile Image</label>
              <div class="input-widget">
                <upload-trigger
                  :open="false"
                  :enableinput="false"
                  :required="false"
                  :multiple="false"
                  location="/assets/uploaded/"
                  :url="$store.state.ASSETS_BASE_URL"
                  :uploadurl="$store.state.BASE_URL + 'upload'"
                  name="main-image"
                  :value="form.profile_image"
                  @onFileSelected="
                    (data) => {
                      form.profile_image = data;
                    }
                  "
                >
                  <div class="image-upload-widget">Choose Profile Image</div>
                </upload-trigger>
              </div>
            </div>
            <div class="options-widget">
              <c-button
                :loading="is_update_loading"
                @click="updateProfile()"
                class="btn-success"
                >SAVE</c-button
              >
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "profile",
  components: {},
  data() {
    return {
      show_add_product_modal: false,
      show_profile_widget: false,
      show_profile_full_content: false,
      show_profile_dropdown: false,
      form: {
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        profile_image: "",
      },
      is_update_loading: false,
    };
  },
  mounted() {
    let vm = this;
    if (vm.$getLoggedUser()) {
      let user = vm.$getLoggedUser().user;
      vm.form.first_name = user.first_name;
      vm.form.last_name = user.last_name;
      vm.form.phone_number = user.phone;
      vm.form.email = user.email;
      vm.form.profile_image = user.profile_image;
    }
    vm.$store.state.eventBus.$on("viewPostProfile", (e) => {
      vm.show_profile_widget = true;
    });
  },
  methods: {
    login() {
      let vm = this;
      vm.$setRedirect(vm.$route.path);
      vm.$router.push({ name: "login" }).catch(() => {});
    },
    showNotification() {
      let vm = this;
      vm.$store.state.show_notification = true;
    },
    updateProfile() {
      let vm = this;
      const rules = {
        first_name: {
          run: (value) => {
            if (value.length >= 2 && value.length <= 10) {
              return false;
            }
            return "First name must have 2 - 10 charcters";
          },
        },
        last_name: {
          run: (value) => {
            if (value.length >= 2 && value.length <= 10) {
              return false;
            }
            return "Last name must have 2 - 10 charcters";
          },
        },
        phone_number: {
          run: (value) => {
            if (value.length == 0) {
              return "Phone number can not be empty";
            } else {
              if (/^\d+$/.test(value)) {
                if (value.match(/\d/g).length === 10) {
                  return false;
                } else {
                  return "Phone number must be 10 digits";
                }
              } else {
                return "We don't accept any character on phone number, use only number";
              }
            }
          },
        },
      };
      let token = vm.$getLoggedUser().access_token;
      if (vm.$isFieldsValidated(vm.form, rules)) {
        vm.is_update_loading = true;
        vm.$store
          .dispatch("USER_UPDATE_INFO", vm.form)
          .then((response) => {
            vm.is_update_loading = false;
            if (response.data.status == "ok") {
              let user = {
                access_token: token,
                user: response.data.return,
              };
              vm.$saveLoginInfo(user);
              vm.$notify({
                group: "status",
                type: "success",
                title: "Important message",
                text: response.data.message,
              });
              vm.hideProfile();
              window.location.reload();
            }
          })
          .catch(({ response }) => {
            vm.is_update_loading = false;
            console.error("USER_UPDATE_INFO", { response });
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
          });
      }
    },
    signOut() {
      let vm = this;
      vm.$localStorage.remove("user_data");
      vm.$localStorage.remove("subscribed_notification");
      vm.$localStorage.remove("shop_data");
      vm.$localStorage.remove("product_data");
      vm.$router.push({ name: "home" }).catch(() => {});
      window.location.reload();
    },
    hideProfile() {
      let vm = this;
      vm.show_profile_widget = false;
    },
    hideDropdown() {
      let vm = this;
      vm.show_profile_dropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes babo {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.option-item {
  .btn-show-profile {
    background: transparent;
    border: none;
    height: 40px;
    padding: 0;
    img {
      vertical-align: middle;
      border-style: none;
      height: 35px;
      width: 35px;
      border: 3px solid #8cc73f;
      border-radius: 50%;
      @media screen and (max-width: 500px) {
        width: 30px;
        height: 30px;
      }
      @media screen and (max-width: 330px) {
        width: 28px;
        height: 28px;
      }
    }
  }
  .btn-sign-in {
    background: transparent;
    padding: 0px 17px;
    font-weight: 800;
    border: 1px solid #91cd43a8;
    height: 40px;
    border-radius: 4px;
    width: 92px;
    @media screen and (max-width: 500px) {
      height: 30px;
      font-size: 12px;
      width: 60px;
      padding: 0px 2px;
    }
    &:hover {
      border: 1px solid #8cc73f;
    }
  }
  .profile-widget {
    width: 400px;
    height: 600px;
    background: #fff;
    position: absolute;
    top: 48px;
    right: 0px;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 500px) {
      position: fixed;
      top: 68px;
      right: 0px;
      left: 0px;
      bottom: 0px;
      width: auto;
      height: auto;
    }
    .header {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 10px;
      background: #fbfbfb;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      .btn-back {
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
      .btn-close {
        border: none;
        background: transparent;
        img {
          width: 14px;
        }
      }
    }
    .body {
      height: calc(100% - 50px);

      .body-container {
        padding: 15px;
        .input-group {
          width: 100%;
          .input-widget {
            width: 100%;
            .uploader {
              width: 200px;
            }
          }
        }
        .options-widget {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
