<template>
  <!-- Side bar -->
  <transition name="slide-right-fade">
    <div
      class="cagura-sidebar-overlay"
      v-if="$store.getters.getSideBar"
      @click.self="hideSideBar()"
    >
      <div class="cagura-sidebar">
        <vue-scroll
          :ops="{
            scrollPanel: {
              scrollingX: false,
              scrollingY: true,
            },
          }"
        >
          <div class="menu-widget">
            <ul>
              <li :class="{ active: $isRouter('home') }">
                <a
                  @click.prevent="
                    $router.push({ name: 'home' }).catch(() => {})
                  "
                >
                  <img src="@/assets/images/icons/house.svg" alt /> Home
                </a>
              </li>
              <li :class="{ active: $isRouter('products') }">
                <a
                  @click.prevent="
                    $router.push({ name: 'products' }).catch(() => {})
                  "
                >
                  <img
                    width="25"
                    src="@/assets/images/icons/cosmetics.svg"
                    alt
                  />
                  Products
                  <!-- <span class="new-badge">New</span> -->
                </a>
              </li>
              <li class="divider"></li>
              <li :class="{ active: $isRouter('affiliate') }">
                <a
                  @click.prevent="
                    $router.push({ name: 'affiliate' }).catch(() => {})
                  "
                >
                  <img src="@/assets/images/icons/network-icon.svg" alt /> Be
                  affiliate
                </a>
              </li>

              <li class="menu-section">LIBRARY</li>
              <li :class="{ active: $isRouter('history') }">
                <a
                  @click.prevent="
                    $router.push({ name: 'history' }).catch(() => {})
                  "
                >
                  <img src="@/assets/images/icons/clock.svg" alt /> History
                </a>
              </li>
              <li :class="{ active: $isRouter('wishlist') }">
                <a
                  @click.prevent="
                    $router.push({ name: 'wishlist' }).catch(() => {})
                  "
                >
                  <img src="@/assets/images/icons/heart.svg" alt /> My wishlist
                </a>
              </li>
              <li :class="{ active: $isRouter('myproducts') }">
                <a
                  @click.prevent="
                    $router.push({ name: 'myproducts' }).catch(() => {})
                  "
                >
                  <img src="@/assets/images/icons/my_product.svg" alt /> My
                  products
                </a>
              </li>
              <li :class="{ active: $isRouter('myshop') }">
                <a
                  @click.prevent="
                    $router.push({ name: 'myshop' }).catch(() => {})
                  "
                >
                  <img src="@/assets/images/icons/store.svg" alt /> My shops
                </a>
              </li>
            </ul>
          </div>
          <div class="footer-widget">
            <div class="footer-links">
              <a
                :class="{ active: $isRouter('contact') }"
                @click.prevent="
                  $router.push({ name: 'contact' }).catch(() => {})
                "
                >Contact us</a
              >
              <a
                :class="{ active: $isRouter('about') }"
                @click.prevent="$router.push({ name: 'about' }).catch(() => {})"
                >About</a
              >

              <a
                :class="{ active: $isRouter('terms') }"
                @click.prevent="$router.push({ name: 'terms' }).catch(() => {})"
                >Terms & Conditions</a
              >
              <a
                :class="{ active: $isRouter('privacy') }"
                @click.prevent="
                  $router.push({ name: 'privacy' }).catch(() => {})
                "
                >Privacy Policy & Safety</a
              >
            </div>
            <p class="copyright">&copy; Copyright Cagura 2020</p>
          </div>
        </vue-scroll>
      </div>
    </div>
  </transition>
</template>

<script>
// @ is an alias to /src
export default {
  name: "sidebar",
  components: {},
  data() {
    return {
      show_submenu_widget: false,
    };
  },
  mounted() {
    let vm = this;
    if (
      vm.$isRouter("movies") ||
      vm.$isRouter("mylist") ||
      vm.$isRouter("movie-series") ||
      vm.$isRouter("movies-search")
    ) {
      vm.toggleMovieSubMenu();
    }
  },
  methods: {
    toggleMovieSubMenu() {
      let vm = this;
      vm.show_submenu_widget = !vm.show_submenu_widget;
    },
    hideSideBar() {
      let vm = this;
      vm.$store.commit("TOGGLE_SIDE_BAR", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-sidebar-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  padding-top: 68px;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 283px;
  border-right: 1px solid #ddd;
  @media screen and (max-width: 1040px) {
    width: 100%;
    background: rgba(0, 0, 0, 0.59);
  }
  .cagura-sidebar {
    width: 282px;
    height: 100%;
    background: #fff;
    .menu-widget {
      width: 100%;
      height: 100%;
      ul {
        padding-left: 0px;
        margin: 0px;
        list-style: none;
        li {
          a {
            padding: 15px 20px;
            display: flex;
            align-items: center;
            color: #515356;
            text-decoration: none;
            font-size: 13px;
            font-weight: 500;
            &:hover {
              color: #8dc73f;
            }
            img {
              margin-right: 10px;
            }
            .new-badge {
              background: #8dc73f;
              padding: 3px 8px;
              color: #fff;
              font-size: 11px;
              border-radius: 5px;
              margin-left: 14px;
            }
          }
          &.active {
            background: rgba(196, 196, 196, 0.2);
          }
          &.divider {
            border-top: 1px solid #f3f5f7;
          }
          &.menu-section {
            background: #f0f0f0;
            padding: 17px 13px;
            border-top: 1px solid #ddd;
          }
          .submenu-widget {
            background: #fff;
            padding-left: 33px;
            li {
              border-left: 2px solid #ddd;
              &.active {
                background: rgba(218, 218, 218, 0.4);
              }
            }
          }
        }
      }
    }
    .footer-widget {
      padding: 0px 20px;
      background: #fff;
      width: 282px;
      margin-top: 60px;
      .footer-links {
        a {
          text-decoration: none;
          color: #b2b2b2;
          margin-right: 15px;
          line-height: 0px;
          font-size: 12px;
          &:hover {
            color: rgb(103, 103, 103);
            transition: 300ms ease-in-out;
          }
          &.active {
            color: rgb(120, 119, 119);
            transition: 300ms ease-in-out;
          }
        }
      }
      .copyright {
        color: #9f9f9f;
        font-size: 13px;
        margin-top: 8px;
        margin-bottom: 3px;
      }
    }
  }
}
</style>
